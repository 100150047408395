import {FC, useEffect, useState} from 'react'
import {KTCard} from '../../../_theme/helpers'
import {NoMatchRecords} from '../components/NoMatchRecords'
import Pagination from '../components/Pagination'
import {UsersListHeader} from './components/userHeader/UsersListHeader'

import {UserItem} from './components/UserItem'
import {GetUsers} from './core/_requests'

export const UsersPage: FC = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      assignment: {
        id: 1,
        name: '',
      },
      roles: [
        {
          id: 1,
          name: '',
        },
      ],
      mobile_number: '',
      phone_number: '',
      experience: 0,
      certificates: '',
      competencies: '',
      associate_degree: '',
      bachelors_degree: '',
      masters_degree: '',
      alternative_email: '',
    },
  ])
  const [assignmentId, setAssignmentId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + limit
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (assignmentId != '') {
      queryParams += '&assignment_id=' + assignmentId
    }

    GetUsers(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setUsers(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, assignmentId])

  return (
    <KTCard>
      <UsersListHeader
        refreshPage={refreshPage}
        setWord={setSearchKeyClick}
        setAssignmentId={setAssignmentId}
      />

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0'>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead
                  className='fs-7 text-gray-400 text-uppercase '
                  style={{
                    position: 'sticky',
                    top: '-15px',
                    backgroundColor: '#fdfcfb',
                    width: '100%',
                  }}
                >
                  <tr>
                    <th></th>
                    <th className=''>AD SOYAD</th>
                    <th className=''>Görev</th>
                    <th className=''>Roller</th>
                    <th className='min-w-200px'>İLETİŞİM BİLGİ</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {users?.length < 1 ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    users.map((user) => {
                      return (
                        <>
                          <UserItem key={user.id} user={user} refreshPage={refreshPage} />
                        </>
                      )
                    })
                  )}
                </tbody>
              </table>
            )}
            <Pagination
              onPageChange={(page: number) => setCurrentPage(page)}
              totalCount={total}
              currentPage={currentPage}
              pageSize={limit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </KTCard>
  )
}
