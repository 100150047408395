import {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {CriticalInventoryItem} from './components/CriticalInventoryItem'

export const CriticalInventory: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [items, setItems] = useState<any>([])
  const [groupAssets, setGroupAssets] = useState<any>([])
  const [directorateAssets, setDirectorateAssets] = useState<any>([])
  const [criticalLevel, setCriticalLevel] = useState('')
  const [refStatus, setRefStatus] = useState('')
  const [viewId, setViewId] = useState('')
  const [currentpage3, setCurrentPage3] = useState(1)
  const [total3, setTotal3] = useState(1)
  const [loading3, setLoading3] = useState(false)

  const currentTableData3 = () => {
    let queryParams = '?page=' + currentpage3 + '&limit=' + 8
    if (viewId == '1314') {
      queryParams += '&view_filter_id=' + [1314, 1315, 1316]
    }
    if (viewId == '1310') {
      queryParams += '&view_filter_id=1310'
    }
    if (viewId == '1311') {
      queryParams += '&view_filter_id=1311'
    }
    if (viewId == '1312') {
      queryParams += '&view_filter_id=1312'
    }
    if (viewId == '1313') {
      queryParams += '&view_filter_id=1313'
    }
    GetReport('/optimate_solution_varlik_envanteri', queryParams).then(({data}) => {
      setTotal3(data?.meta?.total)
      setDirectorateAssets(data?.data)
      setLoading3(false)
    })
  }

  useEffect(() => {
    currentTableData3()
    setLoading3(true)
  }, [currentpage3])
  useEffect(() => {
    setCurrentPage3(1)
    currentTableData3()
  }, [viewId])
  const exportFile = () => {
    window.open(`${ExportLink}/optimate_solution_planlanan_denetimler?token=${loginUser.token}&criticality_level=${criticalLevel}`)
  }
  const exportFile2 = () => {
    window.open(`${ExportLink}/optimate_solution_acik_kapali_bulgu_sayilari?token=${loginUser.token}`)
  }
  const exportFile3 = () => {
    window.open(`${ExportLink}/optimate_solution_varlik_envanteri?token=${loginUser.token}&view_filter_id=${viewId != '1314' ? viewId : [1314, 1315, 1316]}`)
  }

  return (
    <>
      <div>
        <div className='row g-5'>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Varlık Envanterinin Seviye Dağılımı'} widgetId={'92'} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 mt-5 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_varlik_envanteri_check'} />
                <span className='fs-2 fw-bolder mt-3'>Varlık Envanteri</span>
                <div className='d-flex flex-wrap'>
                  <div className=' me-5'>
                    <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px' onChange={(e) => setViewId(e.target.value)}>
                      <option value=''>Tümü</option>
                      <option value={'1314'}>Kritik Sistemler</option>
                      <option value='1310'>Varlıkların Müdürlük Bazında Sayıları</option>
                      <option value='1311'>Varlıkların Grup Müdürlüğü Bazında Sayıları</option>
                      <option value='1312'>Varlıkların Direktörlük Bazında Sayıları</option>
                      <option value='1313'>Varlıkların Sıfır Varlığı Bulunan Birimler</option>
                    </select>
                  </div>
                  <Button className='me-5 h-40px pb-10' onClick={exportFile3}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading3 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>UNIT NAME</th>
                      <th className=''>LEVEL</th>
                      <th className=''>ASSET COUNT</th>
                      <th className=''>CRITICAL ASSET COUNT</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {directorateAssets?.length < 1 && !loading3 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      directorateAssets?.map((item: any) => {
                        return (
                          <>
                            <CriticalInventoryItem key={item?.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage3(page)} totalCount={total3} currentPage={currentpage3} pageSize={8} loading={loading3} />
            </div>
          </div>
        </div>
      </KTCard>
      {/* 
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setRequester={setRequester} setOpeningTime={setOpening_time} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal> */}
    </>
  )
}
