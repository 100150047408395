import React, {FC, useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import PaginationDown from '../../components/PaginationDown'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {getIssues} from '../core/_requests'
import {EditStatus} from './EditStatus'
import {SquareButton} from './SquareButton'
import {TargetItem} from './TargetItem'

type Props = {
  period: string
  assignedBy: string
  assignmentId?: string
  searchKeyClick: any
}
export const YetToStart: FC<Props> = ({period, assignedBy, searchKeyClick, assignmentId}) => {
  const [issues, setIssues] = useState([
    {
      id: 1,
      title: '',
      content: '',
      start_date: '',
      end_date: '',
      phone_notification: false,
      email_notification: false,
      comments: [],
      category: {
        id: 1,
        name: '',
      },
      status: {
        id: '0',
        name: 'Planlanan',
      },
      assigned: {
        id: 1,
        name: '',
        email: '',
      },
    },
  ])
  const {refresh} = useRefresh1()
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const LoadMore = () => {
    setLoading(true)
    const nextPage = currentPage + 1
    currentTableData(nextPage)
  }

  const currentTableData = async (page: any) => {
    if (page > 0) {
      let queryParams = '?page[number]=' + page + '&page[size]=5' + '&status=0'

      if (period !== '') {
        queryParams += '&period=' + period
      }

      if (searchKeyClick !== '') {
        queryParams += '&q=' + searchKeyClick
      }

      if (assignedBy !== '') {
        queryParams += '&assigned_by=' + assignedBy
      }

      if (assignmentId !== '') {
        queryParams += '&assignment_id=' + assignmentId
      }
      await getIssues(queryParams)
        .then((res) => {
          setIssues((prev: any) => [...prev, ...res?.data?.data])
          setTotal(res?.data?.meta?.total)
          setCurrentPage(page)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    const resetAndFetchData = async () => {
      setIssues([])
      setLoading(true)
      await currentTableData(1)
    }

    resetAndFetchData()
  }, [period, assignedBy, searchKeyClick, refresh, assignmentId])

  return (
    <div className='col-md-4 col-lg-12 col-xl-4'>
      <div className='mb-9 bg-primary rounded p-1'>
        <div className='d-flex justify-content-between m-2'>
          <div className='fw-bolder fs-4 text-white '>Planlanan</div>
          <div className='badge badge-circle bg-light'>
            <span className='fs-6 text-primary  p-2'>{issues?.length}</span>
          </div>
        </div>
      </div>
      {issues?.length < 1 && !loading ? (
        <NoMatchRecords isTable={false} />
      ) : (
        issues.map((issue) => {
          return (
            <TargetItem
              key={issue.id}
              issue={issue}
              backgroundColor={'#f7faff'}
              commentColor={'primary'}
            />
          )
        })
      )}
      {total > issues?.length && (
        <Row className='mt-4'>
          <Col>
            <PaginationDown isLoading={loading} onClickHandle={LoadMore} />
          </Col>
        </Row>
      )}
    </div>
  )
}
