import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}
export const TableBody: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.id}</td>
        <td className='text-gray-500'>{item?.name}</td>
        <td className='text-gray-500'>{item?.devices}</td>
        <td className='text-gray-500'>{item?.total}</td>
        <td className='text-gray-500'>{item?.created_by?.name}</td>
        <td className='text-gray-500'>{item?.added_date}</td>
        <td className='text-gray-500'>{item?.modified_date}</td>
        <td className='text-gray-500'>{item?.removed_date}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
