import React, {FC, useEffect, useState} from 'react'
import { getNotificationsByType } from '../../../core/requests'
import {IssuesLists} from './components/TargetLists'
import { TimelineItem } from './components/TimelineItem'

export const Updates = () => {
  let data = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [elements, setElement] = useState<any>([])
  const [refresh,setRefresh ] = useState<any>(true)

  useEffect(()=>{
    getNotificationsByType('Project',data).then((data)=>{
      setElement(data.data.data)
    }).catch((err)=>{
      console.log(err)
    })
  },[])
  return (
    <>
           <div className='card-body'>
          <div className='tab-content'>
            <div
              id='kt_activity_today'
              className='card-body p-0 tab-pane fade show active'
              role='tabpanel'
              aria-labelledby='kt_activity_today_tab'
            >
              <div className='timeline'>
              <TimelineItem elements={elements} />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
