import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport} from '../core/request'
import {SymentecDetailFilter} from './components/DetailFilterSymentic'
import {SymenticItem} from './components/SymenticItem'

export const SymantecStatistics: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/endpoint_protection_data_symantec?token=${loginUser.token}`)
  }
  const [items, setItems] = useState([])
  const [createdAt, setCreatedAt] = useState('')
  const [protectionName, setProtectionName] = useState('')
  const [serverIp, setServerIp] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setCreatedAt('')
    setProtectionName('')
    setServerIp('')
  }
  const closeFilterModal = () => setFilterModalShow(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (serverIp != '') {
      queryParams += '&serverIP=' + serverIp
    }
    if (protectionName != '') {
      queryParams += '&protectionName=' + protectionName
    }
    if (createdAt != '') {
      queryParams += '&created_at=' + createdAt
    }

    GetReport('/endpoint_protection_data_symantec', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [serverIp, protectionName, createdAt])
  return (
    <div>
      <KTCard>
        <div className='row g-5 '>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 '>
            <BarChart horizentalTitle={'Number of Protection Name'} plusOrMinus={true} widgetId='78' linkToPage={''} />
          </div>
        </div>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/endpoint_protection_data_symantec_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div></div>
            <div className='card-body pt-3 overflow-auto'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>Risk Count</th>
                      <th className=''>Protection Name</th>
                      <th className=''>Protection Enabled Client Count</th>
                      <th className=''>Cron Server IP</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <SymenticItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <SymentecDetailFilter setProtectionName={setProtectionName} setServerIp={setServerIp} setCreatedAt={setCreatedAt} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
