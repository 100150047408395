import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import swal from 'sweetalert'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import * as Yup from 'yup'
import {CreateCase, UpdateCase} from '../core/_request'
import {getAssignments, getCSRFToken} from '../../core/requests'
import {UserTags} from '../../components/UserTags'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  caseProp: any
  handleClose: any
  update: boolean
}
const caseSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  start_date: Yup.date()
    .max(new Date(Date.now() + 86400), 'İleri Tarih Girilemez')
    .required('Zorunlu alan'),
  end_date: Yup.date().when('start_date', (start_date, yup) => start_date && yup.min(new Date(start_date - 86400000), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')),
  // .required('Zorunlu alan'),
  system_resource: Yup.string().required('Zorunlu alan'),
  affected_side: Yup.string().required('Zorunlu alan'),
  detail: Yup.string().required('Zorunlu alan'),
  assignment_id: Yup.string().required('Zorunlu alan'),
  status: Yup.string().required('Zorunlu alan'),
})

const updateCaseSchema = Yup.object().shape({
  start_date: Yup.date().max(new Date(Date.now()), 'İleri Tarih Girilemez'),
  end_date: Yup.date()
    // .when(
    //   'start_date',
    //   (start_date, yup) =>
    //     start_date &&
    //     yup.min(
    //       new Date(start_date - 86400000),
    //       'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz'
    //     )
    // ),
    .max(new Date(Date.now()), 'İleri Tarih Girilemez'),
})

export const AddCase: FC<Props> = ({caseProp, handleClose, update}) => {
  const [users, setUsers] = useState<any>([])
  const [assignments, setAssignments] = useState<any>([])
  const {refreshPage} = useRefresh1()
  const getUser = useCallback(
    (selectRole) => {
      setUsers([...users, selectRole])
    },
    [users]
  )
  const deleteUser = useCallback(
    (tagIndex) => {
      setUsers(users.filter((_: any, i: any) => i !== tagIndex))
    },
    [users]
  )

  useEffect(() => {
    getAssignments('?is_directorate=1').then(({data}) => {
      setAssignments(data.data)
    })

    setUsers(caseProp?.action_users)
  }, [])

  const [userForEdit] = useState<any>({
    ...caseProp,
    id: caseProp.id,
    title: caseProp?.title,
    start_date: caseProp?.start_date,
    end_date: caseProp?.end_date,
    assignment_id: caseProp?.assignment.id,
    system_resource: caseProp?.system_resource,
    affected_side: caseProp?.affected_side,
    detail: caseProp?.detail,
    actions: caseProp?.actions == undefined ? caseProp?.actions : '',
    status: caseProp?.status.id,
    users: caseProp?.action_users,
  })

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: update ? updateCaseSchema : caseSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          let selectedUsers = []
          for (let i = 0; i < users?.length; i++) {
            selectedUsers[i] = users[i].id
          }
          const UpdCase = {
            title: values.title,
            start_date: values.start_date,
            end_date: values.end_date,
            assignment_id: values.assignment_id,
            system_resource: values.system_resource,
            affected_side: values.affected_side,
            detail: values.detail,
            actions: values.actions,
            status: values.status,
            users: selectedUsers,
          }
          await UpdateCase(caseProp.id, UpdCase)
            .then(() => {
              swal('Düzenlendi', 'Case başarı ile düzenlendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        } else {
          formData.append('title', values.title)
          formData.append('start_date', values.start_date)
          formData.append('end_date', values.end_date)
          formData.append('assignment_id', values.assignment_id)
          formData.append('system_resource', values.system_resource)
          formData.append('affected_side', values.affected_side)
          formData.append('detail', values.detail)
          formData.append('actions', values.actions)
          formData.append('status', values.status)

          for (let i = 0; i < users?.length; i++) {
            formData.append('users[' + i + ']', users[i].id)
            //console.log(base64);
          }
          await CreateCase(formData)
            .then(() => {
              swal('Eklendi', 'Case başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Olayın Kısa Tanımı</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç Tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='datetime-local'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='datetime-local'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mt-2'>Sorumlu Ekip / Kişi</label>
            <select
              id='assignment_id'
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Sorumlu Ekip Seç'
              {...formik.getFieldProps('assignment_id')}
              name='assignment_id'
              // defaultValue={announcement.announcement_category_id}
            >
              <option hidden>Sorumlu Ekip Seç</option>

              {assignments.map((assignment: any) => {
                return (
                  <option key={assignment.id} value={assignment.id}>
                    {assignment.alternative_name}
                  </option>
                )
              })}
            </select>

            {formik.touched.assignment_id && formik.errors.assignment_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assignment_id}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7'>Aksiyon Sahibi</label>
            <div className={`form-control form-control-solid mt-1`}>
              <UserTags setRoles={getUser} delRole={deleteUser} tags1={caseProp.action_users} />
            </div>
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Olay Yaşanan Sistem Kaynağı</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('system_resource')}
              type='text'
              name='system_resource'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.system_resource && formik.errors.system_resource},
                {
                  'is-valid': formik.touched.system_resource && !formik.errors.system_resource,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.system_resource && formik.errors.system_resource && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.system_resource}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Olaydan etkilenen servis ve müşteri bilgisi</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('affected_side')}
              type='text'
              name='affected_side'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.affected_side && formik.errors.affected_side},
                {
                  'is-valid': formik.touched.affected_side && !formik.errors.affected_side,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.affected_side && formik.errors.affected_side && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.affected_side}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5 required'>Olay nedeni ve detayı</label>
            {/* end::Label */}
            <textarea placeholder='Giriniz' {...formik.getFieldProps('detail')} name='detail' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {formik.touched.detail && formik.errors.detail && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.detail}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>İyileştirme önerileri/aksiyonları</label>
            {/* end::Label */}
            <textarea placeholder='Giriniz' {...formik.getFieldProps('actions')} name='actions' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {formik.touched.actions && formik.errors.actions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.actions}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mt-2'>Durum</label>
            <select
              id='status'
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Durum Seç'
              {...formik.getFieldProps('status')}
              name='status'
              // defaultValue={announcement.announcement_category_id}
            >
              <option hidden>Durum Seç</option>
              <option value='0'> Devam Ediyor</option>
              <option value='1'>Tamamlandı</option>
              <option value='2'>Bilgi Bekleniyor</option>
              <option value='3'>Askıya Alındı</option>
              <option value='4'>İptal</option>
            </select>

            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.status}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              //disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button type='submit' className='btn ' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched || users?.length < 1}>
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
