import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {TableBody} from './components/TableBody'
import {Add} from './add/Add'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const FileIntegrityMonitoring: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [items, setItems] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + 20
    GetReport('/server_change_data', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [show])

  const exportFile = () => {
    window.open(`${ExportLink}/server_change_data?token=${loginUser.token}`)
  }

  return (
    <>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div>
            <div className='row g-5 '>
              <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden'>
                <BarChart horizentalTitle={'İşletim Sistemlerine Göre'} plusOrMinus={true} widgetId='80' linkToPage={''} />
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/server_change_data_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase'>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>ID</th>
                      <th className=''>NAME</th>
                      <th className=''>DEVICES</th>
                      <th className=''>TOTAL</th>
                      <th className=''>CREATED BY</th>
                      <th className=''>ADDED</th>
                      <th className=''>MODIFIED</th>
                      <th className=''>REMOVED</th>

                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <TableBody key={item?.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <Add handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
