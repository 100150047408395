
import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_theme/helpers'



const API_URL = process.env.REACT_APP_API_URL;
const ACTIVITIES = `${API_URL}/activities`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};
export async function getAnnoun() {
  return await axios.get<any>(API_URL+'/announcements?page[size]=5&is_slider=1');
}
const getActivities = (): Promise<any> => {
  return axios
    .get(`${ACTIVITIES}`)
    .then((d: AxiosResponse<any>) => d.data)
}




export {getActivities}
