import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const PROJECT_LIST = `${API_URL}/projects`
export const PROJECT_TYPE = `${API_URL}/project_types`
export const PROJECT_CLASS = `${API_URL}/project_classes`
export const PROJECT_CAT = `${API_URL}/project_categories`
export const GET_ASSIGN = `${API_URL}/assignments`
export const CREATE_PROJECT = `${API_URL}/projects`
const TARGET_CATEGORIES = `${API_URL}/target_categories`
const TARGET = `${API_URL}/targets`
const MILESTONES = `${API_URL}/milestones`
const TASK = `${API_URL}/tasks`
const DOCUMENT_CATEGORIES = `${API_URL}/document_categories`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function getProjects(status: any, link: string) {
  return await axios.get<any>(PROJECT_LIST + status + link)
}
export async function getFilteredProjects(link: string) {
  return await axios.get<any>(PROJECT_LIST + link)
}
export async function getProject(id: any) {
  return await axios.get<any>(PROJECT_LIST + '/' + id)
}
export async function getAssignments() {
  return await axios.get<any>(GET_ASSIGN)
}
export async function getProjectType() {
  return await axios.get<any>(PROJECT_TYPE)
}
export async function getProjectClass() {
  return await axios.get<any>(PROJECT_CLASS)
}
export async function getProjectCat() {
  return await axios.get<any>(PROJECT_CAT)
}
export async function getProjectPlans() {
  return await axios.get<any>(API_URL + `/project_plans`)
}
export async function createProject(form: any) {
  return await axios.post<any>(CREATE_PROJECT, form)
}
export async function getProjectsByClass(class_id: string, status_id: string, assignment_id: any) {
  return await axios.get<any>(
    PROJECT_LIST +
      '?project_class_id=' +
      class_id +
      '&status=' +
      status_id +
      '&directorate_id=' +
      assignment_id
  )
}

export async function DeleteProject(id: any) {
  return await axios.delete(PROJECT_LIST + '/' + id).then(() => {})
}

export async function GetTargetDate(project_id: any) {
  return await axios.get(TASK + '?milestone_id=' + project_id)
}

export async function updateProject(project_id: any, body: any) {
  return await axios.put(PROJECT_LIST + '/' + project_id, body)
}

// documents

export async function getProjectDocuments(project_id: any, q: string) {
  return await axios.get<any>(PROJECT_LIST + '/' + project_id + '/attachments' + q)
}

export async function createProjectDocument(project_id: any, files: any) {
  return await axios.post<any>(PROJECT_LIST + '/' + project_id + '/attachments', files, config)
}

export async function deleteProjectDocument(project_id: any, file_id: any) {
  return await axios.delete<any>(PROJECT_LIST + '/' + project_id + '/attachments/' + file_id)
}

export async function getDocumentCategories() {
  return await axios.get<any>(DOCUMENT_CATEGORIES)
}

//users

export async function getProjectUsers(project_id: any) {
  return await axios.get<any>(PROJECT_LIST + '/' + project_id + '/users')
}

export async function UpdateProjectUsers(project_id: any, users: any) {
  return await axios.post(PROJECT_LIST + '/' + project_id + '/users', users)
}

// target

export async function CreateMilestone(milestone: any) {
  return await axios.post(MILESTONES, milestone, config)
}
export async function updateMilestone(milestone_id: any, body: any) {
  return await axios.put(MILESTONES + '/' + milestone_id, body)
}
export async function GetMilestone(milestone_id: any) {
  return await axios.get(MILESTONES + '?project_id=' + milestone_id)
}
export async function deleteMilestone(milestone_id: any) {
  return await axios.delete(MILESTONES + '/' + milestone_id)
}
//task
export async function CreateTask(task: any) {
  return await axios.post(TASK, task)
}
export async function updateTask(task_id: any, body: any) {
  return await axios.put(TASK + '/' + task_id, body)
}
export async function GetTask(task: any) {
  return await axios.get(TASK + '?milestone_id=' + task)
}
export async function deleteTask(task_id: any) {
  return await axios.delete(TASK + '/' + task_id)
}

export async function getTargetCategories() {
  return await axios.get(TARGET_CATEGORIES)
}

export async function CreateTarget(target: any) {
  return await axios.post(TARGET, target, config)
}

export async function GetTarget(project_id: any) {
  return await axios.get(TASK + '?project_id=' + project_id)
}

export async function deleteTarget(target_id: any) {
  return await axios.delete(TARGET + '/' + target_id)
}
export async function updateTarget(target_id: any, body: any) {
  return await axios.put(TARGET + '/' + target_id, body)
}

export async function CreateComment(comment: any, id: any) {
  return axios.post(TARGET + '/' + id + '/comments', comment)
  //     .then((response: AxiosResponse<Response<CommentModel>>) => response.data)
  //     .then((response: Response<CommentModel>) => response.data)
  //
}

//budgets

export async function getBudgets() {
  return await axios.get<any>(API_URL + '/budgets')
}

//Project widgets

export async function getTargetStatusWidgets(id: string) {
  return await axios.get(API_URL + '/widgets/targets/status/' + id)
}

//project issues
export async function getProcectIssues(id: string) {
  return await axios.get(PROJECT_LIST + '/' + id + '/issues')
}
