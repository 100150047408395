import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {CUItem} from './components/CUItem'
import {DetailFilters} from './components/DetailFilter'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {ChartsWidget4} from '../components/ChartsWidget4'

export const CU: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [datettime, setDatetime] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setDatetime('')
    setDeviceId('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (datettime != '') {
      queryParams += '&datetime=' + datettime
    }
    if (deviceId != '') {
      queryParams += '&device_id=' + deviceId
    }
    GetReport('/device_utilization_availabilities', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [datettime, deviceId])

  const exportFile = () => {
    window.open(`${ExportLink}/device_utilization_availabilities?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
            <ChartsWidget4 horizentalTitle={'Cihaz Availability Durumu'} widgetId={'79'} plusOrMinus={true} linkToPage={''} />
          </div>
          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/device_utilization_availabilities_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-3'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>datetime</th>
                      <th className=''>datetime_str</th>
                      <th className=''>coverage</th>
                      <th className=''>coverage_rate</th>
                      <th className=''>device_id</th>
                      <th className=''>cron_server_ip</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <CUItem key={item?.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setDeviceId={setDeviceId} setDatetime={setDatetime} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
