import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport, GetUpdatedReport} from '../core/request'
import {CarbonDetailFilter} from './components/DetailFilterCarbon'
import {CarbonBlackItem} from './components/CarbonBlackItem'
import Swal from 'sweetalert2'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {CarbonStatisticWidget} from '../../components/widgets/OperationalData/CarbonStatisticWidget'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

export const CarbonBlackStatistics: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/endpoint_protection_data?token=${loginUser.token}`)
  }
  const {refreshPage} = useRefresh1()
  const [items, setItems] = useState([])
  const [createdAt, setCreatedAt] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setCreatedAt('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const checkServis = () => {
    GetUpdatedReport('/check/carbonblack').then((data) => {
      GetReport('/endpoint_protection_data', '').then(async () => {
        await refreshPage()
        Swal.fire(`#${data.status}`, data.data.message, `${data.status == 200 ? 'success' : 'error'}`)
      })
    })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (createdAt != '') {
      queryParams += '&created_at=' + createdAt
    }

    GetReport('/endpoint_protection_data', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [createdAt])
  return (
    <div>
      <KTCard>
        {/* <div className='row g-5 '>
         <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 '>
           {/* <PieChart name={'Endpoint Protection Data Daily'} link={''} widgetId={'43'} plusOrMinus={true} linkToPage={''} /> */}
        {/* <BarChart  horizentalTitle={'Endpoint Protection Data Daily'} plusOrMinus={true} widgetId='43' linkToPage={''}  />
         </div>
         <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
           {/* <PieChart name={'Endpoint Protection Data Daily'} link={''} widgetId={'44'} plusOrMinus={true} linkToPage={''} /> */}
        {/* </div>
  
       </div> */}
        <div className='col-xl-4 mb-xl-5'>
          <CarbonStatisticWidget plusOrMinus={true} widgetId={82} linkToPage={''} />
        </div>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/endpoint_protection_data_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-1' onClick={checkServis}>
                    Servisi güncelle
                  </Button>
                  <Button className='btn mt-3 mx-4' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>

            <div className='card-body pt-3 overflow-auto'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>active_sensor_count</th>
                      <th className=''>num_eventlog_bytes</th>
                      <th className=''>num_storefiles_bytes</th>
                      <th className=''>sensor_count</th>
                      <th className=''>created_at</th>
                      {/* <th className=''>updated_at</th>
                    <th className=''>deleted_at</th> */}
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <CarbonBlackItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <CarbonDetailFilter setCreatedAt={setCreatedAt} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
