import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import swal from 'sweetalert'
import {KTSVG} from '../../../../_theme/helpers'
import {ModalHeader} from '../../components/ModalHeader'
import {AddPurchaseSteps} from '../Budget-edit/AddPurchaseStep'
import {DeletePurchaseStep} from '../core/_requests'

type Props = {
  refreshPage: any
  purchaseStep: any
}

const deletePurchaseStep = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeletePurchaseStep(id).then(() => {
        swal('Silindi!', 'Bütçe başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const PurchaseStep: FC<Props> = ({refreshPage, purchaseStep}) => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(false)
    refreshPage()
  }
  const handleShow = () => {
    setShowModal(true)
  }
  return (
    <>
      <tr>
        <td className='text-gray-500'>{purchaseStep?.sgd_status}</td>
        <td className='text-gray-500'>{purchaseStep?.purchase_status == 0 ? 'SAT Onayla' : purchaseStep?.purchase_status == 1 ? ' SAT Onaylandı' : 'PO Onaylandı'}</td>
        <td className='text-gray-500'>{purchaseStep?.sgd_request_status}</td>
        <td className='text-gray-500'>{purchaseStep?.description}</td>
        <td className='min-w-200px text-end'>
          <Button className='me-3' onClick={handleShow}>
            Düzenle
          </Button>
          <Button
            onClick={() => {
              deletePurchaseStep(purchaseStep?.id, refreshPage)
            }}
          >
            {' '}
            Sil{' '}
          </Button>
        </td>
      </tr>
      <Modal show={showModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddPurchaseSteps handleClose={handleClose} purchaseSteps={purchaseStep} update={true} />
        </Modal.Body>
      </Modal>
    </>
  )
}
