import { FC } from "react"
import { KTCard } from "../../../_theme/helpers"
import { BudgetsList } from "./components/BudgetsList"

export const Budgets : FC=()=>{
    return (
        <KTCard>
        <BudgetsList />
      </KTCard>
    )
}