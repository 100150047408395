import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {MirroringItem} from './components/MirroringItem'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {DetailFilters} from './components/DetailFilter'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const Mirroring: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [items, setItems] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setRequester('')
    setSearchKeyClick('')
    setOpening_time('')
    setGrafDistance('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [requester, setRequester] = useState('')
  const [opening_time, setOpening_time] = useState('')
  const [grafDistance, setGrafDistance] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (requester != '') {
      queryParams += '&requester=' + requester
    }
    if (opening_time != '') {
      queryParams += '&openin_time=' + opening_time
    }
    GetReport('/mirroring', queryParams + grafDistance).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, requester, opening_time, grafDistance])

  const exportFile = () => {
    window.open(`${ExportLink}/mirroring/?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Aylık Grafik'} link={''} widgetId={'35'} plusOrMinus={true} linkToPage={''} setSelectedValue={setGrafDistance} />
          </div>
          <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Haftalık Grafik'} widgetId={'36'} plusOrMinus={true} linkToPage={''} />
          </div>
          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/mirroring_check'} />

                <div>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>KAYIT NUMARASI</th>
                      <th className=''>KATALOG ADI</th>
                      <th className=''>KATALOG ID</th>
                      <th className=''>KAYIT DURUMU</th>
                      <th className=''>FAZ BİLGİSİ</th>
                      {/* <th className=''>ONAY DURUMU</th> */}
                      <th className=''>AÇILMA TARİHİ</th>
                      <th className=''>ONAY TARİHİ</th>
                      <th className=''>ATAMA TARİHİ</th>
                      <th className=''>K.A KULLANICI</th>
                      <th className=''>K.A DEPARTMAN</th>
                      <th className=''>K.A DİREKTÖRLÜK</th>
                      <th className=''>ATANAN KULLANICI</th>
                      <th className=''>ATANAN DEPARTMAN</th>
                      <th className=''>ATANAN DİREKTÖRLÜK</th>
                      <th className=''>TEKNOLOJİ</th>
                      <th className=''>ETKİ</th>
                      <th className=''>ACİLİYET</th>
                      <th className=''>ÖNCELİK</th>
                      <th className=''>BAŞLIK</th>
                      <th className=''>AÇIKLAMA</th>
                      {/* <th className=''></th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <MirroringItem key={item?.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>

      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setRequester={setRequester} setOpeningTime={setOpening_time} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal>
    </>
  )
}
