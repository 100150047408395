import {FC, useEffect, useState} from 'react'
import { GetBudgetCategories, GetSupplierCategories } from '../../Categories/core/_request'
import { GetAssignments } from '../../OrganizationalStructure/core/_requests'
import { GetBudgetCompanies, GetBudgetPurchaseTypes, GetBudgetTypes } from '../core/_requests'

type Props = {
  setCategoryId: any
  setCompanyId: any
  setPurchaseTypeId:any
  setTypeId:any
  setAssignment: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilter: FC<Props> = ({
  setCategoryId,
  setCompanyId,
  setTypeId,
  setPurchaseTypeId,
  setAssignment,
  handleClose,
  setSearchKeyClick,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setCategoryId(selectedCategory)
    setSearchKeyClick(inputWord)
    setTypeId(selectedType)
    setCompanyId(selectedCompany)
    setPurchaseTypeId(selectedPurchaseType)
    setAssignment(selectedAssignment)
    handleClose()
  }
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedCompany, setSelectedCompany ]= useState('')
  const [selectedType, setSelectedType] = useState('')
  const [selectedPurchaseType, setSelectedPurchaseType] = useState('')
  const [selectedAssignment, setSelectedAssignment] = useState('')
  const [inputWord, setInputWord] = useState('')
  const [categories, setCategories] = useState<any>([])
  const [assignments, setAssignments] = useState<any>([])
  const [types, setTypes] = useState([{
      id: 1,
      name: ''
  }])
  const [companies, setCompanies]= useState([{
      id: 1,
      name: ''
  }])
  const [purchaseTypes, setPurchaseTypes]= useState([{
      id: 1,
      name: ''
  }])
  useEffect(() => {
  GetBudgetCategories().then(({data})=>{
      setCategories(data.data)
  })
  GetBudgetTypes().then(({data})=>{
      setTypes(data.data)
  })
  GetBudgetPurchaseTypes().then(({data})=>{
      setPurchaseTypes(data.data)
  })
  GetBudgetCompanies().then(({data})=>{
      setCompanies(data.data)
  })
  GetAssignments().then(({data})=>{
      setAssignments(data.data)
  })
  }, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      <div className='px-7 py-5'>
        <div className='d-flex align-items-center position-relative my-3'>
          <input
            type='text'
            data-kt-filemanager-table-filter='search'
            className={`form-control form-control-solid  me-2  'w-300px'`}
            placeholder={'Aranacak kelimeyi giriniz'}
            onChange={(e) => {
              setInputWord(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Kategori</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedCategory(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Kategori Seç </option>
            {categories.map((category: any) => {
              return <option value={category.id}>{category.name}</option>
            })}
          </select>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Müdürlük</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedAssignment(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Müdürlük Seç </option>
            {assignments.map((assignment: any) => {
              return <option value={assignment.id}>{assignment.alternative_name}</option>
            })}
          </select>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Tür</label>
        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedType(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Türün Seç </option>
            {types.map((type: any) => {
              return <option value={type.id}>{type.name}</option>
            })}
          </select>
        </div>
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Şirket</label>
        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedCompany(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Şirket Seç </option>
            {companies.map((company: any) => {
              return <option value={company.id}>{company.name}</option>
            })}
          </select>
        </div>
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'> Satın Alma Türü</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedPurchaseType(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Satın Alma Türün Seç </option>
            {purchaseTypes.map((type: any) => {
              return <option value={type.id}>{type.name}</option>
            })}
          </select>
        </div>
      </div>
      <div className='px-7 py-5'>
    

        <div className='d-flex justify-content-center'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleClose}
          >
            İptal
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={handleSubmit}
          >
            Detaylı Ara
          </button>
        </div>
      </div>
    </div>

    //</form>
  )
}
