import Chart from 'react-apexcharts'
import {FC, useEffect, useState} from 'react'
import {getProjectsPlan, getProjectsStatus, getProjectsType, updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {bottom} from '@popperjs/core'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
type Props = {
  widgetId: any
  text: string
  plusOrMinus: boolean
  refreshPage?: any
  refresh?: boolean
  setSelectedStatus?: any
  setSelectedPlan?: any
  setSelectedType?: any
  setSelectedName?: any
  linkToPage: string
  pageId?: string
}
export const ProjectPieWidget: FC<Props> = ({widgetId, text, plusOrMinus, refresh, setSelectedName, setSelectedPlan, setSelectedStatus, setSelectedType, linkToPage, pageId}) => {
  const [widgetLabels, setWidgetLabels] = useState<any>([])
  const [widgetData, setWidgetData] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const [statusData, setStatusData] = useState<any>([])
  const [typeData, setTypeData] = useState<any>([])
  const [planData, setPlanData] = useState<any>([])
  const {refreshPage} = useRefresh1()
  useEffect(() => {
    if (widgetId == 9) {
      getProjectsStatus().then(({data}) => {
        setStatusData(data)
        prdStatus(data)
      })
    } else if (widgetId == 10) {
      getProjectsType().then(({data}) => {
        setTypeData(data)
        prdType(data)
      })
    } else if (widgetId == 11) {
      getProjectsPlan().then(({data}) => {
        setPlanData(data)
        prdPlan(data)
      })
    }
  }, [refresh])

  const prdPlan = (data: any) => {
    let series = []
    let label = []
    for (let i = 0; i < data?.length; i++) {
      series.push(data[i]?.total_project)
      label.push(data[i]?.plan_name)
    }
    setWidgetData(series)
    setWidgetLabels(label)
  }

  const prdType = (data: any) => {
    let type = []
    let label = []
    for (let i = 0; i < data?.length; i++) {
      type.push(data[i]?.total_project)
      label.push(data[i]?.type_name)
    }
    setWidgetData(type)
    setWidgetLabels(label)
  }

  const prdStatus = (data: any) => {
    let total = 0
    let status = []
    let label = []
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i]?.total_project
      status.push(data[i]?.total_project)
      label.push(data[i]?.status_name)
    }
    setWidgetData(status)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const chart1 = {
    labels: widgetLabels,
    colors: ['#008FFB', '#ff4560', '#00e396', '#ffb01a', '#775dd0', '#98a9af', '#6BFFC7'],
    dataLabels: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 150,
        offsetY: 150,
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Arial',
        zIndex: 9999, // Burada z-index değerini belirleyebilirsiniz
      },
    },

    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'center',
      fontSize: '15rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}) => {
          setSelectedName(config.w.config.labels[config.dataPointIndex])
          if (widgetId == 9) {
            statusData.map((data: any) => {
              if (data.status_name == config.w.config.labels[config.dataPointIndex]) {
                setSelectedStatus(data.status_id)
              }
            })
            setSelectedPlan('')
            setSelectedType('')
          } else if (widgetId == 10) {
            typeData.map((data: any) => {
              if (data.type_name == config.w.config.labels[config.dataPointIndex]) {
                setSelectedType(data.type_id)
              }
            })
            setSelectedStatus('')
            setSelectedPlan('')
          } else if (widgetId == 11) {
            planData.map((data: any) => {
              if (data.plan_name == config.w.config.labels[config.dataPointIndex]) {
                setSelectedPlan(data.plan_id)
              }
            })
            setSelectedStatus('')
            setSelectedType('')
          }
        },
      },
    },
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  const border = {
    border: '1px solid #50c8da',
  }
  return (
    <div className='card pie-chart' style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='card-body p-3 overflow-scroll'>
        <div className='fs-3 fw-bolder d-flex justify-content-between align-items-center'>
          {widgetId == 9 ? (
            <Link to={plusOrMinus ? '' : linkToPage} style={{color: 'black'}}>
              <div>
                <span className='fs-2qx'>{totalCount}</span> <span className='fs-2'>adet</span>{' '}
              </div>
            </Link>
          ) : (
            <Link to={plusOrMinus ? '' : linkToPage} style={{color: 'black'}}>
              <div>{text}</div>
            </Link>
          )}
          <div>
            <span
              className='btn btn-icon btn-light btn-active-color-primary mt-2 me-2  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
              onClick={() => {
                homeWidgetShow()
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
            <br />
            {plusOrMinus && (
              <span
                className='text-gray-600 fs-8 mt-n2 me-2  d-inline-flex '
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (widgetId == 9) {
                    setSelectedStatus('')
                  } else if (widgetId == 10) {
                    setSelectedType('')
                  } else {
                    setSelectedPlan('')
                  }
                  setSelectedName('')
                }}
              >
                Tümü
              </span>
            )}
          </div>
        </div>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='fs-4 fw-bold text-gray-400 mb-3 position-relative'>Mevcut Projeler</div>

          <Chart options={chart1} series={widgetData} type='donut' height={'300px'} />
        </Link>
      </div>
    </div>
  )
}
