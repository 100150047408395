/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {ID} from '../../../../_theme/helpers'
import {Dropdown} from 'react-bootstrap'
import swal from 'sweetalert'
import {DeleteProject} from '../core/requests'
import {SquareButton} from './SquareButton'
import moment from 'moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  id: ID
  project: any
  refreshPage: any
  refreshCount: any
  type: string
}

const ProjectTableBody: FC<Props> = ({id, project, refreshPage, refreshCount, type}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  function swalDelete() {
    try {
      swal({
        title: '',
        text: 'Kayıt silinecektir emin misiniz?',
        icon: 'warning',
        buttons: ['Vazgeç', 'Evet'],
      }).then((responese) => {
        if (responese) {
          DeleteProject(id).then(async () => {
            await swal('Silindi!', 'Proje başarı ile silindi.', 'success', {timer: 2000})
            refreshPage()
            refreshCount()
          })
        }
      })
    } catch (error) {
      console.error('Hata:', error)
      swal('Hata!', 'Proje silinirken bir hata oluştu.', 'error')
    }
  }

  const setId = (id: any) => {
    localStorage.setItem('projectID', JSON.stringify(id))
  }
  return (
    <tr>
      <td className='text-gray-500'>
        <Link
          state={id}
          to={loginUser?.permissions.filter((permission) => permission.name == 'Show Project').length > 0 ? '../../../projeler/detail' : ''}
          className='fs-4 text-dark'
          onClick={() => setId(id)}
        >
          {project?.name}
        </Link>
      </td>
      <td className='text-gray-500'>{project?.group_directorate?.name}</td>
      <td className='text-gray-500'>{project?.directorate?.name}</td>
      <td className='text-gray-500'>{project?.class?.name} </td>
      <td className='text-gray-500'>{project?.type?.name} </td>
      <td className='text-gray-500'>{project?.category?.name} </td>
      <td className='text-gray-500'> {moment(project?.start_date).format('DD.MM.YYYY')}</td>
      <td className='text-gray-500'> {moment(project?.end_date).format('DD.MM.YYYY')}</td>
      {type === 'tamamlanan' ? <td className='text-gray-500'>{project?.budget?.title}</td> : ''}
      {type === 'tamamlanan' ? <td className='text-gray-500'>{project?.description?.slice(0, 30)} </td> : ''}
      {type === 'aday' ? <td className='text-gray-500'>{project?.budget?.type?.name} </td> : ''}

      <td className='text-gray-500'>
        {project?.description?.slice(0, 30)} {project?.description?.length > 30 ? '...' : ''}
      </td>
      {type === 'tamamlanan' ? <td className='text-gray-500'>{project?.first_owner?.name}</td> : ''}
      {type === 'tamamlanan' ? <td className='text-gray-500'>{project?.second_owner?.name}</td> : ''}
      <td>
        {' '}
        <div>
          <Dropdown className=''>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-light btn-active-light-primary btn-sm ' id='dropdown-basic'>
              {/* <span className='svg-icon svg-icon-4'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20px'
                              height='20px'
                              viewBox='0 0 24 24'
                            >
                              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                                <rect
                                  x='14'
                                  y='5'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x='5'
                                  y='14'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x='14'
                                  y='14'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                              </g>
                            </svg>
                          </span> */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                {' '}
                <SquareButton status={true} issue={id} status_id={project.status?.id} />
              </Dropdown.Item>
              <Dropdown.Item onClick={swalDelete} className='text-danger'>
                Sil
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  )
}

export {ProjectTableBody}
function refreshPage() {
  throw new Error('Function not implemented.')
}
