import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {GetPocDetail} from '../core/_request'

type Props = {
  pocId: any
}
export const PoCInfo: FC<Props> = ({pocId}) => {
  const [poc, setPoc] = useState<any>()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    GetPocDetail(pocId).then(({data}) => {
      setPoc(data.data)
      setLoading(false)
    })
  }, [])
  return (
    <>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-950px mb-10 ml-0'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-5'>
              {/*  <div className='symbol symbol-100px symbol-circle mb-7'>
              <img src='assets/media/avatars/300-6.jpg' alt='image' />
            </div> */}

              <span className='fs-3 text-gray-800 fw-bolder mb-3'>{poc?.title}</span>

              <div className='mb-9'>
                <div className='badge badge-lg badge-light-primary d-inline'>{poc?.status?.name}</div>
              </div>
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Görişme/Demo/PoC</div>
                <div className='text-gray-600'>{poc?.type?.name}</div>
                <div className='fw-bolder mt-5'>Kapsam</div>
                <div className='text-gray-600'>{poc?.title}</div>
                <div className='fw-bolder mt-5'>Müdürlük</div>
                {poc?.directorates?.map((directorate: any) => {
                  return (
                    <div className='d-flex  justify-content-between mt-3'>
                      <div className='d-flex flex-column  w-100'>
                        <div className='fw-bold d-flex align-items-center fs-6 text-gray-600'>{directorate?.name}</div>
                      </div>
                    </div>
                  )
                })}
                <div className='fw-bolder mt-5'>Sorumlusu</div>
                {poc?.action_users?.map((user: any) => {
                  return (
                    <div className='d-flex  justify-content-between mt-3'>
                      <div className='symbol symbol-55px symbol-circle w-55px '>
                        {user?.avatar ? (
                          <div className='symbol symbol-55px symbol-circle w-55px'>
                            <img src={user?.avatar} alt={user?.name} className='w-100' />
                          </div>
                        ) : (
                          <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{user?.name?.substring(0, 1)}</div>
                        )}
                      </div>
                      <div className='d-flex flex-column p-5 w-100'>
                        <div className='fw-bolder d-flex align-items-center fs-6 text-hover-primary'>{user?.name}</div>
                      </div>
                    </div>
                  )
                })}
                <div className='fw-bolder mt-5'>Paydaş Müdürlüklükler</div>
                {poc?.stakeholder_directorates?.map((directorate: any) => {
                  return (
                    <div className='d-flex  justify-content-between mt-3'>
                      <div className='d-flex flex-column  w-100'>
                        <div className='fw-bold d-flex align-items-center fs-6 text-gray-600'>{directorate?.name}</div>
                      </div>
                    </div>
                  )
                })}
                <div className='fw-bolder mt-5'>Görüşülen Firma</div>
                <div className='text-gray-600'>{poc?.firm_detail}</div>
                <div className='fw-bolder mt-5'>Firma İlgilisi</div>
                <div className='text-gray-600'>{poc?.contact_firm}</div>
                <div className='fw-bolder mt-5'>POC Ortamı</div>
                <div className='text-gray-600'>{poc?.poc_environment?.name}</div>
                <div className='fw-bolder mt-5'>Statü</div>
                <div className='text-gray-600'>{poc?.status?.name}</div>
                <div className='fw-bolder mt-5'>Hedef Başlangıç Tarihi</div>
                <div className='text-gray-600'>{moment(poc?.target_start_date).format('DD.MM.YYYY')}</div>
                <div className='fw-bolder mt-5'>Hedef Bitiş Tarihi</div>
                <div className='text-gray-600'>{moment(poc?.target_end_date).format('DD.MM.YYYY')}</div>
                <div className='fw-bolder mt-5'>Gerçekleşen Başlangıç Tarihi</div>
                <div className='text-gray-600'>{moment(poc?.actual_start_date).format('DD.MM.YYYY')}</div>
                <div className='fw-bolder mt-5'>Gerçekleşen Bitiş Tarihi</div>
                <div className='text-gray-600'>{moment(poc?.actual_start_date).format('DD.MM.YYYY')}</div>
                <div className='fw-bolder mt-5'>Poc Sonuç Yorumu</div>
                <div className='text-gray-600'>{poc?.poc_result?.name}</div>
                <div className='fw-bolder mt-5'>PoC Rapor Durumu</div>
                <div className='text-gray-600'>{poc?.poc_report?.name}</div>
                <div className='fw-bolder mt-5'>Not/Bilgi</div>
                <div className='text-gray-600'>{poc?.notes}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function setTotal(total: any) {
  throw new Error('Function not implemented.')
}
