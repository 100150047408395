import {FC, useEffect, useState} from 'react'
import {NoMatchRecords} from '../components/NoMatchRecords'
import Pagination from '../components/Pagination'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {useRefresh1} from '../OrganizationalStructure/Context/RefreshAssignment'
import {CaseItem} from './components/CaseItem'
import {UsersListHeader} from './components/userHeader/UsersListHeader'
import {GetCases} from './core/_request'

export const Cases: FC = () => {
  const [cases, setCases] = useState<any>([])
  const {refresh} = useRefresh1()
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [assginedBy, setAssignedBy] = useState('')
  const [status, setStatus] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [selectedDirectorate, setSelectedDirectorate] = useState<any>('')
  const getDirectorateId = (id: string) => {
    setAssignedBy(id)
  }

  const currentTableData = () => {
    let queryParams = '?q=' + searchKeyClick + '&page[number]=' + currentPage + '&page[size]=' + limit
    if (assginedBy != '') {
      queryParams += '&assignment_id=' + assginedBy
    }
    if (status != '') {
      queryParams += '&status=' + status
    }

    GetCases(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setCases(data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    currentTableData()
  }, [currentPage])
  useEffect(() => {
    setLoading(true)
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, assginedBy, status])
  return (
    <>
      <div className='col-xxl-12'>
        <DirectorateWidget widgetId={81} title='Görev Bazlı Caseler' getSelectedId={getDirectorateId} plusOrMinus={true} linkToPage={''} setSelectedDirectorate={setSelectedDirectorate} />
      </div>
      {/* <KTCard> */}
      <UsersListHeader setWord={setSearchKeyClick} setAssignment={setAssignedBy} setStatus={setStatus} selectedDirectorate={selectedDirectorate} />

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
                  <tr>
                    <th className=''>Kısa tanıtımı</th>
                    <th className=''>Başlangıç Tarİhİ</th>
                    <th className=''>BİTİŞ TARİHİ</th>
                    <th className=''>SÜRESİ</th>
                    <th className=''>Sorumlu Ekİp/KİŞİ</th>
                    <th className=''>AKSİYON Durumu</th>
                    <th className=''>AKSİYON SAHİBİ</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className=''>
                  {cases?.length < 1 ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    cases.map((caseProp: any) => {
                      return <CaseItem key={caseProp.id} caseProp={caseProp} />
                    })
                  )}
                </tbody>
              </table>
            )}
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
          </div>
        </div>
      </div>
      {/* </KTCard> */}
    </>
  )
}
