import {FC, useState} from 'react'
import swal from 'sweetalert'
import {Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {NavLink} from 'react-router-dom'
import moment from 'moment'
import {DeleteCase} from '../core/_request'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {AddCase} from '../edit-modal/AddCase'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  caseProp: any
}

const deleteCase = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteCase(id).then(() => {
        swal('Silindi!', 'Case bilgileri başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const CaseItem: FC<Props> = ({caseProp}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [showModal, setShowmodal] = useState(false)
  const {refreshPage} = useRefresh1()
  const handleClose = () => {
    setShowmodal(false)
  }
  const handleShow = () => {
    setShowmodal(true)
  }

  return (
    <>
      <tr>
        <td className='fw-bolder'>
          <NavLink to='../detail' state={caseProp} className='text-gray-900 text-hover-primary'>
            {caseProp?.title?.slice(0, 100)}
            {caseProp?.title?.length > 100 ? '...' : ''}
          </NavLink>
        </td>
        <td className='text-gray-500'>{moment(caseProp?.start_date).format('DD.MM.YYYY H:mm')}</td>
        <td className='text-gray-500'>{caseProp?.end_date != '0000-00-00 00:00:00' ? moment(caseProp?.end_date).format('DD.MM.YYYY H:mm') : ''}</td>
        <td className='text-gray-500'>{caseProp?.end_date != '0000-00-00 00:00:00' ? caseProp?.duration : '-'}</td>
        <td className='text-gray-500'>{caseProp?.assignment?.alternative_name}</td>
        <td className='text-gray-500'>
          {' '}
          <span
            data-kt-menu-trigger='click'
            className={`badge badge-${
              caseProp?.status?.id == '1'
                ? 'light-success'
                : caseProp?.status?.id == '0'
                ? 'light-primary'
                : caseProp?.status?.id == '2'
                ? 'light-info'
                : caseProp?.status?.id == '3'
                ? 'light-warning'
                : 'light-danger'
            } fw-bolder me-auto`}
          >
            {caseProp?.status?.name}
          </span>
        </td>
        <td className='text-gray-500'>
          {caseProp?.action_users[0]?.name}
          {caseProp?.action_users?.length > 1 ? ' ...' : ''}
          {/* {caseProp.action_users.map((user: any)=> {return( user.name + '; ')})} */}
        </td>
        {caseProp?.created_by?.id == loginUser.id || loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 ? (
          <td className='text-end' data-kt-filemanager-table=''>
            <Dropdown>
              <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  {' '}
                  <NavLink to='../detail' state={caseProp} className='menu-link px-3 text-black'>
                    Görüntüle
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleShow}>
                  {' '}
                  <span className='menu-link px-3'>Düzenle</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    deleteCase(caseProp.id, refreshPage)
                  }}
                >
                  <span className='menu-link text-danger px-3'>Sil</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        ) : (
          ''
        )}
      </tr>
      <Modal show={showModal} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddCase caseProp={caseProp} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>
    </>
  )
}
