/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import { getNotifications } from '../../../../app/pages/core/requests'
import {KTSVG} from '../../../helpers'

import {TimelineItem} from './TimelineItem'

export const DrawerMessenger: FC = () => {
  const [elements, setElement] = useState<any>([])
  const [refresh,setRefresh ] = useState<any>(true)
  useEffect(() => {
    getNotifications()
      .then((data) => {
        setElement(data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [refresh])
  return (
    <>
  <div
    id='kt_drawer_chat'
    className='bg-white'
    data-kt-drawer='true'
    data-kt-drawer-name='chat'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_chat_toggle'
    data-kt-drawer-close='#kt_drawer_chat_close'
  >
    <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
      <div className='card-header pe-5' id='kt_drawer_chat_messenger_header' style={{borderBottom: '1px dashed rgb(152 156 160)'}}>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            <span className='fs-2 fw-bolder  me-1 mb-2 lh-1' style={{color: 'rgb(1 13 88)'}}>
              Bildirimler
            </span>

            <div className='mb-0 lh-1'></div>
          </div>
        </div>

        <div className='card-toolbar'>
          <div onClick={()=>{setRefresh(!refresh)}} className=' btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div className='tab-content'>
          <div
            id='kt_activity_today'
            className='card-body p-0 tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='kt_activity_today_tab'
          >
            <div className='timeline'>
              <TimelineItem elements={elements} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}


