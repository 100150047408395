import { FC } from "react"
import { useListView } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { BudgetToolbar } from "./BudgetToolBar"
import { Button, Dropdown } from "react-bootstrap"
import { SearchComponent } from "../../../components/SearchComponent"

type Props ={
  refreshPage :any
  setWord: any
  setFilterWord: any
  openFilterModal: any
}

const BudgetHeader : FC <Props> = ({refreshPage, setWord, setFilterWord, openFilterModal}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      <SearchComponent setSearchKeyClick={setWord} placeholder={"Bütçe Ara"}/>
     
      {/* begin::Card toolbar */}
     
      <div className='card-toolbar'>
      <Dropdown> 
            <Dropdown.Toggle
            style={{backgroundColor: 'rgb(1 13 88)'}}
              className='btn  me-3 '
              id='filter'
            
            >  Filtrele</Dropdown.Toggle> 
            <Dropdown.Menu>
            <Dropdown.Item   
              onClick={()=>{setFilterWord('')}}
              >
                {' '}
                <span className='menu-link px-3'>Tümü</span>
              </Dropdown.Item>
              <Dropdown.Item   
              onClick={()=>{setFilterWord('2')}}
              >
                {' '}
                <span className='menu-link px-3'>Opex</span>
              </Dropdown.Item>
              <Dropdown.Item 
               onClick={()=>{setFilterWord('1')}}
               >
                <span className='menu-link  px-3'>Capex</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {
                    <button type='button' className='btn me-4' onClick={openFilterModal}>Detaylı Ara</button>
                
                }
        {/* begin::Group actions */}
       <BudgetToolbar refreshPage={refreshPage}/>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BudgetHeader}
