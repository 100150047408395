import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddVN} from './add-CA/Add-VN'
import {VNItem} from './components/VNItem'
import {ChartsWidget4} from '../components/ChartsWidget4'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import {DetailFilters} from './components/DetailFilter'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const VN: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [vnList, setVnList] = useState<any>([])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [technology, setTechnology] = useState('')
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setTechnology('')
    setCategory('')
    setSelectedDirectorate('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (technology != '') {
      queryParams += '&technology=' + technology
    }
    if (category != '') {
      queryParams += '&category=' + category
    }
    if (status != '') {
      queryParams += '&status=' + status
    }
    if (selectedDirectorate != '') {
      queryParams += '&assignment_name=' + selectedDirectorate
    }

    GetReport('/vulnerability_notifications', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setVnList(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [technology, category, status, selectedDirectorate])

  const exportFile = () => {
    window.open(`${ExportLink}/vulnerability_notifications?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div>
          <div className='row g-5 g-xxl-10 mb-5'>
            <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
              <PieChart link={''} widgetId={'65'} plusOrMinus={true} linkToPage={''} name='Genel Zafiyet Bildirim Durum Adetleri' setSelectedValue={setStatus} />
            </div>
            <div className='col-xl-8 mb-xl-7 mb-xxl-12'>
              <ChartsWidget4 horizentalTitle={'Direktörlük Zafiyet Durum Dağılımı'} widgetId={'66'} plusOrMinus={true} linkToPage={''} />
            </div>
            {/* end::Col */}
          </div>
          <div className='row g-5 g-xxl-10  mb-5'>
            <div className='col-12'>
              <ChartsWidget4 horizentalTitle={'Müdürlük Bazında Zafiyet Durum Dağılımı'} widgetId={'67'} plusOrMinus={true} linkToPage={''} />
            </div>
            {/* end::Col */}
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/vulnerability_notifications_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 ' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* 
                  <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto  mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>Incident Id</th>
                      <th className=''>Technology</th>
                      <th className=''>SERVİS</th>
                      <th className=''>KATEGORİ</th>
                      <th className=''>Alt KATEGORİ</th>
                      <th className=''>Kaydı Açan KİŞİ</th>
                      <th className=''>Status</th>
                      <th className=''>Üst Atama Grubu</th>
                      <th className=''>Atama Grubu</th>
                      <th className=''>Impact</th>
                      <th className=''>Atanan Kullanıcı SİCİLİ</th>
                      <th className=''>Atanan Kullanıcı Adı</th>
                      <th className=''>Başlık</th>
                      <th className=''>Açılma Zamanı</th>
                      <th className=''>Çözüm Zamanı</th>
                      <th className=''>Açılma Zamanı Yıl</th>
                      <th className=''>Açılma Zamanı Ay</th>
                      <th className=''> Açılma Zamanı Yıl Ay</th>
                      <th className=''> Resolved By</th>
                      <th className=''>Resolved Group</th>
                      <th className=''>Kapama Kodu</th>
                      <th className=''>Kapama Açıklaması</th>

                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {vnList?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      vnList?.map((vn: any) => {
                        return (
                          <>
                            <VNItem key={vn?.id} vnList={vn} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddVN handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setTechnology={setTechnology} setCategory={setCategory} setDirectorate={setSelectedDirectorate} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
