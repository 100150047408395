import {FC, useEffect, useState} from 'react'

type Props = {
  setCode: any
  setRefId: any
  setRefStatus: any
  setOrgUnit: any
  handleClose: any
}

export const DetailFiltersAksiyon: FC<Props> = ({setCode, setOrgUnit, setRefId, setRefStatus, handleClose}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setCode(selectedCode)
    setRefId(selectedRefId)
    setRefStatus(selectedRefStatus)
    setOrgUnit(selectedOrgUnit)
    handleClose()
  }
  const [selectedCode, setSelectedCode] = useState('')
  const [selectedRefId, setSelectedRefId] = useState('')
  const [selectedRefStatus, setSelectedRefStatus] = useState('')
  const [selectedOrgUnit, setSelectedOrgUnit] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ref Id</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedRefId(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ref Status</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedRefStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Code</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCode(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Organisational Unit</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedOrgUnit(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button type='reset' className='btn btn-sm btn-light btn-active-light-primary me-2' data-kt-menu-dismiss='true' onClick={handleClose}>
          İptal
        </button>
        <button type='submit' className='btn btn-sm' data-kt-menu-dismiss='true' onClick={handleSubmit}>
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
