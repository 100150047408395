import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
  tableId: any
}
export const InfSecurityAssetsItem: FC<Props> = ({item, tableId}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.ref_id}</td>
        {tableId == 1 && <td className='text-gray-500'>{item?.application_type}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.directorate}</td>}
        {tableId == 3 && <td className='text-gray-500'>{item?.directorate}</td>}
      </tr>
    </>
  )
}
