import {INVENTORY} from './../Inventory/core/_requests'
import {USERS} from './../Activities/core/_requests'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const GET_USERS = `${API_URL}/users`
export const GET_ROLE = `${API_URL}/roles`
export const GET_ASSIGN = `${API_URL}/assignments`
export const ISSUE_WIDGETS = `${API_URL}/widgets/issues`
export const BUDGET_WIDGETS = `${API_URL}/widgets/budgets`
export const PROJECT_WIDGETS = `${API_URL}/widgets/projects`
export const WIDGETS = `${API_URL}/widgets`
export const NOTIFICATIONS = `${API_URL}/notifications`
export const ACTIVITIES = `${API_URL}/activities`
export const ISSUE = `${API_URL}/issues`
export const ANNOUNCEMENT = `${API_URL}/announcements`
const BLOG = `${API_URL}/blogs`
const BUDGETS = `${API_URL}/budgets`
const GET_POST = `${API_URL}/documents`
export const PROJECT_LIST = `${API_URL}/projects`
export const ExportLink = `${API_URL}/export`
export const ROLES = `${API_URL}/role`
export const PROFILE = `${API_URL}/profile`
export const LOGOUT = `${API_URL}/logout`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function getUsers() {
  return await axios.get<any>(GET_USERS)
}
export async function getUser(user_id: any) {
  return await axios.get<any>(GET_USERS + '/' + user_id)
}

export async function getRoles() {
  return await axios.get<any>(GET_ROLE)
}

export async function getAssignments(link?: string) {
  return await axios.get<any>(GET_ASSIGN + link)
}

//issue

export async function getIssueStatusWidget() {
  return await axios.get<any>(ISSUE_WIDGETS + '/status')
}

export async function getIssueDirectorateWidget() {
  return await axios.get<any>(ISSUE_WIDGETS + '/statusByDirectorate')
}

export async function getWidgets() {
  return await axios.get<any>(WIDGETS)
}
export async function getWidgetsList(user_id: any) {
  return await axios.get<any>(`${USERS}/${user_id}/widgets`)
}
export async function updateHomeWidgets(user_id: any, data: any) {
  return await axios.patch(USERS + '/' + user_id + '/widgets', data)
}
export async function moveHomeWidgets(user_id: any, widgetsId: any, direction: string) {
  return await axios.patch(`${USERS}/${user_id}/widgets/${widgetsId}/${direction}`)
}

export async function updateReportWidgets(user_id: any, data: any) {
  return await axios.patch(`${USERS}/${user_id}/pagewidgets`, data)
}
export async function moveReportWidgets(user_id: any, widgetsId: any, direction: string, pageId: any) {
  return await axios.patch(`${USERS}/${user_id}/pagewidgets/${widgetsId}/${direction}/${pageId}`)
}
export async function getIssueStatusByStaff() {
  return await axios.get<any>(ISSUE_WIDGETS + '/statusByStaff')
}

// budgets
export async function getTotalBudgetWidget() {
  return await axios.get<any>(BUDGET_WIDGETS + '/totalBudget')
}

export async function GetExchangeRates() {
  return await axios.get<any>(BUDGET_WIDGETS + '/exchangeRates')
}
export async function getBudgetByCategoryWidget() {
  return await axios.get<any>(BUDGET_WIDGETS + '/totalBudgetByCategory')
}
export async function GetMonthlyTotalOpex() {
  return await axios.get<any>(BUDGET_WIDGETS + '/monthlyTotalOpex')
}

export async function GetMonthlyTotalCapex() {
  return await axios.get<any>(BUDGET_WIDGETS + '/monthlyTotalCapex')
}
//project

export async function getProjectsPlan() {
  return await axios.get(PROJECT_WIDGETS + '/plan')
}
export async function getProjectsType() {
  return await axios.get(PROJECT_WIDGETS + '/type')
}

export async function getProjectsStatus() {
  return await axios.get(PROJECT_WIDGETS + '/status')
}
export async function getProjectDirectorateWidget(status: string) {
  return await axios.get(PROJECT_WIDGETS + '/statusByDirectorate?status=' + status)
}

export async function getProjectDirectorateWidgetByClass(class_id: any) {
  return await axios.get(PROJECT_WIDGETS + '/statusByDirectorate?status=1&project_class_id=' + class_id)
}

//notifications

export async function getNotifications() {
  return await axios.get(NOTIFICATIONS)
}
export async function getNotificationsByType(type: string, id: string) {
  return await axios.get(ACTIVITIES + `?subject_type=${type}&subject_id=${id}`)
}

export async function readNotifications() {
  return await axios.put(NOTIFICATIONS)
}

export async function getActivities(q: any) {
  return await axios.get<any>(ISSUE + q)
}
export async function getAnnoun(q: string) {
  return await axios.get<any>(ANNOUNCEMENT + q)
}

export async function getBlog(q: string) {
  return axios.get(`${BLOG}${q}`).then((d: AxiosResponse<any>) => d.data)
}
export async function GetBudgets(q: string) {
  return await axios.get<any>(BUDGETS + q)
}
const getDoc = (id: string): Promise<any> => {
  return axios.get(`${GET_POST}${id}`).then((d: AxiosResponse<any>) => d)
}
export async function getProjects(q: string) {
  return await axios.get<any>(PROJECT_LIST + q)
}

export async function getInventories(q: string) {
  return await axios.get<any>(INVENTORY + q)
}
export {getDoc}

export async function getReportWidgets(userId: any, pageId: string) {
  return await axios.get<any>(WIDGETS + '/' + userId + '/report/' + pageId)
}

export async function getUserPermissionPath(path: string) {
  return await axios.get<any>(ROLES + '/permision?path=' + path)
}

export async function getProfile() {
  return await axios.get<any>(PROFILE)
}

export async function logout() {
  return axios.get<any>(LOGOUT)
}

export async function getCSRFToken() {
  return await axios.get<any>(API_URL + '/csrf/token')
}
export async function flashToken() {
  return await axios.get<any>(API_URL + '/csrf/flush-token')
}
