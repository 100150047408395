
import { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert'
import "../style/style2.css"

export const ErrorComponent : FC=()=>{
    const navigate = useNavigate()
    function showMessage(){
        swal({title:'',text: "Bu modüle erişme yetkiniz yoktur!",icon: "warning", buttons: ["Tamam", false],});
       
    }
    useEffect(()=>{
        showMessage();
        navigate('/ana-sayfa')
        
    },[])
    return(
      
       <></>

    )
}


