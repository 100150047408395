import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTCard} from '../../../../../_theme/helpers'
import {ModalHeader} from '../../../components/ModalHeader'
import {NoMatchRecords} from '../../../components/NoMatchRecords'
import {getProject, getProjectUsers} from '../../core/requests'
import {CreateGroup} from '../add-edit-modals/CreateGroup'
import {UserItem} from './UserItem'
export const Stakeholder: FC = () => {
  const data = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [project, setProject] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      roles: [
        {
          id: 1,
          name: '',
        },
      ],
    },
  ])

  const [refresh, setRefresh] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const openUserModal = () => {
    setShowUserModal(true)
  }
  const closeUserModal = () => {
    setShowUserModal(false)
  }
  useEffect(() => {
    getProjectUsers(data).then(async ({data}) => {
      await setUsers(data?.data)
      setLoading(false)
    })
    getProject(data).then(({data}) => {
      setProject(data.data)
    })
  }, [refresh])
  // const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <KTCard>
        {/* {loginUser.name==='Süper Admin Kullanıcısı' ? (  <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} />):''} */}

        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <a href='#' className='btn btn-sm  me-2' id='kt_user_follow_button' style={{width: '125px', float: 'right'}}>
                <span className='indicator-label' onClick={openUserModal}>
                  Kullanıcı Ekle
                </span>
                <span className='indicator-progress'>
                  Lütfen Bekleyin...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </a>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase'>
                    <tr>
                      <th></th>
                      <th className=''>AD SOYAD</th>
                      <th className=''>Görev</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {users?.length < 1 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      users.map((user) => {
                        return (
                          <>
                            <UserItem key={user?.id} user={user} refreshPage={refreshPage} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={showUserModal} style={{position: 'absolute', top: '0px'}} size='xl'>
        <Modal.Body>
          <ModalHeader handleClose={closeUserModal} titleHeader={'Kullanıcı Ekle'} />
          <CreateGroup setModalShow={closeUserModal} project={project} update={false} oldUsers={project?.users} />
        </Modal.Body>
      </Modal>
    </>
  )
}
