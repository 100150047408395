import moment from "moment"
import { FC } from "react"

type Props ={
    item : any
}
export const WebTrafficItem : FC <Props> = ({item}) =>{
    return(
        <>
        <tr>
       <td className='fw-bolder'>
           {item?.result}
       </td>
       <td className='text-gray-500'>{moment(item?.date_and_time).format('DD.MM.YY H:mm')}</td>
       <td className='text-gray-500'>{item?.bandwidth}</td>
       <td className='text-gray-500'>{item?.category}</td>
       <td className='text-gray-500'>{item?.user_display_name}</td>
       <td className='text-gray-500'>{item?.url}</td>
       {/* <td className='text-gray-500'>{item?.created_at}</td> */}
    
      
     </tr>
      </>
    )
}