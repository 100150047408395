import React, {useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../../_theme/helpers'
import {useLayout} from '../../../../_theme/layout/core'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {UpdateAssign} from '../edit-modal/UpdateAssignment'
import {UserList} from './UserListByAssign'
import {AddAssignment} from '../edit-modal/AddAssignment'
import {useRefresh1} from '../Context/RefreshAssignment'

type Props = {
  // to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  element: any
}

const AssignmentItem: React.FC<Props> = ({
  children,
  // to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  element,
}) => {
  const {pathname} = useLocation()
  const {config} = useLayout()
  const {aside} = config
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [show1, setShow1] = useState(false)
  const handleShow1 = () => setShow1(true)
  const handleClose1 = () => setShow1(false)
  // const {refreshPage} = useRefresh1();
  return (
    <>
      <tr className='w-100 '>
        <td className='w-100 border-0 pl-3'>
          <div className='menu-item' style={{paddingLeft: '30px'}}>
            <span className={clsx('menu-link without-sub')}>
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTSVG path={icon} className='svg-icon svg-icon-5' />
                </span>
              )}
              {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
              <span className='menu-title '>{title}</span>
            </span>
            {/* {children} */}
          </div>
        </td>
        <td className='w-100 border-0 '>
          <Dropdown className='me-5'>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShow}>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleShow1}>
                {' '}
                <span className='menu-link px-3'>Kullanıcılar</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={show}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddAssignment assignment={element} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>
      <Modal show={show1}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose1} titleHeader={'Kullanıcılar'} />
          <UserList assignment={element} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AssignmentItem}
