import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ModalHeader} from '../components/ModalHeader'
import {NoMatchRecords} from '../components/NoMatchRecords'
import {SearchComponent} from '../components/SearchComponent'
import {AddBlog} from './blog-edit-modal/AddBlog'
import {DetailFilters} from './blog-edit-modal/DetailsFilters'
import {BlogItem} from './components/BlogItem'
import {BlogItemHeader} from './components/BlogItemHeader'
import {GetBlogs} from './core/_requests'

export const Blog: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(true)
  const [categoryId, setCategoryId] = useState('')
  const [period, setPeriod] = useState('')
  const [show, setShow] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const openFilterModal = () => setShowFilterModal(true)
  const closeFilterModal = () => setShowFilterModal(false)
  const handleClose = () => {
    setShow(false)
    setLoading(true)
  }
  const handleShow = () => setShow(true)
  const [blog, setBlog] = useState<any>([])
  const [blog1, setBlog1] = useState<any>([])
  const [blog2, setBlog2] = useState<any>([])
  const [blog3, setBlog3] = useState<any>([])
  const [blog4, setBlog4] = useState<any>([])

  const [searchKeyClick, setSearchKeyClick] = useState('')

  useEffect(() => {
    setLoading(true)
    setBlog([])
    let queryParams = '?page[size]=7'
    if (searchKeyClick !== '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (period !== '') {
      queryParams += '&period=' + period
    }
    if (categoryId !== '') {
      queryParams += '&blog_category_id=' + categoryId
    }
    GetBlogs(queryParams).then(({data}) => {
      setBlog(data?.data)
      setLoading(false)
    })
  }, [show, searchKeyClick, period, categoryId])

  useEffect(() => {
    setLoading(true)
    setBlog1([])
    setBlog2([])
    setBlog3([])
    setBlog4([])
    let queryParams = '?page[size]=3'
    if (period !== '') {
      queryParams += '&period=' + period
    }
    if (searchKeyClick !== '') {
      queryParams += '&q=' + searchKeyClick
    }
    GetBlogs(queryParams + '&blog_category_id=1').then(({data}) => {
      setBlog1(data.data)
    })

    GetBlogs(queryParams + '&blog_category_id=2').then(({data}) => {
      setBlog2(data.data)
    })

    GetBlogs(queryParams + '&blog_category_id=3').then(({data}) => {
      setBlog3(data.data)
    })

    GetBlogs(queryParams + '&blog_category_id=4').then(({data}) => {
      setBlog4(data.data)
    })
  }, [show, searchKeyClick, period])

  return (
    // <div id='kt_app_content_container' className='app-container container-fluid'>
    <div className='card'>
      <div className='card-body p-lg-10'>
        <div className='mb-15'>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'>Son Gönderiler</h3>

            <div className='d-flex'>
              <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Blog Ara'} />
              {
                <button className=' btn h-45px mt-3 me-2' onClick={openFilterModal}>
                  Detaylı Ara
                </button>
              }
              {loginUser?.permissions.filter((permission) => permission.name == 'Create Blog').length > 0 && (
                <button className='btn h-45px mt-3' onClick={handleShow}>
                  Yeni Blog
                </button>
              )}
            </div>
          </div>

          <div className='separator separator-dashed mb-9' style={{borderBottom: '1px dashed rgb(152 156 160)'}}></div>
          {blog?.length < 1 && !loading ? (
            <NoMatchRecords isTable={false} />
          ) : (
            blog.map((e: any, index: React.Key | null | undefined) => {
              if (index === 0) {
                return <BlogItemHeader key={index} el={e} path={e.files} category_name={e.category.name} />
                //   }
                // }
              }
            })
          )}
          {loading ? (
            <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='row g-10'>
              {blog.map((e: any, index: React.Key | null | undefined) => {
                {
                  if (index !== 0) {
                    return <BlogItem key={index} categoryName={e.category.name} el={e} path={e.files} />
                  }
                }
              })}
            </div>
          )}
        </div>

        {blog1?.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Vaka Çalışmaları</h3>
              <Link to='ViewDetail' state={{num: 1, category: 'Vaka çalışmaları'}} className='fs-6 fw-bold link-primary' style={{color: 'rgb(1 13 88)'}}>
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9' style={{borderBottom: '1px dashed rgb(152 156 160)'}}></div>
            <div className='row g-5 g-lg-10'>
              {blog1.map((e: any, index: React.Key | null | undefined) => {
                {
                  return <BlogItem key={index} el={e} path={e.files} />
                }
              })}
            </div>
          </div>
        ) : (
          ''
        )}
        {blog2?.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Ürün İncelemeleri</h3>
              <Link to='ViewDetail' state={{num: 2, category: 'Ürün İncelemeleri'}} className='fs-6 fw-bold link-primary' style={{color: 'rgb(1 13 88)'}}>
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9' style={{borderBottom: '1px dashed rgb(152 156 160)'}}></div>
            <div className='row g-5 g-lg-10'>
              {blog2.map((e: any, index: React.Key | null | undefined) => {
                {
                  return <BlogItem key={index} el={e} path={e.files} />
                }
              })}
            </div>
          </div>
        ) : (
          ''
        )}
        {blog3?.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Güncel Trendler</h3>
              <Link to='ViewDetail' state={{num: 3, category: 'Güncel Trendler'}} className='fs-6 fw-bold link-primary' style={{color: 'rgb(1 13 88)'}}>
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9' style={{borderBottom: '1px dashed rgb(152 156 160)'}}></div>
            <div className='row g-5 g-lg-10'>
              {blog3.map((e: any, index: React.Key | null | undefined) => {
                {
                  return <BlogItem key={index} el={e} path={e.files} />
                }
              })}
            </div>
          </div>
        ) : (
          ''
        )}
        {blog1?.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Etkinlikler</h3>
              <Link to='ViewDetail' state={{num: 4, category: 'Etkinlikler'}} className='fs-6 fw-bold link-primary' style={{color: 'rgb(1 13 88)'}}>
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9' style={{borderBottom: '1px dashed rgb(152 156 160)'}}></div>
            <div className='row g-5 g-lg-10'>
              {blog4.map((e: any, index: React.Key | null | undefined) => {
                {
                  return <BlogItem key={index} el={e} path={e.files} />
                }
              })}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* </div> */}

      <Modal show={show} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Yeni Blog'} />
          <AddBlog
            handleClose={handleClose}
            update={false}
            blog={{
              title: '',
              start_date: '',
              content: '',
              is_public: false,
              is_slider: false,
              email_notification: false,
              phone_notification: false,
              blog_category_id: '',
              attachments: '',
              files: '',
              assignments: [],
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showFilterModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeFilterModal} titleHeader={'Detaylı Ara'} />
          <DetailFilters setCategoryId={setCategoryId} setPeriod={setPeriod} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
