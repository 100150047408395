import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ProfilePopUp} from '../../../../Profile/ProfilePopUp'
import {GetTarget} from '../../../core/requests'

type Props = {
  setDate: string
  data: any
}

export const TimeLineBody: FC<Props> = ({setDate, data}) => {
  const [targets, setTargets] = useState<any>([])
  const [update, setUpdate] = useState(false)
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  let [activeDay, setActiveDay] = useState<any>()
  const [day, setDay] = useState<any>()
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }

  let id = JSON.parse(localStorage.getItem('projectID') || '{}')
  useEffect(() => {
    let revDate = setDate.split('.')
    let result = revDate[2] + '-' + revDate[1] + '-' + revDate[0]
    setDay(result)
    setActiveDay(new Date(Number(revDate[2]), Number(revDate[1]) - 1, Number(revDate[0])))
    setTargets(data)
  }, [update, setDate, data])

  return (
    <div className=' mb-1 px-9 h-100 overflow-scroll' style={{maxHeight: '200px', scrollbarWidth: 'auto'}}>
      {targets?.map((target: any) => {
        {
          if (moment(activeDay).format('YYYY-MM-DD') >= target?.planned_start_date && moment(activeDay).format('YYYY-MM-DD') <= target?.planned_end_date) {
            return (
              <>
                {/* {console.log(target?.end_date.split('-').reverse().toString().replaceAll(',','.') )} */}
                <div className=''>
                  <div className='d-flex align-items-center mb-6'>
                    <span data-kt-element='bullet' className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success'></span>

                    <div className='flex-grow-1 me-5'>
                      <div className='text-gray-800 fw-bold fs-6'>
                        {moment(target?.planned_start_date).format('DD.MM.YYYY')} /{moment(target?.planned_end_date).format('DD.MM.YYYY')}
                        <span className={`badge badge-light-${target?.status?.id == 0 ? 'primary' : target?.status?.id == 1 ? 'warning' : 'success'} fw-bolder me-auto px-4 py-2 mr-10`} style={{marginLeft: '10px'}}>
                          {target?.status?.name}
                        </span>
                      </div>

                      <Link to='../target-detail' state={target} className='text-gray-700 fw-bold fs-3'>
                        {target?.title}
                      </Link>

                      <div className='text-gray-400 fw-bold fs-7'>
                        <a
                          className='text-primary opacity-75-hover fw-bold'
                          onClick={async () => {
                            setProfilUserId(target.assigned.id)
                            await openProfilModal()
                          }}
                        >
                          {target?.created_by?.name}
                        </a>{' '}
                        tarafından oluşturuldu
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        }
      })}
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
