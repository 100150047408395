import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {EditStatus} from './EditStatus'
import '../../style/style-swal.css'

type Props = {
  status: boolean
  issue: any
  status_id: any
}
export const SquareButton: FC<Props> = ({status, issue, status_id}) => {
  const [modalShow, setModalshow] = useState(false)
  const handleShow = () => setModalshow(true)
  const handleClose = () => setModalshow(false)
  return (
    <>
      <span onClick={handleShow}>Durum</span>

      <Modal show={modalShow} className='w-300px modal m-auto'>
        <Modal.Body>
          <EditStatus issue={{id: status_id, prd_id: issue}} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
    //   <button
    //     type='button'
    //     className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'
    //     data-kt-menu-trigger='click'
    //   >
    // <span className='svg-icon svg-icon-2'>İşlem</span>
    //     </button>
  )
}
