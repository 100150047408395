import moment from "moment"
import { FC } from "react"
type Props={
    item: any
}
export const NKDY_Item : FC<Props>=({item})=>{
    return(
       <>
         <tr>
         <td className='fw-bolder'>
            {item?.mx_summary}
        </td>
        <td className='text-gray-500'>{item?.ticket_id}</td>
        <td className='text-gray-500'>{item?.description}</td>
        <td className='text-gray-500'>{item?.bt_catalog_priority}</td>
        <td className='text-gray-500'>{item?.bt_catalog_urgency}</td>
        <td className='text-gray-500'>{item?.bt_fullname}</td>
        <td className='text-gray-500'>{item?.bt_mx_group}</td>
        <td className='text-gray-500'>{item?.status} </td>
        <td className='text-gray-500'>{item?.mx_cozumtipi} </td>
        <td className='text-gray-500'>{moment(item?.report_date).format('DD.MM.YYYY')} </td>
        <td className='text-gray-500'>{item?.resolved_time}</td>
        <td className='text-gray-500'>{item?.bt_classification}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}