import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const SymenticItem: FC<Props> = ({item}) => {
  return (
    <>
      <td className='fw-bolder'>{item?.riskCount}</td>
      <td className='text-gray-500'>{item?.protectionName}</td>
      <td className='text-gray-500'>{item?.protectionEnabledClientCount}</td>
      <td className='text-gray-500'>{item?.cron_server_ip}</td>
      <tr></tr>
    </>
  )
}
