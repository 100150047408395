import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import swal from 'sweetalert'
import {SwalOptions} from 'sweetalert/typings/modules/options'
import {getActivityCategories, UpdateIssue} from '../../../Activities/core/_requests'
import {getProjectCat, getProjectClass, getProjectPlans, getProjects, getProjectType} from '../../core/requests'
import * as Yup from 'yup'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../../components/ModalHeader'
import {Modal} from 'react-bootstrap'
import {AddCategory} from '../../../Categories/AddCategory'
import {toAbsoluteUrl} from '../../../../../_theme/helpers'
import {GetAssignments, GetUsers} from '../../../OrganizationalStructure/core/_requests'

type Props = {
  setModalShow: any
  project: any
  update: boolean
  setNext: any
  setForm: any
}
const loginSchema = Yup.object().shape({
  name: Yup.string().required('Zorunlu alan'),
  code: Yup.string()
    .required('Zorunlu alan')
    .matches(/^[0-9]+$/, 'Lütfen sadece sayı girişi yapınız')
    .min(7, 'Lütfen 7 karakter giriniz')
    .max(7, 'Lütfen 7 karakter giriniz'),
  description: Yup.string().required('Zorunlu alan'),
  group_directorate_id: Yup.string().required('Zorunlu alan'),
  directorate_id: Yup.string().required('Zorunlu alan'),
  first_owner: Yup.string().required('Zorunlu alan'),
  // start_date: Yup.date()
  //   .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
  //   .required('Zorunlu alan'),
  end_date: Yup.date()
    .when('start_date', (start_date, yup) => start_date && yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz'))
    // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
    // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
  second_owner: Yup.string().required('Zorunlu alan'),
  email_notification: Yup.string().required('Zorunlu alan'),
  phone_notification: Yup.string().required('Zorunlu alan'),
  project_category_id: Yup.string().required('Zorunlu alan'),
  project_type_id: Yup.string().required('Zorunlu alan'),
  project_class_id: Yup.string().required('Zorunlu alan'),
  project_plan_id: Yup.string().required('Zorunlu alan'),
  security_solution_resource_user: Yup.string().required('Zorunlu alan'),
})

export const ProjectInfo: FC<Props> = ({setModalShow, project, update, setNext, setForm}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [securityUsers, setSecurityUsers] = useState<any>()
  const [activities, setActivities] = useState(false)
  const [assignments, setAssignments] = useState<any>([])
  const [types, setTypes] = useState<any>([])
  const [cats, setCats] = useState<any>([])
  const [classes, setClasses] = useState<any>([])
  const [plans, setPlans] = useState<any>([])
  const [headerMessage, setHeaderMessage] = useState('')
  const [inputsName, setInputsName] = useState('')
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }

  useEffect(() => {
    getActivityCategories().then(async ({data}) => {
      await setCategories(data.data)
    })
    GetAssignments().then((res) => {
      setAssignments(res.data.data)
    })
    getProjectType().then((res) => {
      setTypes(res.data.data)
    })
    getProjectCat().then((res) => {
      setCats(res.data.data)
    })
    getProjectClass().then((res) => {
      setClasses(res.data.data)
    })
    getProjectPlans().then((res) => {
      setPlans(res.data.data)
    })
    GetUsers('').then(({data}) => {
      setUsers(data.data)
    })
    GetUsers('?assignment_id=25').then(({data}) => {
      setSecurityUsers(data.data)
    })
  }, [update, showCategoryModal])

  const [userForEdit] = useState<any>({
    ...project,
    id: project.id,
    code: project.code,
    name: project.name,
    description: project.description,
    group_directorate_id: project.group_directorate_id,
    directorate_id: project.directorate_id,
    first_owner: project.first_owner,
    second_owner: project.second_owner,
    start_date: project.start_date,
    end_date: project.end_date,
    email_notification: project.email_notification,
    phone_notification: project.phone_notification,
    project_category_id: project.project_category_id,
    project_type_id: project.project_type_id,
    project_class_id: project.project_class_id,
    project_plan_id: 1,
    security_solution_resource_user: project?.security_solution_resource_user,
  })

  const cancel = () => {
    setModalShow(false)
  }
  const handleChange = (e: any) => {
    formik.handleChange(e)
    let code = e.target.value
    setActivities(false)
    if (code?.length == 7)
      getProjects('?q=', code).then(async ({data}) => {
        if (data?.meta?.total > 0) {
          setActivities(true)
        } else {
          setActivities(false)
        }
      })
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          await UpdateIssue(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err: {response: {data: {message: string | Partial<SwalOptions>}}}) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          const body = {
            code: values.code,
            name: values.name,
            description: values.description,
            group_directorate_id: values.group_directorate_id,
            directorate_id: values.directorate_id,
            first_owner: values.first_owner,
            second_owner: values.second_owner,
            project_category_id: values.project_category_id,
            project_type_id: values.project_type_id,
            project_class_id: values.project_class_id,
            start_date: values.start_date!,
            end_date: values.end_date,
            email_notification: values.email_notification ? '1' : '0',
            phone_notification: values.phone_notification ? '1' : '0',
            project_plan_id: values.project_plan_id,
            security_solution_resource_user: values.security_solution_resource_user,
          }

          setForm(body)
          setNext(2)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // setSubmitting(true)
        // cancel()
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div style={{width: '70%', minWidth: '300px'}} className='m-auto'>
        <div className='mb-5'>
          <h3>Proje Ayarları</h3>
          <span>
            Daha fazla bilgiye ihtiyacınız varsa, lütfen <a>Proje Yönergelerini kontrol edin</a>
          </span>
        </div>
        <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input Başlık */}
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}

                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Proje Kodu</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Proje Kodu'
                  {...formik.getFieldProps('code')}
                  id='code'
                  type='text'
                  name='code'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.code && formik.errors.code && activities},
                    {
                      'is-valid': formik.touched.code && !formik.errors.code && !activities,
                    }
                  )}
                  onChange={handleChange}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.code && formik.errors.code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.code}</span>
                    </div>
                  </div>
                )}
                {activities && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>Aynı kod ile kayıt yapılamaz!</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Proje Adı</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Proje Adı'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}

                {/* end::Input */}
              </div>

              {/* end::Input Kategori */}
            </div>

            {/* end::Input Başlık */}
            {/* begin::Input Sorumlu */}
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Grup Müdürlüğü*</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('group_directorate_id')}
                  name='group_directorate_id'
                  //defaultValue={activity.assigned_by}
                >
                  {/* {activity.assigned_by!==''} */}
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_group_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
                {formik.touched.group_directorate_id && formik.errors.group_directorate_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.group_directorate_id}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                <label className='required fw-bold fs-7 mb-2'>Müdürlük</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('directorate_id')}
                  name='directorate_id'
                  //defaultValue={activity.issue_category_id}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
                {formik.touched.directorate_id && formik.errors.directorate_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.directorate_id}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* end::Input Kategori */}
            </div>
            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Proje Yöneticisi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('first_owner')}
                name='first_owner'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Seçiniz
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.first_owner && formik.errors.first_owner && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.first_owner}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Proje Teknik Yöneticisi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('second_owner')}
                name='second_owner'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Seçiniz
                </option>
                {users?.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.second_owner && formik.errors.second_owner && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.second_owner}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' required fw-bold fs-7 mb-2'>Güvenlik Çözüm Kaynağı</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('security_solution_resource_user')}
                name='security_solution_resource_user'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Seçiniz
                </option>
                {securityUsers?.map((user: any) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.security_solution_resource_user && formik.errors.security_solution_resource_user && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.security_solution_resource_user}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <div className='d-flex justify-content-between'>
                  <label className='required fw-bold fs-7 mb-2'>Proje Kategorisi</label>
                  {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && (
                    <img
                      src={toAbsoluteUrl('/media/icons/add.png')}
                      alt='add-icon'
                      width='25px'
                      className=''
                      onClick={async () => {
                        setHeaderMessage('Yeni Kategori Ekle')
                        setInputsName('project_category')
                        await openCategoryModal()
                      }}
                    />
                  )}
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('project_category_id')}
                  name='project_category_id'
                  //defaultValue={activity.assigned_by}
                >
                  {/* {activity.assigned_by!==''} */}
                  <option selected disabled hidden>
                    Proje Kategorisi Seçiniz
                  </option>
                  {cats.map((cat: any) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.project_category_id && formik.errors.project_category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.project_category_id}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                <div className='d-flex justify-content-between'>
                  <label className='required fw-bold fs-7 mb-2'>Proje Türü</label>
                  {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && (
                    <img
                      src={toAbsoluteUrl('/media/icons/add.png')}
                      alt='add-icon'
                      width='25px'
                      className=''
                      onClick={async () => {
                        setHeaderMessage('Yeni Tür Ekle')
                        setInputsName('project_type')
                        await openCategoryModal()
                      }}
                    />
                  )}
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('project_type_id')}
                  name='project_type_id'
                  //defaultValue={activity.issue_category_id}
                >
                  <option selected disabled hidden>
                    Proje Türü Seç
                  </option>
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.project_type_id && formik.errors.project_type_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.project_type_id}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* end::Input Kategori */}
            </div>

            <div className='row mb-7 d-flex'>
              {/* Begin::Input Başlangıç Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Proje Hedef Başlangıç Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                    {
                      'is-valid': formik.touched.start_date && !formik.errors.nastart_dateme,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.start_date && formik.errors.start_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.start_date}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Başlangıç tarihi */}
              {/* Begin::Input Bitiş Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Proje Hedef Bitiş Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                    {
                      'is-valid': formik.touched.end_date && !formik.errors.end_date,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.end_date && formik.errors.end_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.end_date}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Bitiş tarihi */}
            </div>

            {/* begin::Input Açıklama */}
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-5'>Proje Amacı</label>
              {/* end::Label */}
              <textarea placeholder='Giriniz' {...formik.getFieldProps('description')} name='description' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Açıklama */}

            <div className='mb-7'>
              {/* <div className='col-xs-12 col-md-6'> */}
              {/* begin::Label */}
              <div className='d-flex justify-content-between'>
                <label className='required fw-bold fs-7 mb-2'>Proje Sınıfı</label>
                {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && (
                  <img
                    src={toAbsoluteUrl('/media/icons/add.png')}
                    alt='add-icon'
                    width='25px'
                    className=''
                    onClick={async () => {
                      setHeaderMessage('Yeni Sınıf Ekle')
                      setInputsName('project_class')
                      await openCategoryModal()
                    }}
                  />
                )}
              </div>

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('project_class_id')}
                name='project_class_id'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Proje Sınıfı Seçiniz
                </option>
                {classes.map((cl: any) => {
                  return (
                    <option key={cl.id} value={cl.id}>
                      {cl.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.project_class_id && formik.errors.project_class_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_class_id}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-7'>
              {/* <div className='col-xs-12 col-md-6'> */}
              {/* begin::Label */}
              <div className='d-flex justify-content-between'>
                <label className=' required fw-bold fs-7 mb-2'>Proje Planı</label>
                {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && (
                  <img
                    src={toAbsoluteUrl('/media/icons/add.png')}
                    alt='add-icon'
                    width='25px'
                    className=''
                    onClick={async () => {
                      setHeaderMessage('Yeni Plan Ekle')
                      setInputsName('project_plan')
                      await openCategoryModal()
                    }}
                  />
                )}
              </div>

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Proje planı seç'
                {...formik.getFieldProps('project_plan_id')}
                name='project_plan_id'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Proje Planı
                </option>
                {plans.map((cl: any) => {
                  return (
                    <option key={cl.id} value={cl.id}>
                      {cl.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.project_plan_id && formik.errors.project_plan_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_plan_id}</span>
                  </div>
                </div>
              )}
              {/* </div> */}
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              {/* <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
              {/* <label className='required fw-bold fs-6 mb-2'>Proje Adı</label> */}
              {/* end::Label */}

              {/* begin::Input */}
              {/* <input
                  placeholder='Başlık'
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
                {/* end::Input */}
              {/* </div>  */}

              {/* end::Input Kategori */}
            </div>
            {/* <div className='mb-7'>
              {/* begin::Label */}
            {/* <label className=' fw-bold fs-7 mb-2'>Proje Bütçe Kategorisi*</label>
              end::Label */}
            {/* begin::Input */}
            {/* <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('assigned_by')}
                name='assigned_by'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
            {/* <option selected disabled hidden>
                  Seçiniz
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select> */}
            {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div> */}
            {/* begin:: Bildirimler */}
            {/* <div className='mb-7 fv-row'>
              <div className='d-flex flex-stack'>
                <div className='fw-bold me-5'>
                  <label className='fs-6'>Bildirim</label>
                  <div className='fs-7 text-gray-400'>
                    Eklendiğinde bildirim gönderilecektir
                  </div>{' '}
                </div>

                <div className='d-flex align-items-center'>
                  <label className='form-check form-check-custom form-check-solid me-10'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      {...formik.getFieldProps('email_notification')}
                      name='email_notification'
                      value='email_notification'
                      defaultChecked={project.email_notification}
                    />
                    <span className='form-check-label fw-bold'>Email</span>
                  </label>

                  <label className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      {...formik.getFieldProps('phone_notification')}
                      name='phone_notification'
                      value='phone_notification'
                      defaultChecked={project.phone_notification}
                    />

                    <span className='form-check-label fw-bold'>Phone</span>
                  </label>
                </div>
              </div>
              {/* {formik.touched.phone_notification && formik.errors.phone_notification && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            {/* </div> */}
            {/* end:: Bildirimler */}

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting}>
                İptal
              </button>

              <button type='submit' className='btn' disabled={formik.isSubmitting || !formik.isValid || !formik.touched || activities} style={{color: 'white'}}>
                <span className='indicator-label'>Bütçe</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </form>
      </div>
      <Modal show={showCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={headerMessage} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu={inputsName}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
