import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setResult: any
  setCategory: any
  setDateTime: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilters: FC<Props> = ({
  setResult,
  setCategory,
  setDateTime,
  handleClose,
  setSearchKeyClick,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setResult(selectedResult)
    setCategory(selectedCategory)
    setDateTime(selectedDateTime)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [inputWord, setInputWord] = useState('')
  const [selectedResult, setSelectedResult] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedDateTime, setSelectedDateTime] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      {/* <div className='px-7 py-5'>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setInputWord(e.target.value)
          }}
        />
      </div> */}
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Result</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedResult(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Category</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCategory(e.target.value)
          }}
        />
      </div>
          
        <div className='px-7 py-5'>
          <label className='form-label fw-bold'>Date</label>
          <input
                placeholder=''
                type='date'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                onChange={(e) => {
                    setSelectedDateTime(e.target.value)
                  }}
              />
        </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
