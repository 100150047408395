import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport} from '../core/request'
import {DetailFilters} from './components/DetailFilter'
import {WebTrafficItem} from './components/WebTrafficItem'

export const WebTraffic: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setResult('')
    setCategory('')
    setDateTime('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [result, setResult] = useState('')
  const [category, setCategory] = useState('')
  const [dateTime, setDateTime] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (result != '') {
      queryParams += '&result=' + result
    }
    if (category != '') {
      queryParams += '&category=' + category
    }
    if (dateTime != '') {
      queryParams += '&date_and_time=' + dateTime
    }
    GetReport('/web_traffics', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, result, category, dateTime])

  const exportFile = () => {
    window.open(`${ExportLink}/web_traffics?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Category Classification Number'} widgetId={'39'} plusOrMinus={true} linkToPage={''} />
          </div>

          {/* end::Col */}

          {/* end::Col */}
        </div>
      </div>
      <div className='card card-flush  border-top-0 border-bottom-0'>
        <div className='card-body pt-3'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center position-relative my-3'>
              <HeaderInfo api={'/web_traffics_check'} />
            </div>
            <div>
              <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                Detaylı Ara
              </Button>
              <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                Dışarı Aktar
              </Button>
            </div>
          </div>
          {loading ? (
            <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Yükleniyor...</span>
              </div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                <tr style={{color: '#50c8da'}}>
                  <th className=''>Result</th>
                  <th className=''>Date and TİME</th>
                  <th className=''>BANDWİDTH</th>
                  <th className=''>CATEGORY</th>
                  <th className=''>USER_DİSPLAY_NAME</th>
                  <th className=''>URL</th>
                  {/* <th className=''>CREATED_AT</th> */}
                </tr>
              </thead>

              <tbody className='fs-6'>
                {items?.length < 1 && !loading ? (
                  <NoMatchRecords isTable={true} />
                ) : (
                  items?.map((item: any) => {
                    return (
                      <>
                        <WebTrafficItem key={item?.id} item={item} />
                      </>
                    )
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
        <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
      </div>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setResult={setResult} setCategory={setCategory} setDateTime={setDateTime} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal>
    </>
  )
}
