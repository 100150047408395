import {FC, useEffect, useState} from 'react'
import {SearchComponent} from '../components/SearchComponent'
import {getUserIssues} from './core/_request'
import {ListIssues} from './ListIssues'
import {TimeLine} from './TimeLine'

type Props = {
  id: any
}
export const Overview: FC<Props> = ({id}) => {
  const [period, setPeriod] = useState('')
  const [status, setStatus] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [issues, setIssues] = useState<any>([])
  useEffect(() => {
    let q = '?q=' + searchKeyClick
    let stat = '&status=' + status
    let per = '&period=' + period
    getUserIssues(id, q + stat + per).then(({data}) => {
      setIssues(data.data)
    })
  }, [searchKeyClick, status, period])
  return (
    <>
      {/* <div id='kt_project_targets_card_pane'>
            <div className='card card-flush'>
                <div className='card-body pt-3'> */}
      <div className='card '>
        <div className='card-body '>
          <TimeLine day={15} user_id={id} />
        </div>
      </div>

      {/* </div>
                </div></div> */}
      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush' style={{border: '1px dashed rgb(1 13 88)'}}>
          <div className='d-flex flex-wrap flex-stack pt-10 ms-5'>
            <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder='Faaliyet Ara' />
            <div className='d-flex flex-wrap '>
              <div className='my-0 me-5'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-sm form-select-solid w-150px h-40px'
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value=''>Tümü</option>
                  <option value='0'>Planlanan</option>
                  <option value='1'>Devam Eden</option>
                  <option value='2'>Tamamlanan</option>
                </select>
              </div>

              <div className='my-0 me-5'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-sm form-select-solid w-150px h-40px'
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value=''>Tümü</option>
                  <option value='daily'>Bugün</option>
                  <option value='weekly'>Hafta</option>
                  <option value='monthly'>Ay</option>
                  <option value='yearly'>{new Date().getFullYear().toString()}</option>
                </select>
              </div>
            </div>
          </div>
          <div className='card-body pt-3rounded'>
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th className=''>FAALİYET</th>
                  <th className=''>KATEGORİ</th>
                  <th className=''>BAŞlANGIÇ TARİHİ</th>
                  <th className=''>BİTİŞ TARİH</th>
                  <th className=''>SORUMLU</th>
                  <th className=''>DURUM</th>
                </tr>
              </thead>

              <tbody className='fs-6'>
                {issues.map((activity: any) => {
                  return (
                    <ListIssues
                      key={activity.id}
                      id={activity.id}
                      title={activity.title}
                      categoryName={activity.category.name}
                      start_date={activity.start_date}
                      end_date={activity.end_date}
                      userName={activity.assigned?.name}
                      status={activity?.status}
                      issue={activity}
                      assigned_id={activity.assigned?.id}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
