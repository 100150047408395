import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const CriticalInventoryItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='text-gray-500'>{item?.ref_id}</td>
        <td className='text-gray-500'>{item?.unit_name}</td>
        <td className='text-gray-500'>{item?.level}</td>
        <td className='text-gray-500'>{item?.asset_count}</td>
        <td className='text-gray-500'>{item?.critical_asset_count}</td>
      </tr>
    </>
  )
}
