import moment from 'moment'
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import swal from 'sweetalert'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ButtonActive} from '../../Activities/components/ButtonActive'
import {ModalHeader} from '../../components/ModalHeader'
import {DeleteTicket} from '../core/_requests'
import {AddTicket} from '../supplier-edit-modal/AddTicket'

type Props = {
  ticket: any
  refresh: boolean
  setRefresh: any
  supplier_id: any
}
export const Ticket: FC<Props> = ({ticket, refresh, setRefresh, supplier_id}) => {
  const [showTicketModal, setShowticketModal] = useState(false)
  const openTicketModal = () => {
    setShowticketModal(true)
  }
  const closeTicketModal = () => {
    setShowticketModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteTicket(id).then(() => {
          swal('Silindi!', 'Ticket başarı ile silindi.', 'success')
          refresh ? setRefresh(false) : setRefresh(true)
        })
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <tr className='d-flex justify-content-between'>
        <td colSpan={10} className='fs-4'>
          {' '}
          {ticket?.case_name} <br />
          <div className='d-flex justify-content-between'>
            <span className='text-gray-600 fs-8 me-5'>
              {moment(ticket?.start_date).format('DD.MM.YYYY')} / {ticket?.end_date ? moment(ticket?.end_date).format('DD.MM.YYYY') : '-'}
            </span>{' '}
            <span data-kt-menu-trigger='click' className={`badge badge-${ticket?.status?.id == '0' ? 'light-success' : 'light-primary'} fw-bolder me-auto`}>
              {ticket?.status?.name}
            </span>
          </div>
          <br />
          <span className='text-gray-300 fs-6'>{ticket?.case_detail}</span>
        </td>
        {user.id == ticket?.created_by?.id || user?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 ? (
          <tr className='text-end'>
            <td className='min-w-80px'>
              <ButtonActive text={'Düzenle'} clickFunc={openTicketModal} />
            </td>
            <td className='min-w-80px'>
              <ButtonActive text={'Sil'} clickFunc={() => swalDelete(ticket?.id)} />
            </td>
          </tr>
        ) : (
          ''
        )}
      </tr>
      <Modal show={showTicketModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Ticket-Case Ekle'} />
          <AddTicket ticket={ticket} supplier_id={supplier_id} handleClose={closeTicketModal} update={true} />
        </Modal.Body>
      </Modal>
    </>
  )
}
