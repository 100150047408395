import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {ID} from '../../../../_theme/helpers'
import {ModalHeader} from '../../components/ModalHeader'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {Comments} from './components/Comments'
import {PoCInfo} from './components/PoCInfo'
import {AddComment} from './edit-modal/AddComment'

type LocationState = {
  id: ID
  title: string
  start_date: string
  end_date: string
  duration: string
  system_resource: string
  affected_side: string
  detail: string
  actions: string
  action_users: [
    {
      id: ID
      name: string
    }
  ]
  assignment: {
    id: ID
    name: string
  }
  status: {
    id: ID
    name: string
  }
}

export const PocDetail: FC = () => {
  const location = useLocation()
  let data = location.state as LocationState
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const {refreshPage} = useRefresh1()

  useEffect(() => {}, [])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            <div id='kt_app_content_container' className='app-container container-fluid p-0'>
              <div className='d-flex flex-column flex-lg-row '>
                <PoCInfo pocId={data?.id} />
                <div className='w-100 pt-0 p-10 pe-0'>
                  <div className='tab-content' id='myTabContent'>
                    <div className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-2 d-flex justify-content-end'>
                      {/* <div className='d-flex justify-content-end w-200px me-0 pe-0'>
                        <div className='d-flex justify-content-end me-0 pe-0' > */}
                      <div onClick={handleShow} className='btn text-end' style={{color: 'white'}}>
                        Görev Aksiyonu Ekle
                      </div>
                      {/* </div>
                      </div> */}
                    </div>

                    <div className='tab-pane fade show active' id='kt_user_view_overview_tab' role='tabpanel'>
                      <div className='card card-flush mb-6 mb-xl-9'>
                        <div className='card-header mt-6'>
                          <Comments PocId={data?.id} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Not Ekle'} />
          <AddComment handleClose={handleClose} PocId={data?.id} />
        </Modal.Body>
      </Modal>
    </>
  )
}
