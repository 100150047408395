import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {ISAMItem} from './components/ISAMItem'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddDLP} from './add-DLP/Add-DLP'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {DetailFilters} from './components/DetailFilter'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const ISAM: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [dlp, setDLP] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setLastUpdate('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [lastUpdate, setLastUpdate] = useState('')
  const [clientInstallationVersion, setClientInstallationVersion] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (lastUpdate != '') {
      queryParams += '&last_update=' + lastUpdate
    }
    if (clientInstallationVersion != '') {
      queryParams += '&client_installation_version=' + clientInstallationVersion
    }
    GetReport('/dlp_statistics', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setDLP(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, lastUpdate, clientInstallationVersion, show, refresh])

  const exportFile = () => {
    window.open(`${ExportLink}/dlp_statistics?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        <div className='row g-5 g-xxl-10'>
          {/* begin::Col */}

          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-10'>
            <PieChart name={'DLP İstatistikleri'} link={'/dlp_statistics/1'} titleText={'Haftalık Durum'} widgetId={'37'} plusOrMinus={true} linkToPage={''} setSelectedValue={setClientInstallationVersion} />
            {/* <div className='card h-100'>
                <div className='card-body p-3'>
                  <div className='fs-2 fw-bolder'> DLP İstatistikleri </div>
                  <div className='fs-4 fw-bold text-gray-400 mb-7'>Haftalık Durum</div>
                  <div>
                    <Chart options={chart3} series={planC} type='pie' />
                  </div>
                </div>
              </div> */}
          </div>
          {/* <div className='col-xl-8 col-xxl-8 mb-xl-6 mb-xxl-12'>
              <ChartsWidget3
                className='h-100'
                chartHeight={200}
                verticalTitle={'CLIENT ADETI'}
                horizentalTitle={'Forcepoint Ajan Versiyonlari'}
                pointTitle={'Adet'}
                plusOrMinus={true}
                widgetId={'38'}
              />
            </div> */}
          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <HeaderInfo api={'/dlp_statistics_check'} />
                <div>
                  <Button className='btn mt-3 mx-5' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '0px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>hostname</th>
                      <th className=''>ip address</th>
                      <th className=''>Logged In Users</th>
                      <th className=''>last update</th>
                      <th className=''>Client Status</th>
                      <th className=''>Endpoint Server</th>
                      <th className=''>Client Installation Version</th>
                      <th className=''>Policy Engine Version</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {dlp?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      dlp?.map((dlp: any) => {
                        return (
                          <>
                            <ISAMItem key={dlp?.id} isam={dlp} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddDLP handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} setLastUpdate={setLastUpdate} />
        </Modal.Body>
      </Modal>
    </>
  )
}
