import {FC} from 'react'
type Props = {
  item: any
  tableId: any
}
export const AudingsAndFindingsItem: FC<Props> = ({item, tableId}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.ref_id}</td>
        {tableId == 1 && <td className='text-gray-500'>{item?.name}</td>}
        {tableId == 1 && <td className='text-gray-500'>{item?.planned_audit_start_date}</td>}
        {tableId == 1 && <td className='text-gray-500'>{item?.planned_audit_end_date}</td>}
        {tableId == 1 && <td className='text-gray-500'>{item?.ref_status}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.data_time_created}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.data_modifier}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.finding}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.ref_status}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.business_unit}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.is_open}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.directorate}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.group_department}</td>}
        {tableId == 2 && <td className='text-gray-500'>{item?.department}</td>}
        {tableId == 3 && <td className='text-gray-500'>{item?.unit_name}</td>}
        {tableId == 3 && <td className='text-gray-500'>{item?.level}</td>}
        {tableId == 3 && <td className='text-gray-500'>{item?.asset_count}</td>}
        {tableId == 3 && <td className='text-gray-500'>{item?.critical_asset_count}</td>}
      </tr>
    </>
  )
}
