import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_theme/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_theme/assets/ts/_utils'
import {PageLink, PageTitle} from '../../_theme/layout/core'
import {HomePage} from '../pages/Home/HomePage'
import {UserModel} from '../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {Blog} from '../pages/Blog/Blog'
import {ActivitesPageWrapper} from '../pages/Activities/ActivitesPageWrapper'
import {AnnouncementsPageWrapper} from '../pages/Announcements/AnnouncementsPageWrapper'
import {ProjectsPageWrapper} from '../pages/Project/ProjectsPageWrapper'
import {BlogPageWrapper} from '../pages/Blog/BlogPageWrapper'
import {SupplierPageWrapper} from '../pages/Supplier/SupplierPageWrapper'
import {Documents} from '../pages/DocumentsShare/Documents'
import DetailPagesWrapper from '../pages/Project/project-details/DetailPagesWrapper'
import {InventoryPageWrapper} from '../pages/Inventory/InventoryPageWrapper'
import {Inventory} from '../pages/Inventory/Inventory'
import {OrganizationPageWrapper} from '../pages/OrganizationalStructure/OrganizationPageWrapper'
import {BudgetsPageWrapper} from '../pages/Budgets/BudgetsPageWrapper'
import {Profile} from '../pages/Profile'
import {OperationalDataWrapper} from '../pages/OperationalData/OperationalDataWrapper'
import {Search} from '../pages/Home/Search'
import {CasesPageWrapper} from '../pages/Case/CasesPageWrapper'
import {SGMPageWrapper} from '../pages/SGM/SGMPageWrapper'
import {ReportPageWrapper} from '../pages/Reports/ReportPageWrapper'
import {EPDataPageWrapper} from '../pages/OperationalData/EndpointProtectionData/EPDataPageWrapper'
import {PocPageWrapper} from '../pages/Project/Poc/PocPageWrapper'
import {Poc} from '../pages/Project/Poc/Poc'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/ana-sayfa' />} />
        {/* Pages */}
        <Route
          path='ana-sayfa'
          element={
            <>
              <PageTitle description={'Merhaba, ' + user.name}> {}</PageTitle>
              {/* <PageTitle>Merhaba{user.name}</PageTitle> */}
              <HomePage />
            </>
          }
        />
        <Route
          path='search'
          element={
            <>
              <PageTitle>Merhaba{user.name}</PageTitle>
              <Search />
            </>
          }
        />
        <Route path='profile' element={<Profile />} />
        <Route path='duyurular/*' element={<AnnouncementsPageWrapper />} />
        <Route path='faaliyetler/*' element={<ActivitesPageWrapper />} />
        <Route path='projeler/*' element={<ProjectsPageWrapper />} />
        <Route path='projettt/*' element={<ProjectsPageWrapper />} />
        <Route path='blog/*' element={<BlogPageWrapper />} />
        <Route
          path='dokuman-yonetimi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Dokümanlar' toDescription='/dokuman-yonetimi'>
                Dokümanlar
              </PageTitle>
              <Documents />
            </>
          }
        />
        <Route path='tedarikci-bilgileri/*' element={<SupplierPageWrapper />} />
        <Route
          path='envanter-yonetimi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Envanter Yönetimi' toDescription='/envanter-yonetimi'>
                Envanter Yönetimi
              </PageTitle>
              <Inventory />
            </>
          }
        />
        <Route
          path='envanter-yonetimi/*'
          element={
            <>
              <InventoryPageWrapper />
            </>
          }
        />
        <Route path='organizasyon-yapisi/*' element={<OrganizationPageWrapper />} />
        <Route path='butce/*' element={<BudgetsPageWrapper />} />
        <Route path='bilgi-guvenligi-verileri/*' element={<OperationalDataWrapper />} />
        <Route path='gorunurluk-izleme-ve-entegrasyon-durumlari/*' element={<OperationalDataWrapper />} />
        <Route path='saldiri-yuzey-yonetimi/*' element={<OperationalDataWrapper />} />
        <Route path='siber-guvenlik-risk-yonetimi-ve-uyumluluk/*' element={<OperationalDataWrapper />} />
        <Route
          path='uc-nokta-koruma-guvenlik-verileri/*'
          element={
            <>
              <EPDataPageWrapper />
            </>
          }
        />
        <Route path='case-yonetimi/*' element={<CasesPageWrapper />} />
        <Route
          path='poc-calismalari/*'
          element={
            <>
              PoC Çalışmaları
              <PocPageWrapper />
            </>
          }
        />{' '}
        <Route
          path='poc-calismalari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='PoC Çalışmaları'>
                PoC Çalışmaları
              </PageTitle>
              <Poc />
            </>
          }
        />
        <Route path='sgm-islemleri/*' element={<SGMPageWrapper />} />
        <Route path='raporlar/*' element={<ReportPageWrapper />} />
        <Route path='poc-calismalari/*' element={<PocPageWrapper />} />
        <Route
          path='projeler/detail/*'
          element={
            <>
              <DetailPagesWrapper />
            </>
          }
        />
        <Route
          path='blog'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bilgi Paylaşım Platformu'>
                Blog
              </PageTitle>
              <Blog />
            </>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
