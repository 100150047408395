import React, {useEffect, useState} from 'react'
import './../style/style2.css'
import {ProjectsList} from './components/ProjectsList'
import {Modal, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import {AddProject} from './project-edit/AddProject'
import {ModalHeader} from '../components/ModalHeader'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import {SearchComponent} from '../components/SearchComponent'
import {getProjects} from './core/requests'
import {ProjectPieWidget} from '../components/widgets/ProjectPieWidgets'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {ExportLink, updateHomeWidgets, updateReportWidgets} from '../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import Swal from 'sweetalert2'
import {ProjectCards} from './ProjectCards'

const ProjectHomePage: React.FC = () => {
  const [listShow, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [totalProject, setTotalProject] = useState(0)
  const handleShow = () => setModalShow(true)
  const handleClose = () => setModalShow(false)
  const [modalShow, setModalShow] = useState(false)
  const [prdCount, setPrdCount] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')

  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [directorateId, setDirectorateId] = useState<any>('')
  const getDirectorateId = (id: string) => {
    setDirectorateId(id)
  }
  const exportFile = () => {
    window.open(`${ExportLink}/project?token=${loginUser.token}`)
  }
  const showListIssueToHomepage = () => {
    Swal.fire({
      title: 'Widget hangi sayfaya eklensin?',
      input: 'select',
      inputOptions: {
        '1': 'Ana Sayfa',
        '2': 'GMY Raporu',
        '3': 'Kalite Raporu',
        '4': 'Haftalık Rapor',
      },
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ekle',
      showLoaderOnConfirm: false,
      // showCancelButton: true,
      inputValidator: function (value) {
        return new Promise(function (resolve, reject) {
          if (value !== '') {
            resolve('')
          } else {
            reject('Sayfa seçmeniz lazım')
          }
        })
      },
    }).then(async function (result) {
      if (result?.value == '1') {
        updateHomeWidgets(loginUser.id, {widgets: [{id: '75', value: true}]})
        await refreshPage()
      } else if (result?.value == '2') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '75', value: true}], page: 61})
      } else if (result?.value == '3') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '75', value: true}], page: 62})
      } else if (result?.value == '4') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '75', value: true}], page: 63})
      }
    })
  }
  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') {
      link = '&q=' + searchKeyClick
    }
    getProjects('?page[size]=1', link).then(async ({data}) => {
      setTotalProject(data?.meta?.total)
      setLoading(false)
    })
  }, [searchKeyClick, selectedPlan, selectedStatus, selectedType, refresh])

  return (
    <>
      {loading ? (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
          <Spinner animation='border' variant='info' style={{width: '50px', height: '50px'}} />
        </div>
      ) : totalProject > 0 ? (
        <div>
          <div className='row g-5 g-xxl-3'>
            {/* begin::Col */}
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <ProjectPieWidget
                key={9}
                widgetId={9}
                refresh={refresh}
                text={'Mevcut Projeler'}
                plusOrMinus={true}
                setSelectedStatus={setSelectedStatus}
                setSelectedPlan={setSelectedPlan}
                setSelectedType={setSelectedType}
                setSelectedName={setSelectedName}
                linkToPage={''}
                pageId='8'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}

            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <ProjectPieWidget
                key={11}
                widgetId={11}
                text={'Proje Aşaması'}
                plusOrMinus={true}
                refresh={refresh}
                setSelectedStatus={setSelectedStatus}
                setSelectedPlan={setSelectedPlan}
                setSelectedType={setSelectedType}
                setSelectedName={setSelectedName}
                linkToPage={''}
                pageId='8'
              />
            </div>
            {/* end::Col */}
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <ProjectPieWidget
                key={10}
                widgetId={10}
                text={'Çalışma Türüne Göre'}
                plusOrMinus={true}
                refresh={refresh}
                setSelectedStatus={setSelectedStatus}
                setSelectedPlan={setSelectedPlan}
                setSelectedType={setSelectedType}
                setSelectedName={setSelectedName}
                linkToPage={''}
                pageId='8'
              />
            </div>
          </div>

          <div className='row  ' style={{minWidth: '400px', minHeight: '60px'}}>
            <div className='col-12 h-100' style={{minWidth: '400px', minHeight: '60px'}}>
              <DirectorateWidget title='Müdürlük Bazlı Projeler' getSelectedId={getDirectorateId} setSelectedDirectorate={setSelectedDirectorate} widgetId={13} plusOrMinus={true} linkToPage={''} pageId='8' />
            </div>
          </div>

          <div className='d-flex mb-6 mt-5'>
            <div className='row w-100'>
              <div className='d-flex flex-stack mb-6'>
                <h3 className='fw-bolder my-2'>
                  {' '}
                  {selectedName !== '' ? selectedName + ' için t' : 'T'}oplam {prdCount} adet proje bulundu
                </h3>
                {selectedDirectorate != '' && (
                  <span className='badge badge-light badge-lg me-2 px-9 py-4' id='kt_user_follow_button'>
                    {selectedDirectorate}
                  </span>
                )}

                <div className='d-flex flex-wrap my-2 align-items-center'>
                  <ul className='nav nav-pills me-5'>
                    <li className='nav-item m-0'>
                      <a
                        style={{backgroundColor: listShow ? 'rgb(1 13 88)' : ''}}
                        className={`btn btn-sm btn-icon btn-light btn-color-muted me-3`}
                        //data-bs-toggle='tab'
                        onClick={() => setShow(true)}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
                            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                              <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                              <rect x='14' y='5' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                              <rect x='5' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                              <rect x='14' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </li>
                    <li className='nav-item m-0'>
                      <a
                        style={{backgroundColor: listShow ? '' : 'rgb(1 13 88)'}}
                        className={`btn btn-sm btn-icon btn-light btn-color-muted `}
                        // data-toggle='tab'
                        onClick={() => setShow(false)}
                      >
                        <span className='svg-icon svg-icon-2'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <path d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z' fill='currentColor' />
                            <path
                              opacity='0.3'
                              d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className='me-4'>
                    <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder='Proje Ara' />
                  </div>

                  {loginUser?.permissions.filter((permission) => permission.name == 'Create Project').length > 0 && (
                    <button onClick={handleShow} className='btn me-2'>
                      Yeni Proje
                    </button>
                  )}

                  {!listShow ? (
                    <>
                      <div onClick={exportFile} className='btn me-2'>
                        Dışarı Aktar
                      </div>
                      <div className='p-1'>
                        <div onClick={showListIssueToHomepage}>
                          <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex ' style={{border: '1px solid rgb(1 13 88)', color: 'rgb(1 13 88)'}}>
                            +
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row g-5 g-xxl-5' style={{width: '103.5%'}}>
            {listShow ? (
              <ProjectCards modalShow={modalShow} setCount={setPrdCount} directorateID={directorateId} searchKeyClick={searchKeyClick} selectedType={selectedType} selectedStatus={selectedStatus} selectedPlan={selectedPlan} />
            ) : (
              <ProjectsList modalShow={modalShow} setCount={setPrdCount} directorateID={directorateId} searchKeyClick={searchKeyClick} selectedType={selectedType} selectedStatus={selectedStatus} selectedPlan={selectedPlan} />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className='d-flex flex-column flex-row-auto flex-center'>
            <h2>Hiç Proje Bulunamadı!</h2>
            <p className='mt-2 w-400px fw-bold text-gray-600 text-center'>Yeni bir proje oluşturmak için lütfen sizden istenilen proje adımlarını tamamlayarak ilerleyebilirsiniz.</p>
            <a onClick={handleShow} className='btn btn-primary mt-6'>
              Yeni Proje Oluştur
            </a>
          </div>
          <div
            style={{
              height: '200px',
              width: '100%',
              borderRadius: '10px',
              color: 'black',
              fontSize: '16px',
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '500',
              marginTop: '12px',
              marginBottom: '12px',
              textAlign: 'center',
            }}
            className='d-flex justify-content-center'
          >
            <img
              style={{
                height: '200px',
                width: 'auto',

                textAlign: 'center',
              }}
              alt='Logo'
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
              className=''
            />
          </div>
        </div>
      )}

      <Modal show={modalShow} size='xl'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Yeni Proje'} />
          <AddProject
            setModalShow={handleClose}
            activity={{
              id: undefined,
              title: '',
              content: '',
              start_date: '',
              end_date: '',
              phone_notification: false,
              email_notification: false,
              comments: [],
              //issue_category_id: ''
              //assigned_by: '',
            }}
            update={false}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ProjectHomePage}
