/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {ID, KTSVG, toAbsoluteUrl} from '../../../../_theme/helpers'

import swal from 'sweetalert'

import moment from 'moment'

type Props = {
project:any
}

const ProjectItem: FC<Props> = ({project}) => {
  return (
    <tr>
      <td className='text-gray-500'>
    
          {project?.name}
      </td>
      <td className='text-gray-500'>{project?.group_directorate?.name}</td>
      <td className='text-gray-500'>{project?.directorate?.name}</td>
      <td className='text-gray-500'>{project?.class?.name} </td>
      <td className='text-gray-500'>{project?.type?.name} </td>
      <td className='text-gray-500'>{project?.category?.name} </td>
      <td className='text-gray-500'> {moment(project?.start_date).format('DD.MM.YYYY')}</td>
      <td className='text-gray-500'> {moment(project?.end_date).format('DD.MM.YYYY')}</td>
      <td className='text-gray-500'>{project?.description}</td>
    </tr>
  )
}

export {ProjectItem}

