import {useFormik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import swal from 'sweetalert'
import {CreateTask, deleteTask, updateTask} from '../../core/requests'
import Multiselect from 'multiselect-react-dropdown'
import './style.css'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'

type Props = {
  setCount: any
  target: any
  users: any
  targetCategories: any
  target_id?: any
  update: boolean
  removeTarget?: any
}

export const AddTargetBodyTest1: FC<Props> = ({setCount, targetCategories, target, users, target_id, update, removeTarget}) => {
  const [localUpdate, setUpdate] = useState<boolean>(update)
  const [loading, setLoading] = useState(false)
  const [selectUsers, setUsers] = useState<any>(target?.users ? target?.users : [])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        deleteTask(target.id)
          .then(() => {
            removeTarget(target)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  const [userForEdit] = useState<any>({
    ...target,
    title: target?.title,
    target_id: target_id,
    target_category_id: target?.plan?.id,
    start_date: target?.planned_start_date,
    end_date: target?.planned_end_date,
    actual_start_date: target?.actual_start_date,
    actual_end_date: target?.actual_end_date,
    content: target?.note,
    other_user: target?.other_user,
    percentage: target?.percentage,
  })
  const [userFor] = useState<any>({
    title: '',
    target_id: '',
    target_category_id: '',
    start_date: '',
    end_date: '',
    actual_start_date: '',
    actual_end_date: '',
    content: '',
    other_user: '',
    percentage: '',
  })

  const targetSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu alan'),
    // target_category_id: Yup.string(),
    // content: Yup.string(),
    //assigned_by: Yup.string().required('Zorunlu alan'),
    start_date: Yup.date()
      // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')

      .required('Zorunlu alan'),
    end_date: Yup.date()
      .when('start_date', (start_date, yup) => start_date && yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz'))
      // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
      // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
      .required('Zorunlu alan'),

    // actual_start_date: Yup.date()
    //   .nullable()
    //   .notRequired()
    // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez'),
    //   .when(
    //     'end_date',
    //     (end_date, yup) =>
    //       end_date &&
    //       yup.max(new Date(end_date), 'Lütfen bitiş tarihinden önceki tarihlerden seçiniz')
    //   ),

    // actual_end_date: Yup.date()
    //   .nullable()
    //   .notRequired()
    // .when('actual_start_date', (actual_start_date, yup) => actual_start_date && yup.min(new Date(actual_start_date), 'Lütfen geçerli bir tarih seçiniz')),
    //   .max(new Date(Date.now()), 'Lütfen geçerli bir tarih seçiniz'),
    percentage: Yup.string().required('Zorunlu alan'),
  })

  const targetSchemaForUpdate = Yup.object().shape({
    actual_start_date: Yup.date()
      // .notRequired()
      // .nullable()
      // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez'),
      .max(new Date(Date.now()), 'İlerki Tarih Girilemez'),
    actual_end_date: Yup.date()
      .notRequired()
      .nullable()
      .max(new Date(Date.now()), 'İlerki Tarih Girilemez')
      .when('actual_start_date', (actual_start_date, yup) => actual_start_date && yup.min(new Date(actual_start_date), 'Lütfen geçerli bir tarih seçiniz')),
    //   .max(new Date(Date.now()), 'Lütfen geçerli bir tarih seçiniz'),
    // .max('actual_start_date', 'cklds'),
  })
  const formik = useFormik({
    initialValues: update ? userForEdit : userFor,
    validationSchema: update ? targetSchemaForUpdate : targetSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        if (localUpdate) {
          let usersId = []
          if (selectUsers?.length < 1) {
            usersId.push({id: loginUser?.id})
          } else {
            for (let i = 0; i < selectUsers?.length; i++) {
              usersId.push({id: selectUsers[i]?.id})
            }
          }
          let UpdTarget = {
            title: values.title,
            // project_plan_id: Number(values.target_category_id)!=0?Number(values.target_category_id):'',
            planned_start_date: values.start_date,
            planned_end_date: values.end_date,
            actual_start_date: values.actual_start_date,
            actual_end_date: values.actual_end_date,
            note: values.content,
            users: usersId,
            milestone_id: Number(target_id),
            other_user: values.other_user,
            percentage: values.percentage,
          }
          await updateTask(target.id, UpdTarget)
            .then(() => {
              setLoading(false)
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          setLoading(false)
        } else {
          let usersId = []
          if (selectUsers.length < 1) {
            usersId.push({id: loginUser?.id})
          } else {
            for (let i = 0; i < selectUsers.length; i++) {
              usersId.push({id: selectUsers[i]?.id})
            }
          }

          const body = {
            title: values.title,
            // project_plan_id: Number(values.target_category_id)!=0?Number(values.target_category_id):'',
            planned_start_date: values.start_date,
            planned_end_date: values.end_date,
            actual_start_date: '',
            actual_end_date: '',
            note: values.content,
            users: usersId,
            revision_date: '',
            revision_note: '',
            milestone_id: Number(target_id),
            other_user: values.other_user,
            percentage: values.percentage,
          }

          await CreateTask(body)
            .then((data) => {
              setCount(data?.data?.data)
              setLoading(false)
              reset()
              resetForm()
              setUsers([])
              // swal('Eklendi', 'Hedef başarı ile eklendi.', 'success', {timer: 2000})
              // window.location.reload()
            })
            .catch((err) => {
              setLoading(false)
              swal('Hata!', err?.response?.data?.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
        setLoading(true)
      } finally {
        setSubmitting(true)
        setLoading(false)
      }
    },
  })

  const reset = () => {
    formik.resetForm()
  }

  return (
    <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form row border-primary border-bottom-dashed align-items-center py-2' noValidate style={{zIndex: '100000', height: 'auto'}}>
      {/* <div className='col p-1' style={{zIndex: '100000'}}>
        {' '}
        <div className='w-100 ' style={{minWidth: '100px'}}>
          <select
            className='form-select form-select-solid'
            data-control='select2'
            data-hide-search='true'
            data-placeholder='Kategori Seç'
            {...formik.getFieldProps('target_category_id')}
            name='target_category_id'
            //defaultValue={activity.assigned_by}
            onKeyDown={handleKeyPress}
          >
            <option hidden>Proje Aşaması Seç</option>
            {targetCategories.map((category: any) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              )
            })}
          </select>
          {formik.touched.target_category_id && formik.errors.target_category_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.target_category_id}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className='col p-1'>
        {' '}
        <div className='w-100' style={{minWidth: '100px'}}>
          {/* begin::Label */}
          {/* <label className='required fw-bold fs-6 mb-2'></label> */}
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz...'
            {...formik.getFieldProps('title')}
            type='text'
            name='title'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.title && formik.errors.title},
              {
                'is-valid': formik.touched.title && !formik.errors.title,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
            //onKeyDown={handleKeyPress}
          />
          {formik.touched.title && formik.errors.title && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.title}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
      </div>
      <div className='col p-1' style={{height: 'auto'}}>
        {' '}
        <div className='w-100 overflov-auto' style={{height: 'auto'}}>
          {/* begin::Label */}

          {/* end::Label */}
          {/* begin::Input */}
          <Multiselect
            style={{height: 50}}
            options={users} // Options to display in the dropdown
            selectedValues={selectUsers} // Preselected value to persist in dropdown
            onSelect={(event: any) => {
              setUsers(event)
            }} // Function will trigger on select event
            onRemove={(event: any) => {
              setUsers(event)
            }} // Function will trigger on remove event
            displayValue='name'
            showCheckbox // Property name to display in the dropdown options
            placeholder='Seç'
          />

          {formik.touched.assigned_by && formik.errors.assigned_by && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.assigned_by}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col p-1'>
        {' '}
        <div className='w-100' style={{minWidth: '100px'}}>
          {/* begin::Label */}
          {/* <label className='required fw-bold fs-6 mb-2'></label> */}
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz...'
            {...formik.getFieldProps('other_user')}
            type='text'
            name='other_user'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.other_user && formik.errors.other_user},
              {
                'is-valid': formik.touched.other_user && !formik.errors.other_user,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
            //onKeyDown={handleKeyPress}
          />
          {formik.touched.other_user && formik.errors.other_user && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.other_user}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
      </div>
      <div className='col p-1'>
        <div className='w-100'>
          <input
            placeholder=''
            type='date'
            {...formik.getFieldProps('start_date')}
            name='start_date'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.start_date && formik.errors.start_date},
              {
                'is-valid': formik.touched.start_date && !formik.errors.start_date,
              }
            )}
            autoComplete='off'
            // onKeyDown={handleKeyPress}
            disabled={formik.isSubmitting}
          />
          {formik.touched.start_date && formik.errors.start_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.start_date}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col p-1'>
        <div className='w-100'>
          <input
            placeholder=''
            type='date'
            {...formik.getFieldProps('end_date')}
            name='end_date'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.end_date && formik.errors.end_date},
              {
                'is-valid': formik.touched.end_date && !formik.errors.end_date,
              }
            )}
            autoComplete='off'
            // onKeyDown={handleKeyPress}
            //disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.end_date && formik.errors.end_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.end_date}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {update ? (
        <>
          <div className='col p-1'>
            <div className='w-100'>
              <input
                placeholder=''
                {...formik.getFieldProps('actual_start_date')}
                type='date'
                name='actual_start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.actual_start_date && formik.errors.actual_start_date,
                  },
                  {
                    'is-valid': formik.touched.actual_start_date && !formik.errors.actual_start_date,
                  }
                )}
                //onKeyDown={handleKeyPress}
                autoComplete='off'
              />
              {formik.touched.actual_start_date && formik.errors.actual_start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.actual_start_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col p-1'>
            <div className='w-100'>
              <input
                placeholder=''
                {...formik.getFieldProps('actual_end_date')}
                type='date'
                name='actual_end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.actual_end_date && formik.errors.actual_end_date},
                  {
                    'is-valid': formik.touched.actual_end_date && !formik.errors.actual_end_date,
                  }
                )}
                autoComplete='off'
              />
              {formik.touched.actual_end_date && formik.errors.actual_end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.actual_end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <div className='col p-1'>
        <div className='w-100'>
          <input placeholder='Hedef ayrıntılarını giriniz' {...formik.getFieldProps('content')} name='content' className='form-control form-control-solid mb-3 mb-lg-0'></input>
          {formik.touched.content && formik.errors.content && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.content}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col p-1'>
        {' '}
        <div className='w-100' style={{minWidth: '100px'}}>
          {/* begin::Label */}
          {/* <label className='required fw-bold fs-6 mb-2'></label> */}
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz...'
            {...formik.getFieldProps('percentage')}
            type='text'
            name='percentage'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.percentage && formik.errors.percentage},
              {
                'is-valid': formik.touched.percentage && !formik.errors.percentage,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
            //onKeyDown={handleKeyPress}
          />
          {formik.touched.percentage && formik.errors.percentage && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.percentage}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
      </div>
      <div className='col p-1'>
        {localUpdate ? (
          <span className='menu-link text-danger px-3' style={{cursor: 'pointer'}} onClick={swalDelete}>
            Sil
          </span>
        ) : (
          ''
        )}
        <button type='submit' id='btnsubmit' className='btn sm-btn  btn-sm' data-kt-users-modal-action='submit' disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update} style={{color: 'white'}}>
          {!loading && <span className='indicator-label'>Gönder</span>}
          {loading && <span className='spinner-border spinner-border-sm align-middle '></span>}
        </button>
      </div>
    </form>
  )
}
