import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const ODATA = `${API_URL}/reports`
const REPORT = `${API_URL}/report`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function GetReport(api: string, filter: string) {
  return await axios.get(ODATA + api + filter)
}

export async function addDLP(body: any) {
  return await axios.post(ODATA + '/dlp_statistics', body, config)
}

export async function GetDLPStatistics(link: string) {
  return await axios.get(ODATA + link)
}

//customer attackt

export async function addCA(body: any) {
  return await axios.post(ODATA + '/customer_attack_statistics', body, config)
}
export async function GetCAStatistics(type: string) {
  return await axios.get(ODATA + '/customer_attack_statistics/' + type)
}

// Vulnerability Notification

export async function addVN(body: any) {
  return await axios.post(ODATA + '/vulnerability_notifications', body, config)
}
export async function GetVNStatistics(type: string) {
  return await axios.get(ODATA + '/vulnerability_notifications/' + type)
}

// Data Securıty Request Number

export async function addDSRN(body: any) {
  return await axios.post(ODATA + '/data_security_request_numbers', body, config)
}
export async function GetDSRN(q: string) {
  return await axios.get(ODATA + '/data_scope_agents' + q)
}
export async function GetDSRNStatistics(type: string) {
  return await axios.get(ODATA + '/data_security_request_numbers/' + type)
}

// Income Assurance Data Summary

export async function addIADS(body: any) {
  return await axios.post(ODATA + '/income_assurance_data', body, config)
}

export async function GetIADSStatistics(type: string) {
  return await axios.get(ODATA + '/income_assurance_data/' + type)
}

// Number of Demand

export async function addNOD(body: any) {
  return await axios.post(ODATA + '/number_of_demands', body, config)
}

export async function GetNODStatistics(type: string) {
  return await axios.get(ODATA + '/number_of_demands/' + type)
}

// Alarm Trekıng

export async function addAT(body: any) {
  return await axios.post(ODATA + '/alarm_tracking', body, config)
}

export async function GetALStatistics(type: string) {
  return await axios.get(ODATA + '/alarm_tracking/' + type)
}

// Mirroring

export async function addMirroring(body: any) {
  return await axios.post(ODATA + '/mirroring', body, config)
}
export async function GetMirroring(q: string) {
  return await axios.get(ODATA + '/mirroring' + q)
}
export async function GetMirroringStatistics(type: string) {
  return await axios.get(ODATA + '/mirroring/' + type)
}

// Services

export async function addReport(name: string, body: any) {
  return await axios.post(ODATA + name, body, config)
}

export async function downloadReport(name: string, q: string) {
  return await axios.get(ODATA + name + '/download')
}
export async function GetReportStatistics(name: string, type: string) {
  return await axios.get(ODATA + name + type)
}

// web traffic

// security score data, status

export async function GetSecurityScoreData(q: string) {
  return await axios.get(ODATA + '/security_score_data' + q)
}

export async function GetSecurityScoreStatus(q: string) {
  return await axios.get(ODATA + '/security_score_status' + q)
}
// malicious statistics

// widgets
export async function GetReportsWidget(link: string) {
  return await axios.get(ODATA + link)
}

export async function GetCheckStatistic(api: string) {
  return await axios.get(ODATA + api)
}
export async function GetUpdatedReport(api: string) {
  return await axios.get(REPORT + api)
}

//ttvm

export async function GetTTVMWeeklyCustomer(link: string) {
  return await axios.get(ODATA + '/ttvm_customer_data_graph/weekly_customer' + link)
}

//kgh

export async function GetKGHWeeklyCustomer(link: string) {
  return await axios.get(ODATA + '/kgh_customer_data_graph/weekly_customer' + link)
}
