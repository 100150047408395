import {FC, useEffect, useState} from 'react'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {GetSuppliers} from '../core/_requests'
import {ListItem} from './ListItem'
import {UsersListHeader} from './userHeader/UsersListHeader'

export const SupplierList: FC = () => {
  const [supplier, setSupplier] = useState([
    {
      id: 1,
      name: '',
      emial: '',
      company_name: '',
      phone: '',
      tax_number: '',
      country: {
        id: 1,
        name: '',
      },
      category: {
        id: 1,
        name: '',
      },
      address: '',
      attachments: [],
      email_notification: false,
      phone_notification: false,
      created_at: '',
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const currentTableData = () => {
    let queryParams = '?q=' + searchKeyClick + '&page[number]=' + currentPage + '&page[size]=' + limit
    if (categoryId != '') {
      queryParams += '&supplier_category_id=' + categoryId
    }

    GetSuppliers(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setSupplier(data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, categoryId])

  return (
    <>
      {/* <KTCard> */}
      <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} setCategoryId={setCategoryId} />
      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className=''>TEDARİKÇİ ADI</th>
                    <th className=''>E-POSTA</th>
                    <th className=''>ÜRETİCİ FİRMA</th>
                    <th className=''>OLUŞTURMA TARİHİ</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className=''>
                  {supplier?.length < 1 && !loading ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    supplier.map((supplier) => {
                      return <ListItem key={supplier.id} supplier={supplier} refreshPage={refreshPage} />
                    })
                  )}
                </tbody>
              </table>
            )}
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
          </div>
        </div>
      </div>
      {/* </KTCard> */}
    </>
  )
}
