import {FC, useState} from 'react'
import {Dropdown, Modal, Popover} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import swal from 'sweetalert'
import {useNavigate} from 'react-router-dom'
import {deleteDoc} from '../core/_requests'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {AssignmentsList} from './AssignmentsList'
import moment from 'moment'
import {login} from '../../../modules/auth/redux/AuthCRUD'
type Props = {
  path: string
  id: string
  attid: string
  name: string
  size: string
  lastMadied: any
  categoryName: string
  fileName: string
  subitem: boolean
  refreshPage: any
  created_by: any
  fileAssignments: any
  created_at?: string
  is_public?: any
}

export const ListFileItem: FC<Props> = ({path, id, attid, name, size, lastMadied, categoryName, fileName, created_by, subitem, refreshPage, fileAssignments, created_at, is_public}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }

  const [showAssignmentsModal, setShowAssignmentsModal] = useState(false)
  const [assignments, setAssignments] = useState<any>([])
  const closeAssignmentsModal = () => {
    setShowAssignmentsModal(false)
  }
  const openAssignmentsModal = async () => {
    await setShowAssignmentsModal(true)
  }

  const filename = (param: any) => {
    let icon = param.split('.')
    icon = icon[icon?.length - 1]
    return icon
  }

  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await deleteDoc(id, attid).then(() => {
          swal('Silindi!', 'Dosya başarı ile silindi.', 'success', {timer: 2000})
          refreshPage()
        })
      }
    })
  }

  const handleDownload = (path: string) => {
    // Replace 'your_image_path.jpg' with the actual path to your JPG file
    const imagePath = path

    // Create a virtual link element
    const link = document.createElement('a')
    link.href = imagePath
    link.download = 'downloaded_image.jpg' // Specify the desired file name

    // Append the link to the document
    document.body.appendChild(link)

    // Trigger a click on the link to start the download
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)
  }

  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <tr style={{borderBottom: '1px dashed rgb(152 156 160)'}}>
        {/* <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input' type='checkbox' value='1' />
          </div>
        </td> */}

        <td data-order='account' className={subitem ? 'px-10' : ''}>
          <div className='d-flex align-items-center'>
            <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
              <img style={{width: '24px'}} src={toAbsoluteUrl(`/media/svg/files/${filename(name)}.svg`)} alt='' />
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z'
                  fill='currentColor'
                />
                <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
              </svg> */}
            </span>

            <a download={fileName} href={path} className='text-gray-800 text-hover-primary'>
              {fileName} <br />
              {/* <span className='text-gray-600 fs-8'> {name}</span> */}
            </a>
          </div>
        </td>

        <td>{(parseInt(size) / 1000000).toFixed(2)} MB</td>

        <td
          onClick={async () => {
            setProfilUserId(lastMadied.id)
            await openProfilModal()
          }}
          className='text-hover-primary'
        >
          {lastMadied?.name}
        </td>
        <td>{categoryName}</td>
        <td className='text-gray-500'>{moment(created_at).format('DD.MM.YYYY')}</td>
        <td className='text-end' data-kt-filemanager-table=''>
          <Dropdown>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                // href={path}
                // download={true}
                onClick={() => handleDownload(path)}
              >
                {' '}
                <a download className='menu-link px-3'>
                  İndir
                </a>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setAssignments(fileAssignments)
                  openAssignmentsModal()
                }}
              >
                {' '}
                <span className='menu-link px-3'>Erişim Bilgisi</span>
              </Dropdown.Item>
              {loginUser?.roles?.filter((role: any) => role?.name === 'Süper Admin')?.length > 0 || loginUser.id == created_by?.id ? (
                <Dropdown.Item onClick={swalDelete}>
                  <span className='menu-link text-danger px-3'>Sil</span>
                </Dropdown.Item>
              ) : (
                ''
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
      <Modal show={showAssignmentsModal} size='lg'>
        <Modal.Body>
          <AssignmentsList handleClose={closeAssignmentsModal} assignments={assignments} is_public={is_public} />
        </Modal.Body>
      </Modal>
    </>
  )
}
function useHistory() {
  throw new Error('Function not implemented.')
}
