import React, {FC, useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {GetReportsWidget} from '../../../OperationalData/core/request'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import {auto, bottom} from '@popperjs/core'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  pageId?: string
  widgetId: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
}
const LineChart: FC<Props> = ({pageId, widgetId, plusOrMinus, linkToPage}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {refreshPage} = useRefresh1()
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [title, setTitle] = useState<any>([])
  const [data, setData] = useState<any>([])
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const yearRange: any[] = []
  // -10 ile +10 yılları içeren bir dizi
  for (let i = currentYear - 15; i <= currentYear + 5; i++) {
    yearRange.push(i)
  }
  useEffect(() => {
    let year = '?year=' + selectedYear
    if (widgetId == '72') {
      GetReportsWidget('/ttvm_customer_data_graph/trend' + year).then((data) => {
        toArray(data.data)
      })
    } else {
      GetReportsWidget('/kgh_customer_data_graph/trend' + year).then((data) => {
        toArray(data.data)
      })
    }
  }, [selectedYear])

  const toArray = (data: any) => {
    let titleTemp = []
    let dataTemp = []

    for (let i = 0; i < data?.length; i++) {
      titleTemp.push(data[i]?.month)
      dataTemp.push(data[i]?.total_count)
    }

    setTitle(titleTemp)
    setData(dataTemp)
  }
  const chartOptions = {
    chart: {
      toolbar: {
        show: false, // Zoom ve Download butonlarını gizler
      },
    },
    xaxis: {
      categories: title,
      labels: {
        style: {
          fontSize: '13px',
        },
      },
      tooltip: {
        enabled: true,

        offsetY: 0,
        style: {
          fontSize: '15px',
        },
      },
    },
    dataLabels: {
      enabled: true,
      display: auto,
      color: '#223388',
      font: {
        size: 10,
      },
    },
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'center',
      fontSize: '20px',
      floating: true,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    colors: ['#ed7d31'],
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,

      style: {
        fontSize: '15px',
      },
    },
  }

  const chartSeries = [
    {
      name: 'series-1',
      data: data,
    },
  ]

  return (
    <div className={`card card-flush pie-chart1`} style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      {/* begin::Header */}

      <div className='d-flex justify-content-end p-3 m-2'>
        <Link to={plusOrMinus ? '' : linkToPage} className='float-left' style={{marginRight: '30%'}}>
          <div className='fs-4 fw-bolder float-left'>Aylık {widgetId == '71' ? 'KGH' : 'TTVM'} Müşteri Artış Trendi</div>
        </Link>

        <>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm form-select-solid w-250px h-50px me-4 mt-n2 '
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option value=''>Tümü</option>
            {yearRange?.map((year: any) => {
              return (
                <option key={year} value={year} selected={year == selectedYear}>
                  {year}
                </option>
              )
            })}
          </select>
          <div
            onClick={() => {
              homeWidgetShow()
            }}
          >
            <span
              className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </>
      </div>

      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body   d-flex justify-content-between flex-column pb-1 px-0'>
        <ReactApexChart options={chartOptions} series={chartSeries} type='line' height={300} />
      </div>
    </div>
  )
}

export default LineChart
