import {FC} from 'react'
import {ID, KTSVG} from '../../../../../_theme/helpers'

type Props = {
  user: {
    id: ID
    name: string
    email: string
    avatar: string
    roles: [
      {
        id: ID
        name: string
      }
    ]
  }
  refreshPage: any
  type: boolean
  setUser: any
  onDelete: any
}
export const UserItem: FC<Props> = ({user, refreshPage, type, setUser, onDelete}) => {
  const add = (user: any) => {
    return type ? '' : setUser(user)
  }
  return (
    <>
      <tr
        onClick={() => {
          add(user)
        }}
      >
        <td>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-45px symbol-circle'>
              {user.avatar ? (
                <div className='symbol symbol-55px symbol-circle'>
                  <img src={user.avatar} alt={user.name} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                  {user.name?.substring(0, 1)}
                </div>
              )}{' '}
            </div>
          </div>
        </td>
        <td className='fw-bolder'>{user.name}</td>
        <td className='text-gray-500'>{user?.roles[0]?.name}</td>
        {type ? (
          <td>
            <a
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={async () => await onDelete(user.id)}
            >
              Sil
            </a>
          </td>
        ) : (
          ''
        )}
      </tr>
    </>
  )
}
