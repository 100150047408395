import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {PluralBarChart} from '../../components/widgets/OperationalData/PluralBarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport} from '../core/request'
import {DetailFilters} from './components/DetailFilter'
import {MaliciousStatisticItem} from './components/MaliciousStatisticItem'

export const MaliciousStatistics: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const downloa = () => {
    window.open(`${ExportLink}/malicious_statistics?token=${loginUser.token}`)
  }
  const [items, setItems] = useState([])
  const [date, setDate] = useState('')
  const [dstip, setDstip] = useState('')
  const [epid, setEpid] = useState('')
  const [euid, setEuid] = useState('')
  const [proto, setProto] = useState('')
  const [srcPort, setSrcPort] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setDate('')
    setDstip('')
    setEpid('')
    setEuid('')
    setProto('')
    setSrcPort('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (date != '') {
      queryParams += '&date=' + date
    }
    if (dstip != '') {
      queryParams += '&dstrip=' + dstip
    }
    if (epid != '') {
      queryParams += '&epid=' + epid
    }
    if (euid != '') {
      queryParams += '&euid=' + euid
    }
    if (proto != '') {
      queryParams += '&proto=' + proto
    }
    if (srcPort != '') {
      queryParams += '&srcport=' + srcPort
    }
    GetReport('/malicious_statistics', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [date, dstip, epid, euid, proto, srcPort])

  return (
    <div>
      <div>
        <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 '>
          <PluralBarChart horizentalTitle={'Son 30 Günlük ve Son 7 Günlük  Veri'} widgetId={'47'} plusOrMinus={true} linkToPage={''} />
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/malicious_statistics_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={downloa}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>

            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>date</th>
                      <th className=''>time</th>
                      <th className=''>ref_id</th>
                      {/* <th className=''>itime</th>
                    <th className=''>euid</th>
                    <th className=''>epid</th>
                    <th className=''>dsteuid</th>
                    <th className=''>dstepid</th>
                    <th className=''>logid</th> */}
                      <th className=''>type</th>
                      <th className=''>subtype</th>
                      <th className=''>level</th>
                      {/* <th className=''>jobid</th> */}
                      <th className=''>risk</th>
                      {/* <th className=''>scanstart</th> */}
                      {/* <th className=''>scanend</th> */}
                      <th className=''>mname</th>
                      <th className=''>fname</th>
                      <th className=''>scrip</th>
                      <th className=''>srcport</th>
                      <th className=''>dstip</th>
                      <th className=''>dstport</th>
                      <th className=''>proto</th>
                      <th className=''>suser</th>
                      <th className=''>stype</th>
                      <th className=''>vmos</th>
                      <th className=''>clientdev</th>
                      <th className=''>clentvd</th>
                      <th className=''>url</th>
                      <th className=''>tzone</th>
                      <th className=''>devid</th>
                      <th className=''>vd</th>
                      <th className=''>dtime</th>
                      <th className=''>itime_t</th>
                      <th className=''>devname</th>
                      <th className=''>created_at</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <MaliciousStatisticItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setDate={setDate} setDstip={setDstip} setEuid={setEuid} setEpid={setEpid} setProto={setProto} setScrPort={setSrcPort} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
