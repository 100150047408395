import Chart from 'react-apexcharts'
import {FC, useEffect, useState} from 'react'
import {auto, bottom, right} from '@popperjs/core'
import {getIssueStatusWidget, getProjectsStatus, getTotalBudgetWidget, updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import NumberFormat from 'react-number-format'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {GetReportsWidget} from '../../OperationalData/core/request'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  widgetId: any
  refresh?: boolean
  text: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
  pageId?: string
}
export const PieWidget: FC<Props> = ({widgetId, refresh, text, plusOrMinus, linkToPage, pageId}) => {
  const [widgetLabels, setWidgetLabels] = useState<any>([])
  const [widgetData, setWidgetData] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {refreshPage} = useRefresh1()
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  useEffect(() => {
    if (widgetId == 7) {
      getIssueStatusWidget().then(({data}) => {
        prdIssueStatus(data)
      })
    } else if (widgetId == 8) {
      getTotalBudgetWidget().then(({data}) => {
        prdTotalBudget(data)
      })
    } else if (widgetId == 9) {
      getProjectsStatus().then(({data}) => {
        prdProjectSatus(data)
      })
    } else if (widgetId == 77) {
      GetReportsWidget('/pentest_asset_statistics_total').then((data) => {
        prdOretaionalStatus(data?.data?.data)
      })
    }
  }, [refresh])

  const prdIssueStatus = (data: any) => {
    let total = 0
    let label = []
    let structuredData = []
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i].total_issues
      label.push(data[i].status_name)
      structuredData.push(data[i].total_issues)
    }
    setWidgetData(structuredData)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const prdTotalBudget = (data: any) => {
    setTotalCount(data.total_capex_budget + data.total_opex_budget - (data.total_capex_usage + data.total_opex_usage))
    let label = []
    let structuredData = []
    label.push('Capex')
    label.push('Opex')
    structuredData.push(data.total_capex_usage)
    structuredData.push(data.total_opex_usage)
    setWidgetData(structuredData)
    setWidgetLabels(label)
  }

  const prdProjectSatus = (data: any) => {
    let total = 0
    let structuredData = []
    let label = []
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i]?.total_project
      structuredData.push(data[i]?.total_project)
      label.push(data[i]?.status_name)
    }
    setWidgetData(structuredData)
    setWidgetLabels(label)
    setTotalCount(total)
  }
  const prdOretaionalStatus = (data: any) => {
    let total = 0
    let label = []
    let structuredData = []
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i].vulnerabilityCount
      label.push(data[i].IP)
      structuredData.push(data[i].vulnerabilityCount)
    }
    setWidgetData(structuredData)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const chart1 = {
    labels: widgetLabels,
    dataLabels: {
      enabled: true,
      display: auto,
      color: '#223388',
      font: {
        size: 100,
      },
    },
    fill: {
      colors: ['#008FFB', '#ff4560', '#00e396', '#ffb01a', '#775dd0', '#98a9af', '#6BFFC7'],
    },
    colors: ['#008FFB', '#ff4560', '#00e396', '#ffb01a', '#775dd0', '#98a9af', '#6BFFC7'],
    legend: {
      show: true,
      position: widgetId == 9 || widgetId == 10 || widgetId == 11 ? right : bottom,
      harizontalAlign: 'center',
      fontSize: '15rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },

    tooltip: {
      style: {
        fontSize: '16px',
        zIndex: 999,
      },

      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ' Adet'
        },
      },
    },
  }

  const textCenterStyles: React.CSSProperties = {
    fontSize: '30px',
    position: 'absolute',
    top: '33%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  }
  return (
    <div className='card  overflow-hidden pie-chart' style={{overflow: 'hidden', backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='card-body p-5 overflow-hidden'>
        <div className='w-100 d-flex justify-content-between'>
          <Link to={plusOrMinus ? '' : linkToPage} style={{color: 'black'}}>
            <div>
              {widgetId == 8 ? (
                <div className='fs-2hx fw-bolder' style={{color: 'rgb(1 13 88)'}}>
                  <NumberFormat value={totalCount} displayType={'text'} thousandSeparator={true} />
                  <span className='fs-2'> ₺</span>
                </div>
              ) : widgetId == 77 ? (
                ''
              ) : (
                <div className='fs-2x fw-bolder ' style={{color: 'rgb(1 13 88)'}}>
                  {totalCount} <span className='fs-3'>adet</span>
                </div>
              )}
              {/* <div className='fs-2hx fw-bolder'>
                    {totalCount} <span className='fs-2'>adet</span>
                  </div> */}
              <div className='fs-5 fw-bold  mb-7' style={{color: 'rgb(1 13 88)'}}>
                {text}
              </div>
            </div>
          </Link>
          <div
            onClick={() => {
              homeWidgetShow()
            }}
          >
            <span
              className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='d-flex justify-content-center w-100 h-100' style={{position: 'relative'}}>
            <Chart
              options={chart1}
              series={widgetData}
              // [data[0].total_issues,data[1].total_issues,data[2].total_issues]}
              type='donut'
              height={'320px'}
            />
            {widgetId == 77 && <div style={textCenterStyles}>{totalCount}</div>}
          </div>
        </Link>
      </div>
    </div>
  )
}
