import React, {FC, useEffect, useState} from 'react'
import {PieWidget} from '../components/widgets/PieWidget'
import {getReportWidgets, moveReportWidgets, updateReportWidgets} from '../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ProjectPieWidget} from '../components/widgets/ProjectPieWidgets'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {ExchangeRates} from '../components/widgets/ExchangeRates'
import {BudgetByCategory} from '../components/widgets/BudgetByCategory'
import {OpexCapexWidget} from '../components/widgets/OpexCapexWidget'
import {PieChart} from '../components/widgets/OperationalData/PieChart'
import {BarChart} from '../components/widgets/OperationalData/BarChart'
import {BarNODChart} from '../components/widgets/OperationalData/BarNODChart'
import {PluralBarChart} from '../components/widgets/OperationalData/PluralBarChart'
import {ChartsWidget4} from '../OperationalData/components/ChartsWidget4'
import TargetListsHomePage from '../Activities/components/TargetListsHomePage'
import ChangeInNumberCustomer from '../components/widgets/OperationalData/ChangeInNumberCustomer'
import LineChart from '../components/widgets/OperationalData/LineChart'
import {CarbonStatisticWidget} from '../components/widgets/OperationalData/CarbonStatisticWidget'

export const WeeklyReport: FC = () => {
  const [refresh, setRefresh] = useState(false)
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(false)
  }
  const [cardList, setCardList] = useState([
    {
      id: 8,
      order: 1,
      class: 'col-xl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: <PieWidget widgetId={8} text={'Kalan Bütçe'} plusOrMinus={false} linkToPage={'/../../butce/anasayfa'} pageId='63' />,
    },
    {
      id: 7,
      order: 3,
      class: 'col-xl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PieWidget widgetId={7} text={'Toplam Faaliyet'} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../faaliyetler/gorevler'} pageId='63' />,
    },
    {
      id: 12,
      order: 4,
      class: 'col-xl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <DirectorateWidget widgetId={12} plusOrMinus={false} refreshPage={refreshPage} title={'Müdürlük Bazlı Faaliyetler'} linkToPage={'/../../faaliyetler/gorevler'} pageId='63' />,
    },
    {
      id: 14,
      order: 5,
      class: 'col-xl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ExchangeRates plusOrMinus={false} widgetId={14} refreshPage={refreshPage} linkToPage={'/../../butce/anasayfa'} pageId='63' />,
    },
    {
      id: 18,
      order: 6,
      class: 'col-xl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <DirectorateWidget widgetId={18} plusOrMinus={false} refreshPage={refreshPage} title={'Kullanıcı Bazlı Faaliyetler'} linkToPage={'/../../faaliyetler/gorevler'} pageId='63' />,
    },
    {
      id: 23,
      order: 7,
      class: 'col-xl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: <TargetListsHomePage plusOrMinus={false} refreshPage={refreshPage} widgetId={23} linkToPage={'/../../faaliyetler/gorevler'} />,
    },
    {
      id: 9,
      order: 8,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ProjectPieWidget widgetId={9} text={''} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../projeler/tum-projeler'} pageId='63' />,
    },
    {
      id: 10,
      order: 9,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ProjectPieWidget widgetId={10} text={'Çalışma Türüne Göre'} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../projeler/tum-projeler'} pageId='63' />,
    },
    {
      id: 11,
      order: 10,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ProjectPieWidget widgetId={11} text={'Proje Aşaması'} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../projeler/tum-projeler'} pageId='63' />,
    },
    {
      id: 15,
      order: 11,
      class: 'col-xl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BudgetByCategory plusOrMinus={false} widgetId={15} refreshPage={refreshPage} linkToPage={'/../../butce/anasayfa'} pageId='63' />,
    },
    {
      id: 16,
      order: 12,
      class: 'col-xl-4 mb-xl-5 mb-xxl-6 overflow-hidden ',
      item: <OpexCapexWidget widgetId={16} color={'#87CEFA'} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../butce/anasayfa'} pageId='63' />,
    },
    {
      id: 17,
      order: 13,
      class: 'col-xl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <OpexCapexWidget widgetId={17} color={'#FF6347'} plusOrMinus={false} refreshPage={refreshPage} linkToPage={'/../../butce/anasayfa'} pageId='63' />,
    },
    {
      id: 13,
      order: 14,
      class: 'col-xl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <DirectorateWidget title='Müdürlük Bazlı Projeler' widgetId={13} refreshPage={refreshPage} plusOrMinus={false} linkToPage={'/../../projeler/tum-projeler'} pageId='63' />,
    },
    {
      id: 25,
      order: 15,
      class: 'col-xl-6  col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Yıllık Şirket Sayısına Göre'}
          plusOrMinus={false}
          widgetId='25'
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/gelir-guvence-verileri'}
          pageId='1'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 28,
      order: 18,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hiddden',
      item: (
        <PieChart
          name={'DB Types'}
          titleText='Classification Number'
          link={'/data_scope_agents_total/db_types'}
          pageId={'62'}
          refreshPage={refreshPage}
          widgetId={'28'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/veri-guvenligi-butunlugu-dam-ajan-talepleri'}
        />
      ),
    },
    {
      id: 29,
      order: 19,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Os Name Classification Numbers'}
          widgetId={'29'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/veri-guvenligi-butunlugu-dam-ajan-talepleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 30,
      order: 20,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Bölümlere yayınlanan olay rapor sayıları'}
          widgetId={'30'}
          plusOrMinus={false}
          linkToPage={'/../../saldiri-yuzey-yonetimi/soc-alarm-takibi'}
          pageId={'63'}
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 31,
      order: 21,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Action Status and Numbers'}
          link={''}
          widgetId={'31'}
          plusOrMinus={false}
          linkToPage={'/../../saldiri-yuzey-yonetimi/soc-alarm-takibi'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 32,
      order: 22,
      class: 'col-xl-8 col-xxl-8  mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Haftalık Toplam Alarm Sayıları'}
          widgetId='32'
          plusOrMinus={false}
          linkToPage={'/../../saldiri-yuzey-yonetimi/soc-alarm-takibi'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 33,
      order: 23,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Tehdit Sayısı Raporu'}
          link={''}
          widgetId={'33'}
          plusOrMinus={false}
          linkToPage={'/../../saldiri-yuzey-yonetimi/soar-alarm-durumlari'}
          pageId={'63'}
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 34,
      order: 24,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Alarm Tipi Sayıları'}
          widgetId={'34'}
          plusOrMinus={false}
          linkToPage={'/../../saldiri-yuzey-yonetimi/soar-alarm-durumlari'}
          pageId={'63'}
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 35,
      order: 25,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Aylık Grafik'}
          link={''}
          widgetId={'35'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/aynalama-talepleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 36,
      order: 26,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Haftalık Grafik'}
          widgetId={'36'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/aynalama-talepleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 37,
      order: 27,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'DLP İstatistikleri'}
          link={'/dlp_statistics/1'}
          titleText={'Haftalık Durum'}
          widgetId={'37'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/dlp-istatistikleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    // {
    //   id: 38,
    //   order: 28,
    //   class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
    //   item: <ChartsWidget3 className='h-100' chartHeight={200} verticalTitle={'CLIENT ADETI'} horizentalTitle={'Forcepoint Ajan Versiyonlari'} pointTitle={'Adet'} plusOrMinus={false} widgetId={'38'} pageId='63' refreshPage={refreshPage} />,
    // },
    {
      id: 39,
      order: 29,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Category Classification Number'}
          widgetId={'39'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/kategori-bazli-web-trafik-durumu'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 40,
      order: 30,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'BT CATALOG URGENCY'} link={''} widgetId={'40'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 41,
      order: 31,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'Domain Bazlı NKDY '} link={''} widgetId={'41'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 42,
      order: 32,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'STATUS DESCRIPTION'} link={''} widgetId={'42'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    },
    // {
    //   id: 43,
    //   order: 33,
    //   class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
    //   item: (
    //     <PieChart
    //       name={'Endpoint Protection Data Daily'}
    //       link={''}
    //       widgetId={'43'}
    //       plusOrMinus={false}
    //       linkToPage={''}
    //       pageId='63'
    //       refreshPage={refreshPage}
    //     />
    //   ),
    // },
    {
      id: 44,
      order: 34,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Number of Cron Server Ip'}
          plusOrMinus={false}
          widgetId='44'
          linkToPage={'../../uc-nokta-koruma-guvenlik-verileri/trendmicro-istatistikleri'}
          pageId={'63'}
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 45,
      order: 35,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Haftanın En Büyük Boyutlu DDos Atağı'} widgetId={'45'} plusOrMinus={false} linkToPage={''} />,
    },
    {
      id: 46,
      order: 36,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'DDos Atak Sayısı Trendi'} widgetId={'46'} plusOrMinus={false} linkToPage={''} />,
    },
    {
      id: 47,
      order: 37,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PluralBarChart key={47} horizentalTitle={'Son 30 Günlük ve Son 7 Günlük  Veri'} widgetId={'47'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 48,
      order: 38,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'61?report_type=Kurumsal Güvenlik'}
          verticalTitle={'Kurumsal Güvenlik Sistemleri Yönetimi'}
          horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
          plusOrMinus={false}
          widgetId={'48'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 49,
      order: 39,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'2?report_type=Kurumsal Güvenlik'}
          verticalTitle={''}
          horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
          plusOrMinus={false}
          widgetId={'49'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 50,
      order: 40,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'3?report_type=Kurumsal Güvenlik'}
          verticalTitle={''}
          horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat)'}
          plusOrMinus={false}
          widgetId={'50'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 51,
      order: 41,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'61?report_type=Kimlik Yönetimi'}
          verticalTitle={'Kimlik Yönetimi'}
          horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
          plusOrMinus={false}
          widgetId={'51'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 52,
      order: 42,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'2?report_type=Kimlik Yönetimi'}
          verticalTitle={''}
          horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
          plusOrMinus={false}
          widgetId={'52'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 53,
      order: 43,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'3?report_type=Kimlik Yönetimi'}
          verticalTitle={''}
          horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat)'}
          plusOrMinus={false}
          widgetId={'53'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 54,
      order: 44,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'61?report_type=Bilgi Güvenliği'}
          verticalTitle={'Bilgi Güvenliği & Teknoloji Risk Yönetimi'}
          horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
          plusOrMinus={false}
          widgetId={'54'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 55,
      order: 45,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'2?report_type=Bilgi Güvenliği'}
          verticalTitle={''}
          horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
          plusOrMinus={false}
          widgetId={'55'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 56,
      order: 46,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarNODChart
          className={''}
          chartHeight={0}
          type={'3?report_type=Bilgi Güvenliği'}
          verticalTitle={''}
          horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat)'}
          plusOrMinus={false}
          widgetId={'56'}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={''}
        />
      ),
    },
    {
      id: 57,
      order: 47,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Durum Bazlı'}
          link={''}
          widgetId={'57'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/gelir-guvence-talep-durumlari'}
          refreshPage={refreshPage}
          pageId='63'
        />
      ),
    },
    {
      id: 58,
      order: 48,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Teknoloji Bazlı'}
          widgetId={'58'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/gelir-guvence-talep-durumlari'}
          refreshPage={refreshPage}
          pageId='63'
        />
      ),
    },
    {
      id: 59,
      order: 49,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Atanan Kullanıcı Bazlı'}
          widgetId={'59'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/gelir-guvence-talep-durumlari'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    // {
    //   id: 60,
    //   order: 50,
    //   class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
    //   item: <PieChart name={'Haftalık Durum'} link={''} widgetId={'60'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    // },
    // {
    //   id: 61,
    //   order: 51,
    //   class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
    //   item: <BarChart horizentalTitle={'Haftalık Grafik'} widgetId={'61'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    // },
    {
      id: 62,
      order: 52,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Category Classification Number'} widgetId={'62'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 63,
      order: 53,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Trusted / Untrusted Classification Number'} widgetId={'63'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 64,
      order: 54,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PluralBarChart horizentalTitle={''} widgetId={'64'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 65,
      order: 55,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PieChart link={''} widgetId={'65'} plusOrMinus={false} linkToPage={''} name='Genel Zafiyet Bildirim Durum Adetleri' pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 66,
      order: 56,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ChartsWidget4 horizentalTitle={'Direktörlük Zafiyet Durum Dağılımı'} widgetId={'66'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 67,
      order: 57,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ChartsWidget4 horizentalTitle={'Müdürlük Bazında Zafiyet Durum Dağılımı'} widgetId={'67'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 68,
      order: 58,
      class: 'col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PluralBarChart horizentalTitle={''} widgetId={'68'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 69,
      order: 57,
      class: 'col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <PluralBarChart horizentalTitle={''} widgetId={'69'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 70,
      order: 60,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ChangeInNumberCustomer widgetId='70' color='#ffc100' plusOrMinus={false} refreshPage={refreshPage} pageId='63' linkToPage={''} />,
    },
    {
      id: 71,
      order: 61,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <LineChart widgetId={'71'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 72,
      order: 62,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <LineChart widgetId={'72'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 73,
      order: 63,
      class: 'col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <ChangeInNumberCustomer widgetId='73' color='#ed7d31' plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 74,
      order: 64,
      class: 'col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Number of States'} widgetId={'74'} plusOrMinus={false} linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 75,
      order: 65,
      class: 'col-xl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: <TargetListsHomePage plusOrMinus={false} refreshPage={refreshPage} widgetId={75} linkToPage={'/../../projeler/tum-projeler'} />,
    },
    {
      id: 76,
      order: 66,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: <ChartsWidget4 horizentalTitle={'Öne Çıkan Sızma Testi Zafiyetleri'} widgetId={'76'} plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 77,
      order: 67,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <ChartsWidget4
          key={77}
          horizentalTitle={'Zafiyet Sayısı En Yüksek 10 Varlık'}
          widgetId={'77'}
          plusOrMinus={false}
          pageId='63'
          refreshPage={refreshPage}
          linkToPage={'/../../saldiri-yuzey-yonetimi/sizma-testi-zafiyet-ve-bulgu-istatistikleri'}
        />
      ),
    },
    {
      id: 78,
      order: 68,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Number of Protection Name'} plusOrMinus={false} widgetId='78' linkToPage={''} pageId='1' refreshPage={refreshPage} />,
    },
    {
      id: 78,
      order: 68,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Number of Protection Name'} plusOrMinus={false} widgetId='78' linkToPage={''} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 79,
      order: 69,
      class: 'col-xl-12 col-xxl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <ChartsWidget4
          horizentalTitle={'Cihaz Availability Durumu'}
          widgetId={'79'}
          plusOrMinus={false}
          refreshPage={refreshPage}
          pageId='63'
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/cihaz-utilization-ve-availability-raporu'}
        />
      ),
    },
    {
      id: 80,
      order: 70,
      class: 'col-xl-12 col-xxl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: <BarChart key={80} horizentalTitle={'İşletim Sistemlerine Göre'} plusOrMinus={false} widgetId='80' linkToPage={''} pageId='63' />,
    },
    {
      id: 81,
      order: 71,
      class: 'col-xl-12 col-xxl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: <DirectorateWidget widgetId={81} title='Görev Bazlı Caseler' plusOrMinus={false} pageId='63' refreshPage={refreshPage} linkToPage={'/../../case-yonetimi/case-listesi'} />,
    },
    {
      id: 82,
      order: 72,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: <CarbonStatisticWidget plusOrMinus={false} widgetId={82} pageId='63' refreshPage={refreshPage} linkToPage={''} />,
    },
    {
      id: 83,
      order: 73,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart name={'Kritiklik Seviyesi'} link={''} widgetId={'83'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/ddo-uyumluluk-durumu'} pageId='63' refreshPage={refreshPage} />
      ),
    },
    {
      id: 84,
      order: 74,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart name={'Durum Dağılımı'} link={''} widgetId={'84'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/ddo-uyumluluk-durumu'} pageId='63' refreshPage={refreshPage} />
      ),
    },
    {
      id: 85,
      order: 75,
      class: 'col-xl-12 ol-xxl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Direktörlük Bazında Aksiyonlar'}
          widgetId={'85'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/ddo-uyumluluk-durumu'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 86,
      order: 76,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart name={'Durum Dağılımı'} link={''} widgetId={'86'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/ddo-uyumluluk-durumu'} pageId='63' refreshPage={refreshPage} />
      ),
    },
    {
      id: 87,
      order: 77,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart name={'Uygulama Tipi Dağılımı'} link={''} widgetId={'87'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/kritik-envanter'} pageId='63' refreshPage={refreshPage} />
      ),
    },
    {
      id: 88,
      order: 78,
      class: 'col-xl-8 col-xxl-8 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart horizentalTitle={'Uygulama Envanteri'} widgetId={'88'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/kritik-envanter'} pageId='63' refreshPage={refreshPage} />
      ),
    },
    {
      id: 89,
      order: 79,
      class: 'col-xl-12 col-xxl-12 mb-5 mb-xxl-6 overflow-hidden',
      item: <BarChart horizentalTitle={'Sistem Envanteri'} widgetId={'89'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/kritik-envanter'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 90,
      order: 80,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Durum Dağılımı'}
          link={''}
          widgetId={'90'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 91,
      order: 81,
      class: 'col-xl-12 col-xxl-12 mb-5 mb-xxl-8 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Direktörlük Bazında Açık Ve Kapalı Bulgu Sayıları'}
          widgetId={'91'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 92,
      order: 82,
      class: 'col-xl-8 col-xxl-8 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Seviye Bazında'}
          widgetId={'92'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-varlik-envanteri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 92,
      order: 82,
      class: 'col-xl-8 col-xxl-8 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Seviye Bazında'}
          widgetId={'92'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          pageId='61'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 93,
      order: 83,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Müdürlük Bazında Açık Ve Kapalı Bulgu Sayıları'}
          widgetId={'93'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 94,
      order: 84,
      class: 'col-xl-6 col-xxl-6 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <BarChart
          horizentalTitle={'Grup Müdürlüğü Bazında Açık Ve Kapalı Bulgu Sayıları'}
          widgetId={'94'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          pageId='63'
          refreshPage={refreshPage}
        />
      ),
    },
    {
      id: 95,
      order: 85,
      class: 'col-xl-4 col-xxl-4 mb-5 mb-xxl-6 overflow-hidden',
      item: <BarChart widgetId={'95'} plusOrMinus={false} linkToPage={'/../../projeler/poc-calismalari'} horizentalTitle={'PoC Tipine Göre'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 96,
      order: 86,
      class: 'col-xl-8 col-xxl-8 mb-5 mb-xxl-6 overflow-hidden',
      item: <DirectorateWidget widgetId={96} title='Müdürlük Bazlı PoC Çalışmaları' plusOrMinus={false} linkToPage={'/../../projeler/poc-calismalari'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 97,
      order: 87,
      class: 'col-xl-8 col-xxl-8 mb-5 mb-xxl-6 overflow-hidden',
      item: (
        <PluralBarChart
          horizentalTitle={'Haftalık NKDY Talep Sayısı '}
          widgetId={'97'}
          plusOrMinus={false}
          linkToPage={'/../../gorunurluk-izleme-ve-entegrasyon-durumlari/nkdy-destek-talebi'}
          pageId='63'
        />
      ),
    },
    {
      id: 98,
      order: 88,
      class: 'col-xl-4 col-xxl-4 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'Durum Dağılımı'} link={''} widgetId={'98'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/risk-yonetimi'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 99,
      order: 89,
      class: 'col-xl-4 col-xxl-4 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'Risk Seviye Dağılımı'} link={''} widgetId={'99'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/risk-yonetimi'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 100,
      order: 90,
      class: 'col-xl-4 col-xxl-4 mb-xxl-6 overflow-hidden',
      item: <PieChart name={'Durum Dağılımı'} link={''} widgetId={'100'} plusOrMinus={false} linkToPage={'/../../bilgi-guvenligi-verileri/risk-yonetimi'} pageId='63' refreshPage={refreshPage} />,
    },
    {
      id: 101,
      order: 91,
      class: 'col-xl-4 col-xxl-4 mb-xxl-6 overflow-hidden',
      item: (
        <PieChart
          name={'Toplam Açık Kapalı Bulgu Sayıları'}
          link={''}
          widgetId={'101'}
          plusOrMinus={false}
          linkToPage={'/../../bilgi-guvenligi-verileri/bilgi-guvenligi-denetimleri'}
          refreshPage={refreshPage}
          pageId='63'
        />
      ),
    },
  ])
  const [visibleCardList, setVisibleCartList] = useState<any>([])
  const [correntCard, setCorrentCard] = useState<any>(null)

  useEffect(() => {
    setVisibleCartList([])
    getUserInfo()
  }, [refresh])

  const getUserInfo = () => {
    if (loginUser.home_widgets == 'all') {
      setVisibleCartList(cardList)
    } else {
      getReportWidgets(loginUser.id, '63').then(({data}) => {
        for (let i = 0; i < data.data?.length; i++) {
          let temp = cardList.filter((item) => item.id === data.data[i]?.id)[0]
          setVisibleCartList((visibleCardList: any) => [
            ...visibleCardList,
            {
              id: temp?.id,
              order: i + 1,
              class: temp?.class,
              item: temp?.item,
            },
          ])
        }
      })
    }
  }

  function dragStartHandler(e: any, item: any): void {
    setCorrentCard(item)
  }

  function dragEndHandler(e: any): void {}

  function dragOverhandler(e: any): void {
    e.preventDefault()
  }

  async function dropHandler(e: any, card: any): Promise<void> {
    e.preventDefault()
    console.log(card.order - correntCard?.order)
    let dif = card.order - correntCard?.order
    if (dif !== 0) {
      setVisibleCartList(
        visibleCardList?.map((c: any) => {
          if (c.id === card.id) {
            return {...c, order: correntCard?.order}
          }
          if (c.id === correntCard.id) {
            return {...c, order: card?.order}
          }
          return c
        })
      )
      if (dif < 0) {
        dif *= -1
        for (let i = 0; i < dif; i++) {
          await moveReportWidgets(loginUser.id, correntCard.id, 'upside', 63)
            .then((res) => {})
            .catch((err) => {
              console.log(err)
            })
        }
        for (let i = 0; i < dif - 1; i++) {
          await moveReportWidgets(loginUser.id, card.id, 'downside', 63)
            .then((res) => {})
            .catch((err) => {
              console.log(err)
            })
        }
      } else {
        for (let i = 0; i < dif; i++) {
          await moveReportWidgets(loginUser.id, correntCard.id, 'downside', 63)
            .then((res) => {})
            .catch((err) => {
              console.log(err)
            })
        }
        for (let i = 0; i < dif - 1; i++) {
          await moveReportWidgets(loginUser.id, card.id, 'upside', 63)
            .then((res) => {})
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  }
  const sortCards = (a: any, b: any) => {
    if (a.order > b.order) return 1
    else return -1
  }

  return (
    <>
      <div className='row g-5 g-xxl-10 py-5'>
        <div className='row d-flex'>
          {visibleCardList?.sort(sortCards)?.map((item: any) => (
            <div
              key={item.id}
              onDragStart={(e) => dragStartHandler(e, item)}
              onDragLeave={(e) => dragEndHandler(e)}
              onDragEnd={(e) => dragEndHandler(e)}
              onDragOver={(e) => dragOverhandler(e)}
              onDrop={(e) => dropHandler(e, item)}
              draggable={true}
              className={item?.class}
              style={{cursor: 'grab'}}
            >
              <React.Fragment>{item?.item}</React.Fragment>
            </div>
          ))}
        </div>
      </div>

      {/* <Targets id={""} type={false}/> */}
    </>
  )
}
