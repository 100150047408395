import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const MaliciousStatisticItem: FC<Props> = ({item}) => {
  return (
    <>
      <td className='fw-bolder'>{moment(item?.date).format('DD.MM.YYYY')}</td>
      <td className='text-gray-500'>{item?.time}</td>
      <td className='text-gray-500'>{item?.ref_id}</td>
      {/* <td className='text-gray-500'>
         {moment(item?.itime).format('DD.MM.YYYY H:mm:ss')}</td>
        <td className='text-gray-500'>{item?.euid}</td>
        <td className='text-gray-500'>{item?.epid} </td>
        <td className='text-gray-500'>{item?.dsteuid} </td>
        <td className='text-gray-500'>{item?.dstepid}</td>
        <td className='text-gray-500'>{item?.logid}</td> */}
      <td className='text-gray-500'>{item?.type}</td>
      <td className='text-gray-500'>{item?.subtype}</td>
      <td className='text-gray-500'>{item?.level}</td>
      {/* <td className='text-gray-500'>{item?.jobid}</td> */}
      <td className='text-gray-500'>{item?.risk}</td>
      {/* <td className='text-gray-500'>{moment(item?.scanstart).format('DD.MM.YYYY H:mm:ss')}</td>
        <td className='text-gray-500'> {moment(item?.scanend).format('DD.MM.YYYY H:mm:ss')}</td> */}
      <td className='text-gray-500'>{item?.mname}</td>
      <td className='text-gray-500'>{item?.fname}</td>
      <td className='text-gray-500'>{item?.scrip}</td>
      <td className='text-gray-500'>{item?.srcport}</td>
      <td className='text-gray-500'>{item?.dstip}</td>
      <td className='text-gray-500'>{item?.dstport}</td>
      <td className='text-gray-500'>{item?.proto} </td>
      <td className='text-gray-500'>{item?.suser} </td>
      <td className='text-gray-500'>{item?.stype}</td>
      <td className='text-gray-500'>{item?.vmos}</td>
      <td className='text-gray-500'>{item?.clientdev}</td>
      <td className='text-gray-500'>{item?.clientvd}</td>
      <td className='text-gray-500'>{item?.url}</td>
      <td className='text-gray-500'>{item?.tzone}</td>
      <td className='text-gray-500'>{item?.devid}</td>
      <td className='text-gray-500'>{item?.vd}</td>
      <td className='text-gray-500'> {moment(item?.dtime).format('DD.MM.YYYY')}</td>
      <td className='text-gray-500'>{item?.itime_t}</td>
      <td className='text-gray-500'>{item?.devname}</td>
      <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
      <tr></tr>
    </>
  )
}
