import moment from 'moment'
import {FC, Key, useEffect, useState} from 'react'
import {Dropdown, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ModalHeader} from '../../../../components/ModalHeader'
import {ProfilePopUp} from '../../../../Profile/ProfilePopUp'
import {AddMilestoneHeader} from '../../add-edit-modals/addMilestoneHeader'
import {AddTargetHeaderTest} from '../../add-edit-modals/addTargetHeaderTest'
import './style.css'

type Props = {
  target: any
  project_id: any
  deleteTarget: any
  refreshPage: any
  number: any
  task: boolean
  milestone_id?: any
  type?: boolean
}
export const TargetItem: FC<Props> = ({target, project_id, deleteTarget, refreshPage, number, task, milestone_id, type}) => {
  const [showTicketModal, setShowticketModal] = useState(false)
  const [showSubModal, setShowSubModal] = useState(false)
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const [taskDates, setTaskDates] = useState<any>([])
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    target?.tasks?.map((tar: any) => {
      setTaskDates((current: any) => [...current, tar?.planned_start_date])
      setTaskDates((current: any) => [...current, tar?.planned_end_date])
    })
  }, [])

  const getMaxDate = () => {
    if (taskDates?.length < 1) {
      return ''
    } else {
      const maxDate = new Date(
        Math.max(
          ...taskDates.map((el: string | number | Date) => {
            return new Date(el)
          })
        )
      )
      return maxDate
    }
  }

  const getMinDate = () => {
    if (taskDates?.length < 1) {
      return ''
    } else {
      const minDate = new Date(
        Math.min(
          ...taskDates.map((el: string | number | Date) => {
            return new Date(el)
          })
        )
      )
      return minDate
    }
  }
  // const [percent, setPercent] = useState(48)
  const [milestoneID, setID] = useState(task ? milestone_id : target?.id)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  const openTicketModal = () => {
    setShowticketModal(true)
  }
  const closeTicketModal = () => {
    refreshPage()
    setShowticketModal(false)
  }
  const openSubModal = (update: boolean) => {
    setUpdate(update)
    setShowSubModal(true)
  }
  const closeSubModal = () => {
    refreshPage()
    setUpdate(false)
    setShowSubModal(false)
  }
  const del = () => {
    deleteTarget(target.id)
  }

  function getBusinessDatesCount(startDate: any, endDate: any) {
    let count = 0
    const curDate = new Date(startDate.getTime())
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++
      curDate.setDate(curDate.getDate() + 1)
    }
    return count
  }

  return (
    <>
      <tr style={{backgroundColor: !target?.planned_start_date ? '#e7e7e7' : ''}}>
        <td className='text-gray-500 p-3'>{target?.plan?.name}</td>
        <td className='text-gray-500'>{number}</td>
        <td className='text-gray-500'>{target?.title}</td>
        <td className='min-w-125px'>
          {/* begin::Team members */}
          <div className='symbol-group symbol-hover mb-1'>
            {/* begin::Member */}
            {target?.users?.map((user: any, index: Key | null | undefined) => {
              return (
                <div key={index} className='symbol symbol-circle symbol-25px'>
                  <OverlayTrigger key='top' placement='top' overlay={<Tooltip id='tooltip-top'>{user?.name}</Tooltip>}>
                    <img
                      src={user?.avatar}
                      alt=''
                      onClick={async () => {
                        setProfilUserId(user?.id)
                        await openProfilModal()
                      }}
                    />
                  </OverlayTrigger>
                </div>
              )
            })}
          </div>
          {/* end::Team members */}
        </td>
        <td>{target?.other_user !== '' ? target?.other_user : '+++'}</td>
        <td>
          <span data-kt-menu-trigger='click' className={`badge badge-${target?.status?.id == '2' ? 'light-success' : target?.status?.id == '0' ? 'light-primary' : 'light-danger'} fw-bolder me-auto`}>
            {target?.status?.name}
          </span>
        </td>
        {task ? (
          <>
            <td className='text-gray-500'>{target?.planned_start_date ? moment(target?.planned_start_date).format('DD.MM.YYYY') : ''}</td>
            <td className='text-gray-500'>{target?.planned_end_date ? moment(target?.planned_end_date).format('DD.MM.YYYY') : ''}</td>
          </>
        ) : (
          <>
            <td className='text-gray-500'>{getMinDate() !== '' && moment(getMinDate()).format('DD.MM.YYYY')}</td>
            <td className='text-gray-500'>{getMaxDate() !== '' && moment(getMaxDate()).format('DD.MM.YYYY')}</td>
          </>
        )}

        {task ? (
          <>
            <td className='text-gray-500'>{getBusinessDatesCount(new Date(target?.planned_start_date), new Date(target?.planned_end_date))}</td>
            <td className='text-gray-500'>{target?.actual_start_date ? moment(target?.actual_start_date).format('DD.MM.YYYY') : ''}</td>
            <td className='text-gray-500'>{target?.actual_end_date ? moment(target?.actual_end_date).format('DD.MM.YYYY') : ''}</td>
            <td className='text-gray-500'>{getBusinessDatesCount(new Date(target?.actual_start_date), target?.actual_end_date != null ? new Date(target?.actual_end_date) : undefined)}</td>
            <td>{target?.note}</td>
          </>
        ) : (
          <>
            <td className='text-gray-500'></td>
            <td className='text-gray-500'></td>
            <td className='text-gray-500'></td>
            <td className='text-gray-500'></td>
            <td>{target.note}</td>
          </>
        )}
        <td>
          <div className='d-flex align-items-center  justify-content-between'>
            <div className='progress h-6px w-100 me-2 bg-light-primary'>
              <div className={`bg-primary rounded h-6px `} role='progressbar' style={{width: `${target.percentage}%`}}></div>
            </div>
            <div>{target.percentage.toFixed(0)}%</div>
            {/* <div className='h-4px'>{percent}%</div> */}
          </div>
        </td>
        <td>
          <Dropdown className=''>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-light btn-active-light-primary btn-sm ' id='dropdown-basic'></Dropdown.Toggle>

            <Dropdown.Menu>
              {!task ? (
                <Dropdown.Item
                  onClick={() => {
                    openSubModal(false)
                  }}
                >
                  <span className='text-success'>Alt Kırılım Ekle</span>{' '}
                </Dropdown.Item>
              ) : (
                ''
              )}
              <Dropdown.Item
                onClick={
                  !task
                    ? openTicketModal
                    : () => {
                        openSubModal(true)
                      }
                }
              >
                Düzenle
              </Dropdown.Item>
              <Dropdown.Item onClick={del}>
                <span className='text-danger'>Sil</span>{' '}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        {/* <td className='text-end'>
            <a
              href='#'
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              İşlem
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
        {/* <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
                <NavLink to='../detail' state={supplier} className='menu-link px-3'>
                  Görüntüle
                </NavLink>
              </div>
              <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={async () => await handleShow()}>
                  Düzenle
                </a>
              </div>
              {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={async () => await deleteSupp(supplier.id, refreshPage)}
                >
                  Sil
                </a>
              </div>
  
              {/* end::Menu item */}
        {/* </div>
          </td>  */}
      </tr>
      <Modal
        show={showTicketModal}
        style={{position: 'absolute', top: '0px'}}
        // dialogClassName='modal-90w'
        size='xl'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Hedefi Güncelle'} />
          <AddMilestoneHeader project_id={project_id} target={target} target_id={undefined} handleClose={closeTicketModal} update={true} />
        </Modal.Body>
      </Modal>

      <Modal show={showSubModal} dialogClassName='modal-90w'>
        <Modal.Body>
          <ModalHeader handleClose={closeSubModal} titleHeader={'Yeni Hedef Oluştur'} />
          <AddTargetHeaderTest project_id={project_id} target={target} target_id={milestoneID} handleClose={closeSubModal} update={update} />
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
