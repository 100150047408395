import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {Activities} from './Activities'
import {ActivityDetail} from './Activity-detail/ActivityDetail'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const ActivitesPageWrapper: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='gorevler'
          element={
            <>
              <PageTitle
                breadcrumbs={dashboardBreadCrumbs}
                description='Faaliyetler'
                toDescription='/faaliyetler/gorevler'
              >
                Görevler
              </PageTitle>
              <Activities />
            </>
          }
        />
        <Route
          path='detail'
          element={
            <>
              <PageTitle
                breadcrumbs={dashboardBreadCrumbs}
                description='Faaliyetler'
                toDescription='/faaliyetler/gorevler'
              >
                Detay
              </PageTitle>
              <ActivityDetail />
            </>
          }
        />
      </Route>

      <Route
        path=''
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Faaliyetler'
              toDescription='/faaliyetler/gorevler'
            >
              Görevler
            </PageTitle>
            <Activities />
          </>
        }
      />
      <Route index element={<Navigate to='/faaliyetler/gorevler' />} />
    </Routes>
  )
}

export {ActivitesPageWrapper}
