import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {VIItem} from './components/VIItem'
import {DetailFilters} from './components/DetailFilter'
import {ChartsWidget4} from '../components/ChartsWidget4'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const VI: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [createdAt, setCreatedAt] = useState('')
  const [attendees, setAttendees] = useState('')
  const [projectName, setProjectName] = useState('')
  const [title, setTitle] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setCreatedAt('')
    setAttendees('')
    setProjectName('')
    setTitle('')
    setStart('')
    setEnd('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (createdAt != '') {
      queryParams += '&created_at=' + createdAt
    }
    if (attendees != '') {
      queryParams += '&attendees=' + attendees
    }
    if (projectName != '') {
      queryParams += '&projectName=' + projectName
    }
    if (title != '') {
      queryParams += '&title=' + title
    }
    if (start != '') {
      queryParams += '&start=' + start
    }
    if (end != '') {
      queryParams += '&end=' + end
    }

    GetReport('/vulnerability_indicator_statistic', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [createdAt, attendees, projectName, title, start, end])

  const exportFile = () => {
    window.open(`${ExportLink}/vulnerability_indicator_statistic?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6'>
            <ChartsWidget4 key={76} horizentalTitle={'En Çok Tespit Edilen 10 Sızma Testi Zafiyeti'} widgetId={'76'} plusOrMinus={true} linkToPage={''} />
          </div>
          <div className='col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6'>
            <ChartsWidget4 key={77} horizentalTitle={'Zafiyet Sayısı En Yüksek 10 Varlık'} widgetId={'77'} plusOrMinus={true} linkToPage={''} />
          </div>

          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/vulnerability_indicator_statistic_check'} />
                </div>
                <span className='mt-5 fw-bolder fs-2'> Sızma Testi Takvimi</span>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-3'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>ATTENDEES</th>
                      <th className=''>START</th>
                      <th className=''>END</th>
                      <th className=''>PROJECT NAME</th>
                      <th className=''>TITLE</th>
                      <th className=''>CREATED AT</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <VIItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setCreatedAt={setCreatedAt}
            setProjectName={setProjectName}
            setAttendees={setAttendees}
            setTitle={setTitle}
            setStart={setStart}
            setEnd={setEnd}
            handleClose={closeFilterModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
