import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../../components/ModalHeader'
import {ExportLink} from '../../../core/requests'
import {AddCase} from '../../edit-modal/AddCase'
import {DetailFilters} from '../DetailsFilter'

import {UsersListFilter} from './UsersListFilter'
type Props = {
  refreshPage?: any
  setWord: any
  setAssignment: any
  setStatus: any
}
const UsersListToolbar: FC<Props> = ({refreshPage, setWord, setAssignment, setStatus}) => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  const [show, setShow] = useState(false)
  const openAddSupModal = () => {
    setShow(true)
  }
  const closeAddSupModal = () => {
    setShow(false)
  }
  const [showDetailSearch, setShowDetailSearch] = useState(false)
  const openDetailModal = () => {
    setWord('')
    setAssignment('')
    setStatus('')
    setShowDetailSearch(true)
  }

  const closeDetailModal = () => {
    setShowDetailSearch(false)
  }

  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/cases?token=${loginUser.token}`)
  }
  return (
    <>
      <div className='d-flex justify-content-end mt-2' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        <button type='button' className='btn  me-3 h-75' onClick={openDetailModal}>
          Detaylı Ara
        </button>
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn h-75 me-3' onClick={openAddSupModal}>
          Case Ekle
        </button>

        <button type='button' className='btn h-75' onClick={exportFile}>
          Dışarı Aktar
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddSupModal} titleHeader={'Yeni Case Ekle'} />
          <AddCase
            caseProp={{
              title: '',
              start_date: '',
              end_date: '',
              duration: '',
              assignment: {
                id: 0,
              },
              system_resource: '',
              affected_side: '',
              detail: '',
              status: {
                id: undefined,
              },
              action_users: [],
            }}
            handleClose={closeAddSupModal}
            update={false}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showDetailSearch}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeDetailModal} titleHeader={'Detaylı Ara'} />
          <DetailFilters handleClose={closeDetailModal} setSearchKeyClick={setWord} setAssignment={setAssignment} setStatus={setStatus} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {UsersListToolbar}
