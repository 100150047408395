import { FC } from "react"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_theme/layout/core"
import { Inventory } from "./Inventory"
import { InventoryDetail } from "./inventory-detail/InventoryDetail"

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const InventoryPageWrapper : FC=()=>{
  return (
    <Routes>
      <Route element={<Outlet />}>
      <Route
          path='envanter-yonetimi'
          element={
            <>
            <PageTitle breadcrumbs={dashboardBreadCrumbs}  description='Envanter Yönetimi' toDescription='/envanter-yonetimi'>Envanter Yönetimi</PageTitle>
             <Inventory/>
            </>
          }
        />
         <Route
          path='envanter-detail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs}  description='Envanter Yönetimi' toDescription='/envanter-yonetimi'>Detay</PageTitle>
              <InventoryDetail/>
             
            </>
          }
        />
          
        <Route index element={<Navigate to='/envanter-yonetimi' />} />

      </Route>
    </Routes>
  )
}