import axios from 'axios'
import {API_URL} from '../../request'

const DOCUMENTS = `${API_URL}/documents`
const USERS = `${API_URL}/users`
const TASKS = `${API_URL}/tasks`
const WIDGET_ISSUE = `${API_URL}/widgets/issues/status`
export async function getUserDocuments(created_by_id: any) {
  return await axios.get<any>(DOCUMENTS + '?created_by=' + created_by_id)
}

export async function getUserIssues(user_id: any, link: string) {
  return await axios.get<any>(USERS + '/' + user_id + '/issues' + link)
}

export async function getUserProjects(user_id: any) {
  return await axios.get<any>(USERS + '/' + user_id + '/projects')
}

export async function getIssueWidget(user_id: any) {
  return await axios.get<any>(WIDGET_ISSUE + '?user_id=' + user_id)
}

export async function getUserById(user_id: any) {
  return await axios.get<any>(USERS + '/' + user_id)
}

export async function getTasksByUserId(user_id: any) {
  return await axios.get<any>(TASKS + '?user_id=' + user_id)
}
