import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setMarka: any
  setDeviceStatus: any
  setDeviceType: any
  setModel: any
  setUnitDirectorate: any
  setDirectoryEmail: any
  handleClose: any
}

export const DetailFilter: FC<Props> = ({
  setMarka,
  setDeviceStatus,
  setDeviceType,
  setModel,
  setUnitDirectorate,
  setDirectoryEmail,
  handleClose,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
   setMarka(selectedMarka)
   setDeviceStatus(selectedDeviceStatus)
   setDeviceType(selectedDeviceType)
   setModel(selectedModel)
   setUnitDirectorate(selectedUnitDirectorate)
   setDirectoryEmail(selectedDirectoryEmail)
   handleClose()
  }
  const [selectedMarka, setSelectedMarka] = useState('')
  const [selectedDeviceStatus, setSelectedDeviceStatus] = useState('')
  const [selectedDeviceType, setSelectedDeviceType] = useState('')
  const [selectedModel, setSelectedModel] = useState('')
  const [selectedUnitDirectorate, setSelectedUnitDirectorate] = useState('')
  const [selectedDirectoryEmail, setSelectedDirectoryEmail] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Marka</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedMarka(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Cihaz Durumu</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDeviceStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Cihaz Tipi</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDeviceType(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Model</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedModel(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>İlgili Birim Müdürlük</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedUnitDirectorate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Müdürlük Email Adresi</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDirectoryEmail(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
