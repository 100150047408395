import React, { FC } from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import { AnnouncementDetail } from './Announcement-detail/AnnouncementDetail'
import {Announcements} from './Announcements'
import { InformationSecurityPolices } from './InformationSecurityPolices'
import { PlannedWorks } from './PlannedWorks'
import { SentryInformation } from './SentryInformation'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const AnnouncementsPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='bilgi-guvenligi-politikalari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs}  description={'Duyurular'} toDescription='/duyurular/tum-duyurular'>Bilgi Güvenliği Politikaları</PageTitle>
            <InformationSecurityPolices/>
            </>
          }
        />
          <Route
          path='tum-duyurular'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description={'Duyurular'} toDescription='/duyurular/tum-duyurular'>Tüm Duyurular</PageTitle>
              <Announcements />
            </>
          }
        />
         <Route
          path='detail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs}  description={'Duyurular'} toDescription='/duyurular/tum-duyurular'>Detay</PageTitle>
              <AnnouncementDetail />
            </>
          }
        />
          <Route
          path='nobetci-bilgisi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Duyurular' toDescription='/duyurular/tum-duyurular'>Nöbetçi Bilgisi</PageTitle>
              <SentryInformation />
            </>
          }
        />
          <Route
          path='planli-calismalar'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Duyurular' toDescription='/duyurular/tum-duyurular'>Planlı Çalışmalar</PageTitle>
              <PlannedWorks />
            </>
          }
        />
        <Route index element={<Navigate to='/duyurular/tum-duyurular' />} />
      </Route>
    </Routes>
  )
}

export {AnnouncementsPageWrapper}
