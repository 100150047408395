import Chart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import React, {FC, useEffect, useState} from 'react'
import {GetALStatistics, GetDLPStatistics, GetReportStatistics, GetReportsWidget, GetVNStatistics} from '../../../OperationalData/core/request'
import {auto, bottom} from '@popperjs/core'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  name?: string
  link: string
  titleText?: string
  pageId?: string
  widgetId: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
  setSelectedValue?: any
}

export const PieChart: FC<Props> = ({name, titleText, link, widgetId, pageId, plusOrMinus, linkToPage, setSelectedValue}) => {
  let total = 0
  const {refreshPage} = useRefresh1()
  const [mirroringData, setMirroringData] = useState<any>([])
  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()
  const [totalCount, setTotalCount] = useState(0)
  const chart3 = {
    labels: title,
    colors: [
      '#008FFB',
      '#ff4560',
      '#00e396',
      '#ffb01a',
      '#775dd0',
      '#98a9af',
      '#6BFFC7',
      '#FF0000',
      '#0000FF',
      '#00FF00',
      '#FFFF00',
      '#FFA500',
      '#800080',
      '#FFC0CB',
      '#A52A2A',
      '#FFD700',
      '#808000',
      '#00FFFF',
    ],
    dataLabels: {
      enabled: true,
      display: auto,
      color: '#223388',
      font: {
        size: 20,
      },
    },

    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'left',
      fontSize: '13rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}) => {
          if (config.w.config.labels[config.dataPointIndex] == 'Açık Kayıt') {
            setSelectedValue('_open=1')
          } else if (config.w.config.labels[config.dataPointIndex] == 'Kapalı Kayıt') {
            setSelectedValue('_closed=1')
          } else if (widgetId == '35') {
            mirroringData?.map((item: any) => {
              if (item?.month == config.w.config.labels[config.dataPointIndex]) {
                setSelectedValue('&start_day=' + item?.start_day + '&end_day=' + item?.end_day)
              }
            })
          } else {
            setSelectedValue(config.w.config.labels[config.dataPointIndex])
          }
        },
      },
    },

    tooltip: {
      style: {
        fontSize: '18px',
        // zIndex: 9999,
      },
      followCursor: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ' Adet'
        },
      },
    },
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  useEffect(() => {
    if (widgetId == '28') {
      GetReportsWidget(link).then((data) => {
        prdDam(data?.data)
        // setStatistic(data.data)
      })
    } else if (widgetId == '31') {
      GetALStatistics('3').then((data) => {
        prdAlarmTracking(data?.data)
      })
    } else if (widgetId == '33') {
      GetReportStatistics('/number_of_alarm_cases/', '1').then((data) => {
        prdNumberAlarm(data?.data)
      })
    } else if (widgetId == '35') {
      GetReportStatistics('/mirroring/', '2').then((data) => {
        setMirroringData(data?.data)
        prdMirroring(data?.data)
      })
    } else if (widgetId == '37') {
      GetDLPStatistics(link).then((data) => {
        prdDLPStatistics(data?.data)
      })
    } else if (widgetId == '40') {
      GetReportsWidget('/nkdy_support_request_total/bt_catalog_urgency').then((data) => {
        prdNKDY(data?.data)
      })
    } else if (widgetId == '41') {
      GetReportsWidget('/nkdy_support_request_total/mx_cozumtipi_description').then((data) => {
        prdNKDY(data?.data)
      })
    } else if (widgetId == '42') {
      GetReportsWidget('/nkdy_support_request_total/status_description').then((data) => {
        prdNKDY(data?.data)
      })
    } else if (widgetId === '57') {
      GetReportsWidget('/income_assurance_request_total/status_description').then((data) => {
        prdIAR(data?.data)
      })
    } else if (widgetId == '58') {
      GetReportsWidget('/income_assurance_request_total/impact_description').then((data) => {
        prdIAR(data?.data)
      })
    } else if (widgetId == '60') {
      GetReportStatistics('/project_demand_tracking/', '1').then((data) => {
        prdProjectDemand(data?.data)
      })
    } else if (widgetId === '65') {
      GetVNStatistics('1').then((data) => {
        prdVN(data.data)
      })
    } else if (widgetId == '83') {
      GetReportsWidget('/optimate_solution_asset_inventory_status_total/1').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '84') {
      GetReportsWidget('/optimate_solution_group_asset_inventory_status_total/1').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '86') {
      GetReportsWidget('/optimate_solution_directorate_asset_inventory_status_total/ref_status').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '87') {
      GetReportsWidget('/optimate_solution_public_app_data_total/1').then((data) => {
        prdPublicData(data?.data)
      })
    } else if (widgetId == '90') {
      GetReportsWidget('/optimate_solution_planlanan_denetimler_total/1').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '98') {
      GetReportsWidget('/optimate_solution_risk_management_risk_distribution_total/ref_status').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '99') {
      GetReportsWidget('/optimate_solution_risk_management_risk_distribution_total/risk_level').then((data) => {
        prdRiskLevel(data?.data)
      })
    } else if (widgetId == '100') {
      GetReportsWidget('/optimate_solution_risk_management_risk_action_total/ref_status').then((data) => {
        prdAssetsInventory(data?.data)
      })
    } else if (widgetId == '101') {
      GetReportsWidget('/optimate_solution_acik_kapali_bulgu_sayilari_total/is_open').then((data) => {
        prdIsOpen(data?.data)
      })
    }
  }, [name])
  const prdDam = (data: any) => {
    setTitle([])
    total = 0
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.db_types)
    }
    setPC(count1)
    setTitle(title1)
  }
  const prdAlarmTracking = (data: any) => {
    setTotalCount(Number(data?.total_open) + Number(data?.total_completed) + Number(data?.total_pending))
    count1.push(data?.total_open)
    count1.push(data?.total_completed)
    count1.push(data?.total_pending)
    title1.push('Devam Ediyor')
    title1.push('Tamamlandı')
    title1.push('Askıya Alındı')
    setTitle(title1)
    setPC(count1)
  }
  const prdNumberAlarm = (data: any) => {
    setTitle([])
    count1.push(data?.total_active)
    count1.push(data?.total_closed)
    title1.push('Active')
    title1.push('Closed')
    setTotalCount(data?.total_active + data?.total_closed)
    setPC(count1)
    setTitle(title1)
  }
  const prdMirroring = (data: any) => {
    setTitle([])
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i]?.total_mirroring
      count1.push(data[i]?.total_mirroring)
      title1.push(data[i]?.month)
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  const prdDLPStatistics = (data: any) => {
    setTitle([])
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.total)
      title1.push(data[i]?.client_installation_version)
    }
    setPC(count1)
    setTitle(title1)
  }
  const prdNKDY = (data: any) => {
    setTitle([])
    if (widgetId == '40') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].bt_catalog_urgency == null) continue
        total = total + data[i]?.total
        count1.push(data[i]?.total)
        title1.push(data[i]?.bt_catalog_urgency)
      }
    } else if (widgetId == '41') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].mx_cozumtipi_description == null) continue
        total = total + data[i]?.total
        count1.push(data[i]?.total)
        title1.push(data[i]?.mx_cozumtipi_description)
      }
    } else if (widgetId == '42') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].status_description == null) continue
        total = total + data[i]?.total
        count1.push(data[i]?.total)
        title1.push(data[i]?.status_description)
      }
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  const prdIAR = (data: any) => {
    setTitle([])
    total = 0
    for (let i = 0; i < data?.length; i++) {
      if (data[i].status_description == null) continue
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      if (widgetId == '57') {
        title1.push(data[i]?.status_description)
      } else if (widgetId == '58') {
        title1.push(data[i]?.impact_description)
      }
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  const prdProjectDemand = (data: any) => {
    setTitle([])
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.security_source + ' ' + data[i]?.total)
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  const prdVN = (data: any) => {
    setTitle([])
    count1.push(Number(data?.total_open))
    count1.push(Number(data?.total_close))
    title1.push('Açık Kayıt')
    title1.push('Kapalı Kayıt')
    setPC(count1)
    setTitle(title1)
  }
  const prdAssetsInventory = (data: any) => {
    setTitle([])
    let total = 0
    if (widgetId == '83') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.criticality_level == null) continue
        total = total + data[i]?.total
        count1.push(data[i]?.total)
        title1.push(data[i]?.criticality_level)
      }
    } else if (widgetId == '84' || widgetId == '86' || widgetId == '90' || widgetId == '98' || widgetId == '100') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.ref_status == null) continue
        total = total + data[i]?.total
        count1.push(data[i]?.total)
        title1.push(data[i]?.ref_status)
      }
    }

    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  const prdPublicData = (data: any) => {
    setTitle([])
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.application_type == null) continue
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.application_type)
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }

  const prdRiskLevel = (data: any) => {
    setTitle([])
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.risk_level == null) continue
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.risk_level)
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }

  const prdIsOpen = (data: any) => {
    setTitle([])
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.is_open == null) continue
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.is_open)
    }
    setTotalCount(total)
    setPC(count1)
    setTitle(title1)
  }
  return (
    <div className='card card-flush pie-chart' style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <Link to={plusOrMinus ? '' : linkToPage}>
            <div>
              <div className='fs-2 fw-bolder'>{name}</div>
              {widgetId == '35' && <span>Aynalama Talepleri</span>}
              {widgetId == '57' && <span>Gelir Güvence Talep Durumları</span>}
              {widgetId == '83' && <span>Varlık Ana Grubu Bazında</span>}
              {widgetId == '84' && <span>Varlık Grup Anketi Bazında</span>}
              {widgetId == '86' && <span>Direktörlük Bazında Aksiyonlar</span>}
              {widgetId == '87' && <span>İnternete Açık Uygulama </span>}
              {widgetId == '90' && <span>Plananan Denetimler</span>}
              {widgetId == '98' && <span>Risk Dağılımı</span>}
              {widgetId == '99' && <span>Risk Dağılımı</span>}
              {widgetId == '100' && <span>Risk Aksiyonları</span>}
              <div className='fs-4 fw-bold text-gray-400 '>
                {widgetId === '31' ||
                widgetId === '33' ||
                widgetId === '35' ||
                widgetId === '60' ||
                widgetId === '40' ||
                widgetId === '41' ||
                widgetId === '42' ||
                widgetId == '57' ||
                widgetId == '83' ||
                widgetId == '84' ||
                widgetId == '86' ||
                widgetId == '87' ||
                widgetId == '90' ||
                widgetId == '98' ||
                widgetId == '99' ||
                widgetId == '100' ||
                widgetId == '101'
                  ? `Toplam ${totalCount} Adet`
                  : titleText}
              </div>
            </div>
          </Link>
          <div>
            <span
              className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex'
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
              onClick={() => {
                homeWidgetShow()
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
            <br />
            {plusOrMinus && (
              <span
                className='text-gray-600 fs-6 mt-n2 me-2  d-inline-flex '
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setSelectedValue('')
                }}
              >
                Tümü
              </span>
            )}
          </div>
        </div>

        <div className='p-5 m-5'>
          <Chart options={chart3} series={planC} type='pie' height={300} />
        </div>
      </div>
    </div>
  )
}
