import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {CreateBudget, GetBudgetCategories, GetBudgetCompanies, GetBudgetPurchaseTypes, GetBudgetTypes, UpdateBudget} from '../core/_requests'
import {getCSRFToken, getUsers} from '../../core/requests'
import {ModalHeader} from '../../components/ModalHeader'
import {Modal} from 'react-bootstrap'
import {AddCategory} from '../../Categories/AddCategory'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {GetAssignments} from '../../OrganizationalStructure/core/_requests'
import Dropzone from 'react-dropzone'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  budget: any
  update: boolean
  handleClose: any
  refreshPage: any
}
const budgetSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  amount: Yup.number().required('Zorunlu alan'),
  currency: Yup.string().required('Zorunlu alan'),
  assignment_id: Yup.number().required('Zorunlu alan'),
  owner_by: Yup.string().required('Zorunlu alan'),
  budget_type_id: Yup.number().required('Zorunlu alan'),
  budget_category_id: Yup.number().required('Zorunlu alan'),
  budget_company_id: Yup.number().required('Zorunlu alan'),
  budget_purchase_type_id: Yup.number().required('Zorunlu alan'),
  year: Yup.number().required('Zorunlu alan'),
  try_amount: Yup.number().required('Zorunlu alan'),
})

export const AddBudget: FC<Props> = ({budget, update, handleClose, refreshPage}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [assignments, setAssignments] = useState<any>([
    {
      id: 1,
      name: '',
      is_directorate: false,
    },
  ])
  const [types, setTypes] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [companies, setCompanies] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [purchaseTypes, setPurchaseTypes] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [userForEdit] = useState<any>({
    ...budget,
    id: budget.id,
    owner_by: budget.owner.id,
    assignment_id: budget.assignment.id,
    budget_type_id: budget.type.id,
    budget_category_id: budget.category.id,
    budget_company_id: budget.company.id,
    budget_purchase_type_id: budget.purchase_type.id,
    kades_no: budget.kades_no,
    try_amount: budget.try_amount,
  })
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const [files, setFiles] = useState<any>([])
  const [errors2, setErrors2] = useState('')
  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }
  const removeAll = () => {
    setFiles([])
  }
  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm  mr-10' onClick={removeFile(file)} style={{color: 'white'}}>
          Sil
        </span>
      </li>
    )
  )

  useEffect(() => {
    GetBudgetCategories().then(({data}) => {
      setCategories(data.data)
    })
    GetBudgetCompanies().then(({data}) => {
      setCompanies(data.data)
    })
    GetBudgetPurchaseTypes().then(({data}) => {
      setPurchaseTypes(data.data)
    })
    GetBudgetTypes().then(({data}) => {
      setTypes(data.data)
    })

    GetAssignments().then(({data}) => {
      setAssignments(data.data)
    })
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
  }, [showCategoryModal])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: budgetSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          let BudgetData = {
            title: values.title,
            amount: values.amount,
            currency: values.currency,
            assignment_id: values.assignment_id,
            owner_by: values.owner_by,
            budget_type_id: values.budget_type_id,
            budget_category_id: values.budget_category_id,
            budget_company_id: values.budget_company_id,
            budget_purchase_type_id: values.budget_purchase_type_id,
            year: values.year,
            kades_no: values.kades_no,
            try_amount: values.try_amount,
          }

          await UpdateBudget(budget.id, BudgetData)
            .then(() => {
              swal('Düzenlendi', 'Bütçe başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values.title)
          formData.append('amount', values.amount)
          formData.append('currency', values.currency)
          formData.append('assignment_id', values.assignment_id)
          formData.append('owner_by', values.owner_by)
          formData.append('budget_type_id', values.budget_type_id)
          formData.append('budget_category_id', values.budget_category_id)
          formData.append('budget_company_id', values.budget_company_id)
          formData.append('budget_purchase_type_id', values.budget_purchase_type_id)
          formData.append('year', values.year)
          formData.append('kades_no', values.kades_no)
          formData.append('try_amount', values.try_amount)
          for (let i = 0; i < files?.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
            //console.log(base64);
          }
          await CreateBudget(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Bütçe başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              console.log(err.message)
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form id='kt_modal_add_inventory_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_inventory_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_inventory_header'
          data-kt-scroll-wrappers='#kt_modal_add_inventory_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Bütçe Başlığı</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tutarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('amount')}
                type='number'
                name='amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.amount && formik.errors.amount},
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.currency && formik.errors.currency},
                  {
                    'is-valid': formik.touched.currency && !formik.errors.currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('currency')}
                name='currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Döviz Birimi Seçiniz
                </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'> TL Tutarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('try_amount')}
                type='number'
                name='try_amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.try_amount && formik.errors.try_amount},
                  {
                    'is-valid': formik.touched.try_amount && !formik.errors.try_amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.try_amount && formik.errors.try_amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.try_amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              <label className='fw-bold fs-7 mb-2'>Kades No</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('kades_no')}
                type='string'
                name='kades_no'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.kades_no && formik.errors.kades_no},
                  {
                    'is-valid': formik.touched.kades_no && !formik.errors.kades_no,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.kades_no && formik.errors.kades_no && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.kades_no}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Görevli</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.assignment_id && formik.errors.assignment_id},
                {
                  'is-valid': formik.touched.assignment_id && !formik.errors.assignment_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Görevli'
              {...formik.getFieldProps('assignment_id')}
              name='assignment_id'
            >
              {/* {activity.assigned_by!==''} */}
              <option value='0' hidden>
                Görevli Seçiniz
              </option>
              {assignments.map((assignment: any) => {
                return assignment.is_directorate ? (
                  <option key={assignment.id} value={assignment.id}>
                    {assignment.alternative_name}
                  </option>
                ) : (
                  ''
                )
              })}
            </select>
            {formik.touched.assignment_id && formik.errors.assignment_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assignment_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              <label className='required fw-bold fs-7 mb-2 required'>Sahibi</label>
              {/* end::Label */}
              <select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Sahip' {...formik.getFieldProps('owner_by')} name='owner_by'>
                {/* {activity.assigned_by!==''} */}
                <option hidden>Bütçe Sahibini Seçiniz</option>
                {users.map((user: any) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.owner_by && formik.errors.owner_by && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.owner_by}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bütçe Tipi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid'
                  // {'is-invalid': formik.touched.budget_type_id && formik.errors.budget_type_id},
                  // {
                  //   'is-valid': formik.touched.budget_type_id&& !formik.errors.budget_type_id,
                  // }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Bütçe Tipi'
                {...formik.getFieldProps('budget_type_id')}
                name='budget_type_id'
              >
                <option value='0' hidden>
                  Bütçe Tipini Seçiniz
                </option>
                {types.map((type: any) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.budget_type_id && formik.errors.budget_type_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_type_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              <div className='d-flex justify-content-between'>
                <label className='required fw-bold fs-7 mb-2'>Bütçe Kategorisi</label>
                {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && <img src='https://cdn-icons-png.flaticon.com/512/992/992651.png' alt='add-icon' width='25px' className='m-1' onClick={openCategoryModal} />}
              </div>

              {/* end::Label */}
              <select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Sahip' {...formik.getFieldProps('budget_category_id')} name='budget_category_id'>
                <option hidden>Bütçe Kategorisini Seçiniz</option>
                {categories.map((category: any) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.budget_category_id && formik.errors.budget_category_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_category_id}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Satınalma Türü</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {
                    'is-invalid': formik.touched.budget_purchase_type_id && formik.errors.budget_purchase_type_id,
                  },
                  {
                    'is-valid': formik.touched.budget_purchase_type_id && !formik.errors.budget_purchase_type_id,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Bütçe Tipi'
                {...formik.getFieldProps('budget_purchase_type_id')}
                name='budget_purchase_type_id'
              >
                <option value='0' hidden>
                  Satın Alma Türünü Seçiniz
                </option>
                {purchaseTypes.map((type: any) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.budget_purchase_type_id && formik.errors.budget_purchase_type_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_purchase_type_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              <label className='required fw-bold fs-7 mb-2'>Şirket adı</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {
                    'is-invalid': formik.touched.budget_company_id && formik.errors.budget_company_id,
                  },
                  {
                    'is-valid': formik.touched.budget_company_id && !formik.errors.budget_company_id,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sahip'
                {...formik.getFieldProps('budget_company_id')}
                name='budget_company_id'
              >
                <option value='0' hidden>
                  Şirket Seçiniz
                </option>
                {companies.map((company: any) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.budget_company_id && formik.errors.budget_company_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_company_id}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Yılı</label>
              {/* end::Label */}
              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('year')}
                type='number'
                name='year'
                min={2000}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.year && formik.errors.year},
                  {
                    'is-valid': formik.touched.year && !formik.errors.year,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.year && formik.errors.year && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.year}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{display: update ? 'none' : 'block'}} className='fv-row mb-8'>
            <label className='fs-7 fw-bold mb-2'>Dosya Yükle</label>

            <div className='dropzone' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z' fill='currentColor' />
                    <path d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z' fill='currentColor' />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

                <Dropzone
                  maxSize={10485760}
                  maxFiles={10}
                  onDrop={async (acceptedFiles, fileRejections) => {
                    setFiles(acceptedFiles)
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === 'file-too-large') {
                          setErrors2(`Hata: ${err.message}`)
                        }

                        if (err.code === 'file-invalid-type') {
                          setErrors2(`Hata: ${err.message}`)
                        } else {
                          setErrors2(`Hata: ${err.message}`)
                        }
                      })
                    })
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>Dosyaları buraya bırakın veya yüklemek için tıklayın</h3>
                          <span className='fw-bold fs-7 text-gray-400'>5 dosyaya kadar yükleyin</span>
                        </div>
                        <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>{errors2}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
            <ul className='m-0 p-0'>{filess}</ul>
          </aside>
          <div className='d-flex justify-content-end'>
            {' '}
            {files?.length > 0 && (
              <span onClick={removeAll} className='btn btn-sm  me-2 ' style={{color: 'white'}}>
                Hepsini Sil
              </span>
            )}
          </div>
          <div className='text-center pt-15'>
            <button type='reset' className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting} onClick={handleClose}>
              İptal
            </button>

            <button type='submit' className='btn ' data-kt-users-modal-action='submit' disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}>
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      <Modal show={showCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='budget'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
