import axios from 'axios';
import { API_URL } from './../../request';
const ANNOUNCEMENT =`${API_URL}/announcement_categories/`
const INVENTORY = `${API_URL}/inventory_categories/`
const BLOG = `${API_URL}/blog_categories/`
const DOCUMENT = `${API_URL}/document_categories/`
const ISSUE = `${API_URL}/issue_categories/`
const SUPPLIER = `${API_URL}/supplier_categories/`
const PROJECT_CATEGORIES = `${API_URL}/project_categories/`
const PROJECT_PLANS =`${API_URL}/project_plans/`
const PROJECT_CLASSES=`${API_URL}/project_classes/`
const PROJECT_TYPES= `${API_URL}/project_types/`
const BUDGET = `${API_URL}/budget_categories/`

// anouncement

export async function GetAnnouncementCategories(){
    return await axios.get(ANNOUNCEMENT)
}

export async function CreateAnnouncementCategory(categoryName: string){
    return await axios.post(ANNOUNCEMENT,categoryName)
}

export async function UpdateAnnouncementCategory(categoryId: any, categoryName: string){
    return await axios.put(ANNOUNCEMENT+categoryId,categoryName)
}


// inventory

export async function GetInventoryCategories(){
    return await axios.get(INVENTORY)
}

export async function CreateInventoryCategory(categoryName:string) {
    return await axios.post(INVENTORY,categoryName)
}

export async function UpdateInventoryCategory(categoryId:any, categoryName:string) {
    return await axios.put(INVENTORY+categoryId,categoryName)
}

//blog

export async function GetBlogCategories() {
    return await axios.get(BLOG)
}

export async function CreateBlogCategory(categoryName: string) {
    return await axios.post(BLOG,categoryName)
}

export async function UpdateBlogCategory(categoryId:any, categoryName: string) {
    return await axios.put(BLOG+categoryId,categoryName)
}


// document

export async function GetDocumentCategories() {
    return await axios.get(DOCUMENT)
}

export async function CreateDocumentCategory(categoryName: string) {
    return await axios.post(DOCUMENT,categoryName)
}

export async function UpdateDocumentCategory(categoryId:any, categoryName: string) {
    return await axios.put(DOCUMENT+categoryId,categoryName)
}

// issue

export async function GetIssueCategories() {
    return await axios.get(ISSUE)
}

export async function CreateIssueCategory(categoryName: string) {
    return await axios.post(ISSUE,categoryName)
}

export async function UpdateIssueCategory(categoryId:any, categoryName: string) {
    return await axios.put(ISSUE+categoryId,categoryName)
}

// supplier

export async function GetSupplierCategories() {
    return await axios.get(SUPPLIER)
}

export async function CreateSupplierCategory(categoryName: string) {
    return await axios.post(SUPPLIER,categoryName)
}

export async function UpdateSupplierCategory(categoryId:any, categoryName: string) {
    return await axios.put(SUPPLIER+categoryId,categoryName)
}

// budget

export async function GetBudgetCategories() {
    return await axios.get(BUDGET)
}

export async function CreateBudgetCategory(categoryName: string) {
    return await axios.post(BUDGET,categoryName)
}

export async function UpdateBudgetCategory(categoryId:any, categoryName: string) {
    return await axios.put(BUDGET+categoryId,categoryName)
}

// project categories

export async function GetProjectCategories() {
    return await axios.get(PROJECT_CATEGORIES)
}

export async function CreateProjectCategory(categoryName: string) {
    return await axios.post(PROJECT_CATEGORIES,categoryName)
}

export async function UpdateProjectCategory(categoryId:any, categoryName: string) {
    return await axios.put(PROJECT_CATEGORIES+categoryId,categoryName)
}

// project plans
export async function GetProjectPlans() {
    return await axios.get(PROJECT_PLANS)
}

export async function CreateProjectPlan(categoryName: string) {
    return await axios.post(PROJECT_PLANS,categoryName)
}

export async function UpdateProjectPlan(categoryId:any, categoryName: string) {
    return await axios.put(PROJECT_PLANS+categoryId,categoryName)
}

// project types
export async function GetProjectTypes() {
    return await axios.get(PROJECT_TYPES)
}

export async function CreateProjectType(categoryName: string) {
    return await axios.post(PROJECT_TYPES,categoryName)
}

export async function UpdateProjectType(categoryId:any, categoryName: string) {
    return await axios.put(PROJECT_TYPES+categoryId,categoryName)
}

//project classes
export async function GetProjectClasses() {
    return await axios.get(PROJECT_CLASSES)
}

export async function CreateProjectClass(categoryName: string) {
    return await axios.post(PROJECT_CLASSES,categoryName)
}

export async function UpdateProjectClass(categoryId:any, categoryName: string) {
    return await axios.put(PROJECT_CLASSES+categoryId,categoryName)
}
