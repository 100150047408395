import {FC} from 'react'
import {NavLink} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  supplier: any
  percentage: any
}
export const UserInfo: FC<Props> = ({supplier, percentage}) => {
  const filename = (param: any) => {
    const icon = param.split('.')[1]
    return icon
  }
  return (
    <>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ml-0'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-5'>
              {/*  <div className='symbol symbol-100px symbol-circle mb-7'>
              <img src='assets/media/avatars/300-6.jpg' alt='image' />
            </div> */}

              <span className='fs-3 text-gray-800 fw-bolder mb-3'>
                <NavLink className='text-gray-800 text-hover-primary' to='../profile'>
                  {supplier.name}
                </NavLink>
              </span>

              <div className='mb-9'>
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {supplier.category.name}
                </div>
              </div>
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Üretici firma adı</div>
                <div className='text-gray-600'>{supplier.company_name}</div>

                <div className='fw-bolder mt-5'>E-Posta</div>
                <div className='text-gray-600'>
                  <a href='#' className='text-gray-600 text-hover-primary'>
                    {supplier.email}
                  </a>
                </div>

                <div className='fw-bolder mt-5'>Telefon Numarası</div>
                <div className='text-gray-600'>{supplier.phone}</div>

                <div className='fw-bolder mt-5'>Tedarikçi Firma Adres Bilgisi</div>
                <div className='text-gray-600'>{supplier.address}</div>

                <div className='fw-bolder mt-5'>Üretici Firma Ülke</div>
                <div className='text-gray-600'>{supplier.country.name}</div>

                {/* <div className='fw-bolder mt-5'>Vergi Numarası </div>
                <div className='text-gray-600'>{supplier.tax_number}</div> */}
                {supplier?.irtibat_bilgisi != null && (
                  <>
                    <div className='fw-bolder mt-5'>İrtibat Bilgisi </div>
                    <div className='text-gray-600'>{supplier.irtibat_bilgisi}</div>
                  </>
                )}
                {supplier?.yerlilik_bilgisi != null && (
                  <>
                    <div className='fw-bolder mt-5'>Yerlilik Bilgisi </div>
                    <div className='text-gray-600'>{supplier.yerlilik_bilgisi}</div>
                  </>
                )}

                <div className='fw-bolder mt-5'>Tedarikçi Firma Kapanan Case Oranı </div>
                <div className='d-flex align-items-center  justify-content-between'>
                  <div className='progress h-6px w-100 me-2 bg-light-primary'>
                    <div
                      className={`bg-primary rounded h-6px `}
                      role='progressbar'
                      style={{width: `${percentage}%`}}
                    ></div>
                  </div>
                  <div>{percentage.toFixed(0)}%</div>
                  {/* <div className='h-4px'>{percent}%</div> */}
                </div>
                {/* <div className='text-gray-600'>{percentage}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
