import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import HeaderInfo from '../components/HeaderInfo'
import {AgentSituationItem} from './components/AgentSituationItem'
import {DetailFilter} from './components/DetailFilter'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const AgentSituation: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setAuditMachineName('')
    setLastUpdatedDate('')
    setIpAddress('')
    setState('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [auditMachineName, setAuditMachineName] = useState('')
  const [lastUpdatedDate, setLastUpdatedDate] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [state, setState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (auditMachineName != '') {
      queryParams += '&AuditMachineName=' + auditMachineName
    }
    if (lastUpdatedDate != '') {
      queryParams += '&LastUpdateTime=' + lastUpdatedDate
    }
    if (ipAddress != '') {
      queryParams += '&IPAddress=' + ipAddress
    }
    if (state != '') {
      queryParams += '&State=' + state
    }

    GetReport('/agent_situations', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [auditMachineName, lastUpdatedDate, ipAddress, state])

  const downloa = () => {
    window.open(`${ExportLink}/agent_situations?token=${loginUser?.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Number of States'} widgetId={'74'} plusOrMinus={true} linkToPage={''} />
          </div>
          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-body'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/agent_situations_check'} />
                </div>
                <div>
                  {' '}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn  mt-3' style={{marginRight: '10px'}} variant='primary' onClick={downloa}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* 
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase thead-fixed' style={{position: 'sticky', top: '0px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>Audit Machine Name</th>
                      <th className=''>IP Address</th>
                      <th className=''>State</th>
                      <th className=''>Agent Version</th>
                      <th className=''>Free Disk Space</th>
                      <th className=''>Last Update Time</th>
                      <th className=''>integrated service</th>
                      <th className=''>created at</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6' style={{overflow: 'scroll'}}>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <AgentSituationItem key={item?.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilter setAuditMachineName={setAuditMachineName} setLastUpdatedDate={setLastUpdatedDate} setIpAddress={setIpAddress} setState={setState} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
