import clsx from 'clsx'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
type Props = {
  id: any
  name: string
  email: string
  avatar?: string
}

export const User: FC<Props> = ({id, name, email, avatar}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className='d-flex align-items-center mb-5 cursor-pointer'>
        <div className='me-5 position-relative'>
          <div className='symbol symbol-35px symbol-circle'>{avatar ? <img alt='Pic' src={avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{name.substring(0, 1)}</div>}</div>
        </div>

        <div className='fw-bold'>
          <a
            className='fs-5 fw-bolder text-gray-900 text-hover-primary'
            onClick={async () => {
              setProfilUserId(id)
              await openProfilModal()
            }}
          >
            {name}
          </a>
          <div className='text-gray-400'>{email}</div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
