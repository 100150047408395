import {FC, useEffect, useState} from 'react'
import {GetAssignment, GetAssignments, GetRole, SetRole} from '../core/_requests'
import * as Yup from 'yup'
import clsx from 'clsx'

type Props = {
  handleClose: any
  refreshPage: any
}
export const SetRoles: FC<Props> = ({handleClose, refreshPage}) => {
  const [assignments, setAssignments] = useState<any>([])
  const [roles, setRoles] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [checkedRoles, setCheckedRoles] = useState<any>([])
  const [checkedAssignment, setCheckedAssignment] = useState('')
  let formData = new FormData()
  useEffect(() => {
    GetAssignments().then(({data}) => {
      setAssignments(data.data)
    })
    GetRole('').then(({data}) => {
      setRoles(data.data)
    })
  }, [])
  const [userForEdit] = useState<any>({})
  const loginSchema = Yup.object().shape({
    parent_id: Yup.string().required('Zorunlu alan'),
  })

  const handleChange = (id: any) => {
    setRoles([])
    GetAssignment(id).then(async ({data}) => {
      setCheckedRoles(data.roles)
      // console.log(data.roles)
      await GetRole('').then(({data}) => {
        setRoles(data.data)
      })
    })
  }

  const sendResult = (e: any, value: boolean) => {
    let structuredData = {
      roles: [
        {
          id: e.target.name,
          value: value,
        },
      ],
    }
    SetRole(checkedAssignment, structuredData).then(async () => {
      await GetAssignment(checkedAssignment).then(({data}) => {
        setCheckedRoles(data.roles)
      })
    })
  }

  return (
    <form id='kt_modal_add_assignment_info_form' className='form' noValidate>
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_info_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_info_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_info_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          <div>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Görev Seç</label>
            {/* send::Label */}

            {/* begin::Input */}
            <select
              className={clsx('form-select form-select-solid')}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Görev Seç'
              name='parent_id'
              onChange={(e) => {
                setCheckedAssignment(e.target.value)
                handleChange(e.target.value)
              }}
              // defaultValue={user.assignment!==null ? user.assignment.id :''}
            >
              <option hidden>Parent</option>
              {/* <option value='null'>Null</option> */}
              {assignments.map((assign: any) => {
                return (
                  <option key={assign.id} value={assign.id}>
                    {assign.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className=''>
          {roles.map((role: any) => {
            return (
              <label className='form-check form-check-custom form-check-solid me-10 mt-3'>
                <input
                  className='form-check-input h-20px w-20px'
                  type='checkbox'
                  name={role.id}
                  // value={checkedRoles.some((x:any)=>x.id==role.id)}
                  defaultChecked={checkedRoles.some((x: any) => x.id == role.id)}
                  //  key={role.id}
                  onChange={(e: any) => {
                    sendResult(e, !checkedRoles.some((x: any) => x.id == role.id))
                    console.log(e.target.name)
                  }}
                />

                <span className='form-check-label fw-bold'>{role.name}</span>
              </label>
            )
          })}
        </div>
      </div>
    </form>
  )
}
