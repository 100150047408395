import {useEffect, useState} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_theme/layout/core'
import {getProject} from '../core/requests'
import {Activity} from './components/Activity'
import {Budget} from './components/Budget'
import {Document} from './components/Document'
import {HomePage} from './components/HomePage'
import {Issues} from './components/issues'

import {ProjectProgress} from './components/ProjectProgress'
import {SettingsPage} from './components/SettingsPage'
import {Stakeholder} from './components/Stakeholder'
import {Updates} from './components/Updates'

import {DetailHeader} from './DetailHeader'
import {TargetDetail} from './TargetDetail'

type LocationState = {
  id: string
}
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DetailPagesWrapper = () => (
  <Routes>
    <Route
      element={
        <>
          <DetailHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path='homepage'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Proje Detay (Karne)
            </PageTitle>
            <HomePage />
          </>
        }
      />
      <Route
        path='target-detail'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Proje Detay (Karne)
            </PageTitle>
            <TargetDetail />
          </>
        }
      />
      <Route
        path='project-progress'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Proje Detay (Karne)
            </PageTitle>
            <ProjectProgress />
          </>
        }
      />
      <Route
        path='budget'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Hedefler
            </PageTitle>
            <Budget />
          </>
        }
      />
      <Route
        path='stakeholders'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Paydaşlar
            </PageTitle>
            <Stakeholder />
          </>
        }
      />
      <Route
        path='documents'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Dosyalar
            </PageTitle>
            <Document />
          </>
        }
      />
      <Route
        path='activity'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Hedefler
            </PageTitle>
            <Activity />
          </>
        }
      />
      <Route
        path='settings'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Ayarlar
            </PageTitle>
            <SettingsPage />
          </>
        }
      />
      <Route
        path='issues'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Faaliyetler
            </PageTitle>
            <Issues />
          </>
        }
      />
      <Route
        path='updates'
        element={
          <>
            <PageTitle
              breadcrumbs={dashboardBreadCrumbs}
              description='Projeler'
              toDescription='/projeler/tum-projeler'
            >
              Güncellemeler
            </PageTitle>
            <Updates />
          </>
        }
      />
      <Route index element={<Navigate to='/projeler/detail/homepage' />} />
    </Route>
  </Routes>
)

export default DetailPagesWrapper
