import moment from "moment"
import { FC } from "react"
type Props={
    item: any
}
export const CarbonBlackItem : FC<Props>=({item})=>{
    return(
       <>
             <td className='fw-bolder'>
            {item?.active_sensor_count}
        </td>
        <td className='text-gray-500'>{(item?.num_eventlog_bytes*0.000000001).toFixed(2)} GB</td>
        <td className='text-gray-500'>{(item?.num_storefiles_bytes*0.000000001).toFixed(2)} GB</td>
        <td className='text-gray-500'>{item?.sensor_count}</td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY H:mm')}</td>
        {/* <td className='text-gray-500'>{moment(item?.updated_at).format('DD.MM.YYYY H:mm')} </td>
        <td className='text-gray-500'>{moment(item?.deleted_at).format('DD.MM.YYYY H:mm')}</td> */}
         <tr>       
      </tr>
       </>
    )
}