import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {downloadReport, GetCAStatistics, GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {SearchComponent} from '../components/SearchComponent'
import {TableBody} from './components/TableBody'
import {Add} from './add/Add'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import HeaderInfo from '../components/HeaderInfo'

export const ProjectDemandTracking: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const api = '/project_demand_tracking'
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [items, setItems] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetReport(api, link).then((data) => {
      setItems(data?.data?.data)
      setSearchKeyClick('')
    })
  }, [refresh, searchKeyClick, show])

  const exportFile = () => {
    window.open(`${ExportLink}/project_demand_tracking?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Haftalık Durum'} link={''} widgetId={'60'} plusOrMinus={true} linkToPage={''} />
          </div>
          <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Haftalık Grafik'} widgetId={'61'} plusOrMinus={true} linkToPage={''} />
          </div>

          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            {/* <div className=''>
              {/* <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                 

                
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div> */}
            {/* 
            </div> */}
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3 overflow-auto'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center position-relative my-3 '>
                  <HeaderInfo api={''} />
                </div>
                <div>
                  <Button className='btn  mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                  <tr style={{color: '#50c8da'}}>
                    <th className=''>Talep No</th>
                    <th className=''>Talep Adı</th>
                    <th className=''>Güvenlik Kaynağı</th>
                    <th className=''>Talep Sahibi</th>
                    <th className=''>Yeni sunucu kurulumu olacak mı? </th>
                    <th className=''>Kullanıcı arayüzde geliştirme yapılacak mı?</th>
                    <th className=''>Yeni entegrasyon yapılacak mı?</th>
                    <th className=''>Kaynak kod geliştirilecek mi? </th>
                    <th className=''>Yeni/mevcut web servis geliştirilecek mi?</th>
                    <th className=''> Mevcut ağ topolojisinde bir değişiklik yapılacak mı?</th>
                    <th className=''>Portföy Yöneticisi</th>
                    <th className=''> Çözüm Sorumlusu</th>

                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {items?.map((item: any) => {
                    return (
                      <>
                        <TableBody key={item?.id} item={item} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <Add handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
