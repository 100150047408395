import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {CreateOrder, UpdateOrder} from '../core/_requests'
import swal from 'sweetalert'
import clsx from 'clsx'
import {getSuppliers} from '../../Supplier/core/_requests'
import {useDispatch} from 'react-redux'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'
import {getCSRFToken} from '../../core/requests'
type Props = {
  handleClose: any
  order: any
  update: boolean
}
export const AddOrder: FC<Props> = ({handleClose, order, update}) => {
  const [suppliers, setSuppliers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [userForEdit] = useState<any>({
    ...order,
    id: order.id,
    order_number: order.order_number,
    tyr_amount: order.try_amount,
    saving_currency: order.saving_currency,
    currency: order.currency,
    efor_birim_bedeli: order.efor_birim_bedeli,
    efor_miktari: order.efor_miktari,
    garanti_bakim_baslangic: order.garanti_bakim_baslangic,
    garanti_bakim_bitis: order.garanti_bakim_bitis,
    migo: order.migo,
    vade_tarihi: order.vade_tarihi,
    fatura_no: order.fatura_no,
    supplier_id: order?.supplier?.id,
  })
  const orderSchema = Yup.object().shape({
    order_number: Yup.string().required('Zorunlu alan'),
    try_amount: Yup.string().required('Zorunlu alan'),
    saving_currency: Yup.string().required('Zorunlu alan'),
    currency: Yup.string().required('Zorunlu alan'),
    amount: Yup.string().required('Zorunlu alan'),
    saving_amount: Yup.number().required('Zorunlu alan'),
  })

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: orderSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          const updOrder = {
            budget_id: values.budget_id,
            order_number: values.order_number,
            currency: values.currency,
            amount: values.amount,
            try_amount: values.try_amount,
            saving_currency: values.saving_currency,
            saving_amount: values.saving_amount,
            efor_birim_bedeli: values.efor_birim_bedeli,
            efor_miktari: values.efor_miktari,
            garanti_bakim_baslangic: values.garanti_bakim_baslangic,
            garanti_bakim_bitis: values.garanti_bakim_bitis,
            migo: values.migo,
            vade_tarihi: values.vade_tarihi,
            fatura_no: values.fatura_no,
            supplier_id: values.supplier_id,
          }
          await UpdateOrder(values.id, updOrder)
            .then(() => {
              swal('Düzenlendi', 'Sipariş başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('budget_id', values.budget_id)
          formData.append('order_number', values.order_number)
          formData.append('currency', values.currency)
          formData.append('amount', values.amount)
          formData.append('try_amount', values.try_amount)
          formData.append('saving_currency', values.saving_currency)
          formData.append('saving_amount', values.saving_amount)
          formData.append('efor_birim_bedeli', values.efor_birim_bedeli)
          formData.append('efor_miktari', values.efor_miktari)
          formData.append('garanti_bakim_baslangic', values.garanti_bakim_baslangic)
          formData.append('garanti_bakim_bitis', values.garanti_bakim_bitis)
          formData.append('migo', values.migo)
          formData.append('vade_tarihi', values.vade_tarihi)
          formData.append('fatura_no', values.fatura_no)
          formData.append('supplier_id', values.supplier_id)
          await CreateOrder(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Sipariş başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  useEffect(() => {
    getSuppliers().then(({data}) => {
      setSuppliers(data.data)
    })
  }, [])
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Sipariş Numarası</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('order_number')}
              type='text'
              name='order_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.order_number && formik.errors.order_number},
                {
                  'is-valid': formik.touched.order_number && !formik.errors.order_number,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.order_number && formik.errors.order_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.order_number}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tutar</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('amount')}
                type='number'
                name='amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.amount && formik.errors.amount},
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.currency && formik.errors.currency},
                  {
                    'is-valid': formik.touched.currency && !formik.errors.currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('currency')}
                name='currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Döviz Birimi Seçiniz
                </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>TL Tutarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('try_amount')}
                type='number'
                name='try_amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.try_amount && formik.errors.try_amount},
                  {
                    'is-valid': formik.touched.try_amount && !formik.errors.try_amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.try_amount && formik.errors.try_amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.try_amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-7 mb-2'>Tedarikçi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.supplier_id && formik.errors.supplier_id},
                  {
                    'is-valid': formik.touched.supplier_id && !formik.errors.supplier_id,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Tedarikçi'
                {...formik.getFieldProps('supplier_id')}
                name='supplier_id'
              >
                <option value='' hidden>
                  Tedarikçi Seçiniz Seçiniz{' '}
                </option>
                {suppliers.map((supplier) => {
                  return (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.supplier_id && formik.errors.supplier_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.supplier_id}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tasarruf Miktarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('saving_amount')}
                type='number'
                name='saving_amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.saving_amount && formik.errors.saving_amount},
                  {
                    'is-valid': formik.touched.saving_amount && !formik.errors.saving_amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.saving_amount && formik.errors.saving_amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.saving_amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.saving_currency && formik.errors.saving_currency},
                  {
                    'is-valid': formik.touched.saving_currency && !formik.errors.saving_currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('saving_currency')}
                name='saving_currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Döviz Birimi Seçiniz
                </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.saving_currency && formik.errors.saving_currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.saving_currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Efor Birim Bedeli</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('efor_birim_bedeli')}
                type='number'
                name='efor_birim_bedeli'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.efor_birim_bedeli && formik.errors.efor_birim_bedeli,
                  },
                  {
                    'is-valid':
                      formik.touched.efor_birim_bedeli && !formik.errors.efor_birim_bedeli,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.efor_birim_bedeli && formik.errors.efor_birim_bedeli && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.efor_birim_bedeli}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Efor Miktarı</label>
              {/* end::Label */}
              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('efor_miktari')}
                type='number'
                name='efor_miktari'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.efor_miktari && formik.errors.efor_miktari},
                  {
                    'is-valid': formik.touched.efor_miktari && !formik.errors.efor_miktari,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />

              {formik.touched.efor_miktari && formik.errors.efor_miktari && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.efor_miktari}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Garanti Bakım Başlangıcı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('garanti_bakim_baslangic')}
                type='date'
                name='garanti_bakim_baslangic'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.garanti_bakim_baslangic &&
                      formik.errors.garanti_bakim_baslangic,
                  },
                  {
                    'is-valid':
                      formik.touched.garanti_bakim_baslangic &&
                      !formik.errors.garanti_bakim_baslangic,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.garanti_bakim_baslangic && formik.errors.garanti_bakim_baslangic && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.garanti_bakim_baslangic}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Garanti Bakım Bitişi</label>
              {/* end::Label */}
              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('garanti_bakim_bitis')}
                type='date'
                name='garanti_bakim_bitis'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.garanti_bakim_bitis && formik.errors.garanti_bakim_bitis,
                  },
                  {
                    'is-valid':
                      formik.touched.garanti_bakim_bitis && !formik.errors.garanti_bakim_bitis,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />

              {formik.touched.garanti_bakim_bitis && formik.errors.garanti_bakim_bitis && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.garanti_bakim_bitis}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Migo</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('migo')}
                type='string'
                name='migo'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.migo && formik.errors.migo},
                  {
                    'is-valid': formik.touched.migo && !formik.errors.migo,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.migo && formik.errors.migo && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.migo}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Vade Tarihi</label>
              {/* end::Label */}
              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('vade_tarihi')}
                type='date'
                name='vade_tarihi'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.vade_tarihi && formik.errors.vade_tarihi},
                  {
                    'is-valid': formik.touched.vade_tarihi && !formik.errors.vade_tarihi,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />

              {formik.touched.vade_tarihi && formik.errors.vade_tarihi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.vade_tarihi}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Fatura No</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('fatura_no')}
              type='string'
              name='fatura_no'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.fatura_no && formik.errors.fatura_no},
                {
                  'is-valid': formik.touched.fatura_no && !formik.errors.fatura_no,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.fatura_no && formik.errors.fatura_no && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fatura_no}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
