import React, {FC, Suspense} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import NoData from '../components/NoData'
import {AgentSituation} from './AgentSituation/AgentSituation'
import {AlarmTraking} from './AlarmTrakıng/AlarmTraking'
import {AssetInventory} from './AssetInventory/AssetInventory'
import {AttackResult} from './AtackResult/AtackResult'
import {AudingsAndFindings} from './AudingsAndFindings/AudingsAndFindings'
import {CU} from './CihazUtilization/CU'
import {CriticalInventory} from './CriciticalInventory/CriticalInventory'
import {CAttact} from './CustomerAttack/CA'
import {DSRN} from './DataSecurıtyRequestNumber/DSRN'
import {EPDataPageWrapper} from './EndpointProtectionData/EPDataPageWrapper'
import {FileIntegrityMonitoring} from './FileIntegrityMonitoring/FileIntegrityMonitoring'
import {IADS} from './IncomeAssuranceDataSummary/IADS'
import {IAR} from './Income_Assurance_Request/IAR'
import {InformationSecurityAssets} from './InformationSecurityAssets/InformationSecurityAssets'
import {ISAM} from './InformationSecurityData/ISAM'
import {MaliciousStatistics} from './MaliciousStatistics/MaliciousStatistics'
import {Mirroring} from './Mirroring/Mirroring'
import {NKDY} from './NKDY_Support_Request/NKDY'
import {NumberOfAlarmCases} from './NumberOfAlarmCases/NumberOfAlarmCases'
import {NOD} from './NumberOfDemond/NOD'
import {ProjectDemandTracking} from './ProjectDemandTracking/ProjectDemandTracking'
import {RiskManagement} from './RiskManagement/RiskManagement'
import {SecurityScore} from './SecurityScore/SecurityScore'
import {VulnerabilityExploitation} from './VulnerabilityExploitation/VulnerabilityExploitation'
import {VI} from './VulnerabilityIndicator/VI'
import {VN} from './VulnerabilityNotification/VN'
import {WebTraffic} from './WebTraffic/WebTraffic'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OperationalDataWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dlp-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                DLP İstatistikleri
              </PageTitle>
              <ISAM />
            </>
          }
        />

        <Route
          path='network-zafiyet-bildirim-durumu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Network Zafiyet Bildirim Durumu
              </PageTitle>
              <VN />
            </>
          }
        />
        <Route
          path='veri-guvenligi-butunlugu-dam-ajan-talepleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Veri Güvenliği Bütünlüğü ve DAM Ajan Durumu
              </PageTitle>
              <DSRN />
            </>
          }
        />
        <Route
          path='gelir-guvence-verileri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Gelir Güvence Veriler
              </PageTitle>
              <IADS />
            </>
          }
        />
        <Route
          path='proje-ttt-ve-talep-takibi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Proje TTT ve Talep Takibi
              </PageTitle>
              <ProjectDemandTracking />
            </>
          }
        />
        <Route
          path='soc-alarm-takibi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                SOC Alarm Takibi
              </PageTitle>
              <AlarmTraking />
            </>
          }
        />
        <Route
          path='aynalama-talepleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Aynalama Talebi
              </PageTitle>
              <Mirroring />
            </>
          }
        />

        <Route
          path='ddo-uyumluluk-durumu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                DDO Uyumluluk Durumu
              </PageTitle>
              <AssetInventory />
            </>
          }
        />
        <Route
          path='farkindalik-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Farkındalık İstatistikleri
              </PageTitle>
              <NoData />
            </>
          }
        />

        <Route
          path='bilgi-guvenligi-denetimleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Bilgi Güvenliği Denetimleri
              </PageTitle>
              <AudingsAndFindings />
            </>
          }
        />
        <Route
          path='bilgi-guvenligi-varlik-envanteri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Bilgi Güvenliği Varlık Envanteri
              </PageTitle>
              <CriticalInventory />
            </>
          }
        />
        <Route
          path='kritik-envanter'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Kritik Envanter
              </PageTitle>
              <InformationSecurityAssets />
            </>
          }
        />
        <Route
          path='risk-yonetimi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Risk Yönetimi
              </PageTitle>
              <RiskManagement />
            </>
          }
        />
        <Route
          path='tehdit-sayilari-raporu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Tehdit Sayıları Raporu
              </PageTitle>
              <NoData />
            </>
          }
        />

        <Route
          path='dosya-butunluk-izleme'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Dosya Bütünlük İzleme
              </PageTitle>
              <FileIntegrityMonitoring />
            </>
          }
        />
        <Route
          path='talep-sayilari-ve-karsilama-sureleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Talep Sayılar ve Karşılama Süreleri
              </PageTitle>
              <NOD />
            </>
          }
        />

        <Route
          path='soar-alarm-durumlari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                SOAR Alarm Durumları
              </PageTitle>
              <NumberOfAlarmCases />
            </>
          }
        />
        <Route
          path='kategori-bazli-web-trafik-durumu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Kategorik Bazlı Web Trafik Durumu
              </PageTitle>
              <WebTraffic />
            </>
          }
        />

        <Route
          path='nkdy-destek-talebi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                NKDY Destek Talebi
              </PageTitle>
              <NKDY />
            </>
          }
        />

        <Route
          path='gelir-guvence-talep-durumlari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Gelir Güvence Talep Durumları
              </PageTitle>
              <IAR />
            </>
          }
        />

        {/* <Route
          path='kurumsal-atak-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Kurumsal Atak İstatistikleri
              </PageTitle>
              <AttackResult />
            </>
          }
        /> */}
        <Route
          path='yazilim-engelleme-basari-orani'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Yazılım Engelleme Başarı Oranı
              </PageTitle>
              <SecurityScore />
            </>
          }
        />

        <Route
          path='uc-nokta-koruma-guvenlik-verileri/*'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Uç Nokta Koruma Güvenlik Verileri
              </PageTitle>
              <EPDataPageWrapper />
            </>
          }
        />

        <Route
          path='zararli-istatistikler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Zararlı İstatistikler
              </PageTitle>
              <MaliciousStatistics />
            </>
          }
        />

        <Route
          path='pam-ajan-durumu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                PAM Ajan Durumu
              </PageTitle>
              <AgentSituation />
            </>
          }
        />
        <Route
          path='sizma-testi-zafiyet-ve-bulgu-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Sızma Testi Zafiyet ve Bulgu İstatistikleri
              </PageTitle>
              <VI />
            </>
          }
        />
        <Route
          path='cihaz-utilization-ve-availability-raporu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Cihaz Utilization ve Availability Raporu
              </PageTitle>
              <CU />
            </>
          }
        />
        <Route
          path='kurumsal-atak-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Kurumsal Atak İstatistikleri
              </PageTitle>
              <AttackResult />
            </>
          }
        />

        <Route
          path='exploit-engelleme'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Exploit Engelleme
              </PageTitle>
              <VulnerabilityExploitation />
            </>
          }
        />

        <Route index element={<Navigate to='/dlp-istatistikleri' />} />
      </Route>
    </Routes>
  )
}

export {OperationalDataWrapper}
