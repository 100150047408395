import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const IAR_Item: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.ticket_id}</td>
        <td className='text-gray-500'>{item?.description}</td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='text-gray-500'>{item?.technology}</td>
        <td className='text-gray-500'>{item?.creation_date ? moment(item?.creation_date).format('DD.MM.YYYY HH:mm:ss') : ''} </td>
        <td className='text-gray-500'>{item?.affected_person_id} </td>
        <td className='text-gray-500'>{item?.owner}</td>
        <td className='text-gray-500'>{item?.assigned_owner_group}</td>
        <td className='text-gray-500'>{item?.impact}</td>
        <td className='text-gray-500'>{item?.urgency}</td>
        <td className='text-gray-500'>{item?.internal_priority}</td>
      </tr>
    </>
  )
}
