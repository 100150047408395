import {FC, useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import swal from 'sweetalert'
import {SwalOptions} from 'sweetalert/typings/modules/options'
import {getCSRFToken, getUsers} from '../../../core/requests'
import {UpdateIssue} from '../../../Activities/core/_requests'
import {UserItem} from './UserItem'
import Dropzone from 'react-dropzone'
import {toAbsoluteUrl} from '../../../../../_theme/helpers'
import {createProject, getDocumentCategories} from '../../core/requests'
import {useDispatch} from 'react-redux'
import {setToken} from '../../../components/csrf/reducers/CSRFSlice'

type Props = {
  handleClose: any
  setModalShow: any
  project: any
  update: boolean
  setNext: any
  projeInfo: any
  usersGroup: any
  budget: any
  refreshPage: any
}

export const Documents: FC<Props> = ({
  setModalShow,
  project,
  update,
  setNext,
  projeInfo,
  usersGroup,
  budget,
  refreshPage,
}) => {
  const filename = (param: any) => {
    const icon = param.split('.')
    const i = icon[icon?.length - 1]
    return i
  }
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      assignment: {
        id: 1,
        name: '',
      },
    },
  ])
  const [categories, setCategories] = useState<any>([])
  const [newFiles, setNewfiles] = useState<any>([])
  const [errors2, setErrors2] = useState('')
  const removeNotAddedFiles = (f: any) => () => {
    const tempFiles = [...newFiles]
    tempFiles.splice(tempFiles.indexOf(f), 1)
    setNewfiles(tempFiles)
  }
  const filess2 = newFiles.map(
    (
      file: {
        path: {} | null | undefined
        name: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => {
      //hidding banner
      {
        return (
          <li key={index} className='d-flex justify-content-between m-5'>
            <span className='w-75 ml-10 d-flex'>
              <img
                style={{width: '24px', marginRight: '10px'}}
                src={toAbsoluteUrl(`/media/svg/files/${filename(file.name)}.svg`)}
                alt=''
              />{' '}
              {file.name} <br /> {file.size} bytes{' '}
            </span>{' '}
            <span className='btn btn-sm mr-10' onClick={removeNotAddedFiles(file)}>
              Sil
            </span>
          </li>
        )
      }
    }
  )
  const [refresh, setRefresh] = useState(false)
  const refreshPage1 = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const [tags, setTags] = useState<any>([])
  const [query, setQuery] = useState('')
  const search = (data: any[]) => {
    return data.filter(
      (item) => item.name.toLowerCase().includes(query) || item.email.toLowerCase().includes(query)
    )
  }
  const onAddUser = (user: {
    id: number
    name: string
    email: string
    avatar: string
    assignment: {id: number; name: string}
  }) => {
    if (tags.filter((tag: any) => tag.id === user.id)?.length == 0) {
      setTags([...tags, user])
      setQuery('')
    }
  }
  const onDelete = (id: any) => {
    setTags(tags.filter((tag: any) => tag.id !== id))
  }

  useEffect(() => {
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
    getDocumentCategories().then(({data}) => {
      setCategories(data.data)
    })
  }, [update])

  const [userForEdit] = useState<any>({
    ...project,
    id: project.id,
  })

  const cancel = () => {
    setModalShow(false)
  }
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          await UpdateIssue(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err: {response: {data: {message: string | Partial<SwalOptions>}}}) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          const form_data = new FormData()
          for (var key in projeInfo) {
            form_data.append(key, projeInfo[key])
          }
          form_data.append('budget_id', budget?.budget_id)
          for (let i = 0; i < newFiles?.length; i++) {
            form_data.append('attachments[' + i + ']', newFiles[i])
            //console.log(base64);
          }
          for (let i = 0; i < usersGroup?.length; i++) {
            form_data.append('users[' + i + ']', usersGroup[i].id)
            //console.log(base64);
          }
          await createProject(form_data)
            .then(() => {
              swal('Eklendi', 'Proje başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
              setModalShow(false)
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // setSubmitting(true)
        // cancel()
      }
    },
  })

  return (
    <>
      <div style={{width: '70%', minWidth: '300px'}} className='m-auto'>
        <div className='mb-5'>
          <h3>Dosyaları yükle</h3>
          <span>
            Daha fazla bilgiye ihtiyacınız varsa, lütfen <a>Dosya Yönergelerini kontrol edin</a>
          </span>
        </div>
        <form
          id='kt_modal_add_user_form'
          onSubmit={formik.handleSubmit}
          className='form'
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Dosya Kategorisi</label>
              {/* end::Label */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Kategori Seç'
                {...formik.getFieldProps('announcement_category_id')}
                name='announcement_category_id'
              >
                <option value='' hidden>
                  {' '}
                  Seçiniz{' '}
                </option>
                {categories.map((category: any) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  )
                })}
              </select>
              {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
            <div className='fv-row mb-8'>
              <label className='fs-7 fw-bold mb-2'>Dosya Ekle</label>

              <div className='dropzone' id='kt_modal_create_ticket_attachments'>
                <div className='dz-message needsclick align-items-center'>
                  <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        opacity='0.3'
                        d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                        fill='currentColor'
                      />
                      <path
                        d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                        fill='currentColor'
                      />
                      <path
                        d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                        fill='currentColor'
                      />
                      <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                    </svg>
                  </span>
                  {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

                  <Dropzone
                    maxSize={10485760}
                    maxFiles={10}
                    onDrop={async (acceptedFiles, fileRejections) => {
                      setNewfiles(acceptedFiles)
                      // setFiles([...files, acceptedFiles.map()])
                      // acceptedFiles.map((file) => {
                      //   console.log(file)
                      //  setFiles([...files, file])
                      // })
                      await console.log(acceptedFiles)
                      fileRejections.forEach((file) => {
                        file.errors.forEach((err) => {
                          if (err.code === 'file-too-large') {
                            setErrors2(`Hata: ${err.message}`)
                          }

                          if (err.code === 'file-invalid-type') {
                            setErrors2(`Hata: ${err.message}`)
                          } else {
                            setErrors2(`Hata: ${err.message}`)
                          }
                        })
                      })
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                              5 dosyaya kadar yükleyin
                            </h3>
                          </div>
                          <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>
                            {errors2}
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>

            <div>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>
                <tbody className='fs-6'>
                  {query != ''
                    ? search(users).map((user) => {
                        return (
                          <>
                            <UserItem
                              key={user.id}
                              user={user}
                              refreshPage={refreshPage1}
                              type={false}
                              setUser={onAddUser}
                              onDelete={onDelete}
                            />
                          </>
                        )
                      })
                    : ''}
                </tbody>
              </table>
            </div>
            {newFiles?.length > 0 ? (
              <div className='card card-flush  border-top-0 border-bottom-0'>
                <div className='card-body pt-3'>
                  <aside>
                    <ul className='m-0 p-0'>{filess2}</ul>
                  </aside>
                </div>
              </div>
            ) : (
              ''
            )}

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
                onClick={() => setNext(3)}
                className='btn btn-light me-3'
                disabled={formik.isSubmitting}
              >
                Geri
              </button>

              <button type='submit' className='btn' style={{color: 'white'}}>
                <span className='indicator-label'>Tamamla</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Lütfen Bekleyin...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </form>
      </div>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
function setRefresh(arg0: boolean) {
  throw new Error('Function not implemented.')
}
