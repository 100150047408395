import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { getBlog } from '../core/_requests'
import { Recent } from './Recent'

export const RecentBlog: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [blog, setBlog] = useState<any>([])
  const [blogHeader, setBlogHeader] = useState({
    el: {},
    
  })
  useEffect(() => {
    getBlog('','?page[size]=4').then(({data}) => {
      setBlog(data)
    })
  }, [])
  return (
    <div className='card card-flush bg-transparent mb-9 card-p-0 border-0 shadow-0'>
    <div className='card-header'>
      <h4 className='card-title fw-bolder p-2'>Blogda en son</h4>
    </div>

    <div className='card-body p-2'>

      {blog.map((e: any, index: React.Key | null | undefined) => {
          
                      return (
                        <Recent blog={e}  path={e.files}/>
                      )
               
              })}
    </div>
  </div>
  )
}
