import { FC } from "react"
import { useListView } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { RoleListSearchComponent } from "./RoleListSearchComponent"
import { RoleListToolbar } from "./RoleListToolbar"



type Props ={
  refreshPage :any
  setWord: any
}

const RoleListHeader : FC <Props> = ({refreshPage, setWord}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0  d-flex justify-content-end'>
      {/* <RoleListSearchComponent setWord={setWord}/> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
       <RoleListToolbar refreshPage={refreshPage}/>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {RoleListHeader}
