import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {Updated} from '../modal/Updated'
import {UpdatedAnnoun} from '../modal/UpdatedAnnoun'
import {UpdatedProject} from '../modal/UpdatedProject'

type Props = {
  time: string
  title: string
  create_by: string
  item: any
}

export const HeaderAlertItem: FC<Props> = ({time, title, create_by, item}) => {
  const [modalShow, setModalShow] = useState(false)
  const [modalShowProject, setModalShowProject] = useState(false)
  const handleShow = (type: string) => {
    if (type == 'Project') setModalShowProject(true)
    else if (type == 'Issue' || type == 'Blog') setModalShow(true)
  }
  const handleClose = () => {
    setModalShow(false)
    setModalShowProject(false)
  }

  const openModal = (type: string) => {
    switch (type) {
      case 'Issue':
        handleShow(type)
        break
      case 'Project':
        handleShow(type)
        break
      case 'Blog':
        handleShow(type)
        break

      default:
        handleShow(type)
        break
    }
  }

  return (
    <>
      <div className='d-flex  align-items-center pb-1' onClick={() => openModal(item?.subject_type)}>
        <span className='badge badge-danger p-3' style={{borderRadius: '15px', backgroundColor: '#D6230E'}}>
          {time}
        </span>
        <span className='text-gray-800 m-3'>
          {title} {create_by && `(${create_by})`}
        </span>
      </div>
      <Modal show={modalShow} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Güncellemeler'} />
          <Updated item={item} />
        </Modal.Body>
      </Modal>

      <Modal show={modalShowProject} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Güncellemeler'} />
          <UpdatedProject item={item} />
        </Modal.Body>
      </Modal>
    </>
  )
}
