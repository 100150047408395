import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getBlogCategories } from '../core/_requests'
import { Category } from './category'

export const Categories: FC = () => {
  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])
 
  useEffect(() => {
    getBlogCategories().then(({data}) => {
      setCat(data.data)
    })
  }, [])

  return (
    <div className='card card-flush bg-transparent card-p-0 border-0 shadow-0 mb-9 p-3'>
    <div className='card-header'>
      <h4 className='card-title fw-bolder text-dark'>Kategoriler</h4>
    </div>

    <div className='card-body pt-2'>

    {categories.map((cat,index)=>{
          return <Category key={index} id={cat.id} name={cat.name}/>
        })}
    </div>
  </div>
  )
}



