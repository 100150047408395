import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_theme/assets/ts/_utils'
import {updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {styled} from '@material-ui/core'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  plusOrMinus: boolean
  widgetId: any
  refreshPage?: any
  linkToPage: string
  pageId?: string
}

export const BudgetByCategory: FC<Props> = ({plusOrMinus, widgetId, linkToPage, pageId}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {refreshPage} = useRefresh1()
  const [totalBudgetByCategory, setTotalBudgetByCategory] = useState<any>(JSON.parse(localStorage.getItem('total_budget_by_category') || '[]'))
  useEffect(() => {
    getTotalBudgetByCategory()
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(totalBudgetByCategory))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [])
  const getTotalBudgetByCategory = () => {
    setTotalBudgetByCategory(JSON.parse(localStorage.getItem('total_budget_by_category') || '{}'))
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  return (
    <div className={`card card-flush `} style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <Link to={plusOrMinus ? '' : linkToPage}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-dark'>Bütçe Kullanım</span>
            <span className='text-gray-400 pt-2 fw-bold fs-6'>Bütçe Kategorilerine göre Kullanım</span>
          </h3>
        </Link>
        <span
          className='btn btn-icon btn-light btn-active-color-primary mt-n2   d-inline-flex '
          style={{
            backgroundColor: '#f6f7f8',
            border: '1px solid rgb(1 13 88)',
            color: 'rgb(1 13 88)',
          }}
          onClick={async () => {
            homeWidgetShow()
          }}
        >
          {plusOrMinus ? '+' : '-'}
        </span>
        {/* end::Title */}
        {/* begin::Toolbar */}
        {/* <div className='card-toolbar'>
          {/* begin::Menu */}
        {/* <button
            className='btn btn-icon btn-color-gray-400 btn-active-color-primary mt-n2 me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-overflow='true'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen023.svg'
              className='svg-icon-1 svg-icon-gray-300 me-n1'
            />
          </button>
          <Dropdown2 />
          {/* end::Menu */}

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <Link to={plusOrMinus ? '' : linkToPage}>
        <div className='card-body pt-5 ps-6'>
          <div ref={chartRef} className='min-h-auto'></div>
        </div>
      </Link>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (totalBudgetByCategory: any[]): ApexOptions => {
  let categories: any[] = []
  let structuredData: any[] = []
  totalBudgetByCategory?.map((budget: any) => {
    categories.push(budget.category_name)
    structuredData.push(budget.category_rate)
  })
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')

  return {
    series: [
      {
        data: structuredData,
        name: '%',
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        barHeight: '23',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    colors: ['#3E97FF', '#F1416C', '#50CD89', '#FFC700', '#7239EA', '#50CDCD', '#3F4254'],
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (val) {
          return ''
        },
        style: {
          colors: getCSSVariableValue('--bs-gray-400'),
          fontSize: '14px',
          fontWeight: '600',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: getCSSVariableValue('--bs-gray-800'),
          fontSize: '14px',
          fontWeight: '600',
        },
        offsetY: 2,
        align: 'left',
      },
    },
    grid: {
      borderColor: 'rgb(1 13 88)',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      strokeDashArray: 4,
    },
  }
}
