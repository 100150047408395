import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {ModalHeader} from '../../../components/ModalHeader'
import {useRefresh1} from '../../Context/RefreshAssignment'
import {AddAssignment} from '../../edit-modal/AddAssignment'
import {SetRoles} from '../../edit-modal/SetRoles'

const AssignSetRole: FC = () => {
  const [show, setShow] = useState(false)
  const {refreshPage} = useRefresh1()
  const openSetRoleModal = () => {
    setShow(true)
  }
  const closeSetRoleModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end me-2' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn' onClick={openSetRoleModal}>
          {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' /> */}
          Rol Tanımla
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeSetRoleModal} titleHeader={'Rol Tanımla'} />
          <SetRoles handleClose={closeSetRoleModal} refreshPage={refreshPage} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AssignSetRole}
