import {CaseItem} from './../components/CaseItem'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from './../../request'

export const CASES = `${API_URL}/cases`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function GetCases(parametres: any) {
  return await axios.get<any>(CASES + parametres)
}

export async function GetCase(id: any) {
  return await axios.get<any>(CASES + '/' + id)
}

export async function CreateCase(caseItem: any) {
  return await axios.post(CASES, caseItem, config)
}

export async function UpdateCase(id: any, CaseItem: any) {
  return await axios.put(CASES + '/' + id, CaseItem)
}
export async function DeleteCase(id: any) {
  return await axios.delete(CASES + '/' + id)
}

export async function CreateComment(caseId: any, comment: string) {
  return await axios.post(CASES + '/' + caseId + '/comments', comment)
}

export async function GetCasesByDirectorate() {
  return await axios.get(API_URL + '/cases_graph/assignment?status=1')
}
