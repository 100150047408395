import {table} from 'console'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import ReactTags from 'react-tag-autocomplete'
import {getUsers} from '../core/requests'



type Props = {
  setRoles: any
  delRole: any
  tags1?: any
}

export const UserTags: FC<Props> = ({setRoles, delRole, tags1}) => {
  const [tags, setTags] = useState<any>([])

  const [suggestions, setSuggestions] = useState([{id: 1, name: 'Apples'}])

  const reactTags = useRef()

  const onDelete = useCallback(
    (tagIndex) => {
      delRole(tagIndex)
      setTags(tags.filter((_: any, i: any) => i !== tagIndex))
    },
    [tags]
  )

  const onAddition = useCallback(
    (newTag) => {
      if (!tags.find((i: any) => i?.id === newTag?.id)) {
        setTags([...tags, newTag])
        setRoles(newTag)
      }
    },
    [tags]
  )
  useEffect(() => {
    getUsers().then(({data}) => {
      setSuggestions(data.data)
    })
    {tags1 !=null &&  setTags(tags1)}
   
  }, [])

  return (
    <ReactTags
      placeholderText={'Kullanıcı bazlı arama yap...'}
      autoresize={true}
      tags={tags}
      suggestions={suggestions}
      onDelete={onDelete}
      onAddition={onAddition}
    />
  )
}
