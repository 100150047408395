import {FC, useEffect, useState} from 'react'
import {Button, Popover} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import swal from 'sweetalert'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {DeletePurchaseAttachment, GetPurchaseAttachments} from '../core/_requests'
import {data} from 'jquery'
import {Divider} from '@material-ui/core'

type Props = {
  purchase_id: any
}

export const PurchaseFiles: FC<Props> = ({purchase_id}) => {
  const [files, setFiles] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    GetPurchaseAttachments(purchase_id).then(({data}) => {
      setFiles(data.data)
    })
  }, [refresh])
  const filename = (param: any) => {
    const icon = param.split('.')[1]
    return icon
  }
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeletePurchaseAttachment(purchase_id, id).then(() => {
          swal('Silindi!', 'Dosya başarı ile silindi.', 'success')
          refresh ? setRefresh(false) : setRefresh(true)
        })
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div className='card card-flush  border-top-0 '>
      <div className='card-body pt-3'>
        <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
          <>
            <thead>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                <th className=''>Adı</th>
                <th className=''>Boyutu</th>
                <th className=''>Oluşturan</th>
              </tr>
            </thead>

            <div className='w-100  d-flex justify-content-end m-5'>
              {files?.length < 1 && (
                <div>
                  <span className='fs-4'> Sözleşme bulunmamaktadır</span>
                </div>
              )}
            </div>

            <tbody className='fw-bold text-gray-600 '>
              {files.map((file: any) => {
                return (
                  <tr>
                    <td data-order='account'>
                      <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                          <img
                            style={{width: '24px'}}
                            src={toAbsoluteUrl(`/media/svg/files/${filename(file.name)}.svg`)}
                            alt=''
                          />
                        </span>

                        <a href={file.path} className='text-gray-800 text-hover-primary'>
                          {file.name}
                        </a>
                      </div>
                    </td>

                    <td>{file.size} KB</td>

                    <td className='min-w-150px'>{file.created_by.name}</td>

                    {user.id == file.created_by.id ||
                    user?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 ? (
                      <td className='text-end'>
                        {' '}
                        <Button
                          variant='primary'
                          onClick={() => {
                            swalDelete(file.id)
                          }}
                        >
                          Sil
                        </Button>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                )
              })}
            </tbody>
          </>
        </table>
      </div>
    </div>
  )
}
