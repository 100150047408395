import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    item: any
}
export const AttackResultItem : FC<Props>=({item})=>{
    return(
       <>
             <td className='fw-bolder'>
            {item?.source_port}
        </td>
        <td className='text-gray-500'>{item?.destination_port}</td>
        <td className='text-gray-500'>{item?.ref_id}</td>
        <td className='text-gray-500'>{item?.string}</td>
        <td className='text-gray-500'>{item?.begin_time}</td>
        <td className='text-gray-500'>{item?.end_time} </td>
        <td className='text-gray-500'>{item?.threat_id} </td>
        <td className='text-gray-500'>{item?.variant}</td>
        <td className='text-gray-500'>{item?.trusted}</td>
        <td className='text-gray-500'>{item?.untrusted}</td>
        <td className='text-gray-500'>{item?.scenario_details}</td>
        <td className='text-gray-500'>{item?.threat_name}</td>
        <td className='text-gray-500'>{item?.l1_category_name}</td>
        <td className='text-gray-500'>{item?.cron_server_ip}</td>
        <td className='text-gray-500'>{item?.created_at}</td>
        <td className='text-gray-500'>{item?.updated_at}</td>
         <tr>


        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}