import React, {FC, useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import PaginationDown from '../components/PaginationDown'
import {Project} from './components/Project'
import {getFilteredProjects} from './core/requests'

type Props = {
  modalShow: any
  setCount: any
  directorateID: any
  searchKeyClick: string
  selectedType: string
  selectedStatus: string
  selectedPlan: string
}

export const ProjectCards: FC<Props> = ({
  modalShow,
  setCount,
  directorateID,
  searchKeyClick,
  selectedPlan,
  selectedStatus,
  selectedType,
}) => {
  const [projects, setProjects] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const refreshCount = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const LoadMore = () => {
    setLoading(true)
    const nextPage = currentPage + 1
    currentTableData(nextPage)
  }

  const currentTableData = async (page: any) => {
    if (page > 0) {
      let queryParams = '?q=' + searchKeyClick + '&page[number]=' + page + '&page[size]=9'
      if (directorateID != '') {
        queryParams += '&directorate_id=' + directorateID
      }
      if (selectedStatus != '') {
        queryParams += '&status=' + selectedStatus
      }
      if (selectedType != '') {
        queryParams += '&project_type_id=' + selectedType
      }
      if (selectedPlan != '') {
        queryParams += '&project_plan_id=' + selectedPlan
      }
      getFilteredProjects(queryParams)
        .then(({data}) => {
          setTotal(data?.meta?.total)
          setProjects((prev: any) => [...prev, ...data?.data])
          setLoading(false)
          setCount(data?.meta?.total)
          setCurrentPage(page)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    const resetAndFetchData = async () => {
      setProjects([])
      setLoading(true)
      await currentTableData(1)
    }

    resetAndFetchData()
  }, [
    refresh,
    modalShow,
    directorateID,
    searchKeyClick,
    selectedPlan,
    selectedStatus,
    selectedType,
  ])

  return (
    <div className='row w-100' style={{marginTop: '0px'}}>
      {projects.map(
        (
          project: any
          // { id:number, name: string; description: string; start_date: string; end_date: string; created_by: IconUserModel[] | undefined; }
        ) => {
          return (
            <Project
              key={project.id}
              id={project.id}
              icon='/media/svg/brand-logos/plurk.svg'
              type={project.type}
              plan={project.plan}
              status={project.status}
              title={project.name}
              description={project.description}
              date={project.start_date}
              budget={project.end_date}
              progress={50}
              users={project.created_by}
              refreshPage={refreshCount}
              refreshCount={refreshCount}
            />
          )
        }
      )}
      {total > projects?.length && (
        <Row className='mt-4'>
          <Col>
            <PaginationDown isLoading={loading} onClickHandle={LoadMore} />
          </Col>
        </Row>
      )}
    </div>
  )
}
