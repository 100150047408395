import React, {FC, useEffect, useState} from 'react'
import Pagination from '../../components/Pagination'
import {ProjectTableBody} from './ProjectTableBody'

type Props = {
  refreshPage: any
  projects: any
  type: string
  total?: any
  currentPage?: any
  limit?: any
  loading: boolean
  setCurrentPage?: any
}

export const ProjectsTableHeader: FC<Props> = ({
  type,
  refreshPage,
  projects,
  total,
  currentPage,
  limit,
  loading,
  setCurrentPage,
}) => {
  const [refresh, setRefresh] = useState(false)
  const refreshCount = () => {
    refreshPage()
    refresh ? setRefresh(false) : setRefresh(true)
  }

  return (
    <div className='row w-100'>
      <div className='card card-flush w-100 p-0' style={{width: '100vw'}}>
        <div className='card-body pt-3 w-100 mh-900  overflow-auto'>
          {loading ? (
            <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>
              <thead
                className='fs-7 text-gray-400 text-uppercase'
                style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}
              >
                <tr style={{color: '#50c8da'}}>
                  <th className=''>Proje İSMİ</th>
                  <th className=''>GRUP MÜDÜRLÜĞÜ</th>
                  <th className=''>MÜDÜRLÜK</th>
                  <th className=''>PROJE SINIFI</th>
                  <th className=''>PROJE TÜRÜ</th>
                  <th className=''>PROJE KATEGORİSİ</th>
                  <th className=''>HEDEF BAŞLANGIÇ TARİHİ</th>
                  <th className=''>HEDEF BİTİŞ TARİHİ</th>
                  {type === 'tamamlanan' ? <th className=''>G.BAŞLANGIÇ TARİHİ</th> : ''}
                  {type === 'tamamlanan' ? <th className=''>G.BİTİŞ TARİHİ</th> : ''}
                  {type === 'aday' ? <th className=''>BÜTÇE TÜRÜ (CAPEX/OPEX)</th> : ''}
                  <th className=''>PROJE AMACI</th>
                  {type === 'tamamlanan' ? <th className=''>PROJE YÖNETİCİSİ</th> : ''}
                  {type === 'tamamlanan' ? <th className=''>PROJE TEKNİK YÖNETİCİSİ</th> : ''}
                  <th></th>

                  {/* <th className=''>FİRMA</th> */}
                </tr>
              </thead>

              <tbody className='fs-6'>
                {projects?.map((project: any) => {
                  return (
                    <>
                      <ProjectTableBody
                        key={project.id}
                        project={project}
                        refreshPage={refreshPage}
                        refreshCount={refreshCount}
                        id={project?.id}
                        type={type}
                      />
                    </>
                  )
                })}
              </tbody>
            </table>
          )}
          <Pagination
            onPageChange={(page: number) => setCurrentPage(page)}
            totalCount={total}
            currentPage={currentPage}
            pageSize={limit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}
