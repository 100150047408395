import moment from "moment"
import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    item: any
}
export const NODItem : FC<Props>=({item})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {item?.report_type}
        </td>
        <td className='text-gray-500'>{item?.task_id}</td>
        <td className='text-gray-500'>{item?.parent_change_id}</td>
        <td className='text-gray-500'>{item?.record_type} </td>
        <td className='text-gray-500'>{item?.access_method}</td>
        <td className='text-gray-500'>{item?.authorization_detail}</td>
        <td className='text-gray-500'>{item?.approval_status} </td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='text-gray-500'>{moment(item?.task_open_date).format('DD.MM.YYYY H:ss')} </td>
        <td className='text-gray-500'>{item?.assign_full_name}</td>
        <td className='text-gray-500'>{item?.assignment_group}</td>
        <td className='text-gray-500'>{item?.task_duration} </td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{item?.created_by?.name} </td>       
      </tr>
       </>
    )
}