import React, {FC, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'

type Props = {
  isLoading: any
  onClickHandle: any
}
const PaginationDown: FC<Props> = ({isLoading, onClickHandle}) => {
  return (
    <div className='  d-flex flex-column w-100  align-items-center justify-content-center'>
      <Button
        onClick={onClickHandle}
        disabled={isLoading}
        className={` border border-secondary blur bg-primary cursor-pointer  text-light rounded-pill  d-flex  py-3 px-4 align-items-center cursor-pointer justify-content-center `}
      >
        {isLoading ? (
          <Spinner animation='border' size='sm' />
        ) : (
          <>
            <i className={`text-light  fi-align-justify fs-sm mx-2`}></i>
            <h3 className={'text-light fs-sm ps-1 pe-1 mb-0 '}>Daha fazlasını göster</h3>
          </>
        )}
      </Button>
    </div>
  )
}

export default PaginationDown
