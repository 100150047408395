import {FC, useEffect, useState} from 'react'
import {deleteTask, GetTask} from '../../../core/requests'
import {TargetItem} from './TargetItem'
import swal from 'sweetalert'

type Props = {
  target_id: any
  taskss: any
  project_id: any
  milestones: any
  refreshPage: any
  number: any
}
export const SubTargetItem: FC<Props> = ({
  target_id,
  refreshPage,
  number,
  project_id,
  milestones,
  taskss,
}) => {
  const [tasks, setTasks] = useState<any>(taskss)

  useEffect(() => {
    // GetTask(target_id).then(({data}) => {
    //   setTasks(data.data)
    // })
  }, [])
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Hedef silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        deleteTask(id)
          .then(() => {
            swal('Silindi!', 'Hedef başarı ile silindi.', 'success', {timer: 2000})
            refreshPage()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  return (
    <>
      {tasks?.map((target: {id: React.Key | null | undefined}, index: number) => {
        return (
          <TargetItem
            key={target.id}
            target={target}
            project_id={project_id}
            milestone_id={milestones}
            deleteTarget={swalDelete}
            refreshPage={refreshPage}
            number={number + '.' + (index + 1)}
            task={true}
            type={true}
          />
        )
      })}
    </>
  )
}
