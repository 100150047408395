import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import swal from 'sweetalert'
import {addCA} from '../../core/request'

type Props = {
  handleClose: any
}

export const AddCAttact: FC<Props> = ({handleClose}) => {
  const [files, setFiles] = useState<any>([])

  const [userForEdit] = useState<any>({})

  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }

  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )
  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        for (let i = 0; i < files?.length; i++) {
          formData.append('file', files[i])
          //console.log(base64);
        }
        await addCA(formData)
          .then(() => {
            swal('Eklendi', 'Dosya başarı ile eklendi.', 'success', {timer: 2000})
          })
          .catch((err) => {
            swal('Hata', err.response.data.message, 'error', {timer: 2000})
          })
        handleClose()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        handleClose()
      }
    },
  })
  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='modal-body'>
          <div className='form-group'>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7 '
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='dropzone' id='kt_modal_create_ticket_attachments'>
                <div className='dz-message needsclick align-items-center'>
                  <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        opacity='0.3'
                        d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                        fill='currentColor'
                      />
                      <path
                        d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                        fill='currentColor'
                      />
                      <path
                        d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                        fill='currentColor'
                      />
                      <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                    </svg>
                  </span>
                  {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

                  <Dropzone
                    maxSize={10485760}
                    maxFiles={10}
                    onDrop={async (acceptedFiles, fileRejections) => {
                      setFiles(acceptedFiles)
                      // setFiles([...files, acceptedFiles.map()])
                      // acceptedFiles.map((file) => {
                      //   console.log(file)
                      //  setFiles([...files, file])
                      // })
                      await console.log(acceptedFiles)
                      // fileRejections.forEach((file) => {
                      //   file.errors.forEach((err) => {
                      //     if (err.code === 'file-too-large') {
                      //       setErrors2(`Hata: ${err.message}`)
                      //     }

                      //     if (err.code === 'file-invalid-type') {
                      //       setErrors2(`Hata: ${err.message}`)
                      //     } else {
                      //       setErrors2(`Hata: ${err.message}`)
                      //     }
                      //   })
                      // })
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                              Dosyaları buraya bırakın veya yüklemek için tıklayın
                            </h3>
                            <span className='fw-bold fs-7 text-gray-400'>
                              5 dosyaya kadar yükleyin
                            </span>
                          </div>
                          <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}></p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
              <aside>
                <ul className='m-0 p-0'>{filess}</ul>
              </aside>
              <button
                type='submit'
                className='btn btn-primary mt-5'
                data-kt-users-modal-action='submit'
                //disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched||files?.length<1}
              >
                <span className='indicator-label'>Gönder</span>
                {/* {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
