import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_theme/assets/ts/_utils'
import {GetALStatistics, GetCAStatistics, GetReportStatistics, GetReportsWidget} from '../../../OperationalData/core/request'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {GetPocWidget} from '../../../Project/Poc/core/_request'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'
import {select} from 'redux-saga/effects'

type Props = {
  horizentalTitle: string
  pageId?: string
  widgetId: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
}

export const BarChart: FC<Props> = ({horizentalTitle, pageId, widgetId, plusOrMinus, linkToPage}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {refresh, refreshPage} = useRefresh1()
  const [selectedYear, setSelectedYear] = useState('')
  const [years, setYears] = useState<any>([])
  const [title, setTitle] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [grafik, setGrafik] = useState(false)
  const [status, setStatus] = useState('')
  const [period, setPeriod] = useState('weekly')
  const [isOpen, setIsOpen] = useState('')
  const [viewId, setViewId] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [auditMachine, setAuditMachine] = useState('')
  const [serverIp, setServerIp] = useState('10.6.236.121')
  const [serverIp2, setServerIp2] = useState('10.6.236.121')
  const [serverIps, setServerIps] = useState<any>([])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
              refreshPage()
              // window.location.reload()
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
              // window.location.reload()
            }
          }
        })
  }
  useEffect(() => {
    setGrafik(false)
    setData([])
    setTitle([])
    GetPocWidget('/vulnerability_exploitation_server_ips').then((data) => {
      setServerIps(data?.data)
    })
    if (widgetId === '25') {
      GetReportsWidget('/income_assurance_data_year_list').then((data) => {
        setYears(data?.data?.data)
      })
    }
    if (widgetId == '29') {
      GetReportsWidget('/data_scope_agents_total/os_name').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '30') {
      GetALStatistics('1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '32') {
      GetALStatistics('2').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '34') {
      GetReportStatistics('/number_of_alarm_cases/', '2').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '36') {
      GetReportStatistics('/mirroring/', '1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '39') {
      GetReportsWidget('/web_traffics_total/category').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '44') {
      GetReportsWidget('/endpoint_protection_data_trendmicro_total_computers/server').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '45') {
      GetCAStatistics('1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '46') {
      GetCAStatistics('2').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '47') {
      GetReportsWidget('/malicious_statistics_total').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '58') {
      GetReportsWidget('/income_assurance_request_total/technology').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '59') {
      GetReportsWidget('/income_assurance_request_total/owner').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId === '61') {
      GetReportStatistics('/project_demand_tracking/', '1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '80') {
      GetReportsWidget('/server_change_data/1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '88') {
      GetReportsWidget('/optimate_solution_uygulama_data_total/1').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '89') {
      GetReportsWidget('/optimate_solution_sistem_data_total/1').then((data) => {
        toArray(data?.data)
      })
    }
    if (widgetId === '91') {
      GetReportsWidget('/optimate_solution_acik_kapali_bulgu_sayilari_yil_listesi').then((data) => {
        setYears(data?.data?.data)
      })
    } else if (widgetId == '95') {
      GetPocWidget('/poc_graph/poc_type').then((data) => {
        toArray(data?.data)
      })
    }
  }, [refresh])
  useEffect(() => {
    if (grafik) {
      const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title, data, '', viewId))
      if (chart) {
        chart.render()
      }
      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }
  }, [grafik, refresh])
  useEffect(() => {
    if (widgetId == '25') {
      setGrafik(false)
      GetReportsWidget('/income_assurance_data_total/company?year=' + selectedYear).then((data) => {
        setData([])
        toArray(data?.data)
      })
    }
  }, [selectedYear])
  useEffect(() => {
    if (widgetId === '63') {
      setGrafik(false)
      GetReportsWidget('/vulnerability_exploitation_total/trusted_untrusted?server_ip=' + serverIp).then((data) => {
        setData([])
        toArray(data?.data)
      })
    }
  }, [serverIp])
  useEffect(() => {
    if (widgetId === '62') {
      setGrafik(false)
      GetReportsWidget('/vulnerability_exploitation_total/category?server_ip=' + serverIp2).then((data) => {
        setData([])
        toArray(data.data)
      })
    }
  }, [serverIp2])
  useEffect(() => {
    if (widgetId == '85') {
      setGrafik(false)
      GetReportsWidget('/optimate_solution_directorate_asset_inventory_status_total/directorate' + '?ref_status=' + status).then(async (data) => {
        setTitle([])
        setData([])
        await toArray(data?.data)
      })
    }
  }, [status])
  useEffect(() => {
    if (widgetId == '78') {
      setGrafik(false)
      GetReportsWidget('/endpoint_protection_data_symantec_total/' + period).then(async (data) => {
        setTitle([])
        setData([])
        await toArray(data?.data)
      })
    }
  }, [period])
  useEffect(() => {
    if (widgetId == '93') {
      setGrafik(false)
      GetReportsWidget('/optimate_solution_acik_kapali_bulgu_sayilari_total/department?is_open=' + isOpen).then((data) => {
        setTitle([])
        setData([])
        toArray(data?.data)
      })
    } else if (widgetId == '94') {
      setGrafik(false)
      GetReportsWidget('/optimate_solution_acik_kapali_bulgu_sayilari_total/group_department?is_open=' + isOpen).then((data) => {
        setTitle([])
        setData([])
        toArray(data?.data)
      })
    }
  }, [isOpen])
  useEffect(() => {
    if (widgetId == '91') {
      setGrafik(false)
      GetReportsWidget('/optimate_solution_acik_kapali_bulgu_sayilari_total/directorate?is_open=' + isOpen + '&year=' + selectedYear).then((data) => {
        setTitle([])
        setData([])
        toArray(data?.data)
      })
    }
  }, [isOpen, selectedYear])
  useEffect(() => {
    if (widgetId == '92') {
      setGrafik(false)
      // let queryParams = ''
      // if (viewId == '1314') {
      //   queryParams += '?view_filter_id=' + [1314, 1315, 1316]
      // }
      // if (viewId == '1310') {
      //   queryParams += '?view_filter_id=1310'
      // }
      // if (viewId == '1311') {
      //   queryParams += '?view_filter_id=1311'
      // }
      // if (viewId == '1312') {
      //   queryParams += '?view_filter_id=1312'
      // }
      // if (viewId == '1313') {
      //   queryParams += '?view_filter_id=1313'
      // }
      if (viewId == '1312') {
        GetReportsWidget('/optimate_solution_varlik_envanteri_total/unit_name').then((data) => {
          setTitle([])
          setData([])
          toArray(data?.data)
        })
      } else {
        GetReportsWidget('/optimate_solution_varlik_envanteri_total/level').then((data) => {
          setTitle([])
          setData([])
          toArray(data?.data)
        })
      }
    }
  }, [viewId])

  useEffect(() => {
    if (widgetId == '74') {
      setGrafik(false)
      GetReportsWidget('/agent_situations_total/State?AuditMachineName=' + auditMachine).then((data) => {
        setData([])
        setTitle([])
        toArray(data?.data)
      })
    }
  }, [auditMachine])
  const toArray = (data: any) => {
    let titleTemp = []
    let dataTemp = []
    if (widgetId == '25') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.company)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '29') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.os_name == null) continue
        titleTemp.push(data[i]?.os_name)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '30') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.directorate_name.split(' '))
        dataTemp.push(data[i]?.total_alarm)
      }
    } else if (widgetId == '32') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push('W' + data[i]?.week_of_year)
        dataTemp.push(data[i]?.total_alarm)
      }
    } else if (widgetId == '34') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.type?.split(' '))
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '36') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push('W' + data[i]?.week_of_year)
        dataTemp.push(data[i]?.total_mirroring)
      }
    } else if (widgetId == '39') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.category)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '44') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.cron_server_ip)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '45') {
      for (let i = 0; i < data?.length; i++) {
        dataTemp.push(data[i]?.attack_size)
        titleTemp.push(moment(data[i].attack_date).format('DD.MM.YYYY') + '(' + data[i]?.customer_name + ')')
        // titleTemp.push(moment(data[i]?.start_day).format('DD.MM.YYYY') + '-' + moment(data[i]?.end_day).format('DD.MM.YYYY') + '(' + data[i]?.customer_name + ')')
      }
    } else if (widgetId == '46') {
      for (let i = 0; i < data?.length; i++) {
        dataTemp.push(data[i]?.total_attacks)
        // titleTemp.push(moment(data[i]?.start_day).format('DD.MM') + '-' + moment(data[i]?.end_day).format('DD.MM.YYYY') + '(' + data[i]?.customer_name + ')')
        if (data[i].customer_name != null) titleTemp.push('W' + data[i]?.week_of_year)
        else titleTemp.push('W' + data[i].week_of_year)
      }
    } else if (widgetId == '47') {
      titleTemp.push('risk')
      titleTemp.push('level')
      titleTemp.push('stype')
      titleTemp.push('suser')
      dataTemp.push(data[0]?.risk)
      dataTemp.push(data[0]?.level)
      dataTemp.push(data[0]?.stype)
      dataTemp.push(data[0]?.suser)
    } else if (widgetId == '58') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.technology == null) continue
        titleTemp.push(data[i].technology)
        dataTemp.push(data[i].total)
      }
    } else if (widgetId == '59') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.owner == null) continue
        titleTemp.push(data[i].owner)
        dataTemp.push(data[i].total)
      }
    } else if (widgetId === '61') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.security_source)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId === '62') {
      for (let i = 0; i < data[0][serverIp2]?.length; i++) {
        titleTemp.push(data[0][serverIp2][i]?.l1_category_name)
        dataTemp.push(data[0][serverIp2][i]?.total)
      }
    } else if (widgetId === '63') {
      titleTemp.push('Trusted')
      titleTemp.push('Untrusted')
      dataTemp.push(data[0][serverIp].trusted)
      dataTemp.push(data[0][serverIp].untrusted)
    } else if (widgetId == '74') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.State)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '76') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.attendees)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '77') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.title)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '78') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.protectionName)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '80') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push(data[i]?.name)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '85' || widgetId == '88' || widgetId == '89' || widgetId == '91') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.directorate == null) continue
        titleTemp.push(data[i]?.directorate)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '92') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.level == null) continue
        titleTemp.push(data[i]?.level)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '93') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.department == null) continue
        titleTemp.push(data[i]?.department)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '94') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.group_department == null) continue
        titleTemp.push(data[i]?.group_department)
        dataTemp.push(data[i]?.total)
      }
    } else if (widgetId == '95') {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.type == null) continue
        titleTemp.push(data[i]?.type)
        dataTemp.push(data[i]?.total)
      }
    }

    setTitle(titleTemp)
    setData(dataTemp)
    setGrafik(true)
  }

  return (
    <div className={`card card-flush overflow-auto `} style={{overflow: 'hidden', backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='d-flex justify-content-between p-5'>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='card-header text-center '>
            <h4 className='card-title  flex-column  w-100 m-1 p-1'>
              <span className='card-label fw-bolder text-dark fs-2'>
                {widgetId == '92'
                  ? viewId == '1310'
                    ? 'Varlıkların Müdürlük Bazında Seviye Dağılımı'
                    : viewId == '1311'
                    ? 'Varlıkların Grup Müdürlüğü Bazında Seviye Dağılımı'
                    : viewId == '1312'
                    ? 'Varlıkların Direktörlük Bazında Seviye Dağılımı'
                    : viewId == '1313'
                    ? 'Varlıkların Sıfır Varlık Bulunan Birimler Bazında Seviye Dağılımı'
                    : viewId == '1314'
                    ? 'Kritik Sistemler Bazında Seviye Dağılımı'
                    : horizentalTitle
                  : horizentalTitle}
              </span>
              {widgetId == '36' && <span className='fs-6'>Aynalama Talepleri</span>}
              {widgetId == '58' && <span className='fs-6'>Gelir Güvence Talep Durumu</span>}
              {widgetId == '59' && <span className='fs-6'>Gelir Güvence Talep Durumu</span>}
              {widgetId == '88' && <span className='fs-6'>Kritik Sistemler</span>}
              {widgetId == '89' && <span className='fs-6'>Kritik Sistemler</span>}
            </h4>
          </div>
        </Link>
        <div className='d-flex'>
          {widgetId == '25' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value='' selected>
                  Tümü
                </option>
                {years?.map((year: any) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {widgetId == '91' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value='' selected>
                  Tümü
                </option>
                {years?.map((year: any) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {widgetId == '62' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setServerIp2(e.target.value)}
              >
                {serverIps?.map((sIp: any) => {
                  return (
                    <option key={sIp?.server_ip} value={sIp?.server_ip}>
                      {sIp?.server_ip}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {widgetId == '63' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setServerIp(e.target.value)}
              >
                {serverIps?.map((sIp: any) => {
                  return (
                    <option key={sIp?.server_ip} value={sIp?.server_ip}>
                      {sIp?.server_ip}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {widgetId == '74' && (
            <div className='pb-5'>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                style={{backgroundColor: '#F4EEEE'}}
                className={`form-control form-control-solid me-2 w-300px h-75 `}
                placeholder={'Audit Machine Name'}
                onChange={(e) => {
                  setSearchKey(e?.target?.value)
                  e.target.value == '' && setAuditMachine('')
                }}
                // enterKeyHint={setSearchKeyClick(searchKey)}
                onKeyDown={(event) => {
                  if (event.key == 'Enter') {
                    setAuditMachine(searchKey)
                  }
                }}
              />
            </div>
          )}
          {widgetId == '85' && (
            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setStatus(e.target.value)}>
                <option value=''>Tümü</option>
                <option value='Tamamlandı'>Tamamlandı</option>
                <option value='İptal Edildi'>İptal Edildi</option>
                <option value='Gerçekleştiriliyor'>Gerçekleştiriliyor</option>
                <option value='Aksiyon Sorumlusu Tarafından Reddedildi'> Aksiyon Sorumlusu Tarafından Reddedildi</option>
                <option value='Oluşturuldu'>Oluşturuldu</option>
              </select>
            </div>
          )}
          {widgetId == '78' && (
            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setPeriod(e.target.value)}>
                <option value='weekly'>Haftalık</option>
                <option value='monthly'>Aylık</option>
                <option value='yearly'>Yıllık</option>
              </select>
            </div>
          )}
          {widgetId == '91' && (
            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setIsOpen(e.target.value)}>
                <option value=''>Tümü</option>
                <option value='Açık'>Açık</option>
                <option value='Kapalı'>Kapalı</option>
              </select>
            </div>
          )}
          {widgetId == '92' && (
            <div className=' me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px' onChange={(e) => setViewId(e.target.value)}>
                <option value=''>Tümü</option>
                {/* <option value={'1314'}>Kritik Sistemler</option>
                <option value='1310'>Varlıkların Müdürlük Bazında Sayıları</option>
                <option value='1311'>Varlıkların Grup Müdürlüğü Bazında Sayıları</option> */}
                <option value='1312'>Varlıkların Direktörlük Bazında Sayıları</option>
                {/* <option value='1313'>Varlıkların Sıfır Varlığı Bulunan Birimler</option> */}
              </select>
            </div>
          )}
          {widgetId == '93' && (
            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setIsOpen(e.target.value)}>
                <option value=''>Tümü</option>
                <option value='Açık'>Açık</option>
                <option value='Kapalı'>Kapalı</option>
              </select>
            </div>
          )}
          {widgetId == '94' && (
            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setIsOpen(e.target.value)}>
                <option value=''>Tümü</option>
                <option value='Açık'>Açık</option>
                <option value='Kapalı'>Kapalı</option>
              </select>
            </div>
          )}

          <div
            onClick={() => {
              homeWidgetShow()
            }}
          >
            <span
              className='btn btn-icon btn-small btn-active-color-primary mt-n2 me-n3  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex justify-content-between flex-column pb-1 px-0 '>
        <div ref={chartRef} className={`min-h-auto ps-4 pe-6 pie-chart1 ${data?.length > 8 && 'apexcharts-bar'} `} style={{height: 'auto'}}></div>
      </div>
    </div>
  )
}

const chartOptions = (widgetId: string, title: any, data: any, pointTitle: string, viewId?: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: pointTitle,
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: '180px',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    dataLabels: {
      enabled: false,
    },
    fill: {},
    stroke: {
      curve: 'straight',
      show: false,
      width: 3,
    },

    // legend: {
    //   show: false,
    //   showForSingleSeries: true,
    //   customLegendItems: ['Os Name sınıflandırılması'],
    //   markers: {
    //       fillColors: ['#00E396', '#775DD0']
    //   },
    // },
    xaxis: {
      categories: title,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      tickAmount: 20,

      labels: {
        show: widgetId == '39' ? false : true,
        rotate: widgetId == '85' || widgetId == '88' || widgetId == '91' || viewId == '1312' ? 90 : 0,
        trim: widgetId == '85' || widgetId == '88' || widgetId == '91' || viewId == '1312' ? false : true,
        rotateAlways: false,
        hideOverlappingLabels: true,
        maxHeight: 30,
        style: {
          fontSize: widgetId == '85' || widgetId == '88' || widgetId == '91' || viewId == '1312' ? '7px' : '12px',
          cssClass: 'apexcharts-bar-label',
        },
        offsetY: -5,
      },
      crosshairs: {
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 1,
        },
      },
      tooltip: {
        enabled: true,
        // offsetY: 100,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      //title:{text: assured},
      tickAmount: 5,
      max: Math.max.apply(Math, data),
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
        // formatter: function (val) {
        //   return '$' + Number(val / 10).toFixed(1) + 'K'
        // },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      x: {
        formatter: function (val) {
          return val.toString()
        },
      },
      y: {
        formatter: function (val) {
          if (
            widgetId == '46' ||
            widgetId == '85' ||
            widgetId == '58' ||
            widgetId == '59' ||
            widgetId == '62' ||
            widgetId == '63' ||
            widgetId == '88' ||
            widgetId == '91' ||
            widgetId == '93' ||
            widgetId == '94' ||
            widgetId == '95'
          )
            return val.toString() + ' adet'
          else if (widgetId == '45') return val.toString() + ' GBps'
          else return val.toString()
        },
      },
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
      size: 5,
    },
  }
}
