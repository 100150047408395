import {devToolsEnhancer} from '@reduxjs/toolkit/dist/devtoolsExtension'
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {ID} from '../../../_theme/helpers'
import {ModalHeader} from '../components/ModalHeader'
import {AddCategory} from './AddCategory'

type Props = {
  data: {
    id: ID
    name: string
  }
  refreshPage: any
  numberMenu: string
}
export const CategoryItem: FC<Props> = ({data, refreshPage, numberMenu}) => {
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = async () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
    refreshPage()
  }
  return (
    <>
      <tr>
        <td className='text-gray-500'>{data.name}</td>
        <td className='text-end'>
          {' '}
          <button className='btn border btn-sm' onClick={openCategoryModal}>
            Düzenle
          </button>
        </td>
      </tr>
      <Modal show={showCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: data.id,
              name: data.name,
            }}
            update={true}
            handleClose={closeCategoryModal}
            numberMenu={numberMenu}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
