import {FC, useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {ID} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {AddPhoto} from '../edit-modal/AddPhoto'
import {AddUser} from '../edit-modal/AddUser'
import {ChangePassword} from '../edit-modal/ChangePassword'
import swal from 'sweetalert'
import {DeleteUser} from '../core/_requests'

type Props = {
  user: {
    id: any
    name: string
    email: string
    avatar: string
    assignment: {
      id: ID
      name: string
    }
    roles: {
      id: ID
      name: string
    }[]
    mobile_number: string
    phone_number: string
    experience: number
    certificates: string
    competencies: string
    associate_degree: string
    bachelors_degree: string
    masters_degree: string
    alternative_email: string
  }
  refreshPage: any
}
export const UserItem: FC<Props> = ({user, refreshPage}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const [showPhoto, setShowphoto] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => setShow(false)
  const openPhotoModal = () => {
    setShowphoto(true)
  }
  const closePhotoModal = () => {
    setShowphoto(false)
    refreshPage()
  }
  const openPasswordModal = () => {
    setShowPasswordModal(true)
  }
  const closePasswordModal = () => {
    setShowPasswordModal(false)
    refreshPage()
  }
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteUser(id).then(() => {
          swal('Silindi!', 'Kullanıcı başarı ile silindi.', 'success', {timer: 2000})
          refreshPage()
        })
      }
    })
  }
  const findAt = (text: string) => {
    return text?.indexOf('@')
  }
  return (
    <>
      <tr>
        <td>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-65px symbol-circle w-65px'>
              {user?.avatar ? (
                <div className='symbol symbol-65px symbol-circle w-65px'>
                  <img src={user.avatar} alt={user.name} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{user?.name?.substring(0, 1)}</div>
              )}{' '}
            </div>
          </div>
        </td>
        <td className='fw-bolder'>
          {/* {loginUser?.roles?.filter((role: any) => role.name == 'Süper Admin' || role?.name == 'Admin')?.length > 0 ? ( */}
          <NavLink className='text-gray-800 text-hover-primary' to='../profile' state={user} onClick={() => localStorage.setItem('userId', user?.id)}>
            {user.name} <br />
            <span className='text-gray-500'>{user?.email?.substring(0, findAt(user?.email))}</span>
          </NavLink>
          {/* // ) : (
          //   <div className='text-gray-800 text-hover-primary'>
          //     {user?.name}
          //     <div className='text-gray-500'>{user?.email?.substring(0, findAt(user?.email))}</div>
          //   </div>
          // )} */}
        </td>

        <td className='text-gray-500'>
          {' '}
          <span className='text-gray-800'>{user?.assignment !== null && user?.assignment?.name}</span>
        </td>
        <td>
          {user?.roles?.map((role: any) => {
            return <span className={`badge badge-light-primary me-2`}>{role?.name}</span>
          })}
        </td>
        <td className='text-gray-500'>
          {user?.alternative_email} <br /> {user?.mobile_number}
        </td>

        {loginUser?.roles?.filter((role: any) => role.name == 'Süper Admin' || role?.name == 'Admin')?.length > 0 ? (
          <td className='text-end' data-kt-filemanager-table=''>
            <Dropdown>
              <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink to='../profile' state={user} className='menu-link px-3 text-black'>
                    Görüntüle
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item onClick={async () => await handleShow()}>
                  {' '}
                  <span className='menu-link px-3'>Düzenle</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={async () => await openPhotoModal()}>
                  {' '}
                  <span className='menu-link px-3'>Fotoğraf Düzenle</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={async () => await openPasswordModal()}>
                  {' '}
                  <span className='menu-link px-3'>Şifre Değiştir</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={async () => await swalDelete(user.id)}>
                  <span className='menu-link text-danger px-3'>Sil</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        ) : (
          ''
        )}
      </tr>
      <Modal show={show} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddUser
            user={{
              id: user.id,
              name: user.name,
              email: user.email,
              avatar: user.avatar,
              assignment: user.assignment,
              certificates: user.certificates,
              experience: user.experience,
              competencies: user.competencies,
              associate_degree: user.associate_degree,
              bachelors_degree: user.bachelors_degree,
              masters_degree: user.masters_degree,
              roles: user.roles,
              mobile_number: user.mobile_number,
              phone_number: user.phone_number,
              alternative_email: user.alternative_email,
            }}
            update={true}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showPhoto}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closePhotoModal} titleHeader={'Fotoğraf Düzenle'} />
          <AddPhoto user_id={user.id} handleClose={closePhotoModal} doc={user.avatar} />
        </Modal.Body>
      </Modal>

      <Modal show={showPasswordModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closePasswordModal} titleHeader={'Şifre Değiştir'} />
          <ChangePassword user={user} handleClose={closePasswordModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
