import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {BudgetInfo} from './BudgetInfo'
import {BudgetHeader} from '../components/header/BudgetHeader'
import {Slider} from './Slider'
import {GetBudgetAttachments, GetOrders, GetPurchases, GetPurchaseSteps} from '../core/_requests'
import {Purchase} from './Purchase'
import {Order} from './Order'
import {PurchaseStep} from './PurchaseStep'
import {ListFileItem} from './ListFileItem'

type LocationState = {
  id: 1
  title: ''
  amount: 30000000
  currency: 'TRY'
  year: 2022
  company_contract_status: false
  internal_signature_status: false
  general_admission_status: false
  invoice_status: false
  migo_status: false
  assignment: {
    id: 1
    name: ''
    order: 2
    parent_id: 4
    is_group_directorate: false
    is_directorat: false
    is_last: false
  }
  owner: {
    id: 5
    name: 'Doğan Emre Öztaş'
    email: 'user5@ecodation.com'
    avatar: 'https://sgd.ecodation.com/storage/713edffcdcf3673864243392ac1f81d6/doğan emre öztaş.jfif'
    roles: [
      {
        id: 10
        name: 'Müdür'
      }
    ]
  }
  type: {
    id: 1
    name: 'Capex'
  }
  category: {
    id: 2
    name: 'Lisans'
  }
  company: {
    id: 2
    name: 'TT'
  }
  purchase_type: {
    id: 3
    name: 'Çerçeve'
  }
  created_at: ''
  created_by: {
    id: 1
    name: ''
    email: ''
    avatar: ''
    roles: [
      {
        id: 11
        name: ''
      }
    ]
  }
}
export const BudgetDetail: FC = () => {
  const location = useLocation()
  let data = location.state as LocationState
  const [active, setActive] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [files, setFiles] = useState<any>([])
  const [purchases, setPurchases] = useState([
    {
      id: 1,
      budget_id: 1,
      budget_code: '',
      request_number: '',
      currency: '',
      amount: 1,
      try_amount: 1,
      status: 0,
      files: [],
      supplier: {
        id: 1,
        name: '',
        company_name: '',
      },
      purchase_by: {
        id: 1,
        name: '',
        roles: [
          {
            id: 1,
            name: '',
          },
        ],
      },
      created_at: '',
    },
  ])
  const [orders, setOrders] = useState([
    {
      id: 1,
      budget_id: 1,
      order_number: '',
      currency: 'USD',
      amount: 2500000,
      try_amount: 36856700,
      saving_currency: 'TRY',
      saving_amount: 500000,
    },
  ])
  const [agreements, setAgreements] = useState([])
  const [purchaseSteps, setPurchaseSteps] = useState([
    {
      id: 1,
      budget_id: 1,
      sgd_status: '',
      purchase_status: '',
      description: '',
    },
  ])

  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  useEffect(() => {
    GetPurchases(data.id).then(({data}) => {
      setPurchases(data.data)
    })
    GetOrders(data.id).then(({data}) => {
      setOrders(data.data)
    })
    GetPurchaseSteps(data.id).then(({data}) => {
      setPurchaseSteps(data.data)
    })
    GetBudgetAttachments(data.id).then(({data}) => {
      setFiles(data.data)
    })
  }, [refresh])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            <div id='kt_app_content_container' className='app-container container-fluid p-0'>
              <div className='d-flex flex-column flex-lg-row '>
                <BudgetInfo budget={data} />
                <div className='w-100 pt-0 p-10 pe-0'>
                  <Slider purchases={purchases} active={active} setActive={setActive} orders={orders} agreements={agreements} budget_id={data.id} refresh={refresh} setRefresh={setRefresh} purchase_steps={purchaseSteps} />
                  <div className='tab-content' id='myTabContent'>
                    <div className='tab-pane fade show active' id='kt_user_view_overview_tab' role='tabpanel'>
                      <div className='card card-flush  border-top-0 '>
                        <div className='card-body pt-3'>
                          <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                            {active == 1 ? (
                              <>
                                <thead className='fs-7 text-gray-400 text-uppercase'>
                                  <tr>
                                    <th className=''>Bütçe kodu</th>
                                    <th className=''>Talep numarası</th>
                                    <th className=''>Kades No</th>
                                    <th className=''>Tutar</th>
                                    <th className=''>TL Tutarı</th>
                                    <th className=''>TEDARİKÇİ</th>
                                    <th className=''>Satın Alan</th>
                                    <th className=''>OLUŞTURULMA TARİHİ</th>
                                    <th className=''>SÖZLEŞME</th>
                                  </tr>
                                </thead>
                                <tbody className='fs-6'>
                                  {purchases.map((purchase) => {
                                    return <Purchase purchase={purchase} refreshPage={refreshPage} />
                                  })}
                                </tbody>
                              </>
                            ) : active == 2 ? (
                              <>
                                <thead className='fs-7 text-gray-400 text-uppercase'>
                                  <tr>
                                    <th className=''>Sgd Durumu</th>
                                    <th className=''>Satın Alma Durumu</th>
                                    <th className=''>Satın Alma Talep Durumu</th>
                                    <th className=''>Açıklama</th>
                                  </tr>
                                </thead>
                                <tbody className='fs-6'>
                                  {purchaseSteps.map((ps) => {
                                    return <PurchaseStep purchaseStep={ps} refreshPage={refreshPage} />
                                  })}
                                </tbody>
                              </>
                            ) : active == 3 ? (
                              <>
                                <thead className='fs-7 text-gray-400 text-uppercase'>
                                  <tr>
                                    <th className=''>SİPARİŞ Numarası</th>
                                    <th className=''>Tutar</th>
                                    <th className=''>TL Tutarı</th>
                                    <th className=''>DÖVİZ Birimi</th>
                                    <th className=''>Tasarruf MİKTARI</th>
                                    <th className=''>TEDARİKÇİ</th>
                                    <th className=''>EFOR BİRİM BEDELİ</th>
                                    <th className=''>EFOR MİKTARI</th>
                                    <th className=''>GARANTİ BAKIM BŞL</th>
                                    <th className=''>GARANTİ BAKIM BTŞ</th>
                                    <th className=''>MİGO</th>
                                    <th className=''>VADE TARİHİ</th>
                                    <th className=''>FATURA NO</th>
                                  </tr>
                                </thead>
                                <tbody className='fs-6'>
                                  {orders.map((order) => {
                                    return <Order order={order} refreshPage={refreshPage} />
                                  })}
                                </tbody>
                              </>
                            ) : (
                              <>
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                                    <th className='min-w-250px'>Adı</th>
                                    <th className='min-w-10px'>Boyutu</th>
                                    <th className='min-w-125px'>Oluşturan</th>
                                  </tr>
                                </thead>
                                <tbody className='fw-bold text-gray-600'>
                                  {files.map((file: any) => {
                                    return <ListFileItem key={file.id} file={file} budget_id={data.id} setRefresh={refreshPage} />
                                  })}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
