/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {RootState} from '../../../../setup'

const AsideUser: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div style={{height: '200px'}} className='py-5'>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div className='me-5 text-center' style={{borderRadius: '50%'}}>
          <div
            className='symbol symbol-40px cursor-pointer'
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-overflow='false'
            data-kt-menu-placement='bottom-start'
            style={{borderRadius: '50% ', border: '2px solid white'}}
          >
            {user?.avatar ? (
              <img
                style={{height: '110px', width: '110px', borderRadius: '20% !important'}}
                src={user?.avatar}
                className='rounded-circle'
                alt=''
              />
            ) : (
              <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-1 h-100px w-100px rounded-circle'>
                {user?.name?.substring(0, 1)}
              </div>
            )}
          </div>
          {/* <HeaderUserMenu /> */}
        </div>
        <div className='mt-4'>
          <div className='d-flex text-center align-items-center flex-stack'>
            <div className='me-2'>
              {/* eslint-disable-next-line */}
              <a
                href='#'
                className=' text-hover-danger fs-3 lh-0 fw-bolder text-white-800 text-light'
              >
                {user?.name}
              </a>
              <span className='text-white-600 fw-bold d-block mt-2 fs-5'>
                {' '}
                {user?.assignment ? user?.assignment?.name : ''}{' '}
              </span>
              {user?.roles?.length > 0 ? (
                <span
                  style={{
                    background: 'rgb(255 255 255)',
                    color: 'rgb(1 13 88)',
                    padding: '10px',
                    borderRadius: '20px',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                  }}
                  className='badge mt-3  fs-7 font-italic me-2'
                >
                  {' '}
                  {user?.roles[0]?.name}{' '}
                  {/* {user?.roles?.length > 1 ? (
         <NavDropdown id='nav-dropdown-dark-example' title='' menuVariant=''>
           {user?.roles?.map((role) => {
             return (
               <NavDropdown.Item>
                 <span className='menu-link px-3'>{role?.name}</span>
               </NavDropdown.Item>
             )
           })}
         </NavDropdown>
       ) : (

         ''
       )} */}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AsideUser
