import { FC } from "react"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_theme/layout/core"
import { BudgetDetail } from "./Budget-detail/BudgetDetail"
import { Budgets } from "./Budgets"
import { Homepage } from "./Homepage"

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const BudgetsPageWrapper : FC =()=>{
    return(
        <Routes>
        <Route element={<Outlet />}>
          <Route
            path='butceler'
            element={<>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bütçeler' toDescription="/butce/butceler">Bütçeler</PageTitle>
              <Budgets />
            </>} />
            <Route
            path='anasayfa'
            element={<>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bütçeler' toDescription="/butce/butceler">Bütçeler</PageTitle>
              <Homepage />
            </>} />
          <Route
            path='detail'
            element={<>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bütçeler' toDescription="/butce/butceler">Detay</PageTitle>
              <BudgetDetail/>
            </>} />
          </Route>

        {/* <Route
          path=''
          element={<>
            <PageTitle>Faaliyetler</PageTitle>
            <Activities />
          </>} /> */}
      <Route index element={<Navigate to='/butce/butceler' />} /></Routes>
    )
}