import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import {useSelector, shallowEqual} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {RootState} from '../../../../setup'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ButtonActive} from '../../Activities/components/ButtonActive'
import {FileItem} from '../../Announcements/components/fileItem'
import {ModalHeader} from '../../components/ModalHeader'
import {DeleteInventory, GetInventory} from '../core/_requests'

type LocationState = {
  id: any
}
export const InventoryDetail: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  let data = location.state as LocationState
  const [showAtachModal, setShowAtachModal] = useState(false)
  const [controlButtons, setControlbuttons] = useState(false)
  const [inventory, setInventory] = useState<any>()
  const handleClose = () => setShow(false)
  const handleCloseAtach = () => setShowAtachModal(false)
  const handleShow = () => setShow(true)
  const handleShowAtach = () => setShowAtachModal(true)

  useEffect(() => {
    // if (data.created_by.id == user.id || user.id === 36) {
    //   setControlbuttons(true)
    // }
    GetInventory(data?.id).then(({data}) => {
      setInventory(data.data)
    })
  }, [show, showAtachModal])

  // Delete inventory and route to inventories
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteInventory(inventory.id)
        swal('Silindi!', 'Envanter Yönetimi başarı ile silindi.', 'success')
        navigate('/envanter-yonetimi')
      }
    })
  }
  // const imgLink = inventory?.files??.length
  //   ? inventory?.files[0]?.path
  //   : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div>
        {/* <div
            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-325px'
            style={{
              backgroundImage: `url(${imgLink})`,
            }}
          ></div> */}
        <div id='kt_project_targets_card_pane'>
          <div className='card ' style={{backgroundColor: '#fdfcfb'}}>
            <div className='card-body'>
              {/* <div className='mb-13 col-xl-6'> */}
              <table>
                <tbody className='fs-4'>
                  <tr className='mb-5'>
                    <td className='col-lg-4 fw-bold fw-bolder min-w-200px'>Ürün Adı:</td>
                    <td className='text-muted'>{inventory?.product_name}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Ürün Kodu:</td>
                    <td className='text-muted'>{inventory?.product_code}</td>
                  </tr>
                  <tr className='mb-5'>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Adedi:</td>
                    <td className='text-muted'>{inventory?.quantity}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Tutar:</td>
                    <td className='text-muted'>
                      <NumberFormat value={inventory?.amount} displayType={'text'} thousandSeparator={true} /> {inventory?.currency}
                    </td>
                  </tr>
                  <tr className='mb-5'>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Kategori:</td>
                    <td className='text-muted'>{inventory?.category?.name}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Başlangıç Tarihi:</td>
                    <td className='text-muted'>{moment(inventory?.start_date).format('DD.MM.YYYY')}</td>
                  </tr>
                  <tr className='mb-5'>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Bitiş Tarihi:</td>
                    <td className='text-muted'>{moment(inventory?.end_date).format('DD.MM.YYYY')}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Sözleşme Tarihi:</td>
                    <td className='text-muted'>{moment(inventory?.contract_date).format('DD.MM.YYYY')}</td>
                  </tr>
                  <br />
                  <tr className='text-center mt-5 mx-auto '>
                    <h3 className='text-center fs-2'>Tedarikçi Bilgileri</h3>
                  </tr>
                  <tr className='mb-5 min-w-200px'>
                    <td className='col-lg-4 fw-bolder'> Adı:</td>
                    <td className='text-muted'>{inventory?.supplier?.name}</td>
                  </tr>
                  <tr className='mb-5  min-h-0px'>
                    <td className='col-lg-4 fw-bolder min-w-200px'>E-Posta:</td>
                    <td className='text-muted'>{inventory?.supplier?.email}</td>
                  </tr>
                  <tr className='mb-5 min-h-20px '>
                    <td className='col-lg-4 fw-bolder min-w-200px'>Şirket Adı:</td>
                    <td className='text-muted'>{inventory?.supplier?.company_name}</td>
                  </tr>
                  <br />
                  <tr className='mt-5 mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Son Güncelleme Tarihi:</td>
                    <td className='text-muted'>{inventory?.son_guncelleme_tarihi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Ürün Lisans Açıklaması:</td>
                    <td className='text-muted'>{inventory?.urun_lisans_aciklamasi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Yenileme Bedeli:</td>
                    <td className='text-muted'>{inventory?.lisans_yenileme_bedeli}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Bedeli:</td>
                    <td className='text-muted'>{inventory?.lisans_bedeli}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Bakım Destek Bitiş Tarihi:</td>
                    <td className='text-muted'>{inventory?.bakim_destek_bitis_tarihi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Sahibi Firma:</td>
                    <td className='text-muted'>{inventory?.lisans_sahibi_firma}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Modeli:</td>
                    <td className='text-muted'>{inventory?.lisans_modeli}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Tipi:</td>
                    <td className='text-muted'>{inventory?.lisans_tipi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Zaafiyet Araştırma Durumu:</td>
                    <td className='text-muted'>{inventory?.zaafiyet_arastirma_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>User Authentication Diğer Açıklama:</td>
                    <td className='text-muted'>{inventory?.user_authentication_diger_aciklama}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>User Authentication Method:</td>
                    <td className='text-muted'>{inventory?.user_authentication_method}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Backup Alımı Durumu:</td>
                    <td className='text-muted'>{inventory?.backup_alimi_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Siement Entegrasyon Durumu:</td>
                    <td className='text-muted'>{inventory?.siement_entegrasyon_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>MFA Rol Durumu:</td>
                    <td className='text-muted'>{inventory?.mfa_rol_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>MFA Uygulama Durumu:</td>
                    <td className='text-muted'>{inventory?.mfa_uygulama_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Alarm İzleme Sistemi:</td>
                    <td className='text-muted'>{inventory?.alarm_izleme_sistemi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Agizleme Entegrasyon Durumu:</td>
                    <td className='text-muted'>{inventory?.agizleme_entegrasyon_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Toplam Cihaz Through Put:</td>
                    <td className='text-muted'>{inventory?.toplam_cihaz_through_put}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Satın Almanın Yapıldığı Firma:</td>
                    <td className='text-muted'>{inventory?.satin_almanin_yapildigi_firma}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Cihaz Hostname:</td>
                    <td className='text-muted'>{inventory?.cihaz_hostname}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Süresiz Garanti Lisans:</td>
                    <td className='text-muted'>{inventory?.suresiz_garanti_lisans}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Kullanım Amacı:</td>
                    <td className='text-muted'>{inventory?.kullanim_amaci}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Cihaz İp:</td>
                    <td className='text-muted'>{inventory?.cihaz_ip}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Racku Bilgisi:</td>
                    <td className='text-muted'>{inventory?.racku_bilgisi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Kabin Adı:</td>
                    <td className='text-muted'>{inventory?.kabin_adi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Salon Adı:</td>
                    <td className='text-muted'>{inventory?.salon_adi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Bitiş Tarihi:</td>
                    <td className='text-muted'>{inventory?.lisans_bitis_tarihi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lisans Başlangıç Tarihi:</td>
                    <td className='text-muted'>{inventory?.lisans_baslangic_tarihi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Müdürlük Email Adresi:</td>
                    <td className='text-muted'>{inventory?.mudurluk_email_adresi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>İlgili Birim Müdürlük:</td>
                    <td className='text-muted'>{inventory?.ilgili_birim_mudurluk}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Seri Numarası:</td>
                    <td className='text-muted'>{inventory?.seri_numarasi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Lokasyon:</td>
                    <td className='text-muted'>{inventory?.lokasyon}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Cihaz Durumu:</td>
                    <td className='text-muted'>{inventory?.cihaz_durumu}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Model:</td>
                    <td className='text-muted'>{inventory?.model}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Marka:</td>
                    <td className='text-muted'>{inventory?.marka}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Cihaz Tipi</td>
                    <td className='text-muted'>{inventory?.cihaz_tipi}</td>
                  </tr>
                  <tr className='mb-5 '>
                    <td className='col-lg-4 fw-bolder min-w-200px '>Sectag:</td>
                    <td className='text-muted'>{inventory?.sectag}</td>
                  </tr>
                </tbody>
              </table>

              {/* </div> */}
              {/* <div
            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded  max-w-75  min-h-325px col-xl-5'
            style={{
              backgroundImage: `url(${imgLink})`,
              backgroundSize: '100% 100%'
            }}
          ></div>   */}
            </div>
            {/* {inventory.files?.length > 1 ? (
                          <div className='overflow-auto pb-5 m-3'>
                            <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-600px p-7  scroll-x'>
                              {inventory?.files?.map(
                                (
                                  el: {path: string; name: string; size: string; id: ID},
                                  index: number
                                ) => {
                                  if (index !== 0)
                                    return (
                                      <FileItem
                                        key={el.id}
                                        fileLink={el.path}
                                        name={el.name}
                                        size={el.size}
                                      />
                                    )
                                }
                              )}
                            </div>
                          </div>
                        ) : (
                          ''
                        )} */}

            {controlButtons ? (
              <div className='d-flex justify-content-left m-3'>
                <div className='d-flex justify-content-between'>
                  <div className='nav-item h-10 me-5 mt-10' role='presentation'>
                    <ButtonActive text={'Düzenle'} clickFunc={handleShow} />
                  </div>
                  <div className='nav-item h-10  mt-10' role='presentation'>
                    <ButtonActive text={'Sil'} clickFunc={swalDelete} />
                  </div>
                  <div className='nav-item h-10 w-150px   mt-10' role='presentation'>
                    <ButtonActive text={'Dosya Düzenle'} clickFunc={handleShowAtach} />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Modal show={show} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          {/* <AddInventory
            inventory={{
              id: inventory.id,
              product_name: inventory.product_name,
              product_code: inventory.product_code,
              quantity: inventory.quantity,
              amount: inventory.amount,
              currency: inventory.currency,
              start_date: inventory.start_date,
              end_date: inventory.end_date,
              contract_date: inventory.contract_date,
              supplier_id: inventory.supplier.id,
              inventory_category_id: inventory.category.id,
              attachments: inventory.files,
            }}
            update={true}
            handleClose={handleClose}
            refreshPage={undefined}
          /> */}
        </Modal.Body>
      </Modal>
      <Modal show={showAtachModal}>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseAtach} titleHeader={'Dosya Düzenle'} />

          {/* <UpdateAtachments
            handleClose={handleCloseAtach}
            update={true}
            inventory={{
              id: data.id,
              attachments: inventory.files,
              banner: inventory.files[0],
            }}
          /> */}
        </Modal.Body>
      </Modal>
    </>
  )
}
