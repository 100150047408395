import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}

export const TableBody: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.crm_customer_id}</td>
        <td className='text-gray-500'>{item?.customer_name}</td>
        {/* <td className='text-gray-500'>{item?.service_no}</td> */}
        <td className='fw-bolder'>{item?.offer_name}</td>
        <td className='text-gray-500'>{item?.product_status}</td>
        <td className='text-gray-500'>{item?.product_location}</td>
        <td className='fw-bolder'>{item?.activation_date}</td>
        <td className='text-gray-500'>{item?.cancel_date}</td>
        <td className='text-gray-500'>{item?.speed}</td>
        <td className='fw-bolder'>{item?.storage_size}</td>
        <td className='text-gray-500'>{item?.service_type}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
