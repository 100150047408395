import moment from "moment"
import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    item: any
}
export const VulnerabilityExploitationItem : FC<Props>=({item})=>{
    return(
       <>
             <td className='fw-bolder'>
           {item?.source_port}
        </td>
        <td className='text-gray-500'>{item?.destination_port}</td>
        <td className='text-gray-500'>{item?.ref_id}</td>
        <td className='text-gray-500'>{item?.string}</td>
        <td className='text-gray-500'>{moment(item?.begin_time).format('DD.MM.YYYY H:mm:ss')}</td>
        <td className='text-gray-500'> {moment(item?.end_time).format('DD.MM.YYYY H:mm:ss')}</td>
        <td className='text-gray-500'>{item?.threat_id}</td>
        <td className='text-gray-500'>{item?.variant}</td>
        <td className='text-gray-500'>{item?.trusted}</td>
        <td className='text-gray-500'>{item?.untrusted}</td>
        <td className='text-gray-500'>{item?.scenario_details}</td>
        <td className='text-gray-500'>{item?.threat_name}</td>
        <td className='text-gray-500'>{item?.l1_category_name} </td>
        <td className='text-gray-500'>{item?.server_ip} </td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{moment(item?.updated_at).format('DD.MM.YYYY')}</td>
         <tr>      
      </tr>
       </>
    )
}