import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {Profile} from '../Profile'
import {Supplier} from './Supplier'
import {SupplierDetail} from './SupplierDetail'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SupplierPageWrapper: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='tedarikci-listesi'
          element={
            <>
              <PageTitle
                breadcrumbs={dashboardBreadCrumbs}
                description='Tedarikçi Bilgileri'
                toDescription='/tedarikci-bilgileri/tedarikci-listesi'
              >
                Tedarikçi Listesi
              </PageTitle>
              <Supplier />
            </>
          }
        />
        <Route
          path='detail'
          element={
            <>
              <PageTitle
                breadcrumbs={dashboardBreadCrumbs}
                description='Tedarikçi Bilgileri'
                toDescription='/tedarikci-bilgileri/tedarikci-listesi'
              >
                Detay{' '}
              </PageTitle>
              <SupplierDetail />
            </>
          }
        />
        <Route path='profile' element={<Profile />} />
        <Route
          path='tedarikci-bilgieri'
          element={
            <>
              <PageTitle
                breadcrumbs={dashboardBreadCrumbs}
                description='Tedarikçi Bilgileri'
                toDescription='/tedarikci-bilgileri/tedarikci-listesi'
              >
                Tedarikçi Listesi
              </PageTitle>
              <Supplier />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/tedarikci-bilgileri' />} />
    </Routes>
  )
}

export {SupplierPageWrapper}
