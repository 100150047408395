import {FC} from 'react'
type Props = {
  item: any
}
export const SubStatusItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td className='fw-bolder'>{item?.id}</td>
        <td className='fw-bolder'>{item?.trusted}</td>
        <td className='text-gray-500'>{item?.untrusted}</td>
        <td className='text-gray-500'>{item?.is_proxy.toString()}</td>
        <td className='text-gray-500'>{item?.description}</td>
        <td className='text-gray-500'>{item?.has_mitigation.toString()}</td>
        <td className='text-gray-500'>{item?.blocked_count}</td>
        <td className='text-gray-500'>{item?.blocked_change}</td>
        <td className='text-gray-500'>{item?.unblocked}</td>
        <td className='text-gray-500'>{item?.unblocked_change}</td>
        <td className='text-gray-500'>{item?.security_score}</td>
        <td className='text-gray-500'>{item?.security_score_change}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
