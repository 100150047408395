import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {createIssue, getActivityCategories, getActivityPriorities, getUserByAssignment, UpdateIssue} from '../core/_requests'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCategory} from '../../Categories/AddCategory'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {getFilteredProjects} from '../../Project/core/requests'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {getCSRFToken} from '../../core/requests'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  handleClose: any
  activity: any
  update: boolean
}
const issueSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  assigned_by: Yup.number().required('Zorunlu alan'),
  issue_category_id: Yup.number().required('Zorunlu alan'),
  issue_priority_id: Yup.number().required('Zorunlu alan'),
  start_date: Yup.date()
    .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
  end_date: Yup.date()
    .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .when('start_date', (start_date, yup) => start_date && yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz'))
    // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
    // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
  project_id: Yup.number(),
})
const issueSchemaUpdate = Yup.object().shape({
  title: Yup.string(),
  assigned_by: Yup.number(),
  issue_category_id: Yup.number(),
  start_date: Yup.date(),
  project_id: Yup.number(),
  end_date: Yup.date(),
  // .when(
  //   'start_date',
  //   (start_date, yup) =>
  //     start_date &&
  //     yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
  // ),
  // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
  // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
})

export const AddActivity: FC<Props> = ({handleClose, activity, update}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [priorities, setPriorities] = useState<any>([])
  const {refreshPage} = useRefresh1()

  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [projects, setProjects] = useState<any>([])
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }

  useEffect(() => {
    getActivityCategories().then(async ({data}) => {
      await setCategories(data.data)
    })

    getActivityPriorities().then(async ({data}) => {
      await setPriorities(data.data)
    })
    getUserByAssignment().then(({data}) => {
      setUsers(data.data)
    })
    getFilteredProjects('')
      .then(({data}) => {
        setProjects(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [update, showCategoryModal])

  const [userForEdit] = useState<any>({
    ...activity,
    id: activity.id,
    title: activity?.title,
    start_date: activity?.start_date,
    end_date: activity?.end_date,
    content: activity?.content,
    email_notification: activity?.email_notification,
    phone_notification: activity?.phone_notification,
    project_id: activity?.project?.id,
    issue_category_id: activity?.category?.id,
    assigned_by: activity?.assigned?.id,
    issue_priority_id: activity?.priority?.id,
  })

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: update ? issueSchemaUpdate : issueSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          let body = {
            title: values.title,
            start_date: values.start_date,
            end_date: values.end_date,
            content: values.content,
            email_notification: values.email_notification ? '1' : '0',
            phone_notification: values.phone_notification ? '1' : '0',
            issue_category_id: Number(values.issue_category_id),
            assigned_by: Number(values.assigned_by),
            project_id: values.project_id ? Number(values.project_id) : '',
            issue_priority_id: values.issue_priority_id,
          }
          await UpdateIssue(values.id, body)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
              refreshPage()
            })
            .catch((err) => {
              swal('Hata', err.response.data.error, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values.title)
          formData.append('start_date', values.start_date!)
          formData.append('end_date', values.end_date)
          formData.append('content', values.content)
          formData.append('email_notification', values.email_notification ? '1' : '0')
          formData.append('phone_notification', values.phone_notification ? '1' : '0')
          formData.append('issue_category_id', values.issue_category_id)
          formData.append('assigned_by', values.assigned_by)
          formData.append('project_id', values.project_id ? values.project_id : '')
          formData.append('issue_priority_id', values.issue_priority_id)
          // formData.append('status', '0')
          await createIssue(formData)
            .then(() => {
              swal('Eklendi', 'Faaliyet başarı ile eklendi.', 'success', {timer: 2000})
              handleClose()
              refreshPage()
            })
            .catch((err) => {
              swal('Hata!', err.response.data.error, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input Başlık */}
          {/* begin::Input Sorumlu */}
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Sorumlu</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('assigned_by')}
                name='assigned_by'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Sorumlu Seç
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.assigned_by && formik.errors.assigned_by && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.assigned_by}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Sorumlu */}

            {/* begin::Input kategori */}
            <div className='col-xs-12 col-md-6'>
              <div className='d-flex justify-content-between'>
                <label className='required fw-bold fs-7 mb-2'>Kategori Seç</label>
                {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && <img src={toAbsoluteUrl('/media/icons/add.png')} alt='add-icon' width='25px' className='' onClick={openCategoryModal} />}
              </div>

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Kategori Seç'
                {...formik.getFieldProps('issue_category_id')}
                name='issue_category_id'
                //defaultValue={activity.issue_category_id}
              >
                <option selected disabled hidden>
                  Kategori Seç
                </option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.issue_category_id && formik.errors.issue_category_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.issue_category_id}</span>
                  </div>
                </div>
              )}
            </div>

            {/* end::Input Kategori */}
          </div>

          <div className='row mb-7 d-flex'>
            {/* Begin::Input Başlangıç Tarihi */}
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_date}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Başlangıç tarihi */}
            {/* Begin::Input Bitiş Tarihi */}
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_date}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Bitiş tarihi */}
          </div>
          <div className='row mb-7'>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <label className='fw-bold fs-7 mb-2'>Proje Seç</label>
              </div>

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Proje Seç'
                {...formik.getFieldProps('project_id')}
                name='project_id'
                //defaultValue={activity.issue_category_id}
              >
                <option selected disabled hidden>
                  Proje Seç
                </option>
                {projects?.map((project: any) => {
                  return (
                    <option key={project?.id} value={project?.id}>
                      {project?.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <label className='fw-bold fs-7 mb-2'>Öncelik Seç</label>
              </div>

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder=''
                {...formik.getFieldProps('issue_priority_id')}
                name='issue_priority_id'
                //defaultValue={activity.issue_category_id}
              >
                <option selected disabled hidden>
                  Öncelik Seç
                </option>
                {priorities?.map((project: any) => {
                  return (
                    <option key={project?.id} value={project?.id}>
                      {project?.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {/* begin::Input Açıklama */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Açıklama</label>
            {/* end::Label */}
            <textarea placeholder='Faaliyet için açıklama yazınız' {...formik.getFieldProps('content')} name='content' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {/* {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )} */}
          </div>
          {/* end::Açıklama */}

          {/* begin:: Bildirimler */}
          {/* <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirim</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={activity.email_notification}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={activity.phone_notification}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div> */}
          {/* end:: Bildirimler */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button type='reset' onClick={handleClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting}>
              İptal
            </button>

            <button type='submit' className='btn ' data-kt-users-modal-action='submit' disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}>
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
      <Modal show={showCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='issue'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
