import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
import {KTSVG} from '../../../../_theme/helpers'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {GetMonthlyBudget} from '../core/_requests'
import {BudgetItem} from './BudgetItem'
type Props = {
  monthName: string
  opexOrCapex: string
  handleClose: any
}
export const ListMonthlyBudgets: FC<Props> = ({opexOrCapex, monthName, handleClose}) => {
  const [budget, setBudget] = useState<any>([])
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  useEffect(() => {
    let now = new Date()
    GetMonthlyBudget(opexOrCapex, now.getFullYear() + '-' + (now.getMonth() + 1)).then(({data}) => {
      setBudget(data.data)
      console.log(data.data)
    })
  }, [])
  return (
    <>
      <div>
        <div className='d-flex justify-content-end'>
          <div className='btn-active-icon-primary ' data-kt-users-modal-action='close' onClick={handleClose} style={{cursor: 'pointer'}}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <h2 className='fw-bolder text-center'>{monthName} ayı bütçe listesi</h2>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            <table className='table table-row-dashed gy-6 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th className=''>BÜTÇE ADI</th>
                  <th className=''>SATIN ALAN</th>
                  <th className=''>TEDARİKÇİ</th>
                  <th className=''>KULLANILAN TUTAR</th>
                  <th className=''></th>
                </tr>
              </thead>

              <tbody className='fs-6'>
                {budget?.length < 1 ? (
                  <NoMatchRecords isTable={true} />
                ) : (
                  budget.map((budg: any) => {
                    return (
                      <tr>
                        <td className='fw-bolder'>
                          <NavLink to='../detail' state={budget} className='text-gray-900 text-hover-primary'>
                            {budg.budget.title}
                          </NavLink>
                        </td>
                        {budg.purchase_by && (
                          <td
                            className='text-gray-500 text-hover-primary'
                            onClick={async () => {
                              setProfilUserId(budg.purchase_by.id)
                              await openProfilModal()
                            }}
                          >
                            {budg.purchase_by.name}
                          </td>
                        )}
                        {budg.supplier && (
                          <td
                            className='text-gray-500 text-hover-primary'
                            onClick={async () => {
                              setProfilUserId(budg.supplier.id)
                              await openProfilModal()
                            }}
                          >
                            {budg.supplier.name}
                          </td>
                        )}
                        <td className='text-gray-500'>
                          <NumberFormat value={budg.amount} displayType={'text'} thousandSeparator={true} /> {budg.currency}
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
