import moment from "moment"
import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    isam: any
}
export const ISAMItem : FC<Props>=({isam})=>{
    return(
       <>
         <tr>
         <td className='text-gray-500'>{isam?.hostname}</td>
         <td className='text-gray-500'>{isam?.ip_address}</td>
         <td className='text-gray-500'>{isam?.logged_in_users}</td>
        <td className='fw-bolder'>
            {moment(isam?.last_update).format('DD.MM.YYYY')}
        </td>
        <td className='text-gray-500'>{isam?.client_status}</td>
        <td className='text-gray-500'>{isam?.endpoint_server}</td>
        <td className='text-gray-500'>  {isam?.client_installation_version}</td>
        <td className='text-gray-500'>{isam?.policy_engine_version}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}