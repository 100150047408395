import React, {FC, useEffect, useState} from 'react'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {getIssues} from '../core/_requests'

import {TargetItem2} from './TargetItem2'

type Props = {
  searchKeyClick: string
  period: string
  status: string
  plusOrMinus?: boolean
  assignedBy?: string
  assignmentId?: string
}
export const TargetLists: FC<Props> = ({assignedBy, searchKeyClick, period, status, plusOrMinus, assignmentId}) => {
  const [activities, setActivities] = useState([
    {
      id: 1,
      title: '',
      content: '',
      start_date: '',
      end_date: '',
      phone_notification: false,
      email_notification: false,
      comments: [],
      category: {
        id: 1,
        name: '',
      },
      status: {
        id: '0',
        name: 'Planlanan',
      },
      assigned: {
        id: 1,
        name: '',
        email: '',
      },
    },
  ])
  const {refresh} = useRefresh1()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const currentTableData = () => {
    let queryParams = '?status=' + status + '&page[number]=' + currentPage + '&page[size]=' + limit

    if (period !== '') {
      queryParams += '&period=' + period
    }

    if (searchKeyClick !== '') {
      queryParams += '&q=' + searchKeyClick
    }

    if (assignedBy !== '') {
      queryParams += '&assigned_by=' + assignedBy
    }

    if (assignmentId !== '') {
      queryParams += '&assignment_id=' + assignmentId
    }

    getIssues(queryParams)
      .then(({data}) => {
        setActivities(data.data)
        setTotal(data?.meta?.total)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        // Hata işleme kodunu ekleyin
      })
  }

  useEffect(() => {
    setLoading(true)
    setCurrentPage(1)
    currentTableData()
  }, [status, searchKeyClick, period, assignedBy, assignmentId, refresh])
  useEffect(() => {
    setLoading(true)
    currentTableData()
  }, [currentPage])
  return (
    <div id='kt_project_targets_card_pane'>
      <div className='card card-flush d-flex justify-content-center '>
        <div className={`card-body mh-900 overflow-auto `}>
          {loading ? (
            <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <table className='table table-row-dashed  gy-4 align-middle fw-bolder '>
              <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-30px', backgroundColor: '#fdfcfb'}}>
                <tr>
                  <th className='min-w-100px'>FAALİYET</th>
                  <th className='min-w-90px'>KATEGORİ</th>
                  <th className='min-w-150px'>BAŞlANGIÇ TARİHİ</th>
                  <th className='min-w-150px'>BİTİŞ TARİHİ</th>
                  <th className='min-w-150px'>SORUMLU</th>
                  <th className='min-w-150px'>Ekleyen</th>
                  <th className='min-w-150px'>ÖNCELİK</th>
                  <th className='min-w-150px'>Görev</th>
                  <th className='min-w-150px'>Hafta</th>
                  <th className='min-w-90px'>DURUM</th>
                  {plusOrMinus && <th></th>}
                </tr>
              </thead>

              <tbody className={`fs-6`}>
                {activities?.length < 1 && !loading ? (
                  <NoMatchRecords isTable={true} />
                ) : (
                  activities.map((activity) => {
                    return (
                      <TargetItem2
                        key={activity.id}
                        id={activity.id}
                        title={activity.title}
                        categoryName={activity.category.name}
                        start_date={activity.start_date}
                        end_date={activity.end_date}
                        userName={activity.assigned?.name}
                        status={'In Progress'}
                        issue={activity}
                        assigned_id={activity.assigned?.id}
                        plusOrMinus={plusOrMinus}
                      />
                    )
                  })
                )}
              </tbody>
            </table>
          )}
          <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
        </div>
      </div>
    </div>
  )
}
