import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
import {getBlogCategories} from '../core/_requests'
type Props = {
  setCategoryId: any
  setPeriod: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilters: FC<Props> = ({
  setCategoryId,
  setPeriod,
  handleClose,
  setSearchKeyClick,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setCategoryId(selectedCategory)
    setPeriod(selectedPeriod)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [inputWord, setInputWord] = useState('')
  const [categories, setCategories] = useState<any>([])
  useEffect(() => {
    getBlogCategories().then(({data}) => {
      setCategories(data.data)
    })
  }, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      <div className='px-7 py-5'>
        <div className='d-flex align-items-center position-relative my-3'>
          <input
            type='text'
            data-kt-filemanager-table-filter='search'
            className={`form-control form-control-solid  me-2  'w-300px'`}
            placeholder={'Aranacak kelimeyi giriniz'}
            onChange={(e) => {
              setInputWord(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Kategori</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedCategory(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Kategori Seç </option>
            {categories.map((category: any) => {
              return <option value={category.id}>{category.name}</option>
            })}
          </select>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}
      </div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Zaman</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-dropdown-parent='#kt_menu_6279569cd15cc'
              data-allow-clear='true'
              onChange={(e) => setSelectedPeriod(e.target.value)}
              // defaultValue={}
            >
              <option value=''> Tümü </option>
              <option value='daily'> Bugün </option>
              <option value='weekly'> Hafta </option>
              <option value='monthly'> Ay </option>
              <option value='yearly'> {date.getFullYear().toString()} </option>
            </select>
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}

        <div className='d-flex justify-content-center'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleClose}
          >
            İptal
          </button>
          <button
            type='submit'
            className='btn btn-sm'
            data-kt-menu-dismiss='true'
            onClick={handleSubmit}
          >
            Detaylı Ara
          </button>
        </div>
      </div>
    </div>

    //</form>
  )
}
