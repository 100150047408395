import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {Prev} from 'react-bootstrap/esm/PageItem'
import {getProject} from '../../../../app/pages/Project/core/requests'
import './style.css'

type Props = {
  data: any
}
export const Timeline: FC<Props> = ({data}) => {
  const id = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [count, setCount] = useState(data.length)
  const [filteredData, setFilteredData] = useState<any>([])

  const [project, setProject] = useState<any>({
    id: 1,
    code: '',
    name: '',
    start_date: '',
    end_date: '',
    status: {
      id: 1,
      name: '',
    },
    description: '',
    email_notification: false,
    phone_notification: false,
    group_directorate: {
      id: 2,
      name: '',
      order: 1,
      parent_id: 1,
      is_group_directorate: false,
      is_directorate: false,
      is_last: false,
    },
    users: [],
    files: [],
    created_at: '',
    created_by: {
      id: 1,
      name: '',
      email: '',
      avatar: '',
    },
  })

  useEffect(() => {
    getProject(id)
      .then((res) => {
        setProject(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    let toplam = 0
    setFilteredData([])
    data?.map((d: any) => {
      toplam = toplam + d?.tasks?.length
      setFilteredData((prevState: any) => [...prevState, {id: d?.id, name: d?.title, planName: d?.plan?.name, date: getMinDate(d)}])
    })
    setCount(data?.length + toplam)
  }, [data])

  const getMinDate = (target: any) => {
    if (target?.tasks?.length > 0) {
      const minDate = new Date(
        Math.min(
          ...target?.tasks?.map((el: any) => {
            return new Date(el?.planned_start_date)
          })
        )
      )
      return moment(minDate).format('YYYY-MM-DD')
    } else {
      return moment(target?.created_at).format('YYYY-MM-DD')
    }
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body  '>
              <h4 className='card-title mb-5'>Toplam Hedef ({count})</h4>
              <div className='events d-flex justify-content-between'>
                <div className=' event-list start'>
                  <div className=''>
                    <div className='event-date bg-primary text-light'>{moment(project?.start_date).format('DD.MM.YYYY')}</div>
                    <h5 className='font-size-16'>Başlangıç Tarihi</h5>
                  </div>
                </div>
                {filteredData
                  ?.sort((a: any, b: any) => {
                    const dateA = new Date(a.date)
                    const dateB = new Date(b.date)
                    return dateA.getTime() - dateB.getTime()
                  })
                  .map((target: any, index: React.Key | null | undefined) => {
                    return (
                      <>
                        <div key={target?.id} className='event-list'>
                          <div className='p-5'>
                            <div className={'bg-primary text-light event-date'}>
                              {moment(target?.date).format('DD.MM.YYYY')}

                              {/* {getMinDate(target)!=='' && moment(getMinDate(target)).format('DD.MM.YYYY')} */}
                            </div>
                            <div>
                              {' '}
                              <h6 className='font-size-12 text-muted' style={{fontSize: '13px'}}>
                                {target?.planName}
                              </h6>
                              <h5
                                // className={getClass(target?.status?.name) + ' p-1 '}
                                style={{borderRadius: '4px', fontSize: '14px'}}
                              >
                                {target?.name?.substring(0, 20)}
                                {target?.name?.length > 20 ? '...' : ''}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}

                <div className=' event-list start'>
                  <div className=''>
                    <div className='event-date bg-success text-light'> {moment(project?.end_date).format('DD.MM.YYYY')}</div>
                    <h5 className='font-size-12'>Bitiş Tarihi</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
