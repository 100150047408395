import moment from 'moment'
import React, {FC} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {BlogModel} from '../model/blogModel'

type Props = {
  categoryName?: string
  el: any
  path: any
}

export const BlogItem: FC<Props> = ({categoryName, el, path}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const link = path?.length > 0 ? path[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')
  return (
    <div className='col-md-4'>
      <div className='card-xl-stretch me-md-6'>
        <Link className='d-block overlay' data-fslightbox='lightbox-hot-sales' to={loginUser?.permissions.filter((permission) => permission.name == 'Show Blog').length > 0 ? 'detail' : ''} state={el}>
          <div className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px' style={{backgroundImage: `url(${link}`}}></div>

          <div className='overlay-layer card-rounded bg-dark bg-opacity-25'>
            <i className='bi bi-eye-fill fs-2x text-white'></i>
          </div>
        </Link>

        <div className='mt-5'>
          <Link
            to={loginUser?.permissions.filter((permission) => permission.name == 'Show Blog').length > 0 ? 'detail' : ''}
            state={el}
            className='fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base'
          >
            {el.title}
          </Link>

          <div
            style={{
              maxHeight: '95px',
              height: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className='fw-bold fs-5 text-gray-600 my-3'
          >
            {el.content}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='fs-6 fw-bolder'>
              <span className='text-gray-500'>{moment(el?.start_date).format('DD.MM.YYYY')}</span>
            </div>
            {categoryName !== '' ? <span className='badge badge-light-primary fw-bolder my-2'>{categoryName}</span> : ''}
          </div>
        </div>
      </div>
    </div>
  )
}
