import React, {FC, useEffect, useState} from 'react'
import {getProjectUsers, getTargetCategories} from '../../core/requests'
import {AddTargetBodyTest1} from './addTargetBodyTest'

type Props = {
  target: any
  target_id: any
  handleClose: any
  update: boolean
  project_id: any
  refreshPage?: any
}

export const AddTargetHeaderTest: FC<Props> = ({target, target_id, project_id, update}) => {
  const init = update ? (target?.length > 0 ? target : [target]) : []
  const [localUpdate, setUpdate] = useState(update)
  const [targets, setTarget] = useState<any>(init)
  const addTarget = (newTarget: any) => {
    setTarget((current: any) => [...current, newTarget])
    targets?.length > 0 ? setUpdate(true) : setUpdate(false)
  }
  const removeTarget = (oldTarget: any) => {
    setTarget(targets.filter((item: {id: any}) => item?.id !== oldTarget?.id))
    targets?.length > 0 ? setUpdate(true) : setUpdate(false)
  }
  const [users, setUsers] = useState<any>([])
  const [targetCategories, setTargetCategories] = useState<any>([])
  useEffect(() => {
    getTargetCategories().then(({data}) => {
      setTargetCategories(data?.data)
    })
    getProjectUsers(project_id).then(({data}) => {
      setUsers(data?.data)
    })
  }, [])

  return (
    <div className='row w-100'>
      <div className='card card-flush w-100 p-0'>
        <div className='card-body pt-3 overflow-scroll w-100'>
          <div className='row d-flex border-primary border-bottom-dashed mb-5 pb-3'>
            {/* <div className='col text-primary' style={{minWidth:'100px'}}>Proje Aşaması</div> */}
            <div className='col required' style={{minWidth: '100px', color: '#000841'}}>
              Görev adı
            </div>
            <div className='col  required' style={{minWidth: '150px', color: '#000841'}}>
              Görev Sahibi
            </div>
            <div className='col ' style={{minWidth: '150px', color: '#000841'}}>
              Diğer Kullanıcılar
            </div>
            <div className='col required' style={{minWidth: '150px', color: '#000841'}}>
              Planlanan Başlangıç Tarihi
            </div>
            <div className='col  required' style={{minWidth: '150px', color: '#000841'}}>
              Planlanan Bitiş Tarihi
            </div>
            {localUpdate ? (
              <>
                <div className='col ' style={{color: '#000841'}}>
                  Gerçekleşen Başlangıç Tarih
                </div>
                <div className='col ' style={{color: '#000841'}}>
                  Gerçekleşen Bitiş Tarih
                </div>
              </>
            ) : (
              ''
            )}
            <div className='col ' style={{color: '#000841'}}>
              Not
            </div>
            <div className='col  required' style={{color: '#000841'}}>
              İlerleme Yüzdesi
            </div>
            <div className='col'></div>
          </div>
          <div className=' '>
            {targets?.map((tar: any, index: React.Key | null | undefined) => {
              return (
                <>
                  <AddTargetBodyTest1 key={index} setCount={setTarget} target={tar} target_id={target_id} users={users} targetCategories={targetCategories} update={true} removeTarget={removeTarget} />
                </>
              )
            })}
          </div>
          {!update && (
            <div className='' style={{zIndex: '100000'}}>
              <AddTargetBodyTest1 key={9999999} setCount={addTarget} target={undefined} target_id={target_id} users={users} targetCategories={targetCategories} update={update} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
