import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const USERS = `${API_URL}/users`
const ASSIGNMENT = `${API_URL}/assignments`
const ROLE = `${API_URL}/roles`
const PAGES = `${API_URL}/pages`
const PERMISSION = `${API_URL}/permissions`
const PERMISSION_GROUPS = `${API_URL}/permission_groups`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

// users

export async function GetUsers(link: string) {
  return await axios.get<any>(USERS + link)
}

export async function CreateUser(user: any) {
  return await axios.post(USERS, user, config)
}

export async function UpdateUser(id: any, user: any) {
  return await axios.put(USERS + '/' + id, user)
}

export async function DeleteUser(id: any) {
  return await axios.delete(USERS + '/' + id)
}
//photo

export async function UpdatePhoto(id: any, avatar: any) {
  return await axios.post(USERS + '/' + id + '/avatar', avatar, config)
}
// assignment

export async function CreateAssignment(assignment: any) {
  return await axios.post(ASSIGNMENT, assignment, config)
}

export async function GetAssignments() {
  return await axios.get(ASSIGNMENT)
}
export async function GetTopAssignment() {
  return await axios.get(ASSIGNMENT + '?only_parents=1&sort=order')
}
export async function UpdateAssignment(id: any, assignment: any) {
  return await axios.put(ASSIGNMENT + '/' + id, assignment)
}

export async function GetAssignment(id: any) {
  return await axios.get(ASSIGNMENT + '/' + id)
}
export async function GetDirectorateAssignments() {
  return await axios.get(ASSIGNMENT + '?is_directorate=1')
}
// roles

export async function CreateRole(role: any) {
  return await axios.post(ROLE, role, config)
}

export async function UpdateRole(role_id: any, role: any) {
  return await axios.put(ROLE + '/' + role_id, role)
}
export async function GetRole(link: string) {
  return await axios.get(ROLE + link)
}

export async function SetRole(assignment_id: any, roles: any) {
  return await axios.patch(ASSIGNMENT + '/' + assignment_id + '/roles', roles)
}
//pages
export async function GetPages() {
  return await axios.get(PAGES)
}

export async function SetPages(role_id: any, pages: any) {
  return await axios.patch(ROLE + '/' + role_id + '/pages', pages)
}

export async function GetCheckedPages(role_id: any) {
  return await axios.get(ROLE + '/' + role_id + '/pages')
}

// permissions

export async function GetPermissions(group_name: string) {
  return await axios.get(PERMISSION + '?group_name_tr=' + group_name)
}

export async function GetPermissionGroups() {
  return await axios.get(PERMISSION_GROUPS)
}

export async function SetPermissions(role_id: any, permission: any) {
  return await axios.patch(ROLE + '/' + role_id + '/permissions', permission)
}

export async function GetCheckedPermissions(role_id: any, group_name: string) {
  return await axios.get(ROLE + '/' + role_id + '/permissions?group_name=' + group_name)
}

// password
export async function ChangeUsersPassword(user_id: any, newPassword: string) {
  return await axios.put(USERS + '/' + user_id, newPassword)
}
