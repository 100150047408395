import { FC } from "react"
import { Button } from "react-bootstrap"

type Props={
    text:string,
    clickFunc:any
    me?: string
}

export const ButtonActive: FC<Props> = ({text,clickFunc, me})=>{
return(
    <div className={`d-flex flex-row-reverse ${me}`}>
    <div className='w-15 btn'  onClick={clickFunc}>
      {text}
    </div>
  </div>
)
}