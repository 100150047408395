import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setAuditMachineName: any
  setLastUpdatedDate: any
  setIpAddress: any
  setState: any
  handleClose:any
}

export const DetailFilter: FC<Props> = ({
 setAuditMachineName,
 setLastUpdatedDate,
 setIpAddress,
 setState,
 handleClose
}) => {
  const handleSubmit = async () => {
    setAuditMachineName(selectedAuditMachName)
    setLastUpdatedDate(selectedLastUpdDate)
    setIpAddress(selectedIpAddress)
    setState(selectedState)
    handleClose()
  }
const [selectedAuditMachName, setSelectedAuditMachName] = useState('')
const [selectedLastUpdDate, setSelectedLastUpdDate] = useState('')
const [selectedIpAddress, setSelectedIpAddress] = useState('')
const [selectedState, setSelectedState] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
  
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Audit Machine Name</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedAuditMachName(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>IP Address</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedIpAddress(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Last Updated Date</label>
        <input
          type='date'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedLastUpdDate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>State</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedState(e.target.value)
          }}
        />
      </div>
      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
