import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const VIItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.attendees}</td>
        <td className='text-gray-500'>{item?.start && moment(item?.start).format('DD.MM.YYYY H:mm')}</td>
        <td className='text-gray-500'>{item?.end && moment(item?.end).format('DD.MM.YYYY H:mm')}</td>
        <td className='text-gray-500'>{item?.projectName} GBps</td>
        <td className='text-gray-500'>{item?.title}</td>
        <td className='text-gray-500'>{item?.created_at && moment(item?.created_at).format('DD.MM.YYYY H:mm')}</td>
      </tr>
    </>
  )
}
