import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import swal from 'sweetalert'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {CreateCase, UpdateCase} from '../core/_request'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {setToken} from '../../../components/csrf/reducers/CSRFSlice'
import {UserTags} from '../../../components/UserTags'
import {getAssignments, getCSRFToken} from '../../../core/requests'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'
import {RolesTags} from '../components/RolesTags'

type Props = {
  caseProp: any
  handleClose: any
  update: boolean
}
const caseSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  target_start_date: Yup.date().required('Zorunlu alan'),
  target_end_date: Yup.date(),
  actual_start_date: Yup.date().max(new Date(Date.now()), 'İleri Tarih Girilemez'),
  actual_end_date: Yup.date().max(new Date(Date.now()), 'İleri Tarih Girilemez'),
  subject: Yup.string(),
  detail: Yup.string(),
  notes: Yup.string(),
  contact_firm: Yup.string().required('Zorunlu alan'),
  firm_detail: Yup.string().required('Zorunlu alan'),
  type: Yup.number().required('Zorunlu alan'),
  // poc_environment: Yup.number().required('Zorunlu alan'),
  // poc_result: Yup.number().required('Zorunlu alan'),
  // poc_report: Yup.number().required('Zorunlu alan'),
  status: Yup.string().required('Zorunlu alan'),
  // .required('Zorunlu alan'),
})

const updateCaseSchema = Yup.object().shape({
  actual_start_date: Yup.date().max(new Date(Date.now()), 'İleri Tarih Girilemez'),
  actual_end_date: Yup.date().max(new Date(Date.now()), 'İleri Tarih Girilemez').notRequired().nullable(),
  // target_start_date: Yup.date().min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez'),
  // target_end_date: Yup.date()
  // .when(
  //   'target_start_date',
  //   (target_start_date, yup) =>
  //     target_start_date &&
  //     yup.min(
  //       new Date(target_start_date - 86400000),
  //       'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz'
  //     )
  // ),
  // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez'),
})

export const AddPoC: FC<Props> = ({caseProp, handleClose, update}) => {
  const [users, setUsers] = useState<any>([])
  const [publicBox, setPublicbox] = useState(caseProp?.is_public)
  const {refreshPage} = useRefresh1()
  const [directorates, setDirectorates] = useState<any>([])
  const [assignments, setAssignments] = useState<any>([])
  const [stakeholderDirectorates, setStakeholderDirectorates] = useState<any>([])
  const getUser = useCallback(
    (selectRole) => {
      setUsers([...users, selectRole])
    },
    [users]
  )
  const deleteUser = useCallback(
    (tagIndex) => {
      setUsers(users.filter((_: any, i: any) => i !== tagIndex))
    },
    [users]
  )

  useEffect(() => {
    setUsers(caseProp?.action_users)
    setDirectorates(caseProp?.directorates?.length > 0 ? caseProp?.directorates : [])
    setStakeholderDirectorates(caseProp?.stakeholder_directorates?.length > 0 ? caseProp?.stakeholder_directorates : [])
  }, [])
  useEffect(() => {
    getAssignments('?is_directorate=1').then(({data}) => {
      setAssignments(data.data)
    })
  }, [])

  const [userForEdit] = useState<any>({
    ...caseProp,
    target_start_date: caseProp?.target_start_date,
    target_end_date: caseProp?.target_end_date,
    actual_start_date: caseProp?.actual_start_date,
    actual_end_date: caseProp?.actual_end_date,
    type: caseProp?.type?.id,
    poc_environment: update ? caseProp?.poc_environment?.id : '',
    poc_result: update ? caseProp?.poc_result?.id : '',
    poc_report: update ? caseProp?.poc_report?.id : '',
    status: caseProp?.status?.id,
    notes: caseProp?.notes,
    directorates: caseProp?.directorates?.length > 0 && caseProp?.directorates[0]?.id,
  })

  const getDirectorates = useCallback(
    (selectRole) => {
      setDirectorates([...directorates, selectRole])
    },
    [directorates]
  )
  const deleteDirectorates = useCallback(
    (tagIndex) => {
      setDirectorates(directorates.filter((_: any, i: any) => i !== tagIndex))
    },
    [directorates]
  )

  const getStakeholderDirectorates = useCallback(
    (selectRole) => {
      setStakeholderDirectorates([...stakeholderDirectorates, selectRole])
    },
    [stakeholderDirectorates]
  )
  const deleteStakeholderDirectorates = useCallback(
    (tagIndex) => {
      setStakeholderDirectorates(stakeholderDirectorates.filter((_: any, i: any) => i !== tagIndex))
    },
    [stakeholderDirectorates]
  )

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: update ? updateCaseSchema : caseSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        let selectedUsers = []
        let selectedAssignment = []
        selectedAssignment[0] = values?.directorates
        for (let i = 0; i < users?.length; i++) {
          selectedUsers[i] = users[i].id
        }

        if (update) {
          const UpdCase = {
            title: values?.title,
            target_start_date: values?.target_start_date,
            target_end_date: values?.target_end_date,
            actual_start_date: values?.actual_start_date,
            actual_end_date: values?.actual_end_date,
            directorates: selectedAssignment,
            // directorates: directorates.map((item: {id: any}) => item.id),
            stakeholder_directorates: stakeholderDirectorates.map((item: {id: any}) => item.id),
            subject: values?.subject,
            detail: values?.detail,
            notes: values?.notes,
            contact_firm: values?.contact_firm,
            firm_detail: values?.firm_detail,
            type: Number(values?.type),
            poc_environment: Number(values?.poc_environment),
            poc_result: Number(values?.poc_result),
            poc_report: Number(values?.poc_report),
            status: Number(values?.status),
            users: selectedUsers,
          }
          await UpdateCase(caseProp.id, UpdCase)
            .then(() => {
              swal('Düzenlendi', 'PoC başarı ile düzenlendi.', 'success', {timer: 2000})
              refreshPage()
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values?.title || '')
          formData.append('target_start_date', values?.target_start_date || '')
          formData.append('target_end_date', values?.target_end_date || '')
          formData.append('actual_start_date', values?.actual_start_date || '')
          formData.append('actual_end_date', values?.actual_end_date || '')
          formData.append('subject', values?.subject || '')
          formData.append('detail', values?.detail || '')
          formData.append('notes', values?.notes || '')
          formData.append('contact_firm', values?.contact_firm || '')
          formData.append('firm_detail', values?.firm_detail || '')
          formData.append('type', values?.type)
          formData.append('poc_environment', values?.poc_environment)
          formData.append('poc_result', values?.poc_result)
          formData.append('poc_report', values?.poc_report)
          formData.append('status', values?.status)
          formData.append('directorates[0]', values?.directorates)
          // directorates.forEach((item: {id: string | Blob}, index: any) => formData.append(`directorates[${index}]`, item.id))
          stakeholderDirectorates.forEach((item: {id: string | Blob}, index: any) => formData.append(`stakeholder_directorates[${index}]`, item.id))

          selectedUsers.forEach((user, index) => formData.append(`users[${index}]`, user))

          await CreateCase(formData)
            .then(() => {
              swal('Eklendi', 'PoC başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Temel Konu</label>
            {/* end::Label */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Hedef Başlangıç Tarih</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('target_start_date')}
                type='date'
                name='target_start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.target_start_date && formik.errors.target_start_date},
                  {
                    'is-valid': formik.touched.target_start_date && !formik.errors.target_start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.target_start_date && formik.errors.target_start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.target_start_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-7 mb-2'>Hedef Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('target_end_date')}
                type='date'
                name='target_end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.target_end_date && formik.errors.target_end_date},
                  {
                    'is-valid': formik.touched.target_end_date && !formik.errors.target_end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.target_end_date && formik.errors.target_end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.target_end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Gerçekleşen Başlangıç Tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('actual_start_date')}
                type='date'
                name='actual_start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.actual_start_date && formik.errors.actual_start_date},
                  {
                    'is-valid': formik.touched.actual_start_date && !formik.errors.actual_start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.actual_start_date && formik.errors.actual_start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.actual_start_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-7 mb-2'>Gerçekleşen Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('actual_end_date')}
                type='date'
                name='actual_end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.actual_end_date && formik.errors.actual_end_date},
                  {
                    'is-valid': formik.touched.actual_end_date && !formik.errors.actual_end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.actual_end_date && formik.errors.actual_end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.actual_end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7'>Sorumlusu</label>
            <div className={`form-control form-control-solid mt-1`}>
              <UserTags setRoles={getUser} delRole={deleteUser} tags1={caseProp.action_users} />
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Görüşülen Firma</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('firm_detail')}
              type='text'
              name='firm_detail'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.firm_detail && formik.errors.firm_detail},
                {
                  'is-valid': formik.touched.firm_detail && !formik.errors.firm_detail,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.firm_detail && formik.errors.firm_detail && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firm_detail}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Firma İlgilisi</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('contact_firm')}
              type='text'
              name='contact_firm'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.contact_firm && formik.errors.contact_firm},
                {
                  'is-valid': formik.touched.contact_firm && !formik.errors.contact_firm,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.contact_firm && formik.errors.contact_firm && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contact_firm}</span>
                </div>
              </div>
            )}
          </div>
          {/* 
          <div className='d-flex flex-column mb-8 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-2 justify-content-between'>
              <span className='required'>Müdürlük</span>
            </label>

            <div style={{display: publicBox ? 'none' : 'block'}} className={`form-control form-control-solid  `}>
              <RolesTags key={2} setRoles={getDirectorates} delRole={deleteDirectorates} tags1={caseProp?.directorates?.length > 0 ? caseProp?.directorates : []} />
            </div>
          </div> */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mt-2'>Müdürlük</label>
            <select
              id='directorates'
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Müdürlük Seç'
              {...formik.getFieldProps('directorates')}
              name='directorates'
              // defaultValue={announcement.announcement_category_id}
            >
              <option hidden>Müdürlük Seç</option>

              {assignments.map((assignment: any) => {
                return (
                  <option key={assignment.id} value={assignment.id}>
                    {assignment.alternative_name}
                  </option>
                )
              })}
            </select>

            {formik.touched.assignment_id && formik.errors.assignment_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assignment_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-column mb-8 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-2 justify-content-between'>
              <span className=''>Paydaş Müdürlükler</span>
              {/* <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify a target priorty'
              ></i> */}
              {/* <div>
                <input
                  className='form-check-input h-18px w-18px'
                  type='checkbox'
                  {...formik.getFieldProps('is_public')}
                  name='is_public'
                  value='public'
                  defaultChecked={publicBox}
                  onChange={() => {
                    publicBox == false ? setPublicbox(true) : setPublicbox(false)
                  }}
                />
                <span className='form-check-label fw-bold'> Herkes</span>
              </div> */}
            </label>

            <div style={{display: publicBox ? 'none' : 'block'}} className={`form-control form-control-solid  `}>
              <RolesTags
                key={3}
                setRoles={getStakeholderDirectorates}
                delRole={deleteStakeholderDirectorates}
                tags1={caseProp?.stakeholder_directorates?.length > 0 ? caseProp?.stakeholder_directorates : []}
              />
            </div>
          </div>

          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Detayı</label>
            {/* end::Label */}
            <textarea placeholder='Giriniz' {...formik.getFieldProps('detail')} name='detail' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {formik.touched.detail && formik.errors.detail && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.detail}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Kapsam</label>
            {/* end::Label */}
            <textarea placeholder='Giriniz' {...formik.getFieldProps('subject')} name='subject' rows={3} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {formik.touched.subject && formik.errors.subject && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.subject}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Not/Bilgi</label>
            {/* end::Label */}
            <textarea placeholder='Giriniz' {...formik.getFieldProps('notes')} name='notes' rows={8} cols={70} className='form-control form-control-solid mb-3 mb-lg-0'></textarea>
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-7 mt-2'>PoC Tipi</label>
                <select
                  id='type'
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Durum Seç'
                  {...formik.getFieldProps('type')}
                  name='type'
                  // defaultValue={announcement.announcement_category_id}
                >
                  <option hidden>PoC Tipi Seç</option>
                  <option value='1'>Görüşme</option>
                  <option value='2'>Demo</option>
                  <option value='3'>PoC</option>
                </select>

                {formik.touched.type && formik.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.type}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-7 mt-2'>Durum</label>
                <select
                  id='status'
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Durum Seç'
                  {...formik.getFieldProps('status')}
                  name='status'
                  // defaultValue={announcement.announcement_category_id}
                >
                  <option hidden>Durum Seç</option>
                  <option value='0'>Planlanıyor</option>
                  <option value='1'>Devam Ediyor</option>
                  <option value='2'>Tamamlandı</option>
                  <option value='3'>İptal Edildi</option>
                </select>

                {formik.touched.status && formik.errors.status && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.status}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-7 mt-2'>POC Ortamı</label>
                <select
                  id='poc_environment'
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Durum Seç'
                  {...formik.getFieldProps('poc_environment')}
                  name='poc_environment'
                  // defaultValue={announcement.announcement_category_id}
                >
                  <option hidden>POC Ortamı Seç</option>
                  <option value='1'>TT test - Onprem</option>
                  <option value='2'>TT test - cloud</option>
                  <option value='3'>Firma test - onprem</option>
                  <option value='4'>Firma test - cloud</option>
                  <option value='5'>TT prod - onprem</option>
                  <option value='6'>TT prod - cloud</option>
                </select>

                {formik.touched.poc_environment && formik.errors.poc_environment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.poc_environment}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='fv-row mb-7'>
                <label className=' fw-bold fs-7 mt-2'>PoC Sonuc Yorumu</label>
                <select
                  id='poc_result'
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Durum Seç'
                  {...formik.getFieldProps('poc_result')}
                  name='poc_result'
                  // defaultValue={announcement.announcement_category_id}
                >
                  <option hidden>PoC Sonuc Yorumu</option>
                  <option value='1'>Başarılı</option>
                  <option value='2'>Başarısız</option>
                </select>

                {formik.touched.poc_result && formik.errors.poc_result && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.poc_result}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-7 mt-2'>PoC Rapor Durumu</label>
                <select
                  id='poc_report'
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Durum Seç'
                  {...formik.getFieldProps('poc_report')}
                  name='poc_report'
                  // defaultValue={announcement.announcement_category_id}
                >
                  <option hidden>PoC Rapor Durumu</option>
                  <option value='1'>Yayınlandı</option>
                  <option value='2'>Yayınlanmadı</option>
                </select>

                {formik.touched.poc_report && formik.errors.poc_report && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.poc_report}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              //disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button type='submit' className='btn ' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched || users?.length < 1}>
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
