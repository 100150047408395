import React, {Key, useEffect} from 'react'

import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

import {RoleItem} from './RoleItem'
import {RoleWithSub} from './RoleWithSub'
import { SetPages } from '../core/_requests'

type Props = {
  element: any
  role_id: any
  checkedPages: any
  // path: string
}

const RoleItems: React.FC<Props> = ({children, element, role_id, checkedPages}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      {element.is_last ? (
        <RoleItem key={element.id} element={element} fontIcon='bi-layers' role_id={role_id} checkedPages={checkedPages}/>
      ) : (
        <RoleWithSub
          key={element.id}
          element={element}
          fontIcon='bi-chat-left'
          role_id={role_id}
          checkedPages={checkedPages}
        >
          {element.children.map(
            (e: {
              id: Key | null | undefined
              name: string
              children: any
              path: string
              is_last: boolean
            }) => {
              if (e.is_last) {
                return (
                  <RoleItem key={e.id} element={e}  role_id={role_id} checkedPages={checkedPages}/>
                )
              } else {
                return <RoleItems key={e.id} element={e} role_id={role_id} checkedPages={checkedPages}></RoleItems>
              }
            }
          )}
        </RoleWithSub>
      )}
    </>
  )
}

export {RoleItems}
