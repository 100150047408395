import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_theme/assets/ts/_utils'
import {GetNODStatistics} from '../../../OperationalData/core/request'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  className: string
  chartHeight: number
  type: any
  verticalTitle: string
  horizontalTitle: string
  plusOrMinus: boolean
  pageId?: string
  widgetId: string
  refreshPage?: any
  linkToPage: string
}

export const BarNODChart: FC<Props> = ({className, chartHeight, type, verticalTitle, horizontalTitle, pageId, widgetId, plusOrMinus, linkToPage}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [title, setTitle] = useState<any>([])
  const [closeAverage, setCloseAverage] = useState<any>([])
  const [openAverage, setOpenAverage] = useState<any>([])
  const [pendingAverage, setPendingAverage] = useState<any>([])
  const [grafik, setGrafik] = useState(false)
  const {refreshPage} = useRefresh1()

  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  useEffect(() => {
    GetNODStatistics(type).then((data) => {
      toArray(data.data)
    })
  }, [])
  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, title, closeAverage, openAverage, pendingAverage, verticalTitle, ''))
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [grafik])

  const toArray = (data: any) => {
    let titleTemp = []
    let closeAverageTemp = []
    let openAverageTemp = []
    let pendingAverageTemp = []
    if (widgetId == '50' || widgetId == '53' || widgetId == '56') {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push('W' + data[i]?.week_of_year)
        closeAverageTemp.push(data[i]?.close_average)
        openAverageTemp.push(data[i]?.open_average)
        pendingAverageTemp.push(data[i]?.pending_average)
      }
    } else {
      for (let i = 0; i < data?.length; i++) {
        titleTemp.push('W' + data[i]?.week_of_year)
        closeAverageTemp.push(data[i]?.total_close)
        openAverageTemp.push(data[i]?.total_open)
        pendingAverageTemp.push(data[i]?.total_pending)
      }
    }

    setTitle(titleTemp)
    setCloseAverage(closeAverageTemp)
    setOpenAverage(openAverageTemp)
    setPendingAverage(pendingAverageTemp)
    setGrafik(true)
  }

  return (
    <div className={`card card-flush overflow-hidden ${className}`}>
      {/* begin::Header */}
      <div className='card-header  mt-5 d-inline-flex'>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div>
            <h4 className='card-title align-items-center flex-column text-center w-100 m-1 p-1'>
              <span className='card-label fw-bolder text-dark fs-5'>{horizontalTitle}</span>
            </h4>
          </div>
        </Link>
        <div
          onClick={() => {
            homeWidgetShow()
          }}
        >
          <span
            className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex'
            style={{
              backgroundColor: '#f6f7f8',
              border: '1px solid rgb(1 13 88)',
              color: 'rgb(1 13 88)',
            }}
          >
            {plusOrMinus ? '+' : '-'}
          </span>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
        {/* begin::Statistics */}
        {/* end::Statistics */}
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6 pie-chart1' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptions = (chartHeight: number, title: any, loss: any, correction: any, assured: any, verticalTitle: string, pointTitle: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: pointTitle,
        data: loss,
      },
      {
        name: pointTitle,
        data: correction,
      },
      {
        name: pointTitle,
        data: assured,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 'auto',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    dataLabels: {
      enabled: false,
    },
    fill: {},
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },

    legend: {
      show: true,
      showForSingleSeries: true,
      fontSize: '12px',
      customLegendItems: ['Çözümlenen', 'Açık - Ele Alınmamış', 'Açık - Üzerinde Çalışılan'],
      markers: {
        //  fillColors: ['#00E396', '#775DD0']
      },
    },
    xaxis: {
      categories: title,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,

      labels: {
        rotate: 0,
        rotateAlways: false,
        style: {
          fontSize: '12px',
        },
        offsetY: 2,
      },
      crosshairs: {
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 1,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {text: verticalTitle},
      tickAmount: 5,
      max: Math.max.apply(Math, [Math.max.apply(Math, correction), Math.max.apply(Math, assured), Math.max.apply(Math, loss)]),
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
        // formatter: function (val) {
        //   return '$' + Number(val / 10).toFixed(1) + 'K'
        // },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
      size: 5,
    },
  }
}
