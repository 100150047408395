import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  fileLink: string
  name: string
  size: string
}

export const FileItem: FC<Props> = ({fileLink, name, size}) => {
  const filename = (param: any) => {
    const icon = param.split('.')
    const img = icon[Number(icon?.length) - 1]
    return img
  }
  return (
    <div className='overlay me-10'>
      <div className='overlay-wrapper d-flex justify-content-center text-start flex-column p-5'>
        <a href={fileLink} className='text-gray-800 text-hover-primary d-flex flex-row'>
          <div className='symbol symbol-60px mb-6 mr-6'>
            <img
              src={toAbsoluteUrl(
                `/media/svg/files/${filename(name) !== 'blob' ? filename(name) : 'jpeg'}.svg`
              )}
              alt=''
            />
          </div>
          <div className='pl-10'>
            <div
              style={{
                width: '70px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className='fs-5 fw-bolder mb-1 ml-4'
            >
              {name}
            </div>
            <div className='fs-7 fw-bold text-gray-400 mt-auto'>{size}</div>
          </div>
        </a>
      </div>
    </div>
  )
}
