import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../../components/ModalHeader'
import {NoMatchRecords} from '../../../components/NoMatchRecords'
import {getProjectDocuments} from '../../core/requests'
import {AddDocument} from '../add-edit-modals/addDocument'
import {CardFileItem} from './CardFileItem'

export const Document: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  const [selectedOption, setSelectedOption] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const id = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [documents, setDocuments] = useState<any>([])

  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    getProjectDocuments(id, link).then(async ({data}) => {
      await setDocuments(data.data)
      setLoading(false)
    })
  }, [searchKeyClick, show, refresh])
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2 fs-5'>
          Dosyalarım
          <span className='fs-6 text-gray-400 fw-bold ms-1'>{documents?.length} kaynak</span>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-3'>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px  me-2'
                placeholder='Dosya ara'
                onChange={(e) => {
                  setSearchKey(e.target.value)
                  e.target.value == '' && setSearchKeyClick('')
                }}
              />
              <button
                type='button'
                className='btn btn-primary me-5'
                onClick={() => {
                  setSearchKeyClick(searchKey)
                }}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-search' viewBox='0 0 16 16'>
                  <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                </svg>
              </button>
            </div>
          </div>
        </h3>

        <div className='d-flex flex-wrap my-1'>
          <ul className='nav nav-pills me-5'>
            <button type='button' className='btn ' data-toggle='modal' data-target='#kt_modal_upload' onClick={handleShow} style={{color: 'white'}}>
              <span className='svg-icon svg-icon-2'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' />
                  <path
                    d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z'
                    fill='currentColor'
                  />
                  <path opacity='0.3' d='M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z' fill='currentColor' />
                </svg>
              </span>
              Dosya Yükle
            </button>

            {/*begin : : cardFiles Button */}
            {/* <li className='nav-item m-0'>
              <a
                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${
                  listShow ? 'active' : ''
                } me-3`}
                data-bs-toggle='tab'
                onClick={() => setListshow(true)}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                      <rect
                        x='14'
                        y='5'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                      <rect
                        x='5'
                        y='14'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                      <rect
                        x='14'
                        y='14'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                    </g>
                  </svg>
                </span>
              </a>
            </li> */}
            {/*end : : cardFiles Button */}
            {/*begin : : ListFiles Button */}
            {/* <li className='nav-item m-0'>
              <a
                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${
                  listShow ? '' : 'active'
                }`}
                data-toggle='tab'
                onClick={() => setListshow(false)}
              >
                <span className='svg-icon svg-icon-2'>
                  {/* <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                      fill='currentColor'
                    />
                    <path
                      opacity='0.3'
                      d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                      fill='currentColor'
                    />
                  </svg> */}
            {/* </span>
              </a>
            </li> */}
            {/*end : : ListFiles Button */}
          </ul>

          <div className='my-0'>
            <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setSelectedOption(e.target.value)}>
              {/* <option value='1' selected>
                Kategori Seçiniz
              </option> */}
              <option value='' selected>
                Tümü
              </option>
              {categories.map((cat, index) => {
                return (
                  <option key={index} value={cat.id}>
                    {cat.name}
                  </option>
                )
              })}
            </select>
          </div>
          {/* <div className='my-0'>
            <OverlayTrigger
              key='top'
              placement='top'
              overlay={<Tooltip id='tooltip-top'>Dosya Yöneticisi</Tooltip>}
            >
              <button className='btn btn-primary btn-sm w-150px'>Dosya Yöneticisi</button>
            </OverlayTrigger>
          </div> */}
        </div>
      </div>
      {loading ? (
        <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='row mb-6 mb-xl-9'>
          {documents?.length < 1 && !loading ? (
            <NoMatchRecords isTable={false} />
          ) : (
            documents.map((document: any) => {
              return <CardFileItem icon={'/media/svg/files/pdf.svg'} create_by={document.created_by} name={document.name} date={document.created_at} files={document} categoryName={''} refreshPage={refreshPage} project_id={id} />
            })
          )}

          <div className='col-sm-6 col-md-3 mb-6 d-flex  align-self-stretch' onClick={handleShow}>
            <div className='dropzone d-flex align-items-center' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center text-center'>
                <div className='mx-5'>
                  <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>Dosya Yükle</h3>
                  {/* <span className='fw-bold fs-7 text-gray-400'>
                  Dosyayı bu alanı sürükleyip bırakın
                </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //  ? <CardFiles cat={selectedOption} searchKey={searchKeyClick} refresh={show} doc={documents} />:'' */}

      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddDocument project_id={id} handleClose={handleClose} doc={undefined} />
        </Modal.Body>
      </Modal>

      {/*end : : add new file */}
    </>
  )
}
