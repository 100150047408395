import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setDate: any
  setDstip: any
  setEuid: any
  setEpid: any
  setProto: any
  setScrPort: any
  handleClose: any
}

export const DetailFilters: FC<Props> = ({
  setDate,
  setDstip,
  setEpid,
  setEuid,
  setProto,
  setScrPort,
  handleClose,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setDate(selectedDate)
    setDstip(selectedDstip)
    setEpid(selectedEpid)
    setEuid(selectedEuid)
    setProto(selectedProto)
    setScrPort(selectedScrPort)
    handleClose()
  }
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedDstip, setSelectedDstip] = useState('')
  const [selectedEpid, setSelectedEpid] = useState('')
  const [selectedEuid, setSelectedEuid] = useState('')
  const [selectedProto, setSelectedProto] = useState('')
  const [selectedScrPort, setSelectedScrPort] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Date</label>
        <input
          placeholder=''
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedDate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Dstip</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDstip(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Euid</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedEuid(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Epid</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedEpid(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Proto</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedProto(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>SrcPort</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedScrPort(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
