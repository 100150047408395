import {FC, useEffect, useState} from 'react'
import {UsersListHeader} from './components/userHeader/UsersListHeader'
import {GetPoc} from './core/_request'
import {DirectorateWidget} from '../../components/widgets/DirectorateWidget'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import Pagination from '../../components/Pagination'
import {PocItem} from './components/PocItem'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'

export const Poc: FC = () => {
  const [pocPoc, setPoc] = useState<any>([])
  const {refresh} = useRefresh1()
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [result, setResult] = useState('')
  const [status, setStatus] = useState('')
  const [directorate, setDirectorate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [selectedDirectorate, setSelectedDirectorate] = useState<any>('')

  const getDirectorateId = (id: string) => {
    setDirectorate(id)
  }

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + limit
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (result != '') {
      queryParams += '&poc_result=' + result
    }
    if (status != '') {
      queryParams += '&status=' + status
    }
    if (directorate != '') {
      queryParams += '&directorates=' + directorate
    }
    GetPoc(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setPoc(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    currentTableData()
  }, [currentPage])
  useEffect(() => {
    setLoading(true)
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, result, status, directorate])
  return (
    <>
      <div className='row g-5'>
        <div className='col-xl-4 col-xxl-4'>
          <BarChart widgetId={'95'} plusOrMinus={true} linkToPage={''} horizentalTitle={'PoC Tipine Göre'} />
        </div>
        <div className='col-xl-8 col-xxl-8'>
          <DirectorateWidget
            widgetId={'96'}
            title='Müdürlük Bazlı PoC Çalışmaları'
            plusOrMinus={true}
            linkToPage={''}
            setSelectedDirectorate={setSelectedDirectorate}
            getSelectedId={getDirectorateId}
          />
        </div>
      </div>
      <UsersListHeader setWord={setSearchKeyClick} setResult={setResult} setStatus={setStatus} selectedDirectorate={selectedDirectorate} />

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
                  <tr>
                    <th className=''>Görüşme/Demo/Poc</th>
                    <th className=''>Temel Konu</th>
                    <th className=''>Statü</th>
                    <th className=''>Müdürlük</th>
                    <th className=''>Sorumlusu</th>
                    <th className=''>GÖRÜŞÜLEN FİRMA</th>
                    <th className=''>POC SONUÇ YORUMU</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className=''>
                  {pocPoc?.length < 1 ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    pocPoc.map((poc: any) => {
                      return <PocItem key={poc?.id} poc={poc} />
                    })
                  )}
                </tbody>
              </table>
            )}
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
          </div>
        </div>
      </div>
      {/* </KTCard> */}
    </>
  )
}
