import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_theme/layout/core"
import { CaseDetail } from "./CaseDetail"
import { Cases } from "./Cases"

const dashboardBreadCrumbs: Array<PageLink> = [
    {
      title: 'Anasayfa',
      path: '/ana-sayfa',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
export const CasesPageWrapper : FC=()=>{
    return(
        <Routes>
        <Route element={<Outlet />}>
          <Route
            path='case-listesi'
            element={
              <>
                <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Case Yönetimi' toDescription='/case-yonetimi/case-listesi'>Case Listesi</PageTitle>
                <Cases/>
              </>
            }
            
          />
          <Route
            path='detail'
            element={
              <>
                <PageTitle  breadcrumbs={dashboardBreadCrumbs} description='Case Yönetimi'  toDescription='/case-yonetimi/case-listesi'>Detay </PageTitle>
                <CaseDetail/>
              </>
            }
          />

        <Route
            path='case-yonetimi'
            element={
              <>
                <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Case Yonetimi'  toDescription='/case-yonetimi/case-listesi'>Case Listesi</PageTitle>
                <Cases/>
              </>
            }
          />
        </Route>
        
        
        <Route index element={<Navigate to='/case-yonetimi' />} />
      </Routes>
    )
}