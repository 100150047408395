import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'

type Props = {
  el: any
  path: any
  category_name: string
}

export const BlogItemHeader: FC<Props> = ({el, path, category_name}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const link = path?.length > 0 ? path[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className='row mb-10 mb-lg-18'>
        <div className='col-xl-6'>
          <div className='h-100 d-flex flex-column justify-content-between pe-xl-6 mb-xl-0 mb-10'>
            <div className='overlay mt-2'>
              <img src={link} alt='blog-photo' className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded' style={{height: '400px', width: '100%'}} />
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className='ps-xl-6'>
            <div className='mb-7'>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='text-gray-500 fw-bolder'>{moment(el?.start_date).format('DD.MM.YYYY')}</span>

                <span className='badge badge-light-info text-uppercase fw-bolder my-2'>{category_name}</span>
              </div>

              <Link
                to={loginUser?.permissions.filter((permission) => permission.name == 'Show Blog').length > 0 ? 'detail' : ''}
                state={el}
                className='fw-bolder text-dark mb-3 fs-3qx lh-sm text-hover-primary'
              >
                {el?.title}
              </Link>

              <div
                style={{
                  maxHeight: '130px',
                  height: 'auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                className='fw-bold fs-5 mt-3 text-gray-600'
              >
                {el?.content}
              </div>
            </div>
            <div className='d-flex flex-stack flex-wrap'>
              <div className='d-flex align-items-center pe-2'>
                <div className='symbol symbol-circle symbol-50px me-3' data-bs-toggle='tooltip' data-bs-boundary='window' data-bs-placement='top' title={el?.created_by?.name}>
                  {el.created_by.avatar ? (
                    <div className='symbol symbol-50px symbol-circle'>
                      <img src={el?.created_by?.avatar} alt={el?.created_by?.name} className='w-100' />
                    </div>
                  ) : (
                    <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{el?.created_by?.name?.substring(0, 1)}</div>
                  )}{' '}
                </div>

                <div className='fs-5 fw-bolder'>
                  <a
                    className='text-gray-700 text-hover-primary cursor-pointer '
                    onClick={async () => {
                      setProfilUserId(el.created_by.id)
                      await openProfilModal()
                    }}
                  >
                    {el?.created_by?.name}
                  </a>
                  {/* <span className='text-gray-500' style={{margin:'5px'}}>{el.category.name}</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
