import {FC, useEffect, useState} from 'react'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {KTCard} from '../../../_theme/helpers'
import {ModalHeader} from '../components/ModalHeader'
import Pagination from '../components/Pagination'
import {RealRoleItem} from './components/RealRoleItem'
import {RoleListHeader} from './components/roleHeader/RoleListHeader'
import {GetRole} from './core/_requests'
import {AddRole} from './edit-modal/AddRole'
import {SetPage} from './edit-modal/SetPage'
import {SetPermission} from './edit-modal/SetPermission'

export const RolesPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [roles, setRoles] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + limit

    GetRole(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setRoles(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh])

  return (
    <>
      {/* <KTCard> */}
      <RoleListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} />
      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-top-0 border-bottom-0'>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className=''>Rol</th>
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {roles.map((role) => {
                    return <RealRoleItem key={role?.id} role={role} refreshPage={refreshPage} />
                  })}
                </tbody>
              </table>
            )}
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
          </div>
        </div>
      </div>
      {/* </KTCard> */}
    </>
  )
}
