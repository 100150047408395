import {FC, useEffect, useState} from 'react'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {GetSupplierCategories} from '../../../Categories/core/_request'
import {SearchComponent} from '../../../components/SearchComponent'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

type Props = {
  setAssignment: any
  setStatus: any
  setWord?: any
  selectedDirectorate: string
}

const UsersListHeader: FC<Props> = ({setWord, setAssignment, setStatus, selectedDirectorate}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      {/* <UsersListSearchComponent setWord={setWord}/> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex justify-content-between'>
        <SearchComponent setSearchKeyClick={setWord} placeholder={'Case Ara'} />
        {selectedDirectorate != '' && (
          <span className='badge badge-light badge-lg me-2 px-9 py-4'>{selectedDirectorate}</span>
        )}
        <UsersListToolbar setWord={setWord} setStatus={setStatus} setAssignment={setAssignment} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
