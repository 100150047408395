import {Suspense, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Outlet, useNavigate} from 'react-router-dom'
import {RootState} from '../setup'
import ControlVisiblePages from '../_theme/helpers/ControlVisiblePages'
import {UserModel} from './modules/auth/models/UserModel'
import {I18nProvider} from '../_theme/i18n/i18nProvider'
import {LayoutSplashScreen, LayoutProvider} from '../_theme/layout/core'
import {MasterInit} from '../_theme/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import {RefreshProvider} from './pages/OrganizationalStructure/Context/RefreshAssignment'

const App = () => {
  const navigate = useNavigate()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [allowUnload, setAllowUnload] = useState(false)
  let text = window.location.pathname
  useEffect(() => {
    if (
      text == '/auth' ||
      text == '/ana-sayfa' ||
      text.endsWith('etail') ||
      text.includes('/projeler/detail') ||
      text.endsWith('profile') ||
      user == null ||
      user?.roles?.filter((item) => item?.name == 'Süper Admin')?.length > 0
    ) {
    } else if (text == '/error/*') {
    } else if (!ControlVisiblePages(text, user?.page_permissions)) {
      console.log(text)
      navigate('error/*')
    }
  }, [window.location.pathname, user])

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Tarayıcı sekmesi kapatılırken bir bayrak ekleyerek localStorage'ı temizle
  //     localStorage.setItem('unloadEventFired', 'true')
  //   }

  //   const handleUnload = () => {
  //     // Sayfa yeniden yüklenirken bayrağı kontrol ederek localStorage'ı temizle
  //     if (localStorage.getItem('unloadEventFired')) {
  //       localStorage.clear()
  //     }
  //   }

  //   // Component mount olduğunda event listener'ları ekleyin
  //   window.addEventListener('beforeunload', handleBeforeUnload)
  //   window.addEventListener('unload', handleUnload)

  //   // Component unmount olduğunda event listener'ları kaldırın
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //     window.removeEventListener('unload', handleUnload)
  //   }
  // }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <RefreshProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </RefreshProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}
export {App}
