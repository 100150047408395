import {useFormik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {CreateComment} from '../core/_request'
import swal from 'sweetalert'
import clsx from 'clsx'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {useDispatch} from 'react-redux'
import {getCSRFToken} from '../../core/requests'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  handleClose: any
  caseId: any
}
export const AddComment: FC<Props> = ({handleClose, caseId}) => {
  const [userForEdit] = useState<any>({
    comment: '',
  })
  const commentSchema = Yup.object().shape({
    content: Yup.string().required('Zorunlu alan'),
  })
  const {refreshPage} = useRefresh1()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: commentSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        // if (update) {
        //   const updPurchaseStep = {
        //     budget_id: values.budget_id,
        //     description: values.description,
        //     sgd_status: values.sgd_dtatus,
        //     purchase_status: values.purchase_status
        //   }
        //   console.log(values)
        //   await UpdatePurchaseStep(values.id, updPurchaseStep)
        //     .then(() => {
        //       swal('Düzenlendi', 'Satın Alma Takibi başarı ile düzenlendi.', 'success', {timer: 2000})
        //       handleClose()
        //     })
        //     .catch((err) => {
        //       swal('Hata', err.response.data.message, 'error', {timer: 2000})
        //     })
        // } else {

        await CreateComment(caseId, values)
          .then(() => {
            handleClose()
            swal('Eklendi', 'Not başarı ile eklendi.', 'success', {timer: 2000})
            refreshPage()
          })
          .catch((err) => {
            console.log(err.message)

            swal('Hata!', err.response.data.message, 'error', {timer: 2000})
          })
        // }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
      {/* begin::Scroll */}
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        {/* begin::Input Başlık */}
        <div className='mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-5 required'>Not</label>
          {/* end::Label */}
          <textarea
            placeholder='Not giriniz'
            {...formik.getFieldProps('content')}
            name='content'
            rows={8}
            cols={70}
            className='form-control form-control-solid mb-3 mb-lg-0'
          ></textarea>
          {formik.touched.content && formik.errors.content && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.content}</span>
              </div>
            </div>
          )}
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            //disabled={formik.isSubmitting}
            onClick={handleClose}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn '
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            style={{color: 'white'}}
          >
            <span className='indicator-label'>Ekle</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Lütfen Bekleyin...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
