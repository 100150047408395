import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}
export const TableBody: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.request_number}</td>
        <td className='text-gray-500'>{item?.request_name}</td>
        <td className='text-gray-500'>{item?.security_source}</td>
        <td className='fw-bolder'>{item?.request_owner}</td>
        <td className='text-gray-500'>{item?.new_server_installation}</td>
        <td className='text-gray-500'>{item?.ui_development}</td>
        <td className='fw-bolder'>{item?.new_integration}</td>
        <td className='text-gray-500'>{item?.source_code_development}</td>
        <td className='text-gray-500'>{item?.web_development}</td>
        <td className='fw-bolder'>{item?.network_topology}</td>
        <td className='text-gray-500'>{item?.portfolio_manager}</td>
        <td className='text-gray-500'>{item?.solution_manager}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
