import moment from 'moment'
import {FC} from 'react'
import {useLocation} from 'react-router-dom'
import {ID} from '../../../../_theme/helpers'
import {ChatInner} from './components/ChatInner'

type LocationState = {
  id: ID
  title: string
  content: string
  start_date: string
  end_date: string
  phone_notification: boolean
  email_notification: boolean
  issue_category_id: string
  assigned_by: {
    id: ID
    name: string
    ail: string
  }
  comments: {
    id: ID
    commentable_type: string
    commentable_id: ID
    content: string
    created_at: string
    created_by: {
      id: ID
      name: string
      email: string
    }
  }
}

export const TargetDetail: FC = () => {
  const location = useLocation()
  let data = location.state as LocationState
  return (
    <>
      <div>
        <div className=''>
          <div className='app-main flex-column flex-row-fluid'>
            <div className='d-flex flex-column flex-column-fluid'>
              <div className='app-content flex-column-fluid'>
                <div className=''>
                  <div className='d-flex flex-column flex-xl-row'>
                    <div className='card w-100 bg-body me-9 '>
                      <div className='card-body '>
                        <div className='mb-13 '>
                          <div className='mb-9 d-flex flex-row justify-content-between'>
                            <div>
                              <h3 className='fs-2qx fw-bolder text-dark'>{data?.title}</h3>
                              <span className='fs-5 fw-bold text-gray-400'>
                                Başlangıç Tarihi: {moment(data?.start_date).format('DD.MM.YYYY')} /
                                Bitiş Tarihi : {moment(data?.end_date).format('DD.MM.YYYY')}
                              </span>
                            </div>
                            {/* <User name={data.} email={data.created_by.email} /> */}
                          </div>
                          <div className='fs-5 fw-bold text-gray-600 mt-4'>
                            <p className='mb-8'>{data?.content}</p>
                          </div>
                        </div>
                        <div>
                          <h1>Yorumlar</h1>
                        </div>
                        <ChatInner data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
