import moment from 'moment'
import React, {FC} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  announcement: any
}

export const CardItem: FC<Props> = ({announcement}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const link = announcement.files?.length ? announcement.files[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')

  return (
    <div className='col-md-4'>
      <div className='card-xl-stretch me-md-6 '>
        <Link
          className='d-block overlay'
          data-fslightbox='lightbox-hot-sales'
          to={loginUser?.permissions.filter((permission) => permission.name == 'Show Announcement').length > 0 ? '/duyurular/detail' : ''}
          state={announcement}
        >
          <div
            className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover  min-h-200px border border-secondary rounded'
            style={{
              backgroundImage: `url(${link}`,
              backgroundPosition: '100% 100%',
              backgroundSize: '100% 100%',
              height: '188px',
            }}
          ></div>

          {/* <div className='overlay-layer card-rounded bg-dark bg-opacity-25'>
            <i className='bi bi-eye-fill fs-2x text-white'></i>
          </div> */}
        </Link>

        <div className='mt-5'>
          <Link
            to={loginUser?.permissions.filter((permission) => permission.name == 'Show Announcement').length > 0 ? '/duyurular/detail' : ''}
            state={announcement}
            className='fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base'
          >
            {announcement?.title}
          </Link>

          <div
            style={{
              maxHeight: '95px',
              height: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className='fw-bold fs-5 text-gray-600 my-3'
          >
            {announcement?.content != null ? announcement?.content?.slice(0, 240) : ''}
            {announcement?.content != null && announcement?.content?.length > 240 ? '...' : ''}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='fs-6 fw-bolder'>
              <span className='text-gray-500'>
                {moment(announcement?.start_date).format('DD.MM.YYYY')} / {moment(announcement?.end_date).format('DD.MM.YYYY')}
              </span>
            </div>

            <span className='badge badge-light-primary fw-bolder my-2'>{announcement?.category?.name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
