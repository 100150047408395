import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import swal from 'sweetalert'
import {CreateAssignment, GetAssignments, UpdateAssignment} from '../core/_requests'
import * as Yup from 'yup'
import {useRefresh1} from '../Context/RefreshAssignment'
import {useDispatch} from 'react-redux'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'
import {getCSRFToken} from '../../core/requests'

type Props = {
  update: boolean
  handleClose?: any
  assignment: any
}
export const AddAssignment: FC<Props> = ({update, handleClose, assignment}) => {
  let formData = new FormData()
  const [assignments, setAssignments] = useState<any>([])
  const [directorateBox, setDirectorateBox] = useState(assignment?.is_directorate)
  const [groupDirectorateBox, setGroupDirectorateBox] = useState(assignment?.is_group_directorate)
  const {refreshPage} = useRefresh1()
  useEffect(() => {
    GetAssignments().then(({data}) => {
      setAssignments(data.data)
    })
  }, [])

  const [userForEdit] = useState<any>({
    ...assignment,
    id: assignment?.id,
    name: assignment?.name,
    parent_id: assignment?.parent_id == null ? '' : assignment?.parent_id,
    order: assignment?.order,
    alternative_name: assignment?.alternative_name,
    is_group_directorate: assignment?.is_group_directorate,
    is_directorate: assignment?.is_directorate,
  })
  const assignmentSchema = Yup.object().shape({
    name: Yup.string().required('Zorunlu alan'),
    // parent_id: Yup.string().required('Zorunlu alan'),
    order: Yup.number().required('Zorunlu alan'),
  })
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: assignmentSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          const updAssignment = {
            name: values.name,
            parent_id: values.parent_id,
            order: values.order,
            alternative_name: values.alternative_name,
            is_group_directorate: groupDirectorateBox,
            is_directorate: directorateBox,
          }
          await UpdateAssignment(assignment.id, updAssignment)
            .then(() => {
              handleClose()
              refreshPage()
              swal('Düzenlendi', 'Görev başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('name', values.name)
          formData.append('parent_id', values.parent_id)
          formData.append('order', values.order)
          formData.append('alternative_name', values.alternative_name)
          formData.append('is_group_directorate', groupDirectorateBox ? '1' : '0')
          formData.append('is_directorate', directorateBox ? '1' : '0')
          await CreateAssignment(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Görev başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              console.log(err.message)
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <form id='kt_modal_add_assignment_info_form' onSubmit={formik.handleSubmit} className='form' noValidate>
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_info_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_info_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_info_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Görev Adı</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Grup Adı</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('alternative_name')}
            type='text'
            name='alternative_name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.alternative_name && formik.errors.alternative_name},
              {
                'is-valid': formik.touched.alternative_name && !formik.errors.alternative_name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.alternative_name && formik.errors.alternative_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.alternative_name}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          <div>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Parent</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': !formik.touched.parent_id && formik.errors.parent_id},
                {
                  'is-valid': formik.touched.parent_id && !formik.errors.parent_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Parent Seç'
              {...formik.getFieldProps('parent_id')}
              name='parent_id'
              // defaultValue={user.assignment!==null ? user.assignment.id :''}
            >
              <option hidden>Parent</option>
              <option value={''}>Ana Görev</option>
              {assignments.map((assign: any) => {
                return (
                  <option key={assign.id} value={assign.id}>
                    {assign.name}
                  </option>
                )
              })}
            </select>
            {!formik.touched.parent_id && formik.errors.parent_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.parent_id}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Sıra Numarası</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('order')}
            type='text'
            name='order'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.order && formik.errors.order},
              {
                'is-valid': formik.touched.order && !formik.errors.order,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.order && formik.errors.order && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.order}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>

        <div className='d-flex align-items-center'>
          <label className='form-check form-check-custom form-check-solid me-10'>
            <input
              className='form-check-input h-20px w-20px'
              type='checkbox'
              // {...formik.getFieldProps('is_directorate')}
              name='is_directorate'
              value='is_directorate'
              defaultChecked={directorateBox}
              onChange={() => (directorateBox ? setDirectorateBox(false) : setDirectorateBox(true))}
            />
            <span className='form-check-label fw-bold'>Müdürlük</span>
          </label>

          <label className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input h-20px w-20px'
              type='checkbox'
              // {...formik.getFieldProps(' is_group_directorate')}
              name='is_group_directorate'
              value=' is_group_directorate'
              defaultChecked={groupDirectorateBox}
              onChange={() => (groupDirectorateBox ? setGroupDirectorateBox(false) : setGroupDirectorateBox(true))}
            />

            <span className='form-check-label fw-bold'>Grup Müdürlüğü</span>
          </label>
        </div>
        <div className='text-center pt-15'>
          <button type='reset' className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting} onClick={handleClose}>
            İptal
          </button>

          <button type='submit' className='btn' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
