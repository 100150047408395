import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_theme/assets/ts/_utils'
import NumberFormat from 'react-number-format'
import {Modal} from 'react-bootstrap'
import {ListMonthlyBudgets} from '../../Budgets/components/ListMonthlyBudgets'
import {GetMonthlyTotalCapex, GetMonthlyTotalOpex, updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {isObject} from 'formik'
import {isArray} from 'react-select/dist/declarations/src/utils'
import {Link} from 'react-router-dom'
import {ModalHeader} from '../ModalHeader'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  widgetId: any
  color: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
  pageId?: string
}

export const OpexCapexWidget: FC<Props> = ({widgetId, color, plusOrMinus, linkToPage, pageId}) => {
  const {refreshPage} = useRefresh1()
  const [totalWidgetBudget, setTotalWidgetBudget] = useState()
  const [widgetMonth, setWidgetMonth] = useState('')
  const [opexBudgetData, setOpexBudgetData] = useState<any>(JSON.parse(localStorage.getItem('total_opex') || '{}'))
  const [capexBudgetData, setCapexBudgetData] = useState<any>(JSON.parse(localStorage.getItem('total_capex') || '{}'))
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (widgetId == 16) {
      GetMonthlyTotalOpex().then(({data}) => {
        localStorage.setItem('total_opex', JSON.stringify(data))
        setOpexBudgetData(data)
        setTotalWidgetBudget(data[data?.length - 1].total_budget)
        setWidgetMonth(data[data?.length - 1].month)
      })
    } else if (widgetId == 17) {
      GetMonthlyTotalCapex().then(({data}) => {
        setCapexBudgetData(data)
        localStorage.setItem('total_capex', JSON.stringify(data))
        setTotalWidgetBudget(data[data?.length - 1].total_budget)
        setWidgetMonth(data[data?.length - 1].month)
      })
    }
    getServerData()
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(250, color, opexBudgetData, capexBudgetData, widgetId))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [widgetId])

  const getServerData = () => {
    setOpexBudgetData(JSON.parse(localStorage.getItem('total_opex') || '[]'))
    setCapexBudgetData(JSON.parse(localStorage.getItem('total_capex') || '[]'))
    // setServerData2(JSON.parse(localStorage.getItem('total_capex') || '{}'))
  }
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  return (
    <>
      <div className={`card card-flush  `} style={{backgroundColor: '#f6f7f8', height: '427px'}}>
        {/* begin::Header */}
        <div className='card-header pt-5'>
          {/* begin::Title */}
          <div className='card-title w-100 d-flex align-items-start  justify-content-between'>
            {/* begin::Info */}
            {/* <div className='d-flex align-items-center  justify-content-between'> */}
            {/* <span className='fs-4 fw-bold text-gray-400 me-1 align-self-start'>$</span>
    {/* end::Currency */}

            {/* begin::Amount */}
            {/* <span className='fs-2hx fw-bolder text-dark me-2 lh-1'>47,07</span> */}
            {/* end::Amount */}

            {/* begin::Badge */}
            {/* <span
      className='badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center'
      style={{height: '22px'}}
    >
      <KTSVG
        path='/media/icons/duotune/arrows/arr067.svg'
        className='svg-icon-7 svg-icon-white ms-n1'
      />
      2.2%
    </span> */}
            {/* end::Badge */}
            {/* </div> */}
            {/* end::Info */}

            {/* begin::Subtitle */}
            <Link to={plusOrMinus ? '' : linkToPage} style={{color: 'black'}}>
              <div className='d-flex flex-column'>
                <div className='fs-2hx w-100 fw-bolder d-flex justify-content-between align-items-center'>
                  <div>
                    {' '}
                    <NumberFormat value={totalWidgetBudget} displayType={'text'} thousandSeparator={true} />₺
                  </div>
                </div>

                <span className='fs-4 fw-bold text-gray-400 mb-2 text-center'>
                  {widgetMonth} Ayı {widgetId == 16 ? 'Opex' : 'Capex'} Kullanımı
                  <span
                    className='ms-1'
                    data-bs-toggle='tooltip'
                    data-bs-placement='right'
                    // title=''
                    data-bs-original-title='Recent Stats'
                  >
                    {/* <KTSVG
      path='/media/icons/duotune/general/gen044.svg'
      className='svg-icon-2 svg-icon-primary'
    /> */}
                  </span>
                </span>
              </div>
            </Link>
            {/* end::Subtitle */}
            <div>
              <span
                className='btn btn-icon btn-light btn-active-color-primary  me-2  d-inline-flex '
                style={{
                  backgroundColor: '#f6f7f8',
                  border: '1px solid rgb(1 13 88)',
                  color: 'rgb(1 13 88)',
                }}
                onClick={() => {
                  homeWidgetShow()
                }}
              >
                {plusOrMinus ? '+' : '-'}
              </span>
              <br />
              <span style={{cursor: 'pointer'}} className=' text-gray-600  mt-1 me-n3  d-inline-flex align-item-end' onClick={handleShow}>
                Detay
              </span>
            </div>
          </div>
          {/* end::Title */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='card-body d-flex align-items-end pb-6 ps-4 pe-6'>
            {/* begin::Chart */}
            <div ref={chartRef} className='w-100 min-h-auto' style={{height: '250px'}}></div>
            {/* end::Chart */}
          </div>
        </Link>
        {/* end::Body */}
      </div>
      <Modal show={show} size='xl'>
        <Modal.Body>
          <ListMonthlyBudgets monthName={widgetMonth} opexOrCapex={widgetId == 16 ? '2' : '1'} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}

const chartOptions = (chartHeight: number, color: string, serverData1: any, serverData2: any, widgetId: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-400')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = color
  const secondaryColor = getCSSVariableValue('--bs-primary')
  let categories: any[] = []
  let structuredData: any[] = []
  let counter = 1
  if (widgetId == 16) {
    serverData1 = serverData1?.length > 0 ? [...serverData1]?.reverse() : []
    serverData1?.map((data: any) => {
      if (counter < 5) {
        categories.push(data.month)
        structuredData.push(data.total_budget)
      }
      counter++
    })
  } else {
    serverData2 = serverData2?.length > 0 ? [...serverData2]?.reverse() : []
    serverData2?.map((data: any) => {
      if (counter < 5) {
        categories.push(data.month)
        structuredData.push(data.total_budget)
      }
      counter++
    })
  }

  return {
    series: [
      {
        name: widgetId == 16 ? 'Opex' : 'Capex',
        data: structuredData ? structuredData?.reverse() : [],
      },
      // {
      //   name: 'Revenue',
      //   data: [12, 16, 16, 21, 21, 18, 18],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: categories ? categories?.reverse() : [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (num: any) {
          if (num > 999 && num < 1000000) {
            return num / 1000 + 'K' // convert to K for number from > 1000 < 1 million
          } else if (num >= 1000000) {
            return num / 1000000 + 'M' // convert to M for number from > 1 million
          } else if (num < 900) {
            return num // if value < 1000, nothing to do
          }
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toLocaleString() + ' ₺'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: 'rgb(1 13 88)',
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [baseColor, secondaryColor],
      strokeColors: [getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-warning')],
      strokeWidth: 3,
    },
  }
}
