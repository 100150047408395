import moment from "moment"
import { FC } from "react"
type Props={
   item: any
}
export const CUItem : FC<Props>=({item})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {moment(item?.datetime).format('DD.MM.YYYY  H:mm')}
        </td>
        <td className='text-gray-500'>{item?.datetime_str}</td>
        <td className='text-gray-500'>{item?.coverage}</td>
        <td className='text-gray-500'>{item?.coverage_rate}</td>
        <td className='text-gray-500'>{item?.device_id}</td>  
        <td className='text-gray-500'>{item?.cron_server_ip}</td>  
      </tr>
       </>
    )
}     