import moment from "moment"
import { FC } from "react"
type Props={
    vnList: any
}
export const VNItem : FC<Props>=({vnList})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {vnList?.incident_id}
        </td>
        <td className='text-gray-500'>{vnList?.technology}</td>
        <td className='text-gray-500'>{vnList?.service}</td>
        <td className='text-gray-500'>{vnList?.category} GBps</td>
        <td className='text-gray-500'>{vnList?.subcategory}</td>
        <td className='text-gray-500'>{vnList?.opened_by}</td>     
        <td className='text-gray-500'>{vnList?.status}</td>
        <td className='text-gray-500'>{vnList?.parent_assignment_name}</td>     
        <td className='text-gray-500'>{vnList?.assignment_name}</td>
        <td className='text-gray-500'>{vnList?.impact}</td>     
        <td className='text-gray-500'>{vnList?.assigned_user_id}</td>
        <td className='text-gray-500'>{vnList?.assigned_user_name}</td>     
        <td className='text-gray-500'>{vnList?.title}</td>
        <td className='text-gray-500'>{moment(vnList?.opened_at).format('DD.MM.YYYY H:mm')}</td>  
        <td className='text-gray-500'>{vnList?.resolved_at}</td>  
        <td className='text-gray-500'>{moment(vnList?.opened_at).format('YYYY')}</td>  
        <td className='text-gray-500'>{moment(vnList?.opened_at).format('MM')}</td>  
        <td className='text-gray-500'>{moment(vnList?.opened_at).format('YYYY-MM')}</td>  
        <td className='text-gray-500'>{vnList?.resolved_by}</td>
        <td className='text-gray-500'>{vnList?.resolved_assignment_name}</td>     
        <td className='text-gray-500'>{vnList?.close_code}</td>   
        <td className='text-gray-500'>{vnList?.close_description}</td>   
      </tr>
       </>
    )
}
