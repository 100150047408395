import {FC, useEffect, useState} from 'react'
import {KTCard} from '../../../_theme/helpers'
import {AssignListHeader} from './components/assignHeader/AssignListHeader'
import {AssignmentItems} from './components/AssignmentItems'
import {RefreshProvider, useRefresh1} from './Context/RefreshAssignment'

import {GetAssignments, GetTopAssignment} from './core/_requests'

export const AssignmentsPage: FC = () => {
  const [assigments, setAssignments] = useState<any>([])
  const {refresh} = useRefresh1()
  const [searchKeyClick, setSearchKeyClick] = useState('')

  useEffect(() => {
    GetTopAssignment().then(({data}) => {
      setAssignments(data.data)
    })
  }, [refresh])
  return (
    // <KTCard>
    <>
      <AssignListHeader setWord={setSearchKeyClick} />
      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-top-0 border-bottom-0'>
          <div className='card-body pt-3'>
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              {/* <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className=''>Görev</th>
                  </tr>
                </thead> */}
              <tbody className='w-100'>
                {assigments.map((assigment: any) => {
                  return <AssignmentItems key={assigment.id} element={assigment} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
    // {/* </KTCard> */}
  )
}
