import {FC} from 'react'
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_theme/layout/core'
import {CarbonBlackStatistics} from './CarbonBlackStatistics'
import {SymantecStatistics} from './SymenticStatistics'
import {TrendmicroStatistics} from './TrendmicroStatistics'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const EPDataPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='carbon-black-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Uç Nokta Koruma Güvenlik Verileri' toDescription='/uc-nokta-koruma-guvenlik-verileri/carbon-black-istatistikleri'>
                Carbon Black İstatistikleri
              </PageTitle>
              <CarbonBlackStatistics />
            </>
          }
        />
        <Route
          path='symantec-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Uç Nokta Koruma Güvenlik Verileri' toDescription='/uc-nokta-koruma-guvenlik-verileri/carbon-black-istatistikleri'>
                Symantec İstatistikleri
              </PageTitle>
              <SymantecStatistics />
            </>
          }
        />
        <Route
          path='trendmicro-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Uç Nokta Koruma Güvenlik Verileri' toDescription='/uc-nokta-koruma-guvenlik-verileri/carbon-black-istatistikleri'>
                Trendmicro İstatistikleri
              </PageTitle>
              <TrendmicroStatistics />
            </>
          }
        />

        <Route index element={<Navigate to='/uc-nokta-koruma-guvenlik-verileri/carbon-black-istatistikleri' />} />
      </Route>
    </Routes>
  )
}
