import {IntlError} from '@formatjs/intl'
import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {GetReportHeader} from '../../Inventory/core/_requests'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {GetCheckStatistic} from '../core/request'

type Props = {
  api: string
}
const HeaderInfo: FC<Props> = ({api}) => {
  const [createdAt, setCreatedAt] = useState('')
  const [integratedService, setIntegratedService] = useState('')
  const {refresh} = useRefresh1()
  useEffect(() => {
    if (api == '') {
      GetReportHeader().then(({data}) => {
        setCreatedAt(data?.data?.created_at)
        setIntegratedService(data?.data?.integrated_service)
      })
    } else {
      GetCheckStatistic(api).then(({data}) => {
        setCreatedAt(data?.data?.created_at)
        setIntegratedService(data?.data?.integrated_service)
      })
    }
  }, [refresh])
  return (
    <div>
      {createdAt != null && (
        <div className='fs-6'>
          <span className='fw-bolder fs-4'>En Son Güncellenme Tarihi: </span>
          {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
        </div>
      )}

      {integratedService != null && (
        <div className='fs-6'>
          <span className='fw-bolder fs-4'>Veri Kaynağı:</span> <span className='text-uppercase'>{integratedService}</span>
        </div>
      )}
    </div>
  )
}

export default HeaderInfo
