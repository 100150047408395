import React, {FC} from 'react'
import {IssuesLists} from './components/TargetLists'

export const Issues = () => {
  let data = JSON.parse(localStorage.getItem('projectID') || '{}')
  return (
    <>
      <IssuesLists project_id={data} />
    </>
  )
}
