import {FC, useState} from 'react'
import swal from 'sweetalert'
import {Dropdown, Modal} from 'react-bootstrap'
import {Link, NavLink} from 'react-router-dom'
import {DeleteCase} from '../core/_request'
import {AddPoC} from '../edit-modal/AddPoc'
import {ModalHeader} from '../../../components/ModalHeader'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  poc: any
}

const deleteCase = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteCase(id).then(() => {
        swal('Silindi!', 'Poc bilgileri başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const PocItem: FC<Props> = ({poc}) => {
  const [showModal, setShowmodal] = useState(false)
  const {refreshPage} = useRefresh1()
  const handleClose = () => {
    setShowmodal(false)
  }
  const handleShow = () => {
    setShowmodal(true)
  }

  return (
    <>
      <tr>
        <Link to={'detail'} state={poc} className='text-gray-900 text-hover-primary cursor-pointer'>
          <td className='text-gray-900'>{poc?.type?.name}</td>
        </Link>

        <td className='text-gray-500'>
          {poc?.title?.slice(0, 50)}
          {poc?.title?.length > 50 ? '...' : ''}
        </td>
        <td className='text-gray-500'>
          {' '}
          <span
            data-kt-menu-trigger='click'
            className={`badge badge-${
              poc?.status?.id == '1' ? 'light-success' : poc?.status?.id == '0' ? 'light-primary' : poc?.status?.id == '2' ? 'light-info' : poc?.status?.id == '3' ? 'light-warning' : 'light-danger'
            } fw-bolder me-auto`}
          >
            {poc?.status?.name}
          </span>
        </td>
        <td className='text-gray-500'>{poc?.directorates[0]?.alternative_name}</td>
        <td className='text-gray-500'>{poc?.action_users[0]?.name}</td>
        <td className='text-gray-500'>{poc?.firm_detail}</td>
        <td className='text-gray-500'>{poc?.poc_result?.name}</td>
        <td className='text-end' data-kt-filemanager-table=''>
          <Dropdown>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {' '}
                <NavLink to='detail' state={poc} className='menu-link px-3 text-black'>
                  Görüntüle
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleShow}>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  deleteCase(poc.id, refreshPage)
                }}
              >
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={showModal} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddPoC caseProp={poc} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>
    </>
  )
}
