import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {KTSVG} from '../../../../../_theme/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../../components/ModalHeader'
import {AddSupp} from '../../supplier-edit-modal/AddSupp'
import {UsersListFilter} from './UsersListFilter'
type Props = {
  refreshPage: any
}
const UsersListToolbar: FC<Props> = ({refreshPage}) => {
  const [show, setShow] = useState(false)
  const openAddSupModal = () => {
    setShow(true)
  }
  const closeAddSupModal = () => {
    setShow(false)
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {loginUser?.permissions.filter((permission) => permission.name == 'Create Supplier').length > 0 && (
          <button type='button' className='btn ' onClick={openAddSupModal}>
            Tedarikçi Ekle
          </button>
        )}
        {/* end::Add user */}
      </div>
      <Modal show={show} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddSupModal} titleHeader={'Yeni Tedarikçi Ekle'} />
          <AddSupp
            supplier={{
              id: 1,
              name: '',
              email: '',
              company_name: '',
              phone: '',
              tax_number: '',
              country_id: '',
              supplier_category_id: '',
              address: '',
              attachments: [],
              phone_notification: false,
              email_notification: false,
            }}
            refreshPage={refreshPage}
            handleClose={closeAddSupModal}
            update={false}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {UsersListToolbar}
