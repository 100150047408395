import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {AssetInventoryItem} from './components/AssetInventoryItem'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'

export const AssetInventory: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [items, setItems] = useState<any>([])
  const [groupAssets, setGroupAssets] = useState<any>([])
  const [directorateAssets, setDirectorateAssets] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setCriticalLevel('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [criticalLevel, setCriticalLevel] = useState('')
  const [refStatus, setRefStatus] = useState('')
  const [refStatus2, setRefStatus2] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [currentpage2, setCurrentPage2] = useState(1)
  const [total2, setTotal2] = useState(1)
  const [loading2, setLoading2] = useState(false)
  const [currentpage3, setCurrentPage3] = useState(1)
  const [total3, setTotal3] = useState(1)
  const [loading3, setLoading3] = useState(false)
  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (criticalLevel != '') {
      queryParams += '&criticality_level=' + criticalLevel
    }
    GetReport('/optimate_solution_asset_inventory_status', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  const currentTableData2 = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (refStatus != '') {
      queryParams += '&ref_status=' + refStatus
    }
    GetReport('/optimate_solution_group_asset_inventory_status', queryParams).then(({data}) => {
      setTotal2(data?.meta?.total)
      setGroupAssets(data?.data)
      setLoading2(false)
    })
  }

  const currentTableData3 = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (refStatus2 != '') {
      queryParams += '&ref_status=' + refStatus2
    }
    GetReport('/optimate_solution_directorate_asset_inventory_status', queryParams).then(({data}) => {
      setTotal3(data?.meta?.total)
      setDirectorateAssets(data?.data)
      setLoading3(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, criticalLevel])
  useEffect(() => {
    currentTableData2()
    setLoading2(true)
  }, [currentpage2])
  useEffect(() => {
    setCurrentPage2(1)
    currentTableData2()
  }, [refStatus])

  useEffect(() => {
    currentTableData3()
    setLoading3(true)
  }, [currentpage3])
  useEffect(() => {
    setCurrentPage3(1)
    currentTableData3()
  }, [refStatus2])
  const exportFile = () => {
    window.open(`${ExportLink}/optimate_solution_asset_inventory_status?token=${loginUser.token}&criticality_level=${criticalLevel}`)
  }
  const exportFile2 = () => {
    window.open(`${ExportLink}/optimate_solution_group_asset_inventory_status?token=${loginUser.token}&ref_status=${refStatus}`)
  }
  const exportFile3 = () => {
    window.open(`${ExportLink}/optimate_solution_directorate_asset_inventory_status?token=${loginUser.token}&ref_status=${refStatus2}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Kritiklik Seviyesi'} link={''} widgetId={'83'} plusOrMinus={true} linkToPage={''} setSelectedValue={setCriticalLevel} />
          </div>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Durum Dağılımı'} link={''} widgetId={'84'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRefStatus} />
          </div>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Durum Dağılımı'} link={''} widgetId={'86'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRefStatus2} />
          </div>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Direktörlük Bazında Aksiyonlar'} widgetId={'85'} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_asset_inventory_status_check'} />

                <span className='fs-2 fw-bolder mt-3'>Varlık Ana Grubu Bazında Dağılımı</span>
                <div>
                  {/* <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button> */}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>ASSET REGULAR CATEGORY</th>
                      <th className=''>CRITICALITY LEVEL</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <AssetInventoryItem key={item?.id} item={item} tableId={1} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0 mt-5 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_group_asset_inventory_status_check'} />
                <span className='fs-2 fw-bolder mt-3'>Varlık Grup Anketi Durum Dağılımı</span>
                <div>
                  {/* <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button> */}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile2}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading2 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>REF_STATUS</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {groupAssets?.length < 1 && !loading2 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      groupAssets?.map((item: any) => {
                        return (
                          <>
                            <AssetInventoryItem key={item?.id} item={item} tableId={2} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage2(page)} totalCount={total2} currentPage={currentpage2} pageSize={8} loading={loading2} />
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0 mt-5 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_directorate_asset_inventory_status_check'} />
                <span className='fs-2 fw-bolder mt-3'>Direktörlük Bazında Aksiyonlar</span>
                <div>
                  {/* <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button> */}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile3}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading3 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>DATETIME_CREATED</th>
                      <th className=''>CODE</th>
                      <th className=''>DIRECTORATE</th>
                      <th className=''>ORGANISATIONAL_UNIT</th>
                      <th className=''>REF_STATUS</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {directorateAssets?.length < 1 && !loading3 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      directorateAssets?.map((item: any) => {
                        return (
                          <>
                            <AssetInventoryItem key={item?.id} item={item} tableId={3} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage3(page)} totalCount={total3} currentPage={currentpage3} pageSize={8} loading={loading3} />
            </div>
          </div>
        </div>
      </KTCard>
      {/* 
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setRequester={setRequester} setOpeningTime={setOpening_time} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal> */}
    </>
  )
}
