import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../../../_theme/layout/core'
import { GetCheckedPages, SetPages } from '../core/_requests'


type Props = {
  fontIcon?: string
  element: any
  role_id: any
  checkedPages: any
}

const RoleItem: React.FC<Props> = ({
  fontIcon,
  element,
  role_id,
  checkedPages,
}) => {

  // const {pathname} = useLocation()
  const {config} = useLayout()
  // const {aside} = config
  const [initialValue, setInitialValue] = useState(false)
  const [checkPages, setCheckPages]=useState(checkedPages)

  const handleChange=(e:any, value:boolean)=>{
   
     let pages={
        pages:[
          {
            id:e.target.name,
            value: value
          }
        ]
      }
    SetPages(role_id,pages).then(async ()=>{
     
      console.log(role_id, pages);
      console.log("Sayfa başarılı eklendi")
      await GetCheckedPages(role_id).then(({data})=>{
        setCheckPages(data.pages)
      })
    }
   
    )
  }
  return (
    <div className='menu-item'>
      <span
        className={clsx('menu-link without-sub')}
      >
      
        <label className='form-check form-check-custom form-check-solid me-10 mt-3'>
          <input
            className='form-check-input h-20px w-20px'
            type='checkbox'
            name={element.id}
            // value={}
            defaultChecked={checkPages.some((x:any)=>x==element.id)}
            onChange={(e) =>  {
              handleChange(e,!(checkPages.some((x:any)=>x==element.id)) )
              // initialValue ? setInitialValue(false) : setInitialValue(true)
            }}
          />
          <span className='form-check-label fw-bold'>{element.name}</span>
        </label>
        {/* 
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span> */}
      </span>
      {/* {children} */}
    </div>
  )
}

export {RoleItem}
