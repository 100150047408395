import {FC} from 'react'
import {OpexCapexWidget} from '../components/widgets/OpexCapexWidget'
import {ExchangeRates} from '../components/widgets/ExchangeRates'
import {BudgetByCategory} from '../components/widgets/BudgetByCategory'
import {PieWidget} from '../components/widgets/PieWidget'

export const Homepage: FC = () => {
  // useEffect(() => {
  //   GetMonthlyTotalOpex().then(({data})=>{
  //     localStorage.setItem('total_opex', JSON.stringify(data))
  //     setTotalOpexBudget(data[data?.length-1].total_budget)
  //     setOpexMonth(data[data?.length-1].month)
  //   })
  //   GetMonthlyTotalCapex().then(({data})=>{
  //     localStorage.setItem('total_capex',JSON.stringify(data))
  //     setTotalCapexBudget(data[data?.length-1].total_budget)
  //     setCapexMonth(data[data?.length-1].month)
  //   })
  // }, [])

  return (
    <>
      <div className='row g-5 g-xxl-10'>
        <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <OpexCapexWidget
            color={'#87CEFA'}
            widgetId={16}
            plusOrMinus={true}
            linkToPage={''}
            pageId='2'
          />
        </div>
        <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <OpexCapexWidget
            color={'#FF6347'}
            widgetId={17}
            plusOrMinus={true}
            linkToPage={''}
            pageId='2'
          />
        </div>
        {}
        <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <PieWidget
            widgetId={8}
            refresh={false}
            text={'Kalan Bütçe'}
            plusOrMinus={true}
            linkToPage={''}
            pageId='2'
          />
        </div>
      </div>
      <div className='row g-5 g-xxl-10'>
        <div className='col-xl-8 mb-xl-5 mb-xxl-10'>
          <BudgetByCategory plusOrMinus={true} widgetId={15} linkToPage={''} pageId='2' />
        </div>
        <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <ExchangeRates plusOrMinus={true} widgetId={14} linkToPage={''} pageId='2' />
        </div>
      </div>
    </>
  )
}
