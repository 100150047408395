import {FC, useEffect, useState} from 'react'
import {ListFileItem} from '../DocumentsShare/components/ListFileItem'
import {ListFolder} from '../DocumentsShare/components/ListFolder'
import {getUserDocuments} from './core/_request'
type Props = {
  id: any
}
export const Documents: FC<Props> = ({id}) => {
  const [files, setFiles] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    getUserDocuments(id).then(({data}) => {
      setFiles(data.data)
    })
  }, [refresh])
  return (
    <div className='card card-flush'>
      {/* <div className='card-header d-flex justify-content-end pt-8'>


        <div className='card-toolbar'>
          <div
            className='d-flex justify-content-end align-items-center d-none'
            data-kt-filemanager-table-toolbar='selected'
            
          >
            <div className='fw-bolder me-5'>
              <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
              Selected
            </div>
            <button
              type='button'
              className='btn btn-danger'
              data-kt-filemanager-table-select='delete_selected'
            >
              Delete Selected
            </button>
          </div>
        </div>
      </div> */}

      <div className='card-body'>
        {/* <div className='d-flex flex-stack'>
          <div className='badge badge-lg badge-light-primary'>
      
          </div>

          {/* <div className='badge badge-lg badge-primary'>
            <span id='kt_file_manager_items_counter'>82 items</span>
          </div> */}
        {/* </div>  */}

        <table
          id='kt_file_manager_list'
          data-kt-filemanager-table='folders'
          className='table align-middle table-row-dashed fs-6 gy-5'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
              {/* <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_file_manager_list .form-check-input'
                    value='1'
                  />
                </div>
              </th> */}
              <th className='min-w-250px'>Adı</th>
              <th className='min-w-10px'>Boyutu</th>
              <th className='min-w-125px'>Sorumlu</th>
              <th className='w-125px'>Kategori</th>
              <th className='w-125px'>Oluşturma TARİHİ</th>
            </tr>
          </thead>

          <tbody className='fw-bold text-gray-600'>
            {files.map((e: any) => {
              {
                if (e.files?.length > 1) {
                  return <ListFolder el={e} files={e.files} refreshPage={refreshPage} />
                }
                return e.files.map((file: any) => {
                  console.log('filesss: ' + file)
                  return (
                    <ListFileItem
                      id={e.id}
                      attid={file.id}
                      key={file.id}
                      categoryName={e.category.name}
                      path={file.path}
                      name={file.name}
                      fileName={e.name}
                      size={file.size.toString()}
                      lastMadied={file.created_by}
                      subitem={false}
                      refreshPage={refreshPage}
                      created_by={file.created_by}
                      created_at={file.created_at}
                      fileAssignments={e?.assignments}
                    />
                  )
                })
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
