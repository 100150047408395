import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {PluralBarChart} from '../../components/widgets/OperationalData/PluralBarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport} from '../core/request'
import {AttackResultItem} from './components/AtackResultItem'
import {DetailFilters} from './components/DetailFilter'

export const AttackResult: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/attack_result?token=${loginUser.token}`)
  }
  const [items, setItems] = useState([])
  const [createdAt, setCreatedAt] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [trusted, setTrusted] = useState('')
  const [untrusted, setUntrusted] = useState('')
  const [refId, setRefId] = useState('')
  const [threatName, setThreatName] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setCreatedAt('')
    setCategoryName('')
    setTrusted('')
    setUntrusted('')
    setRefId('')
    setThreatName('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (createdAt != '') {
      queryParams += '&created_at=' + createdAt
    }
    if (categoryName != '') {
      queryParams += '&l1_category_name=' + categoryName
    }
    if (trusted != '') {
      queryParams += '&trusted=' + trusted
    }
    if (untrusted != '') {
      queryParams += '&untrusted=' + untrusted
    }
    if (refId != '') {
      queryParams += '&ref_id=' + refId
    }
    if (threatName != '') {
      queryParams += '&threat_name=' + threatName
    }
    GetReport('/attack_result', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [createdAt, threatName, trusted, untrusted, refId, categoryName])

  return (
    <div>
      <KTCard>
        <div>
          <div className='row g-5 g-xxl-10 mb-5'>
            <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
              <PluralBarChart horizentalTitle={''} widgetId={'68'} plusOrMinus={true} linkToPage={''} />
            </div>
            <div className='col-xl-8 mb-xl-7 mb-xxl-12'>
              <PluralBarChart horizentalTitle={''} widgetId={'69'} plusOrMinus={true} linkToPage={''} />
            </div>
            {/* end::Col */}
          </div>
        </div>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex m-3 justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/attack_result_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>source_port</th>
                      <th className=''>destination_port</th>
                      <th className=''>ref_id</th>
                      <th className=''>string</th>
                      <th className=''>begin_time</th>
                      <th className=''>end_time</th>
                      <th className=''>threat_id</th>
                      <th className=''>variant</th>
                      <th className=''>trusted</th>
                      <th className=''>untrusted</th>
                      <th className=''>scenario_details</th>
                      <th className=''>threat_name</th>
                      <th className=''>l1_category_name</th>
                      <th className=''>cron_server_ip</th>
                      <th className=''>CREATED_AT</th>
                      <th className=''>UPDATED_AT</th>
                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <AttackResultItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setCreatedAt={setCreatedAt}
            setCategoryName={setCategoryName}
            setTrusted={setTrusted}
            setUntrusted={setUntrusted}
            setRefId={setRefId}
            handleClose={closeFilterModal}
            setThreatName={setThreatName}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
