import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}

export const TableBody: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.customer_id}</td>
        <td className='text-gray-500'>{item?.crm_customer_id}</td>
        <td className='text-gray-500'>{item?.city}</td>
        <td className='fw-bolder'>{item?.region}</td>
        <td className='text-gray-500'>{item?.title}</td>
        {/* <td className='text-gray-500'>{item?.service_no}</td>
        <td className='fw-bolder'>{item?.region_size}</td> */}
        <td className='text-gray-500'>{item?.description}</td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='fw-bolder'>{item?.product_name}</td>
        <td className='text-gray-500'>{item?.bandwidth}</td>
        {/* <td className='text-gray-500'>{item?.associated_circuit_no}</td> */}
        <td className='text-gray-500'>{item?.speed}</td>
        {/* <td className='fw-bolder'>{item?.circuit_type}</td> */}
        <td className='text-gray-500'>{item?.protection_type}</td>
        <td className='text-gray-500'>{item?.cancel_date}</td>
        <td className='text-gray-500'>{moment(item?.establishment_date).format('DD.MM.YYYY')}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
