/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import './style.css'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={
        {
          // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }
      }
    >
      {/* begin::Content */}
      <div className=' pt-5 d-flex justify-content-center ' style={{width: '35%', backgroundColor: 'rgb(1 13 88)'}}>
        <div className='mt-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/bg_login.png')} className='slide-right min-w-600px ' style={{marginTop: '15%'}} />
        </div>
      </div>
      <div className='d-flex flex-center flex-column  flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='slide-left w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto ' style={{marginTop: '200px'}}>
          <Outlet />
        </div>

        <img alt='Logo' src={toAbsoluteUrl('/media/tt_logo.png')} className='slide-right p-0  ' style={{width: '230px', height: '230px', marginBottom: '150px'}} />
        {/* end::Content */}
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
