import React, {useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {useLayout} from '../../../../_theme/layout/core'
import {GetCheckedPages, SetPages} from '../core/_requests'

type Props = {
  element: any
  fontIcon?: string
  role_id: any
  checkedPages: any
}

const RoleWithSub: React.FC<Props> = ({children, fontIcon, element, role_id, checkedPages}) => {
  const {pathname} = useLocation()
  const {config} = useLayout()
  const {aside} = config
  const [initialValue, setInitialValue] = useState(false)
  const [active, setActive] = useState(false)
  const [checkPages, setCheckPages]=useState(checkedPages)
  const Activity = () => {
    active ? setActive(false) : setActive(true)
  }
  const handleChange = (e: any, value: boolean) => {
    let pages = {
      pages: [
        {
          id: e.target.name,
          value: value,
        },
      ],
    }
    SetPages(role_id, pages).then(async () => {
      console.log(role_id, pages)
      console.log('Sayfa başarılı eklendi')
      await GetCheckedPages(role_id).then(({data})=>{
        setCheckPages(data.pages)
      })
    })
  }
  return (
   
    <div
    
      className={clsx('menu-item', {'here show': active}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
   
      <span className='menu-link' onClick={Activity}>
        {/* {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon' >
            <KTSVG path={icon} className='svg-icon svg-icon-5' />
          </span>
        )} */}
        <label className='form-check form-check-custom form-check-solid me-10 mt-3'>
          <input
            className='form-check-input h-20px w-20px'
            type='checkbox'
            name={element.id}
            // value={icon}
            defaultChecked={checkPages.some((x:any)=>x==element.id)}
            onChange={(e) => {
              handleChange(e,!(checkPages.some((x:any)=>x==element.id)))
            }}
          />
          <span className='form-check-label fw-bold'>{element.name}</span>
        </label>
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'></span>
        {/* <span className='menu-arrow'></span>  */}
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': false})}>
        {children}
      </div>
    </div>
  )
}

export {RoleWithSub}
