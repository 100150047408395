import PropTypes from 'prop-types'
import React, {FC, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  blog: any
  path?: any
}

export const MostReadedBlogs: FC<Props> = ({blog, path}) => {
  const link = blog?.files[0]?.path
  //    > 0 ? path[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')
  return (
    <div className='d-flex align-items-center mb-7 p-3'>
      <div className='symbol symbol-70px symbol-2by3 me-4'>
        <div>
          <img src={link} width='100px' height={'70px'} className='rounded' />
        </div>
      </div>

      <div className='m-0'>
        <Link to='.' state={blog} className='fw-bolder'>
          {blog?.title}
        </Link>
        <span
          style={{
            height: 'auto',
            overflow: 'hidden',
          }}
          className='text-gray-600  d-block pt-1 fs-8'
        >
          {blog?.content?.slice(0, 150)}
          {blog?.content?.length > 150 ? '...' : ''}
        </span>
        <span
          style={{
            height: 'auto',
          }}
          className='text-gray-800 fw-bold d-block pt-1 fs-7'
        >
          {blog?.total_read} kez görüntülendi
        </span>
      </div>
    </div>
  )
}
