import React, { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ID } from '../../../../_theme/helpers'

type Props = {
    id:ID
    name: string

}


export const Category: FC<Props> =  ({id,name})=> {
  return (
    <div className='d-flex flex-stack fw-bold fs-5 text-gray-500 mb-4'>
         <NavLink
                to='../ViewDetail'
                state={{num: id, category: name}}
                className='text-gray-500 text-hover-primary pe-2'
              > {name}</NavLink>


    <div className='m-0'></div>
  </div>
  )
}
