import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
import {getAssignments} from '../../core/requests'
type Props = {
  setAssignment: any
  setStatus: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilters: FC<Props> = ({
  setAssignment,
  setStatus,
  handleClose,
  setSearchKeyClick,
}) => {
  const handleSubmit = async () => {
    setAssignment(selectedAssignment)
    setStatus(selectedStatus)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [selectedAssignment, setSelectedAssignment] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [inputWord, setInputWord] = useState('')
  const [assignments, setAssigments] = useState<any>([])
  useEffect(() => {
    getAssignments('?is_directorate=1').then(({data}) => {
      setAssigments(data.data)
    })
  }, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      <div className='px-7 py-5'>
        <div className='d-flex align-items-center position-relative my-3'>
          <input
            type='text'
            data-kt-filemanager-table-filter='search'
            className={`form-control form-control-solid  me-2  'w-300px'`}
            placeholder={'Aranacak kelimeyi giriniz'}
            onChange={(e) => {
              setInputWord(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Sorumlu Ekip/Kişi</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-dropdown-parent='#kt_menu_6279569cd15cc'
            data-allow-clear='true'
            onChange={(e) => setSelectedAssignment(e.target.value)}
            // defaultValue={}
          >
            <option hidden> Sorumlu Ekip/Kişi Seç </option>
            {assignments.map((assignment: any) => {
              return <option value={assignment.id}>{assignment.alternative_name}</option>
            })}
          </select>
        </div>
      </div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-dropdown-parent='#kt_menu_6279569cd15cc'
              data-allow-clear='true'
              onChange={(e) => setSelectedStatus(e.target.value)}
              // defaultValue={}
            >
              <option hidden> Status Seç </option>
              <option value='0'> Devam Ediyor </option>
              <option value='1'> Tamamlandı </option>
              <option value='2'> Bilgi Bekleniyor </option>
              <option value='3'> Askıya Alındı</option>
              <option value='4'> İptal</option>
            </select>
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}

        <div className='d-flex justify-content-center'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleClose}
          >
            İptal
          </button>
          <button
            type='submit'
            className='btn btn-sm'
            data-kt-menu-dismiss='true'
            onClick={handleSubmit}
          >
            Detaylı Ara
          </button>
        </div>
      </div>
    </div>

    //</form>
  )
}
