import {FC, useEffect, useState} from 'react'
import {SquareButton} from './SquareButton'
import swal from 'sweetalert'
import {DeleteIssue} from '../core/_requests'
import {ID} from '../../../../_theme/helpers'
import {ModalHeader} from '../../components/ModalHeader'
import {Dropdown, Modal} from 'react-bootstrap'
import '../../style/style-swal.css'
import {AddActivity} from '../activity-edit-modal/addActivity'
import {Link} from 'react-router-dom'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import moment from 'moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  id: ID
  title: string
  categoryName: string
  start_date: string
  end_date: string
  userName: string
  status: string
  issue: any
  assigned_id: any
  plusOrMinus?: boolean
}
export const TargetItem2: FC<Props> = ({id, title, categoryName, start_date, end_date, issue, assigned_id, plusOrMinus}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [modalShow, setModalShow] = useState(false)
  const handleShow = () => setModalShow(true)
  const handleClose = () => {
    setModalShow(false)
  }
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  const {refreshPage} = useRefresh1()
  useEffect(() => {
    return () => {}
  }, [modalShow])

  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        swal('Silindi!', 'Görev başarı ile silindi.', 'success')
        await DeleteIssue(id)
        refreshPage()
      }
    })
  }
  return (
    <>
      <tr className=''>
        <td className='fw-bolder '>
          <Link to={loginUser?.permissions.filter((permission) => permission?.name == 'Show Issue').length > 0 ? '/faaliyetler/detail' : ''} state={issue} className='text-black'>
            <span className='text-hover-primary'>{title}</span>
          </Link>
        </td>
        <td>
          <span className='badge badge-light fw-bold me-auto'>{categoryName}</span>
        </td>
        <td>{moment(start_date).format('DD.MM.YYYY')}</td>
        <td>{moment(end_date).format('DD.MM.YYYY')}</td>
        <td>
          <div className='symbol-group symbol-hover fs-8' style={{cursor: 'pointer'}}>
            <div
              className=' text-hover-primary'
              // data-bs-toggle='tooltip'
              // title='Susan Redwood'
              onClick={async () => {
                setProfilUserId(issue?.assigned?.id)
                await openProfilModal()
              }}
            >
              {/* <span className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                {issue.assigned!==null ? issue.assigned.name : ''}
              </span> */}
              <span className=''>{issue?.assigned !== null ? issue?.assigned.name : ''}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='symbol-group symbol-hover fs-8' style={{cursor: 'pointer'}}>
            <div
              className=' text-hover-primary'
              // data-bs-toggle='tooltip'
              // title='Susan Redwood'
              onClick={async () => {
                setProfilUserId(issue?.created_by?.id)
                await openProfilModal()
              }}
            >
              {/* <span className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                {issue.assigned!==null ? issue.assigned.name : ''}
              </span> */}
              <span className=''>{issue?.created_by !== null ? issue?.created_by?.name : ''}</span>
            </div>
          </div>
        </td>
        <td>
          <span
            data-kt-menu-trigger='click'
            className={`badge badge-${
              issue?.priority?.id == '1' ? 'light-danger' : issue?.priority?.id == '2' ? 'light-success' : issue?.priority?.id == '3' ? 'light-primary' : 'light-danger'
            } fw-bolder me-auto`}
          >
            {issue?.priority?.name}
          </span>
        </td>
        <td>{issue?.created_by?.assignment?.name}</td>
        <td>{issue?.week}</td>
        <td>
          <SquareButton status={false} issue={issue} />
          {/* <EditStatus issue={issue} handleClose={undefined} />  */}
        </td>
        {/* <td> */}
        {/* <a
            href='#'
            className='btn btn-bg-light btn-active-color-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            
          >
            İşlem
          </a> */}
        {/* <SquareButton status={false} issue={issue} /> */}
        {/* <EditStatus issue={issue} />  */}
        {/* </td> */}
        {issue?.assigned?.id == loginUser.id || issue?.created_by?.id == loginUser.id || (loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && plusOrMinus) ? (
          <td className='text-end' data-kt-filemanager-table=''>
            <Dropdown>
              <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleShow}>
                  {' '}
                  <span className='menu-link px-3'>Düzenle</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={swalDelete}>
                  <span className='menu-link text-danger px-3'>Sil</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        ) : (
          ''
        )}
      </tr>
      <Modal show={modalShow} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddActivity handleClose={handleClose} activity={issue} update={true} />
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
