/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {UserItem} from '../../components/UserItem'
import {Dropdown, Modal} from 'react-bootstrap'
import swal from 'sweetalert'
import {DeleteProject, GetMilestone} from '../core/requests'
import moment from 'moment'
import {EditStatus} from './EditStatus'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  id: any
  icon: string
  type: any
  plan: any
  status: any
  title: string
  description: string
  date: string
  budget: string
  progress: number
  users?: any
  refreshPage: any
  refreshCount: any
}

const Project: FC<Props> = ({id, status, title, description, date, budget, users = undefined, refreshPage, refreshCount}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [modalShow, setModalShow] = useState(false)
  const handleShow = () => setModalShow(true)
  const handleClose = () => setModalShow(false)
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteProject(id)
        swal('Silindi!', 'Proje başarı ile silindi.', 'success', {timer: 2000})
        refreshPage()
        refreshCount()
      }
    })
  }
  const setId = (id: any) => {
    localStorage.setItem('projectID', JSON.stringify(id))
  }

  const [percent, setPercent] = useState(0)
  useEffect(() => {
    let length = 0
    let total = 0
    GetMilestone(id).then(({data}) => {
      length = data?.data?.length
      data?.data?.map((d: any) => {
        {
          total += d.percentage
        }
      })
      {
        length == 0 ? setPercent(0) : setPercent(total / length)
      }
    })
  }, [])
  return (
    <>
      <div className='col-12 col-md-4 mb-3'>
        <div className='card' style={{backgroundColor: '#f6f7f8'}}>
          <div className='card-header border-0 pt-9 d-flex '>
            <div className='card-toolbar d-flex flex-column align-items-start justify-content-start '>
              <div style={{width: '90%', minWidth: '250px'}}>
                <Link
                  state={id}
                  to={loginUser?.permissions.filter((permission) => permission.name == 'Show Project').length > 0 ? '../../../projeler/detail' : ''}
                  className='fs-3 fw-bolder text-dark w-75'
                  onClick={() => setId(id)}
                >
                  {title}
                </Link>

                <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
                  {description?.slice(0, 30)}
                  {description?.length > 30 ? '...' : ''}
                </p>
              </div>

              <div className='me-2' style={{position: 'absolute', right: '0px', top: '32px'}}>
                <Dropdown className=''>
                  <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)'}} className='btn btn-light btn-active-light-primary btn-sm ' id='dropdown-basic'></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleShow}>
                      Durum
                      {/* <SquareButton status={true} issue={id} status_id={status?.id} /> */}
                    </Dropdown.Item>
                    <Dropdown.Item className='text-danger' onClick={swalDelete}>
                      Sil
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{moment(date).format('DD.MM.YYYY')}</div>
                <div className='fw-bold text-gray-400'>Başlangıç T.</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{moment(budget).format('DD.MM.YYYY')}</div>
                <div className='fw-bold text-gray-400'>Bitiş T.</div>
              </div>
            </div>

            <div className='h-4px w-100 bg-light mb-5 d-flex justify-content-between' data-bs-toggle='tooltip'>
              <div className={`rounded h-4px`} role='progressbar' style={{width: `${percent}%`, backgroundColor: 'rgb(1 13 88)'}}></div>
            </div>
            <div className='d-flex justify-content-between'>
              <UserItem id={users?.id} name={users?.name} email={''} />
              <div className='fs-4 fw-bolder'>{percent.toFixed(0)}%</div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalShow} className='w-300px modal m-auto'>
        <Modal.Body>
          <EditStatus issue={{id: status.id, prd_id: id}} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
export {Project}
function refreshPage() {
  throw new Error('Function not implemented.')
}
