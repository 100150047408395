import React, {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {Project} from './components/Project'
import {ProjectsTableHeader} from './components/ProjectsTableHeader'
import {getFilteredProjects} from './core/requests'

const Candidates: React.FC = () => {
  const [listShow, setShow] = useState(false)
  const [type, setType] = useState('aday')
  const [projects, setProjects] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [directorateId, setDirectorateId] = useState<any>('')
  const getDirectorateId = (id: string) => {
    setDirectorateId(id)
  }
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const currentTableData = () => {
    let queryParams = '?status=0' + '&page[number]=' + currentPage + '&page[size]=' + limit
    if (directorateId != '') {
      queryParams += '&directorate_id=' + directorateId
    }

    getFilteredProjects(queryParams)
      .then(({data}) => {
        setTotal(data?.meta?.total)
        setProjects(data.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, directorateId])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6 justify-content-between w-100'>
        <div className='row  w-100'>
          <div className='col-12'>
            <DirectorateWidget title='Müdürlük Bazlı Projeler' getSelectedId={getDirectorateId} setSelectedDirectorate={setSelectedDirectorate} widgetId={19} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
        {loading ? (
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <Spinner animation='border' variant='info' style={{width: '50px', height: '50px'}} />
          </div>
        ) : total > 0 ? (
          <div className='row w-100 mt-10'>
            {listShow ? (
              projects.map((project: any) => {
                return (
                  <Project
                    key={project.id}
                    id={project.id}
                    icon='/media/svg/brand-logos/plurk.svg'
                    type={project.type}
                    plan={project.plan}
                    status={project.status}
                    title={project.name}
                    description={project.description}
                    date={project.start_date}
                    budget={project.end_date}
                    progress={50}
                    users={project.created_by}
                    refreshPage={refreshPage}
                    refreshCount={undefined}
                  />
                )
              })
            ) : (
              <ProjectsTableHeader refreshPage={undefined} projects={projects} type={type} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} total={total} />
            )}
          </div>
        ) : (
          <>
            <div
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '10px',
                color: 'black',
                fontSize: '16px',
                paddingLeft: '16px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: '500',
                marginTop: '12px',
                marginBottom: '12px',
                textAlign: 'center',
              }}
              className='d-flex justify-content-center'
            >
              <img
                style={{
                  height: '200px',
                  width: 'auto',

                  textAlign: 'center',
                }}
                alt='Logo'
                src={toAbsoluteUrl('/media/illustrations/dozzy-1/19.png')}
                className=''
              />
            </div>

            <div className='d-flex  justify-content-center w-100'>
              <h2 className='d-flex  text-center'>Hiç Proje Bulunamadı!</h2>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {Candidates}
