import React, {useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddRole} from '../edit-modal/AddRole'
import {SetPage} from '../edit-modal/SetPage'
import {SetPermission} from '../edit-modal/SetPermission'
type Props = {
  role: any
  refreshPage: any
}
export const RealRoleItem: React.FC<Props> = ({role, refreshPage}) => {
  const [showPermissionModal, setPermissionModal] = useState(false)
  const [showPageModal, setPageModal] = useState(false)
  const [roleId, setRoleId] = useState(0)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const openPermissionModal = (id: any) => {
    setRoleId(id)
    setPermissionModal(true)
  }
  const closePermissionModal = () => {
    setPermissionModal(false)
  }
  const openPageModal = (id: any) => {
    setRoleId(id)
    setPageModal(true)
  }
  const closePageModal = () => {
    setPageModal(false)
  }
  return (
    <>
      <tr key={role.id}>
        <td>{role.name}</td>
        <td className='text-end d-flex justify-content-end'>
          <div className=' btn me-4' onClick={() => openPermissionModal(role.id)}>
            Yetki Tanımla
          </div>
          <div className='btn me-4' onClick={() => openPageModal(role.id)}>
            Sayfa Tanımla
          </div>
          <i className='las la-edit fs-3x me-15 text-gray-900 cursor-pointer' onClick={handleShow}></i>
        </td>
      </tr>
      <Modal show={showPageModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closePageModal} titleHeader={'Sayfa Tanımla'} />
          <SetPage role_id={roleId} />
        </Modal.Body>
      </Modal>
      <Modal show={showPermissionModal} size='xl'>
        <Modal.Body>
          <ModalHeader handleClose={closePermissionModal} titleHeader={'Yetki Tanımla'} />
          <SetPermission role_id={roleId} />
        </Modal.Body>
      </Modal>
      <Modal show={show}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddRole role={role} handleClose={handleClose} update={true} refreshPage={refreshPage} />
        </Modal.Body>
      </Modal>
    </>
  )
}
