import React, {FC, Suspense} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_theme/assets/ts/_utils'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {Canceled} from './Canceled'
import {ClassA} from './ClassA'
import {ClassB} from './ClassB'
import {ClassC} from './ClassC'
import {Completed} from './Completed'
import {Suspended} from './Suspended'
import {ProjectHomePage} from './ProjectHomePage'
import {Candidates} from './Candidates'
import NoData from '../components/NoData'
import {Poc} from './Poc/Poc'
import {PocPageWrapper} from './Poc/PocPageWrapper'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectsPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='aday-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                Aday Projeler
              </PageTitle>
              <Candidates />
            </>
          }
        />
        <Route
          path='a-sinifi-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                A sınıfı projeler
              </PageTitle>
              <ClassA />
            </>
          }
        />
        <Route
          path='b-sinifi-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                B sınıfı projeler
              </PageTitle>
              <ClassB />
            </>
          }
        />
        <Route
          path='c-sinifi-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                C sınıfı projeler
              </PageTitle>
              <ClassC />
            </>
          }
        />
        <Route
          path='tamamlanan-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                Tamamlanan Projeler
              </PageTitle>
              <Completed />
            </>
          }
        />
        <Route
          path='projettt'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                Proje-TTT
              </PageTitle>
              <NoData />
            </>
          }
        />
        <Route
          path='askiya-alinan-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                Askıya Alınan Projeler
              </PageTitle>
              <Suspended />
            </>
          }
        />
        <Route
          path='iptal-edilen-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                İptal Edilen Projeler
              </PageTitle>
              <Canceled />
            </>
          }
        />
        {/* <Route
          path='poc-calismalari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler'>
                POC Çalışmaları
              </PageTitle>
              <Poc />
            </>
          }
        /> */}

        <Route
          path='tum-projeler'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Projeler' toDescription='/projeler/tum-projeler'>
                Tüm Projeler
              </PageTitle>
              <ProjectHomePage />
            </>
          }
        ></Route>

        <Route index element={<Navigate to='/aday-projeler' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {ProjectsPageWrapper}
