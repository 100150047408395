import {FC} from 'react'
import { Link, NavLink } from 'react-router-dom'
import {AssignListToolbar} from './AssignListToolbar'
import { AssignSetRole } from './AssignSetRoles'

type Props = {
  setWord: any
}

const AssignListHeader: FC<Props> = ({setWord}) => {
  return (
    <div className='card-header border-0 pt-6 card-toolbar d-flex justify-content-end'>
      {/* <UsersListSearchComponent setWord={setWord} /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex justify-content-end'>
    <AssignSetRole/>
        {/* begin::Group actions */}
        <AssignListToolbar/>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {AssignListHeader}
