import React, {FC, useEffect, useState} from 'react'
import {ID} from '../../../../../../_theme/helpers'
import {TargetItem2} from '../../../../Activities/components/TargetItem2'
import {NoMatchRecords} from '../../../../components/NoMatchRecords'
import {getProcectIssues} from '../../../core/requests'

type Props = {
  project_id: string
}
export const IssuesLists: FC<Props> = ({project_id}) => {
  const [activities, setActivities] = useState<any>([])
  const [refresh, setRefresh] = useState(1)
  useEffect(() => {
    getProcectIssues(project_id).then(({data}) => {
      setActivities(data.data)
    })
    return () => {}
  }, [refresh])
  return (
    <div id='kt_project_targets_card_pane'>
      <div className='card card-flush'>
        <div className='card-body pt-3'>
          <table className='table table-row-bordered  gy-4 align-middle fw-bolder'>
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-100px'>FAALİYET</th>
                <th className='min-w-90px'>KATEGORİ</th>
                <th className='min-w-150px'>BAŞlANGIÇ TARİHİ</th>
                <th className='min-w-150px'>BİTİŞ TARİHİ</th>
                <th className='min-w-150px'>SORUMLU</th>
                <th className='min-w-150px'>Ekleyen</th>
                <th className='min-w-90px'>DURUM</th>
              </tr>
            </thead>

            <tbody className='fs-6'>
              {activities?.length < 1 ? (
                <NoMatchRecords isTable={true} />
              ) : (
                activities?.map(
                  (activity: {
                    id: ID
                    title: string
                    category: {name: string}
                    create_by: any
                    start_date: string
                    end_date: string
                    assigned: {name: string; id: any}
                  }) => {
                    return (
                      <TargetItem2
                        key={activity.id}
                        id={activity.id}
                        title={activity.title}
                        categoryName={activity.category.name}
                        start_date={activity.start_date}
                        end_date={activity.end_date}
                        userName={activity.assigned?.name}
                        status={'In Progress'}
                        issue={activity}
                        assigned_id={activity.assigned?.id}
                      />
                    )
                  }
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
