import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ID } from '../../../../../_theme/helpers'
import { getProject } from '../../core/requests'
import { ProfileDetails } from '../settings/cards/ProfileDetails'


type LocationState = {
  id: ID,
  code: string,
  name: string,
  start_date: string,
  end_date: string,
  status: {
    id:ID,
    name:string
  },
  description: string,
  email_notification: boolean,
  phone_notification: boolean,
  group_directorate: {
    id: ID,
    name: string,
    order: ID,
    parent_id: ID,
    is_group_directorate: boolean,
    is_directorate: boolean,
    is_last: boolean,
  },
  created_at: string,
  created_by: {
    id: ID,
    name: string,
    email: string,
    avatar: string,
  },
}
export const SettingsPage = () => {
  const location = useLocation()
  const id = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [project,setProject] = useState<any>(null);

  useEffect(() => {
    getProject(id).then(res=>{
 
      setProject(res.data.data)
    }).catch(err=>{
      console.log(err);
    })
  }, [])
  return (
    <>
   {project ?  <ProfileDetails  project={project}/>:''}
  


    </>
  )
}

