import {FC, useEffect, useState} from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {RoleItems} from '../components/RoleItems'
import {GetCheckedPages, GetPages} from '../core/_requests'


type Props={
    role_id: any
}
export const SetPage: FC<Props> = ({role_id}) => {
  const [pages, setPages] = useState<any>([])
  const [checkedPages,setCheckedPages] = useState<any>([])

  useEffect(() => {
    GetPages().then(({data}) => {
      setPages(data.data)
    })
  
      GetCheckedPages(role_id).then(({data})=>{
        setCheckedPages(data.pages)
      })
     
  }, [])

  return (
    <>
      {pages.map((page: any) => {
        return <RoleItems element={page} role_id={role_id} checkedPages={checkedPages} />
      })}
    </>
  )
}
