import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {GetUsers} from '../core/_requests'

type Prop = {
  assignment: any
  handleClose: any
}

export const UserList: FC<Prop> = ({assignment, handleClose}) => {
  const [users, setUsers] = useState<any>([])
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  useEffect(() => {
    GetUsers('?assignment_id=' + assignment.id).then(({data}) => {
      console.log(data.data)
      setUsers(data.data)
    })
  }, [])
  return (
    <div className='card card-flush  border-top-0 border-0'>
      <div className='flex-column flex-lg-row-auto  ml-0 '>
        {/* <div>
        <div className='d-flex justify-content-end'>
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary '
            data-kt-users-modal-action='close'
            onClick={handleClose}
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
       
      </div> */}
        <div className='card-body pt-3'>
          <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
            <thead className='fs-7 text-gray-400 text-uppercase'>
              {/* <tr>
            <th className=''>Yetkililer</th>
          </tr> */}
            </thead>

            <tbody className='fs-6'>
              {users?.length < 1 && <h5>Kullanıcı bulunmamaktadır.</h5>}
              {users?.map((user: any) => {
                return (
                  <tr>
                    <td width={'10%'}>
                      <div className='me-5 position-relative'>
                        <div className='symbol symbol-65px symbol-circle w-65px'>
                          {user?.avatar ? (
                            <div className='symbol symbol-65px symbol-circle w-65px'>
                              <img src={user?.avatar} alt={user?.name} className='w-100' />
                            </div>
                          ) : (
                            <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{user?.name?.substring(0, 1)}</div>
                          )}{' '}
                        </div>
                      </div>
                    </td>
                    <td className='fw-bolder'>
                      <span
                        className='text-hover-primary'
                        onClick={async () => {
                          setProfilUserId(user?.id)
                          await openProfilModal()
                        }}
                      >
                        {user?.name}{' '}
                      </span>

                      <span className='text-muted d-block fw-bold'>{user?.email}</span>
                    </td>
                    <td className='text-gray-500'>{user?.roles[0]?.name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
