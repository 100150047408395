import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {Modal} from 'react-bootstrap'
import moment from 'moment'
import {ID} from '../../../../../_theme/helpers'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'
import {ProfilePopUp} from '../../../Profile/ProfilePopUp'
import {GetPocDetail} from '../core/_request'

type Props = {
  PocId: ID
}

export const Comments: FC<Props> = ({PocId}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const [comments, setComments] = useState<any>([])
  const {refresh} = useRefresh1()
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }

  useEffect(() => {
    GetPocDetail(PocId).then(({data}) => {
      setComments(data?.data?.comments)
    })
  }, [refresh])
  return (
    <>
      {' '}
      <div className='card-body'>
        <div className='tab-content'>
          <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
            <div className='timeline'>
              {comments.map((comment: any) => {
                return (
                  <div className='timeline-item'>
                    <div className='timeline-line w-40px'></div>

                    <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                      <div className='symbol-label bg-light'>
                        <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <path
                              opacity='0.3'
                              d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                              fill='currentColor'
                            />
                            <path
                              d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className='timeline-content mb-10 mt-n1'>
                      <div className='pe-3 mb-5'>
                        <div className='fs-5 fw-bold mb-2'>
                          <p>{comment?.content}</p>
                        </div>

                        <div className='d-flex align-items-center mt-1 fs-6 '>
                          <div className='text-muted me-5 fs-7 text-gray-700'>
                            {moment(comment?.created_at).format('DD.MM.YYYY  H:mm')}
                            <div className='d-flex justify-content-between mt-2'>
                              <div className='me-3 position-relative'>
                                <div className='symbol symbol-35px symbol-circle'>
                                  {comment?.created_by?.avatar ? (
                                    <img alt='Pic' src={comment?.created_by?.avatar} />
                                  ) : (
                                    <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{comment?.created_by?.name?.substring(0, 1)}</div>
                                  )}
                                </div>
                              </div>
                              <span
                                className='text-primary fw-bolder mt-3'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                onClick={async () => {
                                  setProfilUserId(comment?.created_by?.id)
                                  await openProfilModal()
                                }}
                              >
                                {comment?.created_by?.name}
                              </span>
                            </div>
                          </div>

                          {/* <ProfilePopUp user_id={created_by.id} /> */}
                        </div>
                      </div>
                      {/* {element.files?.length > 1 ? (
            <div className='overflow-auto pb-5'>
              <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                  if (index !== 0)
                    return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                })}
              </div>
            </div>
          ) : (
            ''
          )} */}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm' className='mt-135px'>
        <Modal.Body>
          {/* <ModalHeader handleClose={closeProfilModal} titleHeader={'Personel Bilgisi'} /> */}
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
