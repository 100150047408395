import React, {FC} from 'react'
import {DOTS, usePagination} from './usePagination'

type Props = {
  onPageChange: any
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  loading: boolean
}

const Pagination: FC<Props> = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
  loading,
}) => {
  const paginationRange = usePagination(totalCount, pageSize, siblingCount, currentPage) || []
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className='d-flex flex-stack flex-wrap pt-10 mb-4'>
      <div className='fs-6 fw-bold text-gray-700'>Toplam: {totalCount}</div>

      <ul className='pagination'>
        {currentPage !== 1 && (
          <li className='page-item previous'>
            <div onClick={onPrevious} className='page-link'>
              <i className='previous'></i>
            </div>
          </li>
        )}

        {paginationRange?.map((pageNumber: any, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} onClick={onPrevious} className='page-item previous'>
                <div className='page-link'>&#8230;</div>
              </li>
            )
          }

          return (
            <li
              key={index}
              onClick={() => onPageChange(pageNumber)}
              className={`page-item cursor-pointer ${pageNumber === currentPage && 'active'} ${
                loading && 'disabled'
              }`}
            >
              <div className='page-link'>{pageNumber}</div>
            </li>
          )
        })}
        {currentPage !== lastPage && (
          <li className='page-item next'>
            <div onClick={onNext} className='page-link'>
              <i className='next'></i>
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Pagination
