import React, {useState} from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../_theme/helpers'
import {useLayout} from '../../../../_theme/layout/core'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddAssignment} from '../edit-modal/AddAssignment'
import {UserList} from './UserListByAssign'

type Props = {
  // to: string
  element: any
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AssignmentWithSub: React.FC<Props> = ({children, title, icon, fontIcon, hasBullet, element}) => {
  const {config} = useLayout()
  const {aside} = config
  const [active, setActive] = useState(false)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [show1, setShow1] = useState(false)
  const handleShow1 = () => setShow1(true)
  const handleClose1 = () => setShow1(false)
  const Activity = () => {
    active ? setActive(false) : setActive(true)
  }

  return (
    <>
      <tr className='w-100 '>
        <td className='w-100 border-0' style={{padding: '0px', marginRight: '0px'}}>
          <div className={clsx('menu-item', {'here show': active}, 'menu-accordion')} data-kt-menu-trigger='click' style={{marginLeft: '20px'}}>
            <span className='menu-link' onClick={Activity}>
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTSVG path={active ? '/media/icons/duotune/arrows/arr004.svg' : icon} className='svg-icon svg-icon-5' />
                </span>
              )}
              <div className='me-4'>
                <div className='symbol symbol-circle symbol-50px bg-success px-5 py-3'>{element?.children?.length}</div>
              </div>

              {/* {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>} */}
              <span className='menu-title'>{title}</span>
              {/* <span className='menu-arrow'></span> */}
              <Dropdown>
                <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleShow}>
                    {' '}
                    <span className='menu-link px-3'>Düzenle</span>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={handleShow1}>
                    {' '}
                    <span className='menu-link px-3'>Kullanıcılar</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>

            <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': false})}>{children}</div>
          </div>
        </td>
        <td></td>
      </tr>
      <Modal show={show}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddAssignment assignment={element} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>

      <Modal show={show1}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose1} titleHeader={'Kullanıcılar'} />
          <UserList assignment={element} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AssignmentWithSub}
