import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const MirroringItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.ticket_id}</td>
        <td className='text-gray-500'>{item?.catalog_name}</td>
        <td className='text-gray-500'>{item?.catalog_id}</td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='text-gray-500'>{item?.phase}</td>
        <td className='text-gray-500'>{moment(item?.opening_time).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{moment(item?.opening_time).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{moment(item?.approval_date).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{item?.opened_by_person}</td>
        <td className='text-gray-500'>{item?.opened_by_department}</td>
        <td className='text-gray-500'>{item?.opened_by_directorate}</td>
        <td className='text-gray-500'>{item?.assigned_person}</td>
        <td className='text-gray-500'>{item?.assigned_department}</td>
        <td className='text-gray-500'>{item?.assigned_directorate}</td>
        <td className='text-gray-500'>{item?.technology}</td>
        <td className='text-gray-500'>{item?.effect}</td>
        <td className='text-gray-500'>{item?.priority}</td>
        <td className='text-gray-500'>{item?.urgency}</td>
        <td className='text-gray-500'>{item?.title}</td>
        <td className='text-gray-500'>{item?.description}</td>
        {/* <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY H:mm')}</td> */}
      </tr>
    </>
  )
}
