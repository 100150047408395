import {FC, useEffect, useState} from 'react'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {GetSupplierCategories} from '../../../Categories/core/_request'
import {SearchComponent} from '../../../components/SearchComponent'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'

type Props = {
  refreshPage: any
  setWord: any
  setCategoryId: any
}

const UsersListHeader: FC<Props> = ({refreshPage, setWord, setCategoryId}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  useEffect(() => {
    GetSupplierCategories().then(({data}) => {
      setCategories(data.data)
    })
  }, [])
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      <SearchComponent setSearchKeyClick={setWord} placeholder={'Tedarikçi Ara'} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <div className='me-3 '>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm form-select-solid w-180px h-40px '
            onChange={(e) => setCategoryId(e.target.value)}
          >
            {/* <option value='1' selected>
                Kategori Seçiniz
              </option> */}
            <option value='' selected>
              Tümü
            </option>
            {categories.map((category: any) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              )
            })}
          </select>
        </div>
        {/* begin::Group actions */}
        {selected?.length > 0 ? (
          <UsersListGrouping />
        ) : (
          <UsersListToolbar refreshPage={refreshPage} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
