import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {SearchComponent} from '../../../components/SearchComponent'
import {GetAssignments, GetDirectorateAssignments} from '../../core/_requests'
import {UsersListToolbar} from './UserListToolbar'

type Props = {
  refreshPage: any
  setWord: any
  setAssignmentId: any
}

const UsersListHeader: FC<Props> = ({refreshPage, setWord, setAssignmentId}) => {
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const [assignments, setAssignments] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  useEffect(() => {
    GetDirectorateAssignments().then(({data}) => {
      setAssignments(data.data)
    })
  }, [])
  return (
    <div className='card-header border-0 pt-6 card-toolbar d-flex '>
      <SearchComponent setSearchKeyClick={setWord} placeholder={'Kullanıcı Ara'} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex justify-content-end'>
        <div className='my-0 me-3'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm form-select-solid w-180px h-40px '
            onChange={(e) => setAssignmentId(e.target.value)}
          >
            {/* <option value='1' selected>
                Kategori Seçiniz
              </option> */}
            <option value='' selected>
              Tümü
            </option>
            {assignments.map((assignment) => {
              return (
                <option key={assignment.id} value={assignment.id}>
                  {assignment.name}
                </option>
              )
            })}
          </select>
        </div>
        {/* begin::Group actions */}
        {loginUser?.roles?.filter(
          (role: any) => role.name == 'Süper Admin' || role?.name == 'Admin'
        )?.length > 0 ? (
          <UsersListToolbar refreshPage={refreshPage} />
        ) : (
          ''
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
function getGroupDirectorateAssignments() {
  throw new Error('Function not implemented.')
}
