import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {TableBody} from './components/TableBody'
import {Add} from './add/Add'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import ChangeInNumberCustomer from '../../components/widgets/OperationalData/ChangeInNumberCustomer'
import LineChart from '../../components/widgets/OperationalData/LineChart'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'

export const KGHCustomerData: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [items, setItems] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }

    GetReport('/kgh_customer_data', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, show])

  const exportFile = () => {
    window.open(`${ExportLink}/kgh_customer_data?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div>
          <div className='col-xl-12 col-xxl-12 mb-xl-6 mb-xxl-12'>
            <ChangeInNumberCustomer widgetId='70' color='#ffc100' plusOrMinus={true} linkToPage={''} />
          </div>
          <div className='col-xl-12 xol-xxl-12 mb-xl-6 mb-xxl-12'>
            <LineChart widgetId={'71'} plusOrMinus={true} linkToPage={''} />
          </div>
          <div className='row g-5 g-xxl-10  mb-5'>{/* end::Col */}</div>
        </div>
      </div>
      <div className='d-flex mb-6 mt-5'>
        <div className='row w-100'>
          <div className='d-flex flex-stack mb-6'>
            <h3 className='fw-bolder my-2'> Toplam {total} müsteriye Veri Merkezi Güvenlik Hizmeti verilmektedir.</h3>
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card card-flush border-top-0 border-bottom-0'>
              <div className='card-header'>
                <div className='d-flex m-3 justify-content-between w-100'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>
                  <div>
                    {' '}
                    {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin' || role?.name == 'Planlama')?.length > 0 && (
                      <Button className='btn mt-3 me-3' variant='primary' onClick={handleShow}>
                        İçeri Aktar
                      </Button>
                    )}
                    <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                      Dışarı Aktar
                    </Button>
                  </div>
                </div>
              </div>
              <div className='card-body overflow-auto mh-900'>
                {loading ? (
                  <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Yükleniyor...</span>
                    </div>
                  </div>
                ) : (
                  <table className='table table-row-bordered table-row-dashed gy-20 align-middle fw-bolder ' style={{overflow: 'scroll'}}>
                    <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fdfcfb'}}>
                      <tr style={{color: '#50c8da'}}>
                        <th className=''>MÜŞTERİ Id</th>
                        <th className=''>CRM CustomeR ID</th>
                        <th className=''>İl Adı</th>
                        <th className=''>Bölge Adı</th>
                        <th className=''>Soyad Ünvan </th>
                        {/* <th className=''>HİZMET No</th> */}
                        {/* <th className=''>Zone Sayısı</th> */}
                        <th className=''>Alt HİZMET Türü Açıklama </th>
                        <th className=''>Durum</th>
                        <th className=''> Ürün ADI</th>
                        <th className=''>Bant GENİŞLİĞİ</th>
                        {/* <th className=''> İLİŞKİLİ Devre No</th> */}
                        <th className=''> Hız</th>
                        <th className=''>Koruma TİPİ</th>
                        <th className=''> İPTAL TARİHİ</th>
                        <th className=''> TESİS TARİHİ </th>

                        {/* <th className=''>FİRMA</th> */}
                      </tr>
                    </thead>

                    <tbody className='fs-6'>
                      {items?.length < 1 && !loading ? (
                        <NoMatchRecords isTable={true} />
                      ) : (
                        items?.map((item: any) => {
                          return (
                            <>
                              <TableBody key={item?.id} item={item} />
                            </>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <Add handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
