import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {RiskManagementItem} from './components/RiskManagementItem'
import {ModalHeader} from '../../components/ModalHeader'
import {DetailFiltersDistrubutions} from './components/DetailFiltersDistrubutions'
import {DetailFiltersAksiyon} from './components/DetailFiltersAksiyon'

export const RiskManagement: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [groupAssets, setGroupAssets] = useState<any>([])
  const [refIdAks, setRefIdAks] = useState('')
  const [code, setCode] = useState('')
  const [organisationalUnit, setOrganisationalUnit] = useState('')
  const [refStatus, setRefStatus] = useState('')
  const [refIdDis, setRefIdDis] = useState('')
  const [trend, setTrend] = useState('')
  const [refStatusDis, setRefStatusDis] = useState('')
  const [riskLevel, setRiskLevel] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [currentpage2, setCurrentPage2] = useState(1)
  const [total2, setTotal2] = useState(1)
  const [loading2, setLoading2] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalDis, setShowModalDis] = useState(false)
  const handleOpen = () => {
    setCode('')
    setRefIdAks('')
    setRefStatus('')
    setOrganisationalUnit('')
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
  }
  const handleOpenDis = () => {
    setRefIdDis('')
    setTrend('')
    setRefStatusDis('')
    setRiskLevel('')
    setShowModalDis(true)
  }
  const handleCloseDis = () => {
    setShowModalDis(false)
  }
  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (refIdDis != '') {
      queryParams += '&ref_id=' + refIdDis
    }
    if (trend != '') {
      queryParams += '&trend=' + trend
    }
    if (riskLevel != '') {
      queryParams += '&risk_level=' + riskLevel
    }
    if (refStatusDis != '') {
      queryParams += '&ref_status=' + refStatusDis
    }
    GetReport('/optimate_solution_risk_management_risk_distribution', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  const currentTableData2 = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (refIdAks != '') {
      queryParams += '&ref_id=' + refIdAks
    }
    if (refStatus != '') {
      queryParams += '&ref_status=' + refStatus
    }
    if (organisationalUnit != '') {
      queryParams += '&organisatioanl_unit=' + organisationalUnit
    }
    if (code != '') {
      queryParams += '&code=' + code
    }
    GetReport('/optimate_solution_risk_management_risk_action', queryParams).then(({data}) => {
      setTotal2(data?.meta?.total)
      setGroupAssets(data?.data)
      setLoading2(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [trend, refIdDis, refStatusDis, riskLevel])
  useEffect(() => {
    currentTableData2()
    setLoading2(true)
  }, [currentpage2])
  useEffect(() => {
    setCurrentPage2(1)
    currentTableData2()
  }, [code, organisationalUnit, refIdAks, refStatus])

  const exportFile = () => {
    window.open(`${ExportLink}/optimate_solution_risk_management_risk_distribution?token=${loginUser.token}&ref_id=${refIdDis}&ref_status=${refStatusDis}&risk_level=${riskLevel}&trend=${trend}`)
  }
  const exportFile2 = () => {
    window.open(
      `${ExportLink}/optimate_solution_risk_management_risk_action?token=${loginUser.token}&ref_id=${refIdAks}&ref_status=${refStatus}&code=${code}&organisational_unit=${organisationalUnit}`
    )
  }
  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Durum Dağılımı'} link={''} widgetId={'98'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRefStatusDis} />
          </div>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Risk Seviye Dağılımı'} link={''} widgetId={'99'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRiskLevel} />
          </div>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Durum Dağılımı'} link={''} widgetId={'100'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRefStatus} />
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_risk_management_risk_distribution_check'} />

                <span className='fs-2 fw-bolder mt-3'>Risk Dağılımı</span>
                <div>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={handleOpenDis}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th>REF_ID</th>
                      <th>ORGANISATIONAL UNIT</th>
                      <th>RISK TREAMENT DECISION</th>
                      <th>REF STATUS</th>
                      <th>TREND</th>
                      <th>RISK LEVEL</th>
                      <th>RISK SCORE</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <RiskManagementItem key={item?.id} item={item} tableId={1} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0 mt-5 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_risk_management_risk_action_check'} />
                <span className='fs-2 fw-bolder mt-3'>Risk Aksiyonları</span>
                <div>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={handleOpen}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile2}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading2 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>DATE TIME CREATED</th>
                      <th className=''>CODE</th>
                      <th className=''>DIRECTORATE</th>
                      <th className=''>ORGANISATIONAL UNIT</th>
                      <th className=''>REF STATUS</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {groupAssets?.length < 1 && !loading2 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      groupAssets?.map((item: any) => {
                        return (
                          <>
                            {' '}
                            <RiskManagementItem key={item?.id} item={item} tableId={2} />{' '}
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage2(page)} totalCount={total2} currentPage={currentpage2} pageSize={8} loading={loading2} />
            </div>
          </div>
        </div>
      </KTCard>

      <Modal show={showModal}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={handleClose} />
          <DetailFiltersAksiyon setCode={setCode} setRefId={setRefIdAks} setRefStatus={setRefStatus} setOrgUnit={setOrganisationalUnit} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={showModalDis}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={handleCloseDis} />
          <DetailFiltersDistrubutions setRiskLevel={setRiskLevel} setTrend={setTrend} setRefId={setRefIdDis} setRefStatus={setRefStatusDis} handleClose={handleCloseDis} />
        </Modal.Body>
      </Modal>
    </>
  )
}
