import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_theme/helpers'
import { API_URL } from '../../request'
import { DocModel } from '../model/docModel'

const GET_POST = `${API_URL}/documents`
const GET_CATEGORIES = `${API_URL}/document_categories`
const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

const getDoc = (id:string): Promise<any> => {
  return axios
    .get(`${GET_POST}${id}`)
    .then((d: AxiosResponse<any>) => d)
}

export async function getAnnounDetail(id: string) {
  return await axios.get<any>(GET_POST + '/' + id);
}
export async function getDocCategories() {
  return await axios.get<any>(GET_CATEGORIES)
}

export async function deleteDoc(docid:string,attid:string) {
  return await axios.delete(GET_POST+`/${docid}/attachments/${attid}`)
}


const createDoc = (announcement: any): Promise<DocModel | undefined> => {
  return axios
    .post(GET_POST, announcement, config)
    .then((response: AxiosResponse<Response<DocModel>>) => response.data)
    .then((response: Response<DocModel>) => response.data)
}
 
export async function DeleteBlog(id:any) {
  return await axios.delete(GET_POST+'/'+id).then(() => {})
}


export {getDoc,createDoc}
