import {Routes, Outlet, Route, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import NoData from '../components/NoData'
import {CAttact} from '../OperationalData/CustomerAttack/CA'
import {KGHCustomerData} from '../OperationalData/KGHCustomerData/KGHCustomerData'
import {TTVMCustomerData} from '../OperationalData/TTVMCustomerData/TTVMCustomerData'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const SGMPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='kgh-musteri-durumu-verileri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                KGH Müşteri Durumu Verileri
              </PageTitle>
              <KGHCustomerData />
            </>
          }
        />
        <Route
          path='ttvm-musteri-durumu-verileri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                TTVM Müşteri Durumu Verileri
              </PageTitle>
              <TTVMCustomerData />
            </>
          }
        />
        <Route
          path='ddos-atak-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Operasyonel Veriler'>
                Müşteri Atak İstatistikleri
              </PageTitle>
              <CAttact />
            </>
          }
        />
        <Route
          path='erisim-talepleri-sla'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                Erişim Talepleri SLA
              </PageTitle>
              <NoData />
            </>
          }
        />
        <Route
          path='kesinti-ve-olay-bilgileri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                Kesinti Ve Olay Bilgiler
              </PageTitle>
              <NoData />
            </>
          }
        />
        <Route
          path='musteri-urun-bilgileri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                Müşteri Ürün Bilgileri
              </PageTitle>
              <NoData />
            </>
          }
        />
        <Route
          path='musteri-ariza-istatistikleri'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='SGM İşlemleri'>
                Müşteri Arıza İstatistikleri
              </PageTitle>
              <NoData />
            </>
          }
        />
        <Route index element={<Navigate to='/kgh-musteri-durumu-verileri' />} />
      </Route>
    </Routes>
  )
}
