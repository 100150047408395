import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import {GetKGHWeeklyCustomer, GetTTVMWeeklyCustomer} from '../../../OperationalData/core/request'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {getWeeks} from '../../../Activities/core/_requests'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  widgetId: string
  color: string
  plusOrMinus: boolean
  refreshPage?: any
  pageId?: string
  linkToPage: string
}
const ChangeInNumberCustomer: FC<Props> = ({widgetId, color, plusOrMinus, pageId, linkToPage}) => {
  const currentDate = new Date()
  function getCurrentWeekNumber() {
    const now = new Date()

    // Yılın başlangıcını belirle
    const startOfYear = new Date(now.getFullYear(), 0, 1)

    // Bugünün tarihini ve yılın başlangıcını milisaniye cinsinden al
    const currentDateMilliseconds = now.getTime()
    const startOfYearMilliseconds = startOfYear.getTime()

    // Milisaniye cinsinden farkı al ve bir hafta süresine böl
    const millisecondsInAWeek = 7 * 24 * 60 * 60 * 1000
    const weekNumber = Math.ceil((currentDateMilliseconds - startOfYearMilliseconds) / millisecondsInAWeek)

    return weekNumber
  }
  const {refreshPage} = useRefresh1()
  const [widgetData, setWidgetData] = useState<any>()
  const [weeks, setWeeks] = useState<any>([])
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeekNumber().toString())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  let date = new Date()
  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', ' Eylül', 'Ekim', 'Kasım', 'Aralık']

  const currentYear = currentDate.getFullYear()

  const yearRange: any[] = []
  // -10 ile +10 yılları içeren bir dizi
  for (let i = currentYear - 15; i <= currentYear + 5; i++) {
    yearRange.push(i)
  }
  useEffect(() => {
    getWeeks().then(({data}) => {
      setWeeks(data.data)
    })
  }, [])
  useEffect(() => {
    let week = '?week=' + selectedWeek
    let year = '&year=' + selectedYear
    if (widgetId == '73') {
      GetTTVMWeeklyCustomer(week + year).then((data) => {
        setWidgetData(data?.data)
      })
    } else {
      GetKGHWeeklyCustomer(week + year).then((data) => {
        setWidgetData(data?.data)
      })
    }
  }, [selectedWeek, selectedYear])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  return (
    <div>
      <div
        className='p-5 '
        style={{
          borderRadius: '20px',
          borderColor: color,
          borderStyle: 'solid',
          borderWidth: '5px',
          height: '427px',
        }}
      >
        <div className='d-flex justify-content-between'>
          <Link to={plusOrMinus ? '' : linkToPage}>
            <div>
              <div className='card-title d-flex align-items-center text-end'>
                <span className='svg-icon svg-icon-1 svg-icon-primary me-3 lh-0'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path opacity='0.3' d='M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z' fill='currentColor' />
                    <path
                      d='M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>

                <h3 className='fw-bolder m-0 text-gray-800'>
                  {date.getDate().toString()} {months[date.getMonth()]} {date.getFullYear().toString()}
                </h3>
              </div>
            </div>
          </Link>
          <div className='me-5 d-flex'>
            <>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-250px h-50px me-4 mt-n2 '
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value=''>Tümü</option>
                {yearRange?.map((year: any) => {
                  return (
                    <option key={year} value={year} selected={year == selectedYear}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-250px h-50px me-4 mt-n2 '
                onChange={(e) => setSelectedWeek(e.target.value)}
              >
                <option value=''>Tümü</option>
                {weeks.map((week: any) => {
                  return (
                    <option key={week?.id} value={week?.id} selected={week?.id == selectedWeek}>
                      {week?.name}
                    </option>
                  )
                })}
              </select>
            </>
            <div>
              <span
                className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex'
                style={{
                  backgroundColor: '#f6f7f8',
                  border: '1px solid rgb(1 13 88)',
                  color: 'rgb(1 13 88)',
                }}
                onClick={() => {
                  homeWidgetShow()
                }}
              >
                {plusOrMinus ? '+' : '-'}
              </span>
              <br />
            </div>
          </div>
        </div>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='d-flex align-item-center justify-content-center fs-2 fw-bolder p-5'>
            {widgetId == '73' ? 'TTVM' : 'KGH'} Müşteri Sayısı Değişimi ({moment(widgetData?.period.week_start).format('DD.MM')} - {moment(widgetData?.period.week_end).format('DD.MM.YYYY')})
          </div>
        </Link>

        <div className='d-flex justify-content-between'>
          <div style={{margin: '5%'}}>
            <span className='fw-bolder fs-3' style={{color: '#5c6e38'}}>
              {' '}
              {widgetData?.weekly_new_customer_count} Müşteri <br /> TESİS
            </span>
          </div>
          <div style={{marginTop: '4%'}}>
            <i className='fas fa-arrow-right' style={{fontSize: '85px', color: '#92d051'}}></i>
          </div>
          <div style={{backgroundColor: color, padding: '4% 10%', borderRadius: '4%'}}>
            <span className='fs-3 fw-bolder text-white d-flex align-items-center justify-content-center'>
              {' '}
              Toplam {widgetData?.total_active_customer_count} Müşteriye <br /> Kurumsal Güvenlik <br /> Hizmeti verilmektedir
            </span>
          </div>

          <div style={{marginTop: '4%'}}>
            <i className='fas fa-arrow-right' style={{fontSize: '85px', color: '#c20c0a'}}></i>
          </div>
          <div style={{margin: '5%'}}>
            <span className='fw-bolder fs-3' style={{color: '#c20c0a'}}>
              {' '}
              {widgetData?.weekly_inactive_customer_count} Müşteri <br /> İPTAL
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeInNumberCustomer
