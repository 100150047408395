import moment from 'moment'
import {FC} from 'react'
import {Button} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_theme/helpers'
import {UserItem} from '../../../components/UserItem'
import swal from 'sweetalert'
import './style.css'
import {deleteProjectDocument} from '../../core/requests'

function getDate(craeteDat: string) {
  var today = Date()
  var start = moment(craeteDat)
  var end = moment(today)
  return end.diff(start, 'days') > 0 ? end.diff(start, 'days') + ' gün önce' : 'Bugün'
}

type Props = {
  icon: string
  create_by: any
  name: string
  date: string
  files: any
  categoryName: string
  refreshPage: any
  project_id: any
}

export const CardFileItem: FC<Props> = ({
  icon,
  name,
  date,
  create_by,
  files,
  categoryName,
  refreshPage,
  project_id,
}) => {
  let count = files?.length

  const dowFiles = () => {
    window.open(files.path)
    // files.forEach(async (f: {path: string}) => {
    //   console.log(f.path)
    //   setTimeout(function () {
    //
    //   }, 200)
    // })
  }
  const filename = (param: any) => {
    const icon = param.split('.')
    const i = icon[icon?.length - 1]
    return i
  }

  //renderSwitch(files);
  //  var interval = setInterval(dowFiles, 300, files);

  const link = files

  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await deleteProjectDocument(project_id, id).then(() => {
          swal('Silindi!', 'Dosya başarı ile silindi.', 'success')
          refreshPage()
        })
      }
    })
  }
  return (
    <div className='col-sm-6 col-md-3 mb-6'>
      <div className='card h-100'>
        <div className='card-body d-flex justify-content-center text-start flex-column p-5 overflow-hidden'>
          <a
            onClick={() => {
              dowFiles()
            }}
            className='text-gray-800 text-hover-primary d-flex flex-row'
          >
            <div className='symbol symbol-60px mb-6 mr-6'>
              <span className='svg-icon svg-icon-5x svg-icon-primary me-2'>
                <img
                  style={{width: '44px', marginRight: '10px'}}
                  src={toAbsoluteUrl(`/media/svg/files/${filename(name)}.svg`)}
                  alt=''
                />
              </span>
            </div>
            <div className='pl-10 files-count'>
              <div className='fs-7 fw-bolder mb-1 ml-4 '>{name}</div>
              <div className='fs-7 fw-bold text-gray-400 mt-auto'>{getDate(date.slice(0, 10))}</div>
              <div className='fs-7 fw-bold text-gray-400 mt-auto'>{categoryName}</div>
            </div>
          </a>
          <div className='card-footer p-3'>
            <UserItem id={create_by.id} name={create_by.name} email={''} />
          </div>
          <div className='btn' style={{color: 'white'}} onClick={() => swalDelete(files.id)}>
            Sil
          </div>
        </div>
      </div>
    </div>
  )
}
