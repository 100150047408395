import {createSlice} from '@reduxjs/toolkit'

interface HideMenu {
  hideMenu: boolean
}

const initialState: HideMenu = {
  hideMenu: false,
}

export const HideMenuSlice = createSlice({
  name: 'hideMenu',
  initialState,
  reducers: {
    setValue: (state) => {
      state.hideMenu = !state.hideMenu
    },
  },
})

export const {setValue} = HideMenuSlice.actions

export default HideMenuSlice.reducer
