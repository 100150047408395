import React, {useEffect, useState} from 'react'
import {PageDataProvider} from './core'
import {Outlet, useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import HeaderBase from './components/header/HeaderBase'
import AsideBase from './components/aside/AsideBase'
import {ScrollTop} from './components/Scrolltop'
import {CreateApp, DrawerMessenger} from '../partials'
import {Content} from './components/Content'
import {getCSRFToken} from '../../app/pages/core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../app/modules/auth/models/UserModel'
import {RootState} from '../../setup'
import {useAppSelector} from '../../setup/hooks/redux'

const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()

  const [csrfToken, setApiData] = useState<any | null>(null)
  const [remainingTime, setRemainingTime] = useState<number | null>(null)
  const hideMenu = useAppSelector((state) => state.hideMenu.hideMenu)

  const fetchApiData = async () => {
    try {
      getCSRFToken().then(({data}) => {
        setApiData(data.token)
        localStorage.setItem('csrfToken', data.token)
        const currentTime = new Date().getTime()
        localStorage.setItem('lastRequestTimestamp', currentTime.toString())
      })
    } catch (error) {
      console.error('API Request Error:', error)
    }
  }

  useEffect(() => {
    // const cookies = document.cookie
    // const storedApiData = document.cookie

    const storedApiData = localStorage.getItem('csrfToken')
    // console.log(storedApiData)
    if (storedApiData) {
      setApiData(storedApiData)
    } else {
      fetchApiData()
    }

    const storedTimestamp = localStorage.getItem('lastRequestTimestamp')
    const lastRequestTimestamp = storedTimestamp ? parseInt(storedTimestamp, 10) : null

    if (lastRequestTimestamp) {
      const currentTime = new Date().getTime()
      const elapsedTime = currentTime - lastRequestTimestamp

      if (elapsedTime < 3600000) {
        // 1 saat içinde, kalan süreyi hesapla
        const remaining = 3600000 - elapsedTime
        setRemainingTime(remaining)

        // Kalan süre sonunda API isteği gönder
        const remainingIntervalId = setTimeout(() => {
          fetchApiData()
          // Kalan süreyi sıfırla
          setRemainingTime(null)
        }, remaining)

        // Component unmount olduğunda zamanlayıcıyı temizle
        return () => {
          clearInterval(remainingIntervalId)
        }
      }
    } else {
      // 1 saat içinde yapılmış bir istek yoksa hemen bir istek gönder
      fetchApiData()

      // 1 saatte bir istek gönder
      const intervalId = setInterval(() => {
        fetchApiData()
      }, 3600000) // 1 saat = 3600000 milisaniye
      // Component unmount olduğunda zamanlayıcıyı temizle
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [])
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  useEffect(() => {
    if (user) {
      localStorage.removeItem('csrfToken')
      localStorage.removeItem('lastRequestTimestamp')
      fetchApiData()
    }
  }, [user])
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          {!hideMenu && <AsideBase />}

          <div className={` w-100 d-flex flex-column flex-row-fluid' id='kt_wrapper ${hideMenu ? '' : 'wrapper'}`}>
            <HeaderBase />

            <div className='content d-flex flex-column-fluid' id='kt_content'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>
      </div>
      {/* begin:: Drawers */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      <CreateApp />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
