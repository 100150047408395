import {FC, Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {createIssue} from '../core/_requests'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import moment from 'moment'
import {getCSRFToken} from '../../core/requests'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  handleClose: any
  assigned_id: any
}
const issueSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
})

export const AddImmediateActivity: FC<Props> = ({handleClose, assigned_id}) => {
  const [todaysDate, setTodaysDate] = useState('')
  const [afterweek, setAfterweek] = useState<any>()
  const {refreshPage} = useRefresh1()
  useEffect(() => {
    let date11 = new Date()
    date11.setDate(date11.getDate())
    let stringDate = date11.toLocaleString()
    setTodaysDate(moment(date11).format('YYYY-MM-DD'))
    date11.setDate(date11.getDate() + 7)
    stringDate = date11.toLocaleDateString()
    setAfterweek(moment(date11).format('YYYY-MM-DD'))
  }, [])
  const [userForEdit] = useState<any>({
    title: '',
    content: '',
    email_notification: false,
    phone_notification: false,
  })

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: issueSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        formData.append('title', values.title)
        formData.append('start_date', todaysDate)
        formData.append('end_date', afterweek)
        formData.append('content', values.content)
        formData.append('email_notification', values.email_notification ? '1' : '0')
        formData.append('phone_notification', values.phone_notification ? '1' : '0')
        formData.append('issue_category_id', '11')
        formData.append('assigned_by', assigned_id)
        formData.append('project_id', '')
        formData.append('issue_priority_id', '1')

        await createIssue(formData)
          .then(() => {
            swal('Eklendi', 'Görev başarı ile eklendi.', 'success', {timer: 2000})
            refreshPage()
            handleClose()
          })
          .catch((err) => {
            swal('Hata!', err.response.data.error, 'error', {timer: 2000})
          })
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  let date = new Date(formik.values.start_date)
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input Başlık */}

          {/* begin::Input Açıklama */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Açıklama</label>
            {/* end::Label */}
            <textarea
              placeholder='Faaliyet için açıklama yazınız'
              {...formik.getFieldProps('content')}
              name='content'
              rows={8}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>
            {/* {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )} */}
          </div>
          {/* end::Açıklama */}

          {/* begin:: Bildirimler */}
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirim</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          {/* end:: Bildirimler */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={handleClose}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn '
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
