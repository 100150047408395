import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

type Props = {
  active: number
  setActive: any
  refresh: boolean,
  setRefresh: any
}
export const Slider: FC<Props> = ({active, setActive, refresh, setRefresh,}) => {
  const [showSlaModal, setSlaModal] = useState(false)
  const [showTicketModal, setShowticketModal] = useState(false)
  const [showDocModal, setShowdocModal] = useState(false)

  const openSlaModal = () => {
    setSlaModal(true)
 
  }
  const closeSlaModal = () => {
    setSlaModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const openTicketModal = () => {
      setShowticketModal(true)
  }
  const closeTicketModal = () => {
      setShowticketModal(false)
      refresh ? setRefresh(false) : setRefresh(true)
  }
  const openDocModal = () => {
    setShowdocModal(true)
  }
  const closeDocModal = () => {
    setShowdocModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  return (
    <>
      <div className='w-200'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_tab'
              onClick={() => setActive(1)}
            >
              Genel Bakış
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 2 ? 'active' : ''}`}
              data-kt-countup-tabs='true'
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_security'
              onClick={() => setActive(2)}
            >
              Dokümanlar
            </a>
          </li>
        </ul>
      </div>
    
    </>
  )
}
