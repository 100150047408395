import {FC, useEffect, useState} from 'react'
import {GetReportsWidget} from '../../core/request'
type Props = {
  setTechnology: any
  setCategory: any
  setDirectorate: any
  handleClose: any
}

export const DetailFilters: FC<Props> = ({setTechnology, setCategory, handleClose, setDirectorate}) => {
  const handleSubmit = async () => {
    setTechnology(selectedTechnology)
    setCategory(selectedCategory)
    setDirectorate(selectedDirectorate)
    handleClose()
  }
  useEffect(() => {
    GetReportsWidget('/vulnerability_notifications_directorates').then((data) => {
      setDirectorates(data?.data)
    })
  }, [])
  const [directorates, setDirectorates] = useState<any>([])
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [selectedTechnology, setSelectedTechnology] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Technology</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedTechnology(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Category</label>
        <input
          placeholder='Aranacak kelimeyi giriniz'
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedCategory(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Atama Grubu</label>
        <div className='my-0 '>
          <select name='status' data-control='select2' data-hide-search='true' className='form-select  form-select-solid' onChange={(e) => setSelectedDirectorate(e.target.value)}>
            <option value='' hidden>
              Seçiniz
            </option>
            {directorates?.map((directorate: any) => {
              return (
                <option key={directorate?.assignment_name} value={directorate?.assignment_name}>
                  {directorate?.assignment_name}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        <button type='reset' className='btn btn-sm btn-light btn-active-light-primary me-2' data-kt-menu-dismiss='true' onClick={handleClose}>
          İptal
        </button>
        <button type='submit' className='btn btn-sm' data-kt-menu-dismiss='true' onClick={handleSubmit}>
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
