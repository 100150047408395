import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {IADSItem} from './components/IADSItem'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {DetailFilters} from './components/DetailFilter'

export const IADS: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [iads, setIADS] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState('')
  const [caseTitle, setCaseTitle] = useState('')
  const [caseStatus, setCaseStatus] = useState('')
  const [raUser, setRaUser] = useState('')
  const [caseAssignedGroup, setCaseAssignedGroup] = useState('')
  const [informedDepartments, setInformedDepartments] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setCompany('')
    setCaseTitle('')
    setCaseStatus('')
    setRaUser('')
    setCaseAssignedGroup('')
    setInformedDepartments('')
    setShow(true)
  }

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (company != '') {
      queryParams += '&company=' + company
    }
    if (caseTitle != '') {
      queryParams += '&case_title=' + caseTitle
    }
    if (caseStatus != '') {
      queryParams += '&case_status=' + caseStatus
    }
    if (raUser != '') {
      queryParams += '&ra_user=' + raUser
    }
    if (caseAssignedGroup != '') {
      queryParams += '&case_assigned_gorup=' + caseAssignedGroup
    }
    if (informedDepartments != '') {
      queryParams += '&informed_departments=' + informedDepartments
    }
    GetReport('/income_assurance_data', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setIADS(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [show])

  const exportFile = () => {
    window.open(`${ExportLink}/income_assurance_data?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>

        <div>
          <div className='row g-5 '>
            <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarChart horizentalTitle={'Yıllık Şirket Sayısına Göre'} plusOrMinus={true} widgetId='25' linkToPage={''} />
            </div>
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-header'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/income_assurance_data_check'} />

                <div>
                  <Button className='btn mt-3 mx-5' style={{marginRight: '10px'}} onClick={handleShow}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
                {/* <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
               
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div> */}
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>case_no</th>
                      <th className=''>ra_user</th>
                      <th className=''>company</th>
                      <th className=''>case_title</th>
                      <th>case_status</th>
                      <th className=''>case_assigned_group</th>
                      <th className=''>effected_amount</th>
                      <th className=''>effected_customer</th>
                      <th className=''>loss</th>
                      <th className=''>correction</th>
                      <th className=''>assured</th>
                      <th className=''>informed_departments</th>
                      <th>case_entry_date</th>
                      <th>case_update_date</th>
                      <th>case_close_date</th>

                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {iads?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      iads?.map((iads: any) => {
                        return (
                          <>
                            <IADSItem key={iads?.id} item={iads} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={handleClose} />
          <DetailFilters
            setCompany={setCompany}
            setCaseTitle={setCaseTitle}
            setCaseStatus={setCaseStatus}
            setRaUser={setRaUser}
            setAssignedGroup={setCaseAssignedGroup}
            handleClose={handleClose}
            setInformedDepartments={setInformedDepartments}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
