import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {ModalHeader} from '../../../components/ModalHeader'
import {useRefresh1} from '../../Context/RefreshAssignment'
import {AddAssignment} from '../../edit-modal/AddAssignment'

const AssignListToolbar: FC = () => {
  const [show, setShow] = useState(false)
  const openAddAssignModal = () => {
    setShow(true)
  }
  const closeAddAssignModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn' onClick={openAddAssignModal}>
          Görev Ekle
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddAssignModal} titleHeader={'Yeni Görev Ekle'} />
          <AddAssignment
            assignment={{
              name: '',
              order: '',
              parent_id: 0,
              is_group_directorate: false,
              is_directorate: false,
            }}
            update={false}
            handleClose={closeAddAssignModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AssignListToolbar}
