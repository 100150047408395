import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {GetMilestone, GetTargetDate} from '../../../core/requests'
import './style.css'

type Props = {
  getDate: any
  days: number
  setCount: any
}

export const TimeLineHeader: FC<Props> = ({getDate, days, setCount}) => {
  const [loading, setLoading] = useState(true)
  const d = new Date()
  let today = new Date()
  let [activeDay, setActiveDay] = useState(new Date())
  const [dates1, setDates1] = useState<any>([])
  const [busyDays, setBusyDays] = useState<any>([])
  let id = JSON.parse(localStorage.getItem('projectID') || '{}')
  const getData = async () => {
    setBusyDays([])
    await GetMilestone(id).then((data) => {
      let toplam = 0
      data?.data?.data?.map((item: any) => {
        GetTargetDate(item?.id).then((data) => {
          toplam += data.data?.length
          data?.data?.data?.map((item: any) => {
            setBusyDays((prev: any) => [...prev, item])
          })
        })
      })
      setCount(toplam)
    })
    setLoading(false)
  }
  useEffect(() => {
    var dates = []
    var count = -(days / 2 - 1).toFixed(0)
    for (let i = 1; i < days; i++) {
      today.setDate(today.getDate() + count)
      dates.push(new Date(today))
      today = new Date()
      count += 1
    }
    setDates1(dates)
    getData()

    return () => {}
  }, [])

  const setActive = (day: any) => {
    setActiveDay(day)
    getDate(day)
  }
  var options = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  //day1?.start_date.split('-').reverse().toString().replaceAll(',','.')

  return (
    <>
      {!loading && (
        <ul className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'>
          {/* {console.log(busyDays)} */}
          {dates1.map((day: any) => {
            // {console.log(day.toLocaleDateString())}
            return (
              <li className='nav-item p-0 ms-0'>
                <a
                  style={{
                    backgroundColor:
                      activeDay.toLocaleDateString('tr') == day.toLocaleDateString('tr')
                        ? '#D6230E'
                        : '',
                  }}
                  className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger ${
                    activeDay.toLocaleDateString('tr') == day.toLocaleDateString('tr')
                      ? 'active'
                      : ''
                  } ${
                    busyDays?.find((day1: any) => {
                      // console.log(moment(day).format( "YYYYMMDD") );

                      return (
                        moment(day).format('YYYY-MM-DD') >= day1?.planned_start_date &&
                        moment(day).format('YYYY-MM-DD') <= day1?.planned_end_date
                      )
                    })
                      ? 'busyDay'
                      : ''
                  } )
              }`}
                  data-bs-toggle='tab'
                  onClick={() => setActive(day)}
                >
                  <span className='fs-7 fw-bold' style={{color: 'rgb(1 13 88)'}}>
                    {day.toLocaleDateString('tr', options).slice(11)}
                  </span>
                  <span className='fs-6 fw-bolder' style={{color: 'rgb(1 13 88)'}}>
                    {day.toLocaleDateString('tr', options).slice(0, 2)}
                  </span>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}
