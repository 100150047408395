import {FC} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PocDetail} from './PocDetail'
import {Poc} from './Poc'
import {PageLink, PageTitle} from '../../../../_theme/layout/core'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
export const PocPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='poc-calismalari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='PoC Çalışmaları' toDescription='/poc-calismalari'>
                PoC Çalışmaları
              </PageTitle>
              <Poc />
            </>
          }
        />
        <Route
          path='detail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='PoC Çalışmaları' toDescription='/poc-calismalari'>
                Detay
              </PageTitle>
              <PocDetail />
            </>
          }
        />

        <Route
          path='poc-calismalari'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='PoC Çalışmaları' toDescription='/projeler/poc-calismalari'>
                PoC Çalışmaları
              </PageTitle>
              <Poc />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/poc-calismalari' />} />
    </Routes>
  )
}
