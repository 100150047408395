/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {PageTitle} from './page-title/PageTitle'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getCSRFToken, getNotifications, readNotifications} from '../../../../app/pages/core/requests'
import {Link} from 'react-router-dom'
import {setToken} from '../../../../app/pages/components/csrf/reducers/CSRFSlice'

const HeaderTopbar: React.FC = () => {
  const {classes} = useLayout()
  const [totalNot, setTotalNat] = useState<any>(0)
  const [searchKey, setSearchKey] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const readNot = async () => {
    await fetchApiData()
    readNotifications()
      .then((res) => {
        setTotalNat(0)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const setId = (key: any) => {
    localStorage.setItem('searchKey', JSON.stringify(key))
  }

  useEffect(() => {
    getNotifications()
      .then((data) => {
        setTotalNat(data?.data?.total_notifications)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div className={`${classes.headerContainer} py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between`}>
      <PageTitle />

      <div className='d-flex align-items-center overflow-auto pt-3 pt-sm-0'>
        <div className='header-search '>
          {/* <Search /> */}
          <div className='d-flex'>
            <div className='d-flex align-items-center position-relative my-3 m-5'>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid ps-8'
                placeholder='Ara'
                onChange={(e) => {
                  setSearchKey(e.target.value)
                  // if (e.target.value == '') navigate('/ana-sayfa')
                }}
                onKeyDown={(event) => {
                  if (event.key == 'Enter') {
                    setSearchKeyClick(searchKey)
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className='d-flex'>
          <div className='d-flex align-items-center me-4'>
            <Link to='./search' state={searchKey} onClick={() => setId(searchKey)} className='btn  btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary'>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-search' viewBox='0 0 16 16'>
                <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
              </svg>
            </Link>
          </div>

          <div className='d-flex align-items-center me-4'>
            {/* eslint-disable-next-line  */}
            <a
              href='#'
              id='kt_drawer_chat_toggle'
              className='btn btn-light btn-icon  btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              style={{position: 'relative'}}
              onClick={readNot}
            >
              <span className={` badge badge-circle ms-1 fs-7 fw-bolder text-white`} style={{position: 'absolute', top: '-10px', right: '-10px', background: '#D6230E'}}>
                {totalNot}
              </span>

              {/* <KTSVG path='/media/icons/duotune/communication/com014.svg' className='svg-icon-1' /> */}
              <i className='fas fa-duotone fa-bell fs-2'></i>
            </a>
          </div>

          <div className='d-flex align-items-center'>
            {/* eslint-disable-next-line */}
            <div className='btn btn-icon btn-primary' data-kt-menu-attach='parent' data-kt-menu-flip='bottom' data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-overflow='false' data-kt-menu-placement='bottom-start'>
              {user.avatar ? <img style={{width: '100%'}} className='rounded' src={user.avatar} alt='' /> : <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-3 '>{user.name?.substring(0, 1)}</div>}
            </div>

            <HeaderUserMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTopbar
