import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {KTCard} from '../../../_theme/helpers'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ModalHeader} from '../components/ModalHeader'
import {NoMatchRecords} from '../components/NoMatchRecords'
import Pagination from '../components/Pagination'
import {ExportLink, getInventories} from '../core/requests'
import HeaderInfo from '../OperationalData/components/HeaderInfo'
import {DetailFilter} from './components/DetailFilter'
import {InventoryItem} from './components/InventoryItem'

export const Inventory: FC = () => {
  const [inventory, setInventory] = useState<any>([])
  const [showFilterModal, setShowFilterModal] = useState(false)
  const openFilterModal = () => {
    setShowFilterModal(true)
    setDeviceStatus('')
    setDeviceType('')
    setMarka('')
    setModel('')
    setUnitDirectorate('')
    setDirectoryEmail('')
  }
  const closeFilterModal = () => {
    setShowFilterModal(false)
  }
  const [marka, setMarka] = useState('')
  const [deviceStatus, setDeviceStatus] = useState('')
  const [deviceType, setDeviceType] = useState('')
  const [model, setModel] = useState('')
  const [unitDirectorate, setUnitDirectorate] = useState('')
  const [directoryEmail, setDirectoryEmail] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/inventories?token=${loginUser.token}`)
  }
  const [screenHeight, setScreenHeight] = useState(window.innerHeight + 100)

  // Ekran boyutu değiştiğinde state'i güncelle
  const handleResize = () => {
    setScreenHeight(window.innerHeight + 100)
  }

  useEffect(() => {
    // Component monte edildiğinde ve ekran boyutu değiştiğinde event listener'ı ekle
    window.addEventListener('resize', handleResize)

    // Component kaldırıldığında event listener'ı temizle
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=10'
    if (marka != '') {
      queryParams += '&marka=' + marka
    }
    if (deviceStatus != '') {
      queryParams += '&cihaz_durumu=' + deviceStatus
    }
    if (deviceType != '') {
      queryParams += '&cihaz_tipi=' + deviceType
    }
    if (model != '') {
      queryParams += '&model=' + model
    }
    if (unitDirectorate != '') {
      queryParams += '&ilgili_birim_mudurluk=' + unitDirectorate
    }
    if (directoryEmail != '') {
      queryParams += '&mudurluk_email_adresi=' + directoryEmail
    }

    getInventories(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setInventory(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [marka, deviceStatus, deviceType, model, unitDirectorate, directoryEmail])

  return (
    <>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          {/* <div className='card border-0 d-flex justify-content-end'>
              <div className='card-header'>
        <div className='d-flex m-3'>
          <UsersListHeader
            refreshPage={refreshPage}
            setWord={setSearchKeyClick}
            setCategoryId={setCategoryId}
          />
          </div>
          </div></div> */}
          <div className='card card-flush  border-0'>
            <div className='card-header'>
              <div className='d-flex justify-content-between w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={''} />
                </div>
                <div>
                  <button className='btn me-3' onClick={openFilterModal}>
                    Detaylı Ara
                  </button>
                  <button className='btn' onClick={exportFile}>
                    Dışarı Aktar
                  </button>
                </div>
              </div>
            </div>
            {/* <div className='card-body pt-3 overflow-auto' style={{maxHeight: screenHeight}}>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder' style={{borderBottom: '2px solid black'}}>
                  <thead
                    className='fs-7 text-gray-400 text-uppercase'
                    style={{
                      position: 'sticky',
                      left: '0',
                      top: '-10px',
                      zIndex: '10',
                      width: '100%',
                      backgroundColor: '#fcfaf8',
                      flexGrow: '0',
                      flexShrink: '0',
                    }}
                  >
                    <tr>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        SERİ NUMARASI
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        İLGİLİ BİRİM MÜDÜRLÜK
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LOKASYON
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        KABİN ADI
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        RACKU BİLGİSİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        CİHAZ TİPİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        MARKA
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        MODEL
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        ZAAFİYET ARAŞTIRMA DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        CİHAZ DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        CİHAZ İP
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        CİHAZ HOSTNAME
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        CİHAZDAKİ LİSANS BİLGİSİ
                      </th>

                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LİSANS SAHİBİ FİRMA
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LİSANS MODELİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LİSANS TİPİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LİSANS BİTİŞ TARİHİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        LİSANS BAŞLANGIÇ TARİHİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        KULLANIM AMACI
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        ALARM İZLEME SİSTEMİ
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        SATIN ALMANIN YAPILDIĞI FİRMA
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        SÜRESİZ GARANTİ LİSANS
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        EDR DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        ANTİVİRUS DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        MFA UYGULAMA DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        SİEMENT ENTEGRASYON DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        BACKUP ALIM DURUMU
                      </th>
                      <th className='' style={{backgroundColor: '#c9c9c9', border: '2px solid black'}}>
                        USER authentication method
                      </th>
                    </tr>
                  </thead>

                  <tbody className=''>
                    {inventory?.length < 1 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      inventory.map((inv: any) => {
                        return (
                          <>
                            <InventoryItem key={inv.id} inventory={inv} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div> */}
            <div id='kt_project_targets_card_pane'>
              <div className='card card-flush  border-0 '>
                <div className='card-body pt-3'>
                  {loading ? (
                    <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                      <thead className='fs-7 text-gray-400 text-uppercase'>
                        <tr>
                          <th className=''>SERİ NUMARASI</th>
                          <th className=''>İLGİLİ BİRİM MÜDÜRLÜK</th>
                          <th className=''>LOKASYON</th>
                          <th className=''>CİHAZ TİPİ</th>
                          <th className=''>MARKA</th>
                          <th className=''>CİHAZ DURUMU</th>
                          <th className=''>CİHAZ İP</th>
                          <th className=''>KULLANIM AMACI</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody className=''>
                        {inventory?.length < 1 ? (
                          <NoMatchRecords isTable={true} />
                        ) : (
                          inventory.map((inv: any) => {
                            return (
                              <>
                                <InventoryItem key={inv.id} inventory={inv} />
                              </>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  )}
                  <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={10} loading={loading} />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={showFilterModal}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilter setMarka={setMarka} setDeviceStatus={setDeviceStatus} setDeviceType={setDeviceType} setModel={setModel} setUnitDirectorate={setUnitDirectorate} setDirectoryEmail={setDirectoryEmail} handleClose={closeFilterModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
