import {FC, Key, useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/css'
import {getAnnoun} from '../core/_requests'
import {Link} from 'react-router-dom'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import {Navigation, Pagination, Autoplay} from 'swiper'
import './style.css'

import $ from 'jquery'
import moment from 'moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  className: string
  textClass: string
  heightProp?: string
}

export const Banner: FC<Props> = ({className, textClass, heightProp}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState<boolean>(false)
  const [announ, setAnnoun] = useState<any>([])
  const [swiper, setSwiper] = useState<any>(null)
  const [height, setHeight] = useState(false)
  useEffect(() => {
    getAnnoun().then((data) => {
      setAnnoun(data.data.data)
      setHeight(true)
    })
  }, [])
  const pagination = {
    clickable: true,
  }

  $('.swiper-pagination-bullet').hover(function () {
    $(this).trigger('click')
  })
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        autoHeight={true}
        pagination={{
          clickable: true,
        }}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{delay: 2500}}
        onSwiper={(s) => {
          setSwiper(s)
        }}
        style={{height: heightProp}}
      >
        {announ?.map((announ: any, index: Key | null | undefined) => {
          return announ?.files?.length > 0 ? (
            <SwiperSlide key={index}>
              <Link
                to={loginUser?.permissions.filter((permission) => permission.name == 'Show Announcement').length > 0 ? '/duyurular/detail' : ''}
                state={announ}
                onClick={() => {
                  setShow(!show)
                }}
                className={`card bgi-no-repeat bgi-size-cover overflow-hidden max-w-100px  ${className}`}
                style={{
                  backgroundImage: announ?.files?.length > 0 ? ` url(${announ?.files[0]?.path})` : '',
                  backgroundPosition: '100% 100%',
                  backgroundSize: '100% 100%',
                  height: 'auto',
                  minHeight: '400px',
                }}
              >
                {/* <Link
                    to='/duyurular/detail'
                    state={announ}
                 
                  
                    onClick={() => {
                      setShow(!show)
                    }}
                  > */}

                <img src={announ?.files[0]?.path} style={{visibility: 'hidden', height: heightProp}} />
                {/* </Link> */}

                {/* begin::Body */}
                <div
                  style={{
                    backgroundColor: 'white',
                    height: '450px',
                    width: '600px',
                    top: '20%',
                    left: '-15%',
                    position: 'absolute',
                    opacity: 0.8,
                    transform: 'rotate(35deg)',
                  }}
                ></div>
                <div
                  className=' d-flex flex-column justify-content-center ps-lg-15'
                  style={{
                    position: 'absolute',
                    top: '55%',
                  }}
                >
                  <span
                    className={`${textClass} fs-6  text-dark d-flex`}
                    dangerouslySetInnerHTML={{
                      __html: moment(announ?.start_date).format('DD.MM.YYYY'),
                    }}
                    style={{}}
                  ></span>
                  <span
                    className={`${textClass} fs-4 mb-3 mb-lg-10 text-dark  d-flex`}
                    style={{width: '250px', marginLeft: '0px', textAlign: 'left'}}
                    dangerouslySetInnerHTML={{
                      __html: announ?.title,
                    }}
                  ></span>

                  <span
                    className={`${textClass} fs-6  mb-4 mb-lg-8 text-dark d-flex`}
                    dangerouslySetInnerHTML={{
                      __html: announ?.category?.name,
                    }}
                    style={{}}
                  ></span>
                </div>
                {/* </div> */}

                <div className='m-0' style={{position: 'absolute', bottom: '10px', left: '15px'}}>
                  {/* eslint-disable-next-line */}
                </div>
              </Link>
            </SwiperSlide>
          ) : (
            ''
          )
        })}
      </Swiper>

      {/* <Main
        show={show}
        handleClose={() => {
          setShow(false)
        }}
      /> */}
    </>
  )
}
