import moment from 'moment'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {ID} from '../../../_theme/helpers'

type Props = {
  id: ID
  title: string
  categoryName: string
  start_date: string
  end_date: string
  userName: string
  status: string
  issue: any
  assigned_id: any
}
export const ListIssues: FC<Props> = ({id, title, categoryName, start_date, end_date, issue, assigned_id}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder '>
          <Link to='/faaliyetler/detail' state={issue} className='text-black'>
            <span className='text-hover-primary'>{title}</span>
          </Link>
        </td>
        <td>
          <span className='badge badge-light fw-bold me-auto'>{categoryName}</span>
        </td>
        <td> {moment(start_date).format('DD.MM.YYYY')}</td>
        <td>{moment(end_date).format('DD.MM.YYYY')}</td>
        <td className=''>
          <div className='symbol-group symbol-hover fs-8'>
            {/* <div
              className='symbol symbol-25px symbol-circle'
              data-bs-toggle='tooltip'
              title='Melody Macy'
            >
              <img alt='Pic' src='assets/media/avatars/300-2.jpg' />
            </div>
            <div
              className='symbol symbol-25px symbol-circle'
              data-bs-toggle='tooltip'
              title='John Mixin'
            >
              <img alt='Pic' src='assets/media/avatars/300-14.jpg' />
            </div> */}
            {/* <div
              className='symbol symbol-25px symbol-circle'
              data-bs-toggle='tooltip'
             
            > */}
            {/* <span className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                {issue.assigned!==null ? issue.assigned.name : ''}
              </span> */}
            <span className=''>{issue?.assigned !== null ? issue?.assigned?.name : ''}</span>
            {/* </div> */}
          </div>
        </td>
        <td>
          <span data-kt-menu-trigger='click' className={`badge badge-${issue.status.id == '1' ? 'light-warning' : issue.status.id == '0' ? 'light-primary' : 'light-success'} fw-bolder me-auto`}>
            {issue?.status?.name}
          </span>
          {/* <SquareButton status={false} issue={issue} />   */}
          {/* <EditStatus issue={issue} handleClose={undefined} />  */}
        </td>
        {/* <td> */}
        {/* <a
            href='#'
            className='btn btn-bg-light btn-active-color-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            
          >
            İşlem
          </a> */}
        {/* <SquareButton status={false} issue={issue} /> */}
        {/* <EditStatus issue={issue} />  */}
        {/* </td> */}
        <td className='text-end'>
          <div className='d-flex flex-row-reverse'>
            {/* <Button
              className='w-15 '
              variant='primary'
              onClick={() => {
                setModalUpdate(true)
                handleShow()
              }}
            >
              Düzenle
            </Button> */}
            {/* <ButtonActive text={'Düzenle'} clickFunc={handleShow}/> */}
          </div>
        </td>
        <td>{/* <ButtonActive text={'Sil'} clickFunc={swalDelete} /> */}</td>
      </tr>
      {/* <Modal show={modalShow} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddActivity
            setModalShow={handleClose}
            activity={{
              id: id,
              title: title,
              content: issue.content,
              start_date: '2022-01-01',
              end_date: end_date,
              phone_notification: issue.phone_notification,
              email_notification: issue.email_notification,
              comments: issue.comments,
              issue_category_id: issue.category.id,
              assigned_by: assigned_id,
            }}
            update={true}
          />
          {setRefresh(id)}
        </Modal.Body>
      </Modal> */}
    </>
  )
}
