import { Budged } from './../../Project/project-edit/components/Budget';
import axios from 'axios';
import { API_URL, config } from './../../request';

const BUDGETS=`${API_URL}/budgets`
const BUDGET_COMPANIES=`${API_URL}/budget_companies`
const BUDGET_PURCHASE_TYPES=`${API_URL}/budget_purchase_types`
const BUDGET_TYPES=`${API_URL}/budget_types`
const BUDGET_CATEGORIES=`${API_URL}/budget_categories`
const PURCHASE_STEPS = `${API_URL}/purchase_steps`
const PURCHASE =`${API_URL}/purchases`
const ORDER = `${API_URL}/orders`
const WIDGET = `${API_URL}/widgets/budgets`



// budgets

export async function GetBudgets(budget_type_id:string,word: string){
    return await axios.get<any>(BUDGETS+'?budget_type_id='+budget_type_id+word)
}

export async function CreateBudget(budget: any){
    return await axios.post<any>(BUDGETS,budget,config)
}

export async function DeleteBudget(budget_id:any) {
    return await axios.delete<any>(BUDGETS+'/'+budget_id)
}

export async function UpdateBudget(budget_id: any, budget: any){
    return await axios.put<any>(BUDGETS+'/'+budget_id, budget)
}

export async function GetMonthlyBudget( budget_type_id: string, period: string){
    return await axios.get<any>(PURCHASE+'?budget_type_id='+budget_type_id+'&period='+period)
}

// budget companies

export async function  GetBudgetCompanies(){
    return await axios.get<any>(BUDGET_COMPANIES)
}

// budget types
export async function  GetBudgetTypes(){
    return await axios.get<any>(BUDGET_TYPES)
}

//budget purchase types
export async function  GetBudgetPurchaseTypes(){
    return await axios.get<any>(BUDGET_PURCHASE_TYPES)
}

//budget categories
export async function  GetBudgetCategories(){
    return await axios.get<any>(BUDGET_CATEGORIES)
}

//purchases
export async function GetPurchases(budget_id: any){
    return await axios.get<any>(BUDGETS+'/'+budget_id+'/purchases')
}

export async function CreatePurchase(purchase: any){
    return await axios.post<any>(PURCHASE, purchase, config)
}

export async function DeletePurchase(purchase_id: any){
    return await axios.delete<any>(PURCHASE+'/'+purchase_id)
}
export async function UpdatePurchase(purchase_id: any, purchase: any){
    return await axios.put<any>(PURCHASE+'/'+purchase_id, purchase)
}
//purchase_steps
export async function GetPurchaseSteps(budget_id: any){
    return await axios.get<any>(BUDGETS+'/'+budget_id+'/purchase_steps')
}

export async function CreatePurchaseStep(purchaseSteps: any){
    return await axios.post<any>(PURCHASE_STEPS, purchaseSteps, config)
}

export async function DeletePurchaseStep(id: any){
    return await axios.delete<any>(PURCHASE_STEPS+'/'+id)
}

export async function UpdatePurchaseStep(id: any, purchaseStep: any){
    return await axios.put<any>(PURCHASE_STEPS+'/'+id, purchaseStep)
}
//orders

export async function GetOrders(budget_id:any) {
    return await axios.get<any>(BUDGETS+'/'+budget_id+'/orders')
}

export async function CreateOrder(order: any) {
    return await axios.post<any>(ORDER, order, config)
}

export async function DeleteOrder(id: any){
    return await axios.delete<any>(ORDER+'/'+id)
}

export async function UpdateOrder(id:any, order: any){
    return await axios.put<any>(ORDER+'/'+id, order)
}
// documents

export async function GetBudgetAttachments(budget_id: any) {
    return await axios.get<any>(BUDGETS+'/'+budget_id+'/attachments')
}
export async function GetPurchaseAttachments(budget_id: any) {
    return await axios.get<any>(PURCHASE+'/'+budget_id+'/attachments')
}


export async function CreatePurchaseAttachment(budget_id: any, files:any) {
    return await axios.post<any>(PURCHASE+'/'+budget_id+'/attachments',files,config)
    
}

export async function CreateBudgetAttachment(budget_id: any, files:any) {
    return await axios.post<any>(BUDGETS+'/'+budget_id+'/attachments',files,config)
    
}

export async function DeletePurchaseAttachment(budget_id: any, file_id: any) {
    return await axios.delete<any>(PURCHASE+'/'+budget_id+'/attachments/'+file_id)
}

export async function DeleteBudgetAttachment(budget_id: any, file_id: any) {
    return await axios.delete<any>(BUDGETS+'/'+budget_id+'/attachments/'+file_id)
}
// widgets 

export async function GetTotalBudget() {
    return await axios.get<any>(WIDGET+'/totalBudget')
}

export async function GetExchangeRates(){
    return await axios.get<any>(WIDGET+'/exchangeRates')
}

export async function GetBudgetByCategory(){
    return await axios.get<any>(WIDGET+'/totalBudgetByCategory')
}

export async function GetMonthlyTotalOpex(){
    return await axios.get<any>(WIDGET+'/monthlyTotalOpex')
}

export async function GetMonthlyTotalCapex(){
    return await axios.get<any>(WIDGET+'/monthlyTotalCapex')
}