import clsx from 'clsx'
import moment from 'moment'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row} from 'react-table'
import {User} from '../../../modules/apps/user-management/users-list/core/_models'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'

type Props = {
  user: Row<User>
}

const UserListItem: FC<Props> = ({user}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState<any>(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <tr>
        <td className='text-start d-flex align-items-center'>
          <div className='symbol symbol-circle symbol-35px me-3' data-bs-toggle='tooltip' data-bs-boundary='window' data-bs-placement='top' title={user?.original?.name}>
            {user.original.avatar ? (
              <div className='symbol symbol-35px symbol-circle'>
                <img src={user?.original?.avatar} alt={user?.original?.name} className='' />
              </div>
            ) : (
              <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{user?.original?.name?.substring(0, 1)}</div>
            )}{' '}
          </div>
          <div>
            <span
              className='cursor-pointer'
              onClick={async () => {
                setProfilUserId(user.original.id)
                await openProfilModal()
              }}
            >
              {user.original.name}
            </span>{' '}
            <br />
            {user.original.email}
          </div>
        </td>

        <td className='text-end'> {user?.original?.created_at && moment(user?.original?.created_at).format('DD.MM.YYYY')}</td>
      </tr>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {UserListItem}
