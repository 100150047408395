import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  isam: any
}
export const DSRNItem: FC<Props> = ({isam}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{isam?.server_name}</td>
        <td className='text-gray-500'>{isam?.ip_address}</td>
        <td className='text-gray-500'>{isam?.os_name}</td>
        <td className='text-gray-500'>{isam?.db_types}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
