import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {GetBudgets} from '../../core/requests'
import {DetailFilter} from '../Budget-edit/DetailFilter'
import {BudgetItem} from './BudgetItem'
import {BudgetHeader} from './header/BudgetHeader'

export const BudgetsList: FC = () => {
  const [budget, setBudget] = useState([
    {
      id: 1,
      title: '',
      amount: 30000000,
      currency: 'TRY',
      year: 2022,
      company_contract_status: false,
      internal_signature_status: false,
      general_admission_status: false,
      invoice_status: false,
      migo_status: false,
      assignment: {
        id: 1,
        name: '',
        order: 2,
        parent_id: 4,
        is_group_directorate: false,
        is_directorat: false,
        is_last: false,
      },
      owner: {
        id: 5,
        name: 'Doğan Emre Öztaş',
        email: 'user5@ecodation.com',
        avatar: 'https://sgd.ecodation.com/storage/713edffcdcf3673864243392ac1f81d6/doğan emre öztaş.jfif',
        roles: [
          {
            id: 10,
            name: 'Müdür',
          },
        ],
      },
      type: {
        id: 1,
        name: 'Capex',
      },
      category: {
        id: 2,
        name: 'Lisans',
      },
      company: {
        id: 2,
        name: 'TT',
      },
      purchase_type: {
        id: 3,
        name: 'Çerçeve',
      },
      created_at: '',
      created_by: {
        id: 1,
        name: '',
        email: '',
        avatar: '',
        roles: [
          {
            id: 11,
            name: '',
          },
        ],
      },
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterWord, setFilterWord] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const [showFilterModal, setShowFilterModal] = useState(false)
  const openFilterModal = () => {
    setCategoryId('')
    setCompanyId('')
    setPurchaseTypeId('')
    setTypeId('')
    setAssignment('')
    setShowFilterModal(true)
  }
  const closeFilterModal = () => {
    setShowFilterModal(false)
  }
  const [categoryId, setCategoryId] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [purchaseTypeId, setPurchaseTypeId] = useState('')
  const [typeId, setTypeId] = useState('')
  const [assignment, setAssignment] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=' + limit
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (typeId != '') {
      queryParams += '&budget_type_id=' + typeId
    }
    if (companyId != '') {
      queryParams += '&budget_company_id=' + companyId
    }
    if (purchaseTypeId != '') {
      queryParams += '&budget_purchase_type_id=' + purchaseTypeId
    }
    if (categoryId != '') {
      queryParams += '&budget_category_id=' + categoryId
    }
    if (assignment != '') {
      queryParams += '&assignment_id=' + assignment
    }

    GetBudgets(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setBudget(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, filterWord, categoryId, typeId, purchaseTypeId, companyId, assignment])

  return (
    <>
      <div id='kt_project_targets_card_pane'>
        <BudgetHeader refreshPage={refreshPage} setWord={setSearchKeyClick} setFilterWord={setFilterWord} openFilterModal={openFilterModal} />
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Yükleniyor...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-dashed gy-6 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
                  <tr>
                    <th className=''>BAŞLIK</th>
                    <th className=''>GÖREVLİ</th>
                    <th className=''>SAHİP</th>
                    <th className=''>TUTAR</th>
                    <th className=''></th>
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {budget?.length < 1 ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    budget.map((budg) => {
                      return <BudgetItem key={budg.id} budget={budg} refreshPage={refreshPage} />
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} loading={loading} />
      </div>
      <Modal show={showFilterModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeFilterModal} titleHeader={'Detaylı Ara'} />
          <DetailFilter setCategoryId={setCategoryId} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} setCompanyId={setCompanyId} setPurchaseTypeId={setPurchaseTypeId} setTypeId={setTypeId} setAssignment={setAssignment} />
        </Modal.Body>
      </Modal>
    </>
  )
}
