import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setSeverity: any
  setStatus: any
  setOccured: any
  setType: any
  setName: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilters: FC<Props> = ({
  setSeverity,
  setStatus,
  setOccured,
  setType,
  setName,
  handleClose,
  setSearchKeyClick,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setSeverity(selectedSeverity)
    setStatus(selectedStatus)
    setOccured(selectedOccured)
    setName(selectedName)
    setType(selectedType)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [inputWord, setInputWord] = useState('')
  const [selectedSeverity, setSelectedSeverity] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedOccured, setSelectedOccured] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [selectedType, setSelectedType] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Name</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedName(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Severity</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedSeverity(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Status</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Type</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedType(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Occured</label>
        <input
          placeholder=''
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedOccured(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
