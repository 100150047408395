import React, {FC, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {getAnnounCategories} from '../core/_requests'
import {Category} from './Category'

type Props = {
  setCategoryId: any
  setAnnouncements: any
  setCurrentPage: any
}
export const Categories: FC<Props> = ({setCategoryId, setAnnouncements, setCurrentPage}) => {
  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  useEffect(() => {
    getAnnounCategories().then(({data}) => {
      setCat(data.data)
    })
  }, [])

  return (
    <div className='card card-flush bg-transparent card-p-0 border-0 shadow-0 mb-9'>
      <div className=''>
        <h4 className='card-title fw-bolder text-dark px-2'>Kategoriler</h4>
      </div>

      <div className='card-body scroll-y' style={{height: '435px'}}>
        {categories.map((cat, index) => {
          return (
            <Category
              key={index}
              category={cat}
              setCategoryId={setCategoryId}
              setAnnouncements={setAnnouncements}
              setCurrentPage={setCurrentPage}
            />
          )
        })}
      </div>
    </div>
  )
}
