import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {UsersListWrapper} from '../../modules/apps/user-management/users-list/UsersList'
import {ErrorComponent} from '../components/ErrorComponent'
import {AssignmentsPage} from './AssignmentsPage'
import {Profile} from '../Profile'
import {RolesPage} from './RolesPage'
import {UsersPage} from './UsersPage'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const OrganizationPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='personel-listesi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Organizasyon Yapısı' toDescription='/organizasyon-yapisi/personel-listesi'>
                Personel Listesi
              </PageTitle>
              <UsersPage />
            </>
          }
        />

        <Route
          path='rol-yapisi'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Organizasyon Yapısı' toDescription='/organizasyon-yapisi/personel-listesi'>
                Rol Yapısı
              </PageTitle>
              {/* <ErrorComponent/> */}
              <RolesPage />
            </>
          }
        />
        <Route
          path='profile'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Organizasyon Yapısı' toDescription='/organizasyon-yapisi/personel-listesi'>
                Personel Bilgisi
              </PageTitle>{' '}
              <Profile />
            </>
          }
        />
        <Route
          path='atamalar'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Organizasyon Yapısı' toDescription='/organizasyon-yapisi/personel-listesi'>
                Görevler
              </PageTitle>
              {/* <ErrorComponent/> */}
              <AssignmentsPage />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/personel-listesi' />} />
      {/* <Route path='/personel-listesi/roles' element={<Navigate to='/roles' />} /> */}
    </Routes>
  )
}
