import {FC, useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {EditStatus} from './EditStatus'
import '../../style/style-swal.css'
import {ModalHeader} from '../../components/ModalHeader'
import {AddActivity} from '../activity-edit-modal/addActivity'
import swal from 'sweetalert'
import {DeleteIssue} from '../core/_requests'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  status: boolean
  issue: any
}
export const SquareButton: FC<Props> = ({status, issue}) => {
  const [modalShow, setModalshow] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const handleShoweditModal = () => setEditModal(true)
  const handleCloseEditModal = () => setEditModal(false)
  const handleShow = () => setModalshow(true)
  const handleClose = () => setModalshow(false)
  const {refreshPage} = useRefresh1()
  async function swalDelete() {
    try {
      const response = await swal({
        title: '',
        text: 'Kayıt silinecektir emin misiniz?',
        icon: 'warning',
        buttons: ['Vazgeç', 'Evet'],
      })

      if (response) {
        const data = await DeleteIssue(issue.id)
        swal('Silindi!', 'Görev başarı ile silindi.', 'success')
        refreshPage()
      }
    } catch (error) {
      console.error('Hata:', error)
      swal('Hata!', 'Görev silinirken bir hata oluştu.', 'error')
    }
  }

  return (
    <>
      <span
        className={` btn-sm btn-icon btn-color-light-dark ${status ? 'btn-active-light-primary' : ''}`}
        data-kt-menu-trigger='click'
        // onClick={handleShow}
      >
        {status ? (
          <Dropdown>
            <Dropdown.Toggle className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShoweditModal}>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleShow}>
                {' '}
                <span className='menu-link px-3'>Durumunu değiştir</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={swalDelete}>
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <span data-kt-menu-trigger='click' className={`badge badge-${issue.status.id == '1' ? 'light-warning' : issue.status.id == '0' ? 'light-primary' : 'light-success'} fw-bolder me-auto`}>
            {issue.status.name}
          </span>
        )}
      </span>
      <Modal show={modalShow} className='w-300px modal m-auto'>
        <Modal.Body>
          <EditStatus
            issue={{
              id: issue.id,
              title: issue.title,
              content: issue.content,
              start_date: issue.start_date,
              end_date: issue.end_date,
              phone_notification: issue.phone_notification,
              email_notification: issue.email_notification,
              issue_category_id: issue.issue_category_id,
              assigned_by: issue.asigned_by,
              status: issue.status,
            }}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
      <Modal show={editModal} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseEditModal} titleHeader={'Düzenle'} />
          <AddActivity handleClose={handleCloseEditModal} activity={issue} update={true} />
          {/* {setRefresh(id)} */}
        </Modal.Body>
      </Modal>
    </>
    //   <button
    //     type='button'
    //     className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'
    //     data-kt-menu-trigger='click'
    //   >
    // <span className='svg-icon svg-icon-2'>İşlem</span>
    //     </button>
  )
}
