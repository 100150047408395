import {auto} from '@popperjs/core'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {
  getActivityCategories,
  getStaticWidget,
  getUserByAssignment,
} from '../../Activities/core/_requests'
import {getAnnounCategories} from '../../Announcements/core/_requests'
import {getAssignments, getUsers} from '../../core/requests'
import { GetAssignments } from '../../OrganizationalStructure/core/_requests'
import {getProjectCat, getProjectClass, getProjectType} from '../../Project/core/requests'

type Props = {
  item: any
}

export const UpdatedProject: FC<Props> = ({item}) => {

  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [assignments, setAssignments] = useState<any>([])
  const [types, setTypes] = useState<any>([])
  const [cats, setCats] = useState<any>([])
  const [classes, setClasses] = useState<any>([])

  useEffect(() => {

    GetAssignments().then((res) => {
      setAssignments(res.data.data)
    })
    getProjectType().then((res) => {
      setTypes(res.data.data)
    })
    getProjectCat().then((res) => {
      setCats(res.data.data)
    })
    getProjectClass().then((res) => {
      setClasses(res.data.data)
    })
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
  }, [])
  const [userForEdit] = useState<any>({
    ...item?.changes?.old,
    id: item?.changes?.old?.id,
    code: item?.changes?.old?.code,
    name: item?.changes?.old?.name,
    description: item?.changes?.old?.description,
    group_directorate_id: item?.changes?.old?.group_directorate_id,
    directorate_id: item?.changes?.old?.directorate_id,
    first_owner: item?.changes?.old?.first_owner,
    second_owner: item?.changes?.old?.second_owner,
    start_date: item?.changes?.old?.start_date,
    end_date: item?.changes?.old?.end_date,
    project_status_id: item?.changes?.old?.status?.id,
    email_notification: item?.changes?.old?.email_notification,
    phone_notification: item?.changes?.old?.phone_notification,
    project_category_id: item?.changes?.old?.project_category_id,
    project_type_id: item?.changes?.old?.project_type_id,
    project_class_id: item?.changes?.old?.project_class_id,
    status: item?.changes?.old?.status?.id,
    risk: item?.changes?.old?.risk,
    escalation: item?.changes?.old?.escalation,
  })
  const [userForUpdated] = useState<any>({
    ...item?.changes?.attributes,
    id: item?.changes?.attributes?.id,
    code: item?.changes?.attributes?.code,
    name: item?.changes?.attributes?.name,
    description: item?.changes?.attributes?.description,
    group_directorate_id: item?.changes?.attributes?.group_directorate_id,
    directorate_id: item?.changes?.attributes?.directorate_id,
    first_owner: item?.changes?.attributes?.first_owner,
    second_owner: item?.changes?.attributes?.second_owner,
    start_date: item?.changes?.attributes?.start_date,
    end_date: item?.changes?.attributes?.end_date,
    project_status_id: item?.changes?.attributes?.status?.id,
    email_notification: item?.changes?.attributes?.email_notification,
    phone_notification: item?.changes?.attributes?.phone_notification,
    project_category_id: item?.changes?.attributes?.project_category_id,
    project_type_id: item?.changes?.attributes?.project_type_id,
    project_class_id: item?.changes?.attributes?.project_class_id,
    status: item?.changes?.attributes?.status?.id,
    risk: item?.changes?.attributes?.risk,
    escalation: item?.changes?.attributes?.escalation,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })
  const formik1 = useFormik({
    initialValues: userForUpdated,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })

  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row d-flex'>
            {item?.changes?.old?.code && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Proje Kodu</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik.getFieldProps('code')}
                    type='text'
                    name='code'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', 'line-through')}
                    autoComplete='off'
                    disabled={true}
                    style={{textDecoration: 'line-through'}}
                  />
                </div>
              </div>
            )}

            {item?.changes?.attributes?.code && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Yeni Proje Kodu</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik1.getFieldProps('code')}
                    // defaultValue={item?.changes?.attributes?.title}
                    type='text'
                    name='code'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled={true}
                  />

                  {/* end::Input */}
                </div>
              </div>
            )}

            {/* end::Input Kategori */}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.name && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Proje Adı</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', 'line-through')}
                    autoComplete='off'
                    disabled={true}
                    style={{textDecoration: 'line-through'}}
                  />
                </div>
              </div>
            )}

            {item?.changes?.attributes?.name && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Yeni Proje Adı</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik1.getFieldProps('name')}
                    // defaultValue={item?.changes?.attributes?.title}
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled={true}
                  />

                  {/* end::Input */}
                </div>
              </div>
            )}

            {/* end::Input Kategori */}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.group_directorate_id && (
              <div className='col-xs-12 mb-7 col-md-6'>
                <label className=' fw-bold fs-7 mb-2'>Grup Müdürlüğü</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('group_directorate_id')}
                  name='group_directorate_id'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_group_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.group_directorate_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Grup Müdürlüğü</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('group_directorate_id')}
                  name='group_directorate_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_group_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
            )}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.directorate_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Müdürlük</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('directorate_id')}
                  name='directorate_id'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {assignments.map((as: any) => {
                    if (as?.is_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.directorate_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Müdürlük</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('directorate_id')}
                  name='directorate_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {assignments.map((as: any) => {
                    if (as?.is_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
            )}
          </div>

          <div className='row  d-flex'>
            {item?.changes?.old?.first_owner && (
              <div className='col-xs-12 mb-7 col-md-6'>
                <label className=' fw-bold fs-7 mb-2'>Proje Yöneticisi</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('first_owner')}
                  name='first_owner'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.first_owner && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Proje Yöneticisi</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('first_owner')}
                  name='first_owner'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          <div className='row  d-flex'>
            {item?.changes?.old?.second_owner && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Proje Teknik Yöneticisi</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('second_owner')}
                  name='second_owner'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.second_owner && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Proje Teknik Yöneticisi</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('second_owner')}
                  name='second_owner'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          <div className='row  d-flex'>
            {item?.changes?.old?.project_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Proje Kategorisi</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('project_category_id')}
                  name='project_category_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {cats.map((cat: any) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.project_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Yeni Proje Kategorisi</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('project_category_id')}
                  name='project_category_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {cats.map((cat: any) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          {/* start anouns */}
          <div className='row  d-flex'>
            {item?.changes?.old?.project_type_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Proje Türü</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('project_type_id')}
                  name='project_type_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.project_type_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Yeni Proje Türü</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('project_type_id')}
                  name='project_type_id'
                  disabled={true}
                >
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          {/* end anouns */}

          <div className='row '>
            {item?.changes?.old?.start_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Başlangıç Tarihi</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                />
              </div>
            )}

            {item?.changes?.attributes?.start_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Yeni Başlangıç Tarihi</label>

                <input
                  placeholder=''
                  {...formik1.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                />
              </div>
            )}
          </div>
          <div className='row  d-flex'>
            {item?.changes?.old?.end_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                />
              </div>
            )}

            {item?.changes?.attributes?.end_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Yeni Bitiş Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik1.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                />
              </div>
            )}

            {/* end::Input Bitiş tarihi */}
          </div>

          {item?.changes?.old?.description && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Proje Amacı</label>
              {/* end::Label */}
              <textarea
                placeholder='Faaliyet için açıklama yazınız'
                {...formik.getFieldProps('description')}
                name='description'
                className='form-control form-control-solid mb-3 mb-lg-0'
                disabled={true}
                style={{textDecoration: 'line-through'}}
              ></textarea>
            </div>
          )}
          {item?.changes?.attributes?.description && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Yeni Proje Amacı</label>
              {/* end::Label */}
              <textarea
                placeholder='Faaliyet için açıklama yazınız'
                {...formik1.getFieldProps('description')}
                name='description'
                className='form-control form-control-solid mb-3 mb-lg-0'
                disabled={true}
              ></textarea>
            </div>
          )}

          <div className='row d-flex'>
            {item?.changes?.old?.risk && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Risk</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik.getFieldProps('risk')}
                    type='text'
                    name='risk'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', 'line-through')}
                    autoComplete='off'
                    disabled={true}
                    style={{textDecoration: 'line-through'}}
                  />
                </div>
              </div>
            )}

            {item?.changes?.attributes?.risk && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Yeni Risk</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik1.getFieldProps('risk')}
                    // defaultValue={item?.changes?.attributes?.title}
                    type='text'
                    name='risk'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled={true}
                  />

                  {/* end::Input */}
                </div>
              </div>
            )}

            {/* end::Input Kategori */}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.escalation && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Eskalasyon</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik.getFieldProps('escalation')}
                    type='text'
                    name='escalation'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', 'line-through')}
                    autoComplete='off'
                    disabled={true}
                    style={{textDecoration: 'line-through'}}
                  />
                </div>
              </div>
            )}

            {item?.changes?.attributes?.escalation && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Yeni Eskalasyon</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik1.getFieldProps('escalation')}
                    // defaultValue={item?.changes?.attributes?.title}
                    type='text'
                    name='escalation'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled={true}
                  />

                  {/* end::Input */}
                </div>
              </div>
            )}

            {/* end::Input Kategori */}
          </div>

          <div className='row  d-flex'>
            {item?.changes?.old?.project_class_id && (
              <div className='col-xs-12 mb-7 col-md-6'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Proje Sınıfı</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('project_class_id')}
                  name='project_class_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.project_class_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Proje Sınıfı</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('project_class_id')}
                  name='project_class_id'
                  disabled={true}
                >
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>



          {/* begin:: Bildirimler */}
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirim</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center' style={{textDecoration: 'line-through'}}>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={item?.changes?.old?.email_notification}
                    disabled={true}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={item?.changes?.old?.phone_notification}
                    disabled={true}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'></div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={item?.changes?.attributes?.email_notification}
                    disabled={true}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={item?.changes?.attributes?.phone_notification}
                    disabled={true}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          {/* end:: Bildirimler */}

          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
