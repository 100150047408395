import React, {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {ExportLink, getProjects, updateHomeWidgets} from '../../core/requests'
import {getIssues, getWeeks} from '../core/_requests'
import {TargetItem2} from './TargetItem2'
import swal from 'sweetalert'
import {ProjectItem} from '../../Home/components/ProjectItem'
import {Button} from 'react-bootstrap'
import {GetAssignments} from '../../OrganizationalStructure/core/_requests'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  widgetId: any
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
}
const TargetListsHomePage: FC<Props> = ({widgetId, plusOrMinus, linkToPage}) => {
  const {refreshPage} = useRefresh1()
  const [period, setPeriod] = useState('')
  const [issues, setIssues] = useState<any>([])
  const [assignments, setAssignments] = useState<any>([])
  const [selectedAssignment, setSelectedAssignment] = useState('')
  const [weeks, setWeeks] = useState<any>([])
  const [selectedWeek, setSelectedWeek] = useState('')
  const [loading, setLoading] = useState(true)
  let date = new Date()
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', ' Eylül', 'Ekim', 'Kasım', 'Aralık']
  const exportFile = () => {
    window.open(`${ExportLink}/issues?token=${loginUser.token}&week=${selectedWeek}&period=${period}&assignment_id=${selectedAssignment}`)
  }
  const deleteListIssue = () => {
    swal({
      title: '',
      text: 'Widget silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: false}]})
        await refreshPage()
      }
    })
  }
  useEffect(() => {
    GetAssignments().then(({data}) => {
      setAssignments(data?.data)
    })
    getWeeks().then(({data}) => {
      setWeeks(data?.data)
    })
  }, [])
  useEffect(() => {
    if (widgetId == '23') {
      getIssues('?period=' + period + '&assignment_id=' + selectedAssignment + '&week=' + selectedWeek).then(async ({data}) => {
        setIssues(data?.data)
        await setLoading(false)
      })
    } else if (widgetId == '75') {
      getProjects('?period=' + period).then(async ({data}) => {
        setIssues(data?.data)
        await setLoading(false)
      })
    }
  }, [period, selectedAssignment, selectedWeek])
  return (
    <div className='card-header card-header-stretch mb-2 rounded' style={{backgroundColor: '#f6f7f8', padding: '30px'}}>
      <div className='d-flex justify-content-between'>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div>
            <div className='card-title d-flex align-items-center text-end'>
              <span className='svg-icon svg-icon-1 svg-icon-primary me-3 lh-0'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path opacity='0.3' d='M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z' fill='currentColor' />
                  <path
                    d='M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z'
                    fill='currentColor'
                  />
                  <path
                    d='M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>

              <h3 className='fw-bolder m-0 text-gray-800'>
                {date.getDate().toString()} {months[date.getMonth()]} {date.getFullYear().toString()}
              </h3>
            </div>
          </div>
        </Link>
        <div className='my-0 me-5 d-flex'>
          {widgetId == '23' && (
            <>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-250px h-40px me-4'
                onChange={(e) => setSelectedWeek(e.target.value)}
              >
                <option value=''>Tümü</option>
                {weeks.map((week: any) => {
                  return (
                    <option key={week?.id} value={week?.id}>
                      {week?.name}
                    </option>
                  )
                })}
              </select>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-250px h-40px me-4'
                onChange={(e) => setSelectedAssignment(e.target.value)}
              >
                <option value=''>Tümü</option>
                {assignments.map((assignment: any) => {
                  return (
                    <option key={assignment.id} value={assignment.id}>
                      {assignment.name}
                    </option>
                  )
                })}
              </select>
            </>
          )}

          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder ' role='tablist'>
            <li className='nav-item'>
              <a
                id='kt_activity_today_tab'
                className={`nav-link justify-content-center text-active-gray-800 ${period === 'daily' ? 'active' : ''}`}
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_today'
                onClick={() => setPeriod('daily')}
              >
                Bugün
              </a>
            </li>
            <li className='nav-item'>
              <a
                id='kt_activity_week_tab'
                className={`nav-link justify-content-center text-active-gray-800 ${period === 'weekly' ? 'active' : ''}`}
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_week'
                onClick={() => setPeriod('weekly')}
              >
                Hafta
              </a>
            </li>
            <li className='nav-item'>
              <a
                id='kt_activity_month_tab'
                className={`nav-link justify-content-center text-active-gray-800 ${period === 'monthly' ? 'active' : ''}`}
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_month'
                onClick={() => setPeriod('monthly')}
              >
                Ay
              </a>
            </li>
            <li className='nav-item'>
              <a
                id='kt_activity_year_tab'
                className={`nav-link justify-content-center text-active-gray-800 text-hover-gray-800' ${period === 'yearly' ? 'active' : ''}`}
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_year'
                onClick={() => setPeriod('yearly')}
              >
                {date.getFullYear().toString()}
              </a>
            </li>
            <li>
              <Button className='h-45px ' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                Dışarı Aktar
              </Button>
            </li>
            <li className=''>
              <div onClick={deleteListIssue} className='mt-1'>
                <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex ' style={{border: '1px solid rgb(1 13 88)', color: 'rgb(1 13 88)'}}>
                  -
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush ' style={{height: plusOrMinus ? 'auto' : '350px'}}>
          <div className='card-body pt-3 scroll-x w-100'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-dashed  gy-4 align-middle fw-bolder '>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  {widgetId == '23' ? (
                    <tr>
                      <th className='min-w-100px'>FAALİYET</th>
                      <th className='min-w-90px'>KATEGORİ</th>
                      <th className='min-w-150px'>BAŞlANGIÇ TARİHİ</th>
                      <th className='min-w-150px'>BİTİŞ TARİHİ</th>
                      <th className='min-w-150px'>SORUMLU</th>
                      <th className='min-w-150px'>Ekleyen</th>
                      <th className='min-w-150px'>Öncelik</th>
                      <th className='min-w-150px'>Görev</th>
                      <th className='min-w-150px'>Hafta</th>
                      <th className='min-w-90px'>DURUM</th>
                      {plusOrMinus && <th></th>}
                    </tr>
                  ) : (
                    <tr>
                      <th className=''>Proje İSMİ</th>
                      <th className=''>GRUP MÜDÜRLÜĞÜ</th>
                      <th className=''>MÜDÜRLÜK</th>
                      <th className=''>PROJE SINIFI</th>
                      <th className=''>PROJE TÜRÜ</th>
                      <th className=''>PROJE KATEGORİSİ</th>
                      <th className=''>HEDEF BAŞLANGIÇ TARİHİ</th>
                      <th className=''>HEDEF BİTİŞ TARİHİ</th>
                      <th className=''>PROJE AMACI</th>
                      {plusOrMinus && <th></th>}
                    </tr>
                  )}
                </thead>

                <tbody className='fs-6 scroll-y'>
                  {issues?.length < 1 && !loading ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    issues.map((issue: any) => {
                      return widgetId == '23' ? (
                        <TargetItem2
                          key={issue.id}
                          id={issue.id}
                          title={issue.title}
                          categoryName={issue.category.name}
                          start_date={issue.start_date}
                          end_date={issue.end_date}
                          userName={issue.assigned?.name}
                          status={'In Progress'}
                          issue={issue}
                          assigned_id={issue.assigned?.id}
                          plusOrMinus={plusOrMinus}
                        />
                      ) : (
                        <ProjectItem project={issue} />
                      )
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TargetListsHomePage
