import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}
export const IADSItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.case_no}</td>
        <td className='text-gray-500'>{item?.ra_user}</td>
        <td className='text-gray-500'>{item?.company}</td>
        <td className='text-gray-500'>{item?.case_title}</td>
        <td className='text-gray-500'>{item?.case_status}</td>
        <td className='text-gray-500'>{item?.case_assigned_group}</td>
        <td className='text-gray-500'>{item?.effected_amount} </td>
        <td className='text-gray-500'>{item?.effected_customer} </td>
        <td className='text-gray-500'>{item?.loss}</td>
        <td className='text-gray-500'>{item?.correction}</td>
        <td className='text-gray-500'>{item?.assured}</td>
        <td className='text-gray-500'>{item?.informed_departments}</td>
        <td className='text-gray-500'>{item?.case_entry_date ? moment(item?.case_entry_date).format('DD.MM.YYYY') : ''}</td>
        <td className='text-gray-500'>{item?.case_update_date ? moment(item?.case_update_date).format('DD.MM.YYYY') : ''}</td>
        <td className='text-gray-500'>{item?.case_close_date ? moment(item?.case_close_date).format('DD.MM.YYYY') : ''}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
