/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ProfilePopUp} from '../../../../Profile/ProfilePopUp'

type Props = {
  className: string
  users: any
  project_id: any
}

const ListsWidget2: React.FC<Props> = ({className, users, project_id}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bolder text-dark'>Kullanıcılar</h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}

            <Link to='../stakeholders' state={project_id} style={{color: 'rgb(1 13 88)'}}>
              Tümünü gör
            </Link>

            {/*  <Dropdown1 />
   {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          {users.map((user: any) => {
            return (
              <div key={user?.id} className='d-flex align-items-center mb-7'>
                {/* begin::Avatar */}
                <div className='symbol symbol-55px symbol-circle w-85px'>
                  {user?.avatar ? (
                    <div className='symbol symbol-50px me-5'>
                      <img src={user?.avatar} className='' alt={user?.name} />
                    </div>
                  ) : (
                    <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2  symbol-40px me-5'>{user?.name?.substring(0, 1)}</div>
                  )}
                </div>
                {/* end::Avatar */}
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <span
                    className='text-dark fw-bolder fs-6 text-hover-primary cursor-pointer'
                    onClick={async () => {
                      setProfilUserId(user.id)
                      await openProfilModal()
                    }}
                  >
                    {user?.name}
                  </span>
                  <span className='text-muted d-block fw-bold'>{user?.roles[0]?.name}</span>
                </div>
                {/* end::Text */}
              </div>
            )
          })}
        </div>
        {/* end::Body */}
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ListsWidget2}
