import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddAlarmTrakıng} from './add-AlarmTrakıng/Add-AlarmTrakıng'
import {ATItem} from './components/ATItem'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import {DetailFilters} from './components/DetailFilter'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const AlarmTraking: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [iads, setIADS] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setActionOwner('')
    setActionStatus('')
    setActionCloseDate('')
    setCaseStartDate('')
    setCaseEndDate('')
    setResponsiblePerson('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [actionStatus, setActionStatus] = useState('')
  const [actionOwner, setActionOwner] = useState('')
  const [caseStartDate, setCaseStartDate] = useState('')
  const [caseEndDate, setCaseEndDate] = useState('')
  const [actionCloseDate, setActionCloseDate] = useState('')
  const [responsiblePerson, setResponsiblePerson] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=12'
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (actionStatus != '') {
      queryParams += '&action_status=' + actionStatus
    }
    if (actionOwner != '') {
      queryParams += '&actionOwner=' + actionOwner
    }
    if (caseStartDate != '') {
      queryParams += '&case_start_date=' + caseStartDate
    }
    if (caseEndDate != '') {
      queryParams += '&case_end_date=' + caseEndDate
    }
    if (actionCloseDate != '') {
      queryParams += '&action_close_date=' + actionCloseDate
    }
    if (responsiblePerson != '') {
      queryParams += '&responsible_persons=' + responsiblePerson
    }

    GetReport('/alarm_tracking', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setIADS(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, actionStatus, actionOwner, caseStartDate, caseEndDate, actionCloseDate, responsiblePerson])

  const exportFile = () => {
    window.open(`${ExportLink}/alarm_tracking?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div>
          <div className='row g-5 '>
            <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6'>
              <BarChart horizentalTitle={'Bölümlere yayınlanan olay rapor sayıları'} widgetId={'30'} plusOrMinus={true} linkToPage={''} />
            </div>

            {/* end::Col */}
          </div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
              <PieChart name={'Action Status and Numbers'} link={''} widgetId={'31'} plusOrMinus={true} linkToPage={''} setSelectedValue={setActionStatus} />
            </div>
            <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 ' style={{height: '350px'}}>
              <BarChart horizentalTitle={'Haftalık Toplam Alarm Sayıları'} widgetId='32' plusOrMinus={true} linkToPage={''} />
            </div>

            {/* <div className='col-xl-8 col-xxl-8 mb-xl-7 mb-xxl-6 overflow-hidden'>
              <BarAlarmChart4
                className={''}
                chartHeight={300}
                type={'4'}
                verticalTitle={''}
                horizentalTitle={'GELİR GÜVENCE BULGULARI (TT Mobil)'}
                pointTitle={''}
              />
            </div> */}

            {/* end::Col */}
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className=''>
              <div className='card-header'>
                {/* <SearchComponent setWord={setSearchKeyClick} /> */}
                <div className='d-flex m-3 justify-content-between w-100'>
                  <div className='d-flex align-items-center position-relative my-3'></div>
                  {/* <HeaderInfo api={'/alarm_tracking_check'}/> */}
                  <div>
                    <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                      {' '}
                      Detaylı Ara
                    </Button>
                    <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                      Dışarı Aktar
                    </Button>
                  </div>

                  {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
                </div>
              </div>
            </div>

            <div className='card-body pt-3 overflow-auto  mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr color='#50c8da' style={{color: '#50c8da'}}>
                      <th className=''>case_no </th>
                      <th className=''>case_title</th>
                      <th className=''> case_start_date</th>
                      <th className=''>case_end_date</th>
                      <th className=''>case_duration</th>
                      <th className=''>responsible_persons </th>
                      <th className=''>case_system_resource</th>
                      <th className=''> affected_side</th>
                      <th className=''>case_detail</th>
                      <th className=''>actions</th>
                      <th className=''>action_status </th>
                      <th className=''>action_owner</th>
                      <th className=''> action_close_date</th>
                      <th className=''>created_at</th>
                      <th className=''>created_by</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {iads?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      iads?.map((iads: any) => {
                        return (
                          <>
                            <ATItem key={iads?.id} item={iads} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={12} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddAlarmTrakıng handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setActionStatus={setActionStatus}
            setActionOwner={setActionOwner}
            handleClose={closeFilterModal}
            setSearchKeyClick={setSearchKeyClick}
            setStartDate={setCaseStartDate}
            setEndDate={setCaseEndDate}
            setActionCloseDate={setActionCloseDate}
            setResponsiblePerson={setResponsiblePerson}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
