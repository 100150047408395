import moment from 'moment'
import {FC} from 'react'
import {NavLink} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  CaseProp: any
}
export const CaseInfo: FC<Props> = ({CaseProp}) => {
  return (
    <>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-950px mb-10 ml-0'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-5'>
              {/*  <div className='symbol symbol-100px symbol-circle mb-7'>
              <img src='assets/media/avatars/300-6.jpg' alt='image' />
            </div> */}

              <span className='fs-3 text-gray-800 fw-bolder mb-3'>{CaseProp?.title}</span>

              <div className='mb-9'>
                <div className='badge badge-lg badge-light-primary d-inline'>{CaseProp?.status?.name}</div>
              </div>
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Başlangıç Tarihi</div>
                <div className='text-gray-600'>{moment(CaseProp?.start_date).format('DD.MM.YYYY')}</div>

                <div className='fw-bolder mt-5'>Bitiş Tarihi</div>
                <div className='text-gray-600'>
                  <a href='#' className='text-gray-600 text-hover-primary'>
                    {moment(CaseProp?.end_date).format('DD.MM.YYYY')}
                  </a>
                </div>

                <div className='fw-bolder mt-5'>Olayın Süresi</div>
                <div className='text-gray-600'>{CaseProp?.duration}</div>

                <div className='fw-bolder mt-5'>Sorumlu ekip/kişi</div>
                <div className='text-gray-600'>{CaseProp?.assignment?.alternative_name}</div>

                <div className='fw-bolder mt-5'>Olay Yaşanan Sistem Kaynağı</div>
                <div className='text-gray-600'>{CaseProp?.system_resource}</div>

                <div className='fw-bolder mt-5'>Olaydan etkilenen servis ve müşteri bilgisi</div>
                <div className='text-gray-600'>{CaseProp?.affected_side}</div>

                <div className='fw-bolder mt-5'>Olay nedeni ve detayı </div>
                <div className='text-gray-600'>{CaseProp?.detail}</div>

                <div className='fw-bolder mt-5'>İyileştirme önerileri/aksiyonları </div>
                <div className='text-gray-600'>{CaseProp?.actions}</div>

                <div className='fw-bolder mt-5'>Aksiyon Sahibi</div>

                {CaseProp.action_users.map((user: any) => {
                  return (
                    <div className='d-flex  justify-content-between mt-3'>
                      <div className='symbol symbol-55px symbol-circle w-55px '>
                        {user?.avatar ? (
                          <div className='symbol symbol-55px symbol-circle w-55px'>
                            <img src={user?.avatar} alt={user?.name} className='w-100' />
                          </div>
                        ) : (
                          <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{user?.name?.substring(0, 1)}</div>
                        )}
                      </div>
                      <div className='d-flex flex-column p-5 w-100'>
                        <div className='fw-bolder d-flex align-items-center fs-6 text-hover-primary'>{user?.name}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
