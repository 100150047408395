import {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {UpdateIssue} from '../core/_requests'
import swal from 'sweetalert'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
type Props = {
  issue: any
  handleClose: any
}

export const EditStatus: FC<Props> = ({issue, handleClose}) => {
  const [selectedStatus, setSelectedstatus] = useState(issue.status.id)
  const {refreshPage} = useRefresh1()
  const handleSubmit = async () => {
    const statuseUpd = {
      status: selectedStatus,
    }
    await UpdateIssue(issue.id, statuseUpd)
      .then((data) => {
        refreshPage()
        handleClose(false)
      })
      .catch((err) => {
        handleClose(false)
        swal('Hata!', err.response.data.error, 'error', {timer: 2000})
      })
  }
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>İşlem</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Durum:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-dropdown-parent='#kt_menu_6279569cd15cc'
              data-allow-clear='true'
              onChange={(e) => setSelectedstatus(e.target.value)}
              defaultValue={issue.status.id}
            >
              <option hidden> Durum Seç </option>
              <option value='0'>Planlanan</option>
              <option value='1'>Devam Eden</option>
              <option value='2'>Tamamlanan</option>
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleClose}
          >
            İptal
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={handleSubmit}
          >
            Gönder
          </button>
        </div>
      </div>
    </div>

    //</form>
  )
}
