import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setMachine: any
  setCreatedAt: any
  setClusterName: any
  setIpAddress: any
  setOsRelease: any
  setOsVersion: any
  setDbVersion: any
  handleClose: any
  setSearchKeyClick: any
}

export const DetailFilters: FC<Props> = ({
  setMachine,
  setCreatedAt,
  handleClose,
  setSearchKeyClick,
  setClusterName,
  setIpAddress,
  setOsRelease,
  setOsVersion,
  setDbVersion
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setMachine(selectedMachine)
    setCreatedAt(selectedCreatedAt)
    setClusterName(selectedClusterName)
    setIpAddress(selectedIpAddress)
    setOsRelease(selectedOsRelease)
    setOsVersion(selectedOsversion)
    setDbVersion(selectedDbVersion)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [inputWord, setInputWord] = useState('')
  const [selectedMachine, setSelectedMachine] = useState('')
  const [selectedCreatedAt, setSelectedCreatedAt] = useState('')
  const [selectedClusterName, setSelectedClusterName] = useState('')
  const [selectedIpAddress, setSelectedIpAddress] = useState('')
  const [selectedOsRelease, setSelectedOsRelease] = useState('')
  const [selectedOsversion, setSelectedOsVersion] = useState('')
  const [selectedDbVersion, setSelectedDbVersion] = useState('')

  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Server Name</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setInputWord(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Machine</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedMachine(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Created Date</label>
        <input
          placeholder=''
          type='date'
          name='end_date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedCreatedAt(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Cluster Name</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedClusterName(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ip Address</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedIpAddress(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Os Release</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedOsRelease(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Os Version</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedOsVersion(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Db Version</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDbVersion(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
