import {FC,useState} from 'react'
type Props = {
  setDeviceId: any
  setDatetime: any
  handleClose: any
}

export const DetailFilters: FC<Props> = ({setDeviceId,setDatetime, handleClose}) => {
  const handleSubmit = async () => {
    setDatetime(selectedDatetime)
    setDeviceId(selectedDeviceId)
    handleClose()
  }
  const [selectedDatetime, setSelectedDatetime] = useState('')
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
    
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Device Id</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedDeviceId(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Date Time</label>
        <input
          placeholder=''
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedDatetime(e.target.value)
          }}
        />
      </div>


      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
