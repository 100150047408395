import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {CreatePurchase, UpdatePurchase} from '../core/_requests'
import swal from 'sweetalert'
import {getSuppliers} from '../../Supplier/core/_requests'
import clsx from 'clsx'
import {getCSRFToken, getUsers} from '../../core/requests'
import {useDispatch} from 'react-redux'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  handleClose: any
  purchase: any
  update: boolean
}
export const AddPurchase: FC<Props> = ({handleClose, purchase, update}) => {
  const [suppliers, setSuppliers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  useEffect(() => {
    getSuppliers().then(({data}) => {
      setSuppliers(data.data)
    })
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
  }, [])
  const [userForEdit] = useState<any>({
    ...purchase,
    id: purchase.id,
    supplier_id: purchase.supplier.id,
    currency: purchase.currency,
    purchase_by: purchase.purchase_by.id,
    kades_no: purchase.kades_no == 'undefined' ? '' : purchase?.kades_no,
  })
  const purchaseSchema = Yup.object().shape({
    supplier_id: Yup.string().required('Zorunlu alan'),
    budget_code: Yup.string().required('Zorunlu alan'),
    request_number: Yup.string().required('Zorunlu alan'),
    currency: Yup.string().required('Zorunlu alan'),
    amount: Yup.string().required('Zorunlu alan'),
    try_amount: Yup.string().required('Zorunlu alan'),
    purchase_by: Yup.string().required('Zorunlu alan'),
  })

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: purchaseSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          const updPurchase = {
            budget_id: values.budget_id,
            budget_code: values.budget_code,
            reques_number: values.request_number,
            currency: values.currency,
            amount: values.amount,
            try_amount: values.try_amount,
            purchase_by: values.purchase_by,
            supplier_id: values.supplier_id,
            kades_no: values.kades_no,
            status: '0',
          }
          await UpdatePurchase(values.id, updPurchase)
            .then(() => {
              swal('Düzenlendi', 'Satın Alma başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('budget_id', values.budget_id)
          formData.append('budget_code', values.budget_code)
          formData.append('request_number', values.request_number)
          formData.append('currency', values.currency)
          formData.append('amount', values.amount)
          formData.append('try_amount', values.try_amount)
          formData.append('purchase_by', values.purchase_by)
          formData.append('supplier_id', values.supplier_id)
          formData.append(' kades_no', values.kades_no)
          formData.append('status', '0')
          await CreatePurchase(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Satın Alma  başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tedarikçi</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.supplier_id && formik.errors.supplier_id},
                {
                  'is-valid': formik.touched.supplier_id && !formik.errors.supplier_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Tedarikçi'
              {...formik.getFieldProps('supplier_id')}
              name='supplier_id'
            >
              <option value='' hidden>
                Tedarikçi Seçiniz Seçiniz{' '}
              </option>
              {suppliers.map((supplier) => {
                return (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.supplier_id && formik.errors.supplier_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.supplier_id}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bütçe Kodu</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('budget_code')}
                type='text'
                name='budget_code'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.bugdet_code && formik.errors.budget_code},
                  {
                    'is-valid': formik.touched.budget_code && !formik.errors.budget_code,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.budget_code && formik.errors.budget_code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_code}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Talep Numarası</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('request_number')}
                type='text'
                name='request_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.request_number && formik.errors.request_number},
                  {
                    'is-valid': formik.touched.request_number && !formik.errors.request_number,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.request_number && formik.errors.reques_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.request_number}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-7 mb-2'>Kades No</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('kades_no')}
              type='text'
              name='kades_no'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.kades_no && formik.errors.kades_no},
                {
                  'is-valid': formik.touched.kades_no && !formik.errors.kades_no,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.kades_no && formik.errors.kades_no && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.kades_no}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tutar</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('amount')}
                type='number'
                name='amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.amount && formik.errors.amount},
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.currency && formik.errors.currency},
                  {
                    'is-valid': formik.touched.currency && !formik.errors.currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('currency')}
                name='currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Döviz Birimi Seçiniz
                </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>TL Tutarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('try_amount')}
                type='number'
                name='try_amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.try_amount && formik.errors.try_amount},
                  {
                    'is-valid': formik.touched.try_amount && !formik.errors.try_amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.try_amount && formik.errors.try_amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.try_amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Satın Alan</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.purchase_by && formik.errors.purchase_by},
                  {
                    'is-valid': formik.touched.purchase_by && !formik.errors.pruchase_by,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Purchase_by'
                {...formik.getFieldProps('purchase_by')}
                name='purchase_by'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Satın Alanı Seçiniz
                </option>
                {users.map((user: any) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.request_number && formik.errors.reques_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.request_number}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
