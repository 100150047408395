import {is} from 'immer/dist/internal'
import React, {FC} from 'react'
import {KTSVG} from '../../../../_theme/helpers'

type Prop = {
  assignments: any
  handleClose: any
  is_public?: boolean
}

export const AssignmentsList: FC<Prop> = ({assignments, handleClose, is_public}) => {
  return (
    <div className='card card-flush  border-top-0 border-0'>
      <div className='flex-column flex-lg-row-auto  ml-0 '>
        <div>
          <div className='d-flex justify-content-end'>
            <div className='btn-icon btn-sm btn-active-icon-primary ' data-kt-users-modal-action='close' onClick={handleClose} style={{cursor: 'pointer'}}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <h2 className='fw-bolder text-center'>Erişimi Olan Kullanıcılar</h2>
        </div>
        <div className='card-body pt-3'>
          <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
            <thead className='fs-7 text-gray-400 text-uppercase'>
              {/* <tr>
            <th className=''>Yetkililer</th>
          </tr> */}
            </thead>

            <tbody className='fs-6'>
              {is_public ? (
                <span className='d-flex justify-content-center text-gray-600 fs-4 fw-bold'>Herkes erişim sağlayabilir</span>
              ) : assignments?.length < 1 ? (
                <span className='d-flex justify-content-center text-gray-600 fs-4 fw-bold'>Kullanıcı bulunmamaktadır</span>
              ) : (
                assignments?.map((assignment: any) => {
                  return (
                    <tr key={assignment?.id}>
                      <td>{assignment?.name}</td>
                      <td className='text-end'></td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
