import axios, { AxiosResponse } from 'axios'
import { Announcement } from '../models/announModel'
import { ID, Response} from '../../../../_theme/helpers'
const API_URL = process.env.REACT_APP_API_URL;

export const GET_CATEGORIES = `${API_URL}/announcement_categories`
export const ANNOUNCEMENT=`${API_URL}/announcements`
const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};
// Server should return AuthModel
export async function getAnnoun(page:string) {
  return await axios.get<any>(ANNOUNCEMENT+'?period='+page)
}
export async function getAnnounDetail(id: string|undefined) {
  return await axios.get<any>(ANNOUNCEMENT + '/' + id)
}
export async function getAnnounCategories() {
  return await axios.get<any>(GET_CATEGORIES)
}

export const CreateAnnouncement = (announcement: any): Promise<Announcement | undefined> => {
  return axios
    .post(ANNOUNCEMENT, announcement, config)
    .then((response: AxiosResponse<Response<Announcement>>) => response.data)
    .then((response: Response<Announcement>) => response.data)
}
 
export async function DeleteAnnoun(id:any) {
  return await axios.delete<any>(ANNOUNCEMENT+'/'+id)
}
export async function DeleteAttachment(id:any,attachID:string) {
  return await axios.delete<any>(ANNOUNCEMENT+'/'+id+'/attachments/'+attachID)
}

export async function UpdateAnnouncement(id:any, announcement:any) {
  return await axios.put(ANNOUNCEMENT+'/'+id,announcement)
}
export async function getAnnouncementbyCategory(id:any, period:string){
  return await axios.get(ANNOUNCEMENT+'?announcement_category_id='+id+'&period='+period)
}

export async function createAttachment(id: any, attachment: any){
  return await axios.post(ANNOUNCEMENT+'/'+id+'/attachments',attachment,config)
}

export async function updateBanner(id: any, banner: any) {
  return await axios.post(ANNOUNCEMENT+'/'+id+'/banner',banner, config)
}

export async function GetMostReadedAnnouncements() {
  return await axios.get(ANNOUNCEMENT+'?most_read=1&page[size]=10')
}