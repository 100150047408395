import {FC} from 'react'

type Props = {
  category: any
  setCategoryId: any
  setAnnouncements: any
  setCurrentPage: any
}
export const Category: FC<Props> = ({
  category,
  setCategoryId,
  setAnnouncements,
  setCurrentPage,
}) => {
  return (
    <div className='d-flex flex-stack fw-bold fs-5 text-gray-500 mb-4'>
      <span
        onClick={() => {
          setAnnouncements([])
          setCurrentPage(1)
          setCategoryId(category.id)
        }}
        className='text-gray-500 text-hover-primary px-2 cursor-pointer'
      >
        {' '}
        {category.name}
      </span>

      <div className='m-0'></div>
    </div>
  )
}
