import {FC, useState} from 'react'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {Supplier} from '../../Supplier/Supplier'
import swal from 'sweetalert'
import {DeletePurchase} from '../core/_requests'
import {ModalHeader} from '../../components/ModalHeader'
import {AddPurchase} from '../Budget-edit/AddPurchase'
import NumberFormat from 'react-number-format'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import moment from 'moment'
import {AddDoc} from '../Budget-edit/AddDoc'
import {PurchaseFiles} from './PurchaseFiles'

type Props = {
  refreshPage: any
  purchase: any
}
const deletePurchase = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeletePurchase(id).then(() => {
        swal('Silindi!', 'Bütçe başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const Purchase: FC<Props> = ({refreshPage, purchase}) => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(false)
    refreshPage()
  }
  const handleShow = () => {
    setShowModal(true)
  }
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }

  const [showDocModal, setShowDocModal] = useState(false)
  const openDocModal = () => {
    setShowDocModal(true)
  }
  const closeDocModal = () => {
    setShowDocModal(false)
  }
  const [listDocuments, setListDocuments] = useState(false)
  const openListDoc = () => {
    setListDocuments(true)
  }
  const closeListDoc = () => {
    setListDocuments(false)
  }

  return (
    <>
      <tr className=''>
        <td className='text-gray-500'>{purchase?.budget_code}</td>
        <td className='text-gray-500'>{purchase?.request_number}</td>
        <td className='text-gray-500'>{purchase?.kades_no != 'undefined' ? purchase?.kades_no : '-'}</td>
        <td className='text-gray-500'>
          <NumberFormat value={purchase?.amount} displayType={'text'} thousandSeparator={true} /> {purchase?.currency}
        </td>
        <td className='text-gray-500'>
          <NumberFormat value={purchase?.try_amount} displayType={'text'} thousandSeparator={true} />
        </td>
        <td className='text-gray-500'>
          {purchase?.supplier?.name} <br /> <span className='fs-8'>{purchase?.supplier?.company_name}</span>
        </td>
        {purchase?.purchase_by && (
          <td className='text-gray-500 fs-6'>
            <span
              className='text-hover-primary cursor-pointer'
              onClick={async () => {
                setProfilUserId(purchase?.purchase_by?.id)
                await openProfilModal()
              }}
            >
              {' '}
              {purchase?.purchase_by?.name}
            </span>
            <br /> <span className='fs-8'>{purchase?.purchase_by?.roles[0]?.name}</span>
          </td>
        )}
        <td className='text-gray-500'> {moment(purchase?.created_at).format('DD.MM.YYYY')}</td>
        <td>
          <Button className='' variant='primary' onClick={openListDoc}>
            Sözleşmeler
          </Button>
        </td>
        <td className='text-end'>
          <Dropdown>
            <Dropdown.Toggle className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShow}>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={openDocModal}>
                {' '}
                <span className='menu-link px-3'>Sözleşme Ekle</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  deletePurchase(purchase.id, refreshPage)
                }}
              >
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button  onClick={handleShow} className='me-3'>Düzenle</Button>
          <Button className='mt-1' onClick={()=>{deletePurchase(purchase.id, refreshPage)}}>Sil</Button>*/}
        </td>
      </tr>
      <Modal show={showModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddPurchase handleClose={handleClose} purchase={purchase} update={true} />
        </Modal.Body>
      </Modal>
      <Modal show={showDocModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeDocModal} titleHeader={'Sözleşme Ekle'} />
          <AddDoc budget_id={purchase.id} handleClose={closeDocModal} doc={{}} isPurchase={true} />
        </Modal.Body>
      </Modal>
      <Modal show={listDocuments} size='xl'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeListDoc} titleHeader={'Sözleşmeler'} />
          <PurchaseFiles purchase_id={purchase?.id} />
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
