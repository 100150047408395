import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddNOD} from './add-NOD/Add-NOD'
import {NODItem} from './components/NODItem'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import {DetailFilters} from './components/DetailFilter'
import {BarNODChart} from '../../components/widgets/OperationalData/BarNODChart'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const NOD: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [iads, setIADS] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setCreatedAt('')
    setRecordType('')
    setApprovalStatus('')
    setStatus('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [createdAt, setCreatedAt] = useState('')
  const [recordType, setRecordType] = useState('')
  const [aprrovalStatus, setApprovalStatus] = useState('')
  const [status, setStatus] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (createdAt != '') {
      queryParams += '&created_at=' + createdAt
    }
    if (recordType != '') {
      queryParams += '&record_type=' + recordType
    }
    if (aprrovalStatus != '') {
      queryParams += '&approval_status=' + aprrovalStatus
    }
    if (status != '') {
      queryParams += '&status=' + status
    }
    GetReport('/number_of_demands', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setIADS(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, createdAt, recordType, aprrovalStatus, status, show])

  const exportFile = () => {
    window.open(`${ExportLink}/number_of_demands?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'1?report_type=Kurumsal Güvenlik'}
                verticalTitle={'Kurumsal Güvenlik Sistemleri Yönetimi'}
                horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
                plusOrMinus={true}
                widgetId={'48'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'2?report_type=Kurumsal Güvenlik'}
                verticalTitle={''}
                horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
                plusOrMinus={true}
                widgetId={'49'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'3?report_type=Kurumsal Güvenlik'}
                verticalTitle={''}
                horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat)'}
                plusOrMinus={true}
                widgetId={'50'}
                linkToPage={''}
              />
            </div>
            {/* end::Col */}
          </div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'1?report_type=Kimlik Yönetimi'}
                verticalTitle={'Kimlik Yönetimi'}
                horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
                plusOrMinus={true}
                widgetId={'51'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'2?report_type=Kimlik Yönetimi'}
                verticalTitle={''}
                horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
                plusOrMinus={true}
                widgetId={'52'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'3?report_type=Kimlik Yönetimi'}
                verticalTitle={''}
                horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat) '}
                plusOrMinus={true}
                widgetId={'53'}
                linkToPage={''}
              />
            </div>
            {/* end::Col */}
          </div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'1?report_type=Bilgi Güvenliği'}
                verticalTitle={'Bilgi Güvenliği & Teknoloji Risk Yönetimi'}
                horizontalTitle={'Haftalık Talep Karşılama Durumu Trendi'}
                plusOrMinus={true}
                widgetId={'54'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'2?report_type=Bilgi Güvenliği'}
                verticalTitle={''}
                horizontalTitle={'Haftalık 48 saati aşan Talep Sayısı'}
                plusOrMinus={true}
                widgetId={'55'}
                linkToPage={''}
              />
            </div>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <BarNODChart
                className={''}
                chartHeight={0}
                type={'3?report_type=Bilgi Güvenliği'}
                verticalTitle={''}
                horizontalTitle={'Haftalık Ortalama Talep Karşılama Süresi(saat)'}
                plusOrMinus={true}
                widgetId={'56'}
                linkToPage={''}
              />
            </div>
            {/* end::Col */}
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card-header'>
            {/* <SearchComponent setWord={setSearchKeyClick} /> */}
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex align-items-center position-relative my-3 m-5'>
                <HeaderInfo api={'/number_of_demands_check'} />
              </div>
              <div>
                <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                  Detaylı Ara
                </Button>

                <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                  Dışarı Aktar
                </Button>
              </div>

              {/* 
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
            </div>
          </div>

          <div className='card-body pt-3 overflow-auto mh-900'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Yükleniyor...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
                  <tr style={{color: '#50c8da'}}>
                    <th className=''>Report type</th>
                    <th className=''>Task_Id</th>
                    <th className=''> parent_change_id</th>
                    <th className=''>record_type</th>
                    <th className=''>access_method</th>
                    <th className=''>authorization_detail</th>
                    <th className=''>approval_status</th>
                    <th className=''>status</th>
                    <th className=''>task_open_date</th>
                    <th className=''>assign_full_name</th>
                    <th className=''>assignment_group</th>
                    <th className=''>task_duration</th>
                    <th className=''>created_at</th>
                    <th className=''>created_by</th>
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {iads?.length < 1 && !loading ? (
                    <NoMatchRecords isTable={true} />
                  ) : (
                    iads?.map((iads: any) => {
                      return (
                        <>
                          <NODItem key={iads?.id} item={iads} />
                        </>
                      )
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
          <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddNOD handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setCreatedDate={setCreatedAt}
            setRecordType={setRecordType}
            setAppStatus={setApprovalStatus}
            setStatus={setStatus}
            handleClose={closeFilterModal}
            setSearchKeyClick={setSearchKeyClick}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
