import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {PluralBarChart} from '../../components/widgets/OperationalData/PluralBarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetReport} from '../core/request'
import {DetailFilters} from './components/DetailFilter'
import {NKDY_Item} from './components/NKDY_Item'

export const NKDY: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/nkdy_support_request?token=${loginUser.token}`)
  }
  const [items, setItems] = useState([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setCreatedDate('')
    setSearchKeyClick('')
    setStatusDescription('')
    setReportDate('')
    setCatalogUrgency('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [createdDate, setCreatedDate] = useState('')
  const [statusDescription, setStatusDescription] = useState('')
  const [catalogUrgency, setCatalogUrgency] = useState('')
  const [cozumTipiDescription, setCozumTipiDescription] = useState('')
  const [reportDate, setReportDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 20
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }
    if (createdDate != '') {
      queryParams += '&created_date=' + createdDate
    }
    if (statusDescription != '') {
      queryParams += '&status_description=' + statusDescription
    }
    if (catalogUrgency != '') {
      queryParams += '&catalog_urgency=' + catalogUrgency
    }
    if (reportDate != '') {
      queryParams += '&report_date=' + reportDate
    }
    if (cozumTipiDescription != '') {
      queryParams += '&mx_cozumtipi_description=' + cozumTipiDescription
    }
    GetReport('/nkdy_support_request', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, createdDate, statusDescription, catalogUrgency, reportDate, cozumTipiDescription])

  return (
    <div>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'BT CATALOG URGENCY'} link={''} widgetId={'40'} plusOrMinus={true} linkToPage={''} setSelectedValue={setCatalogUrgency} />
          </div>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Domain Bazlı NKDY '} link={''} widgetId={'41'} plusOrMinus={true} linkToPage={''} setSelectedValue={setCozumTipiDescription} />
          </div>

          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'STATUS DESCRIPTION'} link={''} widgetId={'42'} plusOrMinus={true} linkToPage={''} setSelectedValue={setStatusDescription} />
          </div>
          <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-6 '>
            <PluralBarChart horizentalTitle={'Haftalık NKDY Talep Sayısı '} widgetId={'97'} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex align-items-center position-relative my-3'>
                <HeaderInfo api={'/nkdy_support_request_check'} />
              </div>
              <div>
                <Button className='btn mt-3 mx-5' style={{marginRight: '10px'}} onClick={showFilterModal}>
                  Detaylı Ara
                </Button>
                <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                  Dışarı Aktar
                </Button>
              </div>

              {/* <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REQUEST TASK ID</th>
                      <th className=''>REQUEST ID</th>
                      <th className=''>BAŞLIK</th>
                      <th className=''>ACİLİYET</th>
                      <th className=''>ÖNCELİK</th>
                      <th className=''>KAYDI AÇAN KULLANICI FULL NAME</th>
                      <th className=''>KAYDI AÇAN MÜDÜRLÜK</th>
                      <th className=''>STATUS</th>
                      <th className=''>TEKNOLOJİ</th>
                      <th className=''>AÇILMA TARİHİ</th>
                      <th className=''>CLOSED TİME</th>
                      <th className=''>KATALOG ADI</th>

                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <NKDY_Item key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setCreatedDate={setCreatedDate}
            setStatusDesc={setStatusDescription}
            setCatalogUrg={setCatalogUrgency}
            setReportdate={setReportDate}
            handleClose={closeFilterModal}
            setSearchKeyClick={setSearchKeyClick}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
