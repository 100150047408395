import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {getIssueDirectorateWidget, getIssueStatusByStaff, getProjectDirectorateWidget, getProjectDirectorateWidgetByClass, updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {GetCasesByDirectorate} from '../../Case/core/_request'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {GetPocWidget} from '../../Project/Poc/core/_request'

type Props = {
  widgetId: any
  title?: string
  getSelectedId?: any
  setSelectedDirectorate?: any
  setShowImmediateButton?: any
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
  pageId?: string
}

export const DirectorateWidget: FC<Props> = ({title, getSelectedId, setSelectedDirectorate, setShowImmediateButton, widgetId, plusOrMinus, linkToPage, pageId}) => {
  const [widgetData, setWidgetData] = useState<any>([])
  const {refresh, refreshPage} = useRefresh1()
  useEffect(() => {
    if (widgetId == 12) {
      getIssueDirectorateWidget().then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 13 || widgetId == 19 || widgetId == 20 || widgetId == 21 || widgetId == 22) {
      getProjectDirectorateWidget('').then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 18) {
      getIssueStatusByStaff().then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 23) {
      getProjectDirectorateWidgetByClass(1).then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 24) {
      getProjectDirectorateWidgetByClass(2).then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 25) {
      getProjectDirectorateWidgetByClass(3).then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 81) {
      GetCasesByDirectorate().then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 96) {
      GetPocWidget('/poc_graph/directorates').then(({data}) => {
        setWidgetData(data)
      })
    }
  }, [refresh])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  const handleSelectedItem = (item: any) => {
    if (plusOrMinus) {
      widgetId == '81' || widgetId == '96' ? getSelectedId(item?.assignment_id) : getSelectedId(item?.id)
      setSelectedDirectorate(item?.name)
      widgetId == 18 && setShowImmediateButton(true)
    }
  }

  return (
    <div className={`card card-flush  `} style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <Link to={plusOrMinus ? '' : linkToPage}>
          <h3 className='card-title align-items-start flex-column'>{title && <span className='card-label fw-bolder text-dark fs-3'>{title}</span>}</h3>
        </Link>
        {/* end::Title */}
        {/* begin::Menu */}
        <div>
          {widgetId == 13 || widgetId == 18 || widgetId == 12 || widgetId == 81 || widgetId == 96 ? (
            <span
              className='btn btn-icon btn-light btn-active-color-primary mt-n2   d-inline-fle'
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
              onClick={async () => {
                homeWidgetShow()
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          ) : (
            ''
          )}

          <br />
          {plusOrMinus && (
            <span
              className={`text-gray-600  mt-1 me-n3  d-inline-flex align-item-end fs-5 cursor-pointer`}
              onClick={() => {
                getSelectedId('')
                setSelectedDirectorate('')
                setShowImmediateButton(false)
              }}
            >
              Tümü
            </span>
          )}
        </div>

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body  scroll-y w-100' style={{height: '185px'}}>
        {widgetData.map((item: any, index: any) => {
          return (
            <div key={index}>
              <div className='d-flex flex-stack'>
                {/* begin::Section */}
                <div className='d-flex align-items-center me-3'>
                  {/* begin::Icon */}
                  {/* <img src={toAbsoluteUrl(item.image)} className='me-3 w-30px' alt='' /> */}
                  {/* end::Icon */}
                  {/* begin::Section */}
                  <div className='flex-grow-1' style={{cursor: 'pointer'}}>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => handleSelectedItem(item)} className='text-gray-800 text-hover-primary fs-5 fw-bolder lh-0'>
                      {item.name}
                    </a>
                    {/* <span className='text-gray-400 fw-bold d-block fs-6'>{item.text}</span> */}
                  </div>
                  {/* end::Section */}
                </div>

                {/* end::Section */}
                {/* begin::Statistics */}
                {widgetId == 12 || widgetId == 18 ? (
                  <>
                    <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                      <div>
                        <span className=' text-gray-600'>{item.completed_issues}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_issues}</span>
                      </div>
                      {/* begin::Progress */}
                      <div className='progress h-6px w-100 me-2 bg-light-success'>
                        <div
                          className='progress bg-success'
                          style={{
                            width: `${item.total_issues == 0 ? '0' : ((item.completed_issues * 100) / item.total_issues).toFixed(0)}%`,
                          }}
                        ></div>
                      </div>
                      {/* end::Progress */}
                      {/* begin::Value */}

                      <span className='text-gray-400 fw-bold'>{item.total_issues == 0 ? '0' : ((item.completed_issues * 100) / item.total_issues).toFixed(0)}%</span>
                      {/* end::Value */}
                    </div>
                  </>
                ) : widgetId == 81 ? (
                  <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                    <div>
                      <span className=' text-gray-600'>{item.completed}</span>
                    </div>
                    <div className='text-gray-600'>{'/'}</div>
                    <div>
                      <span className='me-5 text-gray-600'>{item.total}</span>
                    </div>
                    {/* begin::Progress */}
                    <div className='progress h-6px w-100 me-2 bg-light-success'>
                      <div
                        className='progress bg-success'
                        style={{
                          width: `${item.total == 0 ? '0' : ((item.completed * 100) / item.total).toFixed(0)}%`,
                        }}
                      ></div>
                    </div>
                    {/* end::Progress */}
                    {/* begin::Value */}

                    <span className='text-gray-400 fw-bold'>{item.total == 0 ? '0' : ((item.completed * 100) / item.total).toFixed(0)}%</span>
                    {/* end::Value */}
                  </div>
                ) : widgetId == 96 ? (
                  <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                    <div>
                      <span className=' text-gray-600'>{item.completed}</span>
                    </div>
                    <div className='text-gray-600'>{'/'}</div>
                    <div>
                      <span className='me-5 text-gray-600'>{item.total}</span>
                    </div>
                    {/* begin::Progress */}
                    <div className='progress h-6px w-100 me-2 bg-light-success'>
                      <div
                        className='progress bg-success'
                        style={{
                          width: `${item.total == 0 ? '0' : ((item.completed * 100) / item.total).toFixed(0)}%`,
                        }}
                      ></div>
                    </div>
                    {/* end::Progress */}
                    {/* begin::Value */}

                    <span className='text-gray-400 fw-bold'>{item.total == 0 ? '0' : ((item.completed * 100) / item.total).toFixed(0)}%</span>
                    {/* end::Value */}
                  </div>
                ) : widgetId == 19 ? (
                  <div className='text-gray-600'>
                    {' '}
                    <div className='d-flex'>
                      <div>
                        <span className=' text-gray-600'>{item.candidate_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                    </div>
                  </div>
                ) : widgetId == 20 ? (
                  <div className='text-gray-600'>
                    {' '}
                    <div className='d-flex'>
                      <div>
                        <span className=' text-gray-600'>{item.suspended_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                    </div>
                  </div>
                ) : widgetId == 21 ? (
                  <div className='text-gray-600'>
                    {' '}
                    <div className='d-flex'>
                      <div>
                        <span className=' text-gray-600'>{item.canceled_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                    </div>
                  </div>
                ) : widgetId == 22 ? (
                  <div className='text-gray-600'>
                    <div className='d-flex'>
                      <div>
                        <span className=' text-gray-600'>{item.completed_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                    </div>
                  </div>
                ) : widgetId == 23 || widgetId == 24 || widgetId == 25 ? (
                  <div className='d-flex'>
                    <div>
                      <span className=' text-gray-600'>{item.ongoing_projects}</span>
                    </div>
                    <div className='text-gray-600'>{'/'}</div>
                    <div>
                      <span className='me-5 text-gray-600'>{item.total_project}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                      <div>
                        <span className=' text-gray-600'>{item.completed_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                      {/* begin::Progress */}
                      <div className='progress h-6px w-100 me-2 bg-light-success'>
                        <div
                          className='progress-bar bg-success'
                          style={{
                            width: `${item.total_project == 0 ? '0' : ((item.completed_projects * 100) / item.total_project).toFixed(0)}%`,
                          }}
                        ></div>
                      </div>
                      {/* end::Progress */}
                      {/* begin::Value */}

                      <span className='text-gray-400 fw-bold'>{item.total_project == 0 ? '0' : ((item.completed_projects * 100) / item.total_project).toFixed(0)}%</span>
                      {/* end::Value */}
                    </div>
                  </>
                )}

                {/* end::Statistics */}
              </div>
              <div className='my-4' style={{borderTop: ' 1px dashed rgb(152 156 160)'}}></div>
            </div>
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}
