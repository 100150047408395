import HideMenuSlice from './../../app/pages/components/hideMenuRedux'
import CSRFSlice from './../../app/pages/components/csrf/reducers/CSRFSlice'
import {all} from 'redux-saga/effects'
import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'

const persistConfig = {
  key: 'showMenu',
  storage,
}

export const rootReducer = combineReducers({
  auth: auth.reducer,
  csrfToken: CSRFSlice,
  hideMenu: HideMenuSlice,
})

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
