import {FC} from 'react'
import {SubDataItem} from './SubDataItem'
type Props = {
  item: any
}
export const DataItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr style={{backgroundColor: '#e7e7e7'}}>
        <td className='fw-bolder'>{item?.current_data_total_vector_count}</td>
        <td className='text-gray-500'>{item?.current_data_total_threat_count}</td>
        <td className='text-gray-500'>{item?.current_data_total_security_score}</td>
        <td className='text-gray-500'>{item?.current_data_blocked}</td>
        <td className='text-gray-500'>{item?.current_data_unblocked}</td>
        <td className='text-gray-500'>{item?.changes_data_total_threat_count_change}</td>
        <td className='text-gray-500'>{item?.changes_data_total_security_score_change}</td>
        <td className='text-gray-500'>{item?.changes_data_total_blocked_change}</td>
        <td className='text-gray-500'>{item?.changes_data_total_unblocked_change}</td>
        <td className='text-gray-500'>{item?.cron_server_ip}</td>
        <td className='text-gray-500'>{item?.created_at}</td>
        <td className='text-gray-500'>{item?.updated_at}</td>
        <td>{''}</td>
        <td>{''}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
      {item.current_data_categories.map((i: any) => {
          return <SubDataItem item={i} />
        })}
    </>
  )
}
