import {S_IFBLK} from 'constants'
import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {setContext} from 'redux-saga/effects'
import {ID} from '../../../_theme/helpers'
import {ListFileItem} from './components/ListFileItem'
import {SLA} from './components/SLA'
import {Slider} from './components/Slider'
import {Ticket} from './components/Ticket'
import {UserInfo} from './components/UserInfo'
import {GetSLACategories, GetSupplierById, GetSuppliers, GetTickets} from './core/_requests'

type LocationState = {
  id: ID
  name: string
  email: string
  company_name: string
  phone: string
  tax_number: string
  country: {
    id: ID
    name: string
  }
  category: {
    id: ID
    name: string
  }
  address: string
  attachments: any
  phone_notification: boolean
  email_notification: boolean
}
export const SupplierDetail: FC = () => {
  const location = useLocation()
  let data = location.state as LocationState
  const [active, setActive] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [sla, setSla] = useState([
    {
      id: 1,
      title: '',
      description: '',
      response_time: '',
      supplier_id: 1,
    },
  ])
  const [ticket, setTicket] = useState([
    {
      id: 1,
      supplier_id: 1,
      category: {
        id: 1,
        title: '',
        description: '',
      },
      assignment_id: 22,
      case_name: '',
      product_name: '',
      start_date: '2022-06-11',
      end_date: '2022-06-22',
      case_detail: '',
      is_service_interruption: 1,
      status: {
        id: 0,
        name: '',
      },
      email_notification: 0,
      phone_notification: 0,
      created_by: {
        id: 1,
        name: '',
      },
    },
  ])
  const [doc, setDoc] = useState<any>([
    {
      created_by: {
        id: 1,
        name: '',
      },
    },
  ])

  const [percent, setPercent] = useState(0)
  const [today, setToday] = useState(new Date())
  useEffect(() => {
    let test = 0
    GetTickets(data.id).then(({data}) => {
      setTicket(data.data)
      if (data.data?.length < 1) setPercent(0)
      else {
        data.data.map((t: any) => {
          if (new Date(t?.end_date) < today && t?.status?.id == 0) {
            test = test + 1
          }
        })
        setPercent((test / data.data?.length) * 100)
      }
    })

    GetSLACategories(data.id).then(({data}) => {
      setSla(data.data.reverse())
    })

    GetSupplierById(data.id).then(({data}) => {
      setDoc(data.data.files)
    })
  }, [refresh])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            <div id='kt_app_content_container' className='app-container container-fluid p-0'>
              <div className='d-flex flex-column flex-lg-row '>
                <UserInfo supplier={data} percentage={percent} />
                <div className='w-100 pt-0 p-10 pe-0'>
                  <Slider
                    ticket={ticket}
                    active={active}
                    setActive={setActive}
                    sla={sla}
                    doc={doc}
                    supplier_id={data.id}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='kt_user_view_overview_tab'
                      role='tabpanel'
                    >
                      <div className='card card-flush mb-6 mb-xl-9'>
                        <div className='card-header mt-6'>
                          <div className='card-title flex-column w-100'>
                            {active == 1 ? (
                              <table className='table gy-4 align-middle fw-bolder'>
                                <thead className='fs-2'> Public Tickets</thead>
                                <tbody>
                                  {ticket.map((t) => {
                                    return (
                                      <Ticket
                                        key={t?.id}
                                        ticket={t}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        supplier_id={data.id}
                                      />
                                    )
                                  })}
                                </tbody>
                              </table>
                            ) : active == 2 ? (
                              <table
                                id='kt_file_manager_list'
                                data-kt-filemanager-table='folders'
                                className='table align-middle table-row-dashed fs-6 gy-5'
                              >
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                                    <th className='min-w-250px'>Adı</th>
                                    <th className='min-w-10px'>Boyutu</th>
                                    <th className='min-w-125px'>Oluşturan</th>
                                  </tr>
                                </thead>
                                <tbody className='fw-bold text-gray-600'>
                                  {doc.map((file: any) => {
                                    return (
                                      <ListFileItem
                                        key={file.id}
                                        file={file}
                                        supplier_id={data.id}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                      />
                                    )
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                                <thead></thead>
                                <tbody className=''>
                                  {sla.map((s) => {
                                    return (
                                      <SLA
                                        key={s?.id}
                                        refresh={refresh}
                                        sla={s}
                                        setRefresh={setRefresh}
                                        supplier_id={data.id}
                                      />
                                    )
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
