import React from 'react'
import { toAbsoluteUrl } from '../../../_theme/helpers'

const NoData = () => {
  return (
    <>
    <div
      style={{
        height: '200px',
        width: '100%',
        borderRadius: '10px',
        color: 'black',
        fontSize: '16px',
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        marginTop: '12px',
        marginBottom: '12px',
        textAlign: 'center',
      }}
      className='d-flex justify-content-center'
    >
      <img
        style={{
          height: '200px',
          width: 'auto',

          textAlign: 'center',
        }}
        alt='Logo'
        src={toAbsoluteUrl('/media/illustrations/dozzy-1/19.png')}
        className=''
      />
    </div>

    <div className='d-flex  justify-content-center w-100'>
      <h2 className='d-flex  text-center'>Veri Bulunamadı</h2>
    </div>
  </>
  )
}

export default NoData