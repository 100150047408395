import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {connect, shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {getActivities} from '../core/_requests'

import {HeaderAlertItem} from './HeaderAlertItem'

export const HeaderAlert: FC = () => {
  const [activities, setActivities] = useState<any>([])
  useEffect(() => {
    getActivities().then((data) => {
      setActivities(data.data)
    })
  }, [])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div className='mb-5'>
      {/* filter((ac:any)=> loginUser.name!==ac.created_by ) */}
      {activities.slice(0, 5).map((ac: {created_at: string; description: string; created_by: string}, index: React.Key | null | undefined) => {
        return <HeaderAlertItem key={index} time={moment(ac.created_at).format('DD.MM.YYYY H:mm')} title={ac.description} create_by={ac?.created_by} item={ac} />
      })}

      {/* <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/>
        <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/>
        <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/> */}
    </div>
  )
}
