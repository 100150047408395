import {API_URL} from '../../../request'
import axios, {AxiosResponse} from 'axios'

export const POC = `${API_URL}/poc`

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function GetPoc(parametres: any) {
  return await axios.get<any>(POC + parametres)
}

export async function GetPocDetail(id: any) {
  return await axios.get<any>(POC + '/' + id)
}

export async function CreateCase(caseItem: any) {
  return await axios.post(POC, caseItem, config)
}

export async function UpdateCase(id: any, CaseItem: any) {
  return await axios.put(POC + '/' + id, CaseItem)
}
export async function DeleteCase(id: any) {
  return await axios.delete(POC + '/' + id)
}

export async function CreateComment(caseId: any, comment: string) {
  return await axios.post(POC + '/' + caseId + '/comments', comment)
}

export async function GetPocWidget(api: string) {
  return await axios.get(API_URL + api)
}
