import moment from 'moment'
import {FC, useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {ModalHeader} from '../../components/ModalHeader'
import {InventoryModal} from '../inventory-detail/InventoryModal'
type Props = {
  inventory: any
}
export const InventoryItem: FC<Props> = ({inventory}) => {
  const [showModal, setShowmodal] = useState(false)
  const handleClose = () => {
    setShowmodal(false)
  }
  const handleShow = () => {
    setShowmodal(true)
  }
  return (
    <>
      {/* <tr style={{backgroundColor: '#edeced'}}>
        <td className='' style={{border: '2px solid black'}}>
          <NavLink to='envanter-detail' state={inventory} className='text-gray-900 text-hover-primary'>
            {inventory?.seri_numarasi}{' '}
          </NavLink>
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.ilgili_birim_mudurluk}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lokasyon}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.kabin_adi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.racku_bilgisi}
        </td>
        <td className='fw-bolder p-2' style={{border: '2px solid black'}}>
          {inventory?.cihaz_tipi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.marka}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.model}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.zaafiyet_arastirma_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {' '}
          {inventory?.cihaz_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.cihaz_ip}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.cihaz_hostname}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.cihazdaki_lisans_bilgisi}
        </td>

        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lisans_sahibi_firma}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lisans_modeli}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lisans_tipi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lisans_bitis_tarihi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.lisans_baslangic_tarihi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.kullanim_amaci}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.alarm_izleme_sistemi}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.satin_almanin_yapildigi_firma}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.suresiz_garanti_lisans}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.edr_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.antivirus_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.mfa_uygulama_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.siement_entegrasyon_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.backup_alimi_durumu}
        </td>
        <td className='' style={{border: '2px solid black'}}>
          {inventory?.user_authentication_method}
        </td>
        <td>
          <i className='far fa-eye'></i>
        </td>
      </tr> */}
      <tr>
        <td className='fw-bolder'>{inventory?.seri_numarasi}</td>
        <td className='text-gray-500'>{inventory?.ilgili_birim_mudurluk}</td>
        <td className='text-gray-500'>{inventory?.lokasyon}</td>
        <td className='text-gray-500'>{inventory?.cihaz_tipi}</td>
        <td className='text-gray-500'>{inventory?.marka}</td>
        <td className='text-gray-500'>{inventory?.cihaz_durumu}</td>
        <td className='text-gray-500'>{inventory?.cihaz_ip}</td>
        <td className='text-gray-500'>{inventory?.kullanim_amaci}</td>
        <td className='text-end cursor-pointer' onClick={handleShow}>
          <i className='far fa-eye text-gray-900'></i>
          {/*         
          <Dropdown>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item></Dropdown.Item>
              <Dropdown.Item>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </td>
      </tr>
      <Modal show={showModal} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <InventoryModal inventory_id={inventory?.id} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
