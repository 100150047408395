import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}

export const TableBody: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.report_id}</td>
        <td className='text-gray-500'>{item?.name}</td>
        <td className='text-gray-500'>{item?.type}</td>
        <td className='fw-bolder'>{item?.severity}</td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='text-gray-500'>{moment(item?.occurred).format('DD.MM.YYYY H:mm')}</td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY H:mm')}</td>

        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
