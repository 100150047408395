

import React, {createContext, useContext, useState} from 'react'

const RefreshContext = createContext();

const RefreshProvider=({children})=>{
    const [refresh, setRefresh] = useState(false)
    const refreshPage=()=>{
        refresh ? setRefresh(false) : setRefresh(true)
    }

    const values={
        refresh,
        refreshPage
    }
   return <RefreshContext.Provider value={values}>{children} </RefreshContext.Provider>; 
  
}
const useRefresh1 = () => useContext(RefreshContext)
export {RefreshProvider, useRefresh1}
