import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_theme/assets/ts/_utils'
import {GetNODStatistics, GetReportStatistics, GetReportsWidget} from '../../../OperationalData/core/request'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {GetPocWidget} from '../../../Project/Poc/core/_request'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  horizentalTitle: string
  widgetId: string
  pageId?: string
  refreshPage?: any
  plusOrMinus: boolean
  linkToPage: string
}

export const PluralBarChart: FC<Props> = ({horizentalTitle, widgetId, pageId, plusOrMinus, linkToPage}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {refreshPage} = useRefresh1()
  const [title, setTitle] = useState<any>([])
  const [closeAverageTemp, setCloseAverage] = useState<any>([])
  const [openAverage, setOpenAverage] = useState<any>([])
  const [pendingAverage, setPendingAverage] = useState<any>([])
  const [blocked, setBlocked] = useState<any>([])
  const [unblocked, setUnblocked] = useState<any>([])
  const [grafik, setGrafik] = useState(false)

  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [serverIp, setServerIp] = useState('10.6.236.121')
  const [serverIps, setServerIps] = useState<any>()

  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  useEffect(() => {
    if (widgetId === '64') {
      setGrafik(false)
      GetReportsWidget('/security_score_data_graph?server_ip=' + serverIp).then((data) => {
        toArray(data?.data)
      })
    }
  }, [serverIp])
  useEffect(() => {
    if (widgetId === '47') {
      GetReportsWidget('/malicious_statistics_total/risk').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId === '64') {
      GetPocWidget('/vulnerability_exploitation_server_ips').then((data) => {
        setServerIps(data?.data)
      })
    } else if (widgetId === '68') {
      GetReportsWidget('/attack_result_total/trusted_untrusted').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId === '69') {
      GetReportsWidget('/attack_result_total/category').then((data) => {
        toArray(data?.data)
      })
    } else if (widgetId == '97') {
      GetReportsWidget('/nkdy_support_request_total/status').then((data) => {
        toArray(data?.data)
      })
    }
  }, [])
  useEffect(() => {
    const chart = new ApexCharts(
      chartRef.current,
      widgetId === '64' || widgetId === '69'
        ? chartOptionsSecurityScore(title, closeAverageTemp, openAverage, pendingAverage, blocked, unblocked, '', widgetId)
        : chartOptionsVulnerabilitity(title, closeAverageTemp, openAverage, pendingAverage, blocked, '', widgetId)
    )
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [grafik])

  const toArray = (data: any) => {
    let titleTemp = []
    let closeAverageTemp = []
    let openAverageTemp = []
    let pendingAverageTemp = []
    let blockedCount = []
    let unblockedCount = []
    if (widgetId === '47') {
      for (let i = 0; i < data['aylik']?.length; i++) {
        titleTemp.push(data['aylik'][i].risk)
        if (data['aylik'][i].risk == 'High Risk') {
          closeAverageTemp.push(data['aylik'][i].total)
        } else if (data['aylik'][i].risk == 'Low Risk') {
          openAverageTemp.push(data['aylik'][i].total)
        } else if (data['aylik'][i].risk == 'Malicious') {
          pendingAverageTemp.push(data['aylik'][i].total)
        } else if (data['aylik'][i].risk == 'Medium Risk') {
          blockedCount.push(data['aylik'][i].total)
        }
      }
      for (let i = 0; i < data['haftalik']?.length; i++) {
        titleTemp.push(data['haftalik'][i].risk)
        if (data['haftalik'][i].risk == 'High Risk') {
          closeAverageTemp.push(data['haftalik'][i].total)
        } else if (data['haftalik'][i].risk == 'Low Risk') {
          openAverageTemp.push(data['haftalik'][i].total)
        } else if (data['haftalik'][i].risk == 'Malicious') {
          pendingAverageTemp.push(data['haftalik'][i].total)
        } else if (data['haftalik'][i].risk == 'Medium Risk') {
          blockedCount.push(data['haftalik'][i].total)
        }
      }
    } else if (widgetId === '64') {
      titleTemp.push('Total Vector Count')
      titleTemp.push('Total Threat Count')
      titleTemp.push('Total Security Score')
      titleTemp.push('Data Blocked')
      titleTemp.push('Data Unblocked')
      closeAverageTemp.push(data[0][serverIp]?.current_data.total_vector_count)
      closeAverageTemp.push(0)
      openAverageTemp.push(data[0][serverIp].current_data.total_threat_count)
      openAverageTemp.push(data[0][serverIp].changes_data.total_threat_count_change)
      pendingAverageTemp.push(data[0][serverIp].current_data.total_security_score)
      pendingAverageTemp.push(data[0][serverIp].changes_data.total_security_score_change)
      blockedCount.push(data[0][serverIp].current_data.blocked)
      blockedCount.push(data[0][serverIp].changes_data.total_blocked_change)
      unblockedCount.push(data[0][serverIp].current_data.unblocked)
      unblockedCount.push(data[0][serverIp].changes_data.total_unblocked_change)
    } else if (widgetId === '68') {
      titleTemp.push('Trusted')
      titleTemp.push('Untrusted')
      closeAverageTemp.push(data[0]['10.6.236.121'].trusted)
      closeAverageTemp.push(data[1]['10.6.236.122'].trusted)
      openAverageTemp.push(data[0]['10.6.236.121'].untrusted)
      openAverageTemp.push(data[1]['10.6.236.122'].untrusted)
    } else if (widgetId === '69') {
      titleTemp.push('Attack Scenario')
      titleTemp.push('Data Exfiltration')
      titleTemp.push('Malicious Code')
      titleTemp.push('Vulnerability Exploitation')
      titleTemp.push('Web Application Attacks')
      closeAverageTemp.push(data[0]['10.6.236.121'][0].total)
      openAverageTemp.push(data[0]['10.6.236.121'][1].total)
      pendingAverageTemp.push(data[0]['10.6.236.121'][2].total)
      blockedCount.push(data[0]['10.6.236.121'][3].total)
      unblockedCount.push(data[0]['10.6.236.121'][4].total)
      closeAverageTemp.push(data[1]['10.6.236.122'][0].total)
      openAverageTemp.push(data[1]['10.6.236.122'][1].total)
      pendingAverageTemp.push(data[1]['10.6.236.122'][2].total)
      blockedCount.push(data[1]['10.6.236.122'][3].total)
      unblockedCount.push(data[1]['10.6.236.122'][4].total)
    } else if (widgetId === '97') {
      titleTemp.push(data['aylik'][0].status_description)
      closeAverageTemp.push(data['aylik'][0]?.total)
      titleTemp.push(data['aylik'][1]?.status_description)
      openAverageTemp.push(data['aylik'][1]?.total)
      titleTemp.push(data['haftalik'][0]?.status_description)
      closeAverageTemp.push(data['haftalik'][0]?.total)
      titleTemp.push(data['haftalik'][1]?.status_description)
      openAverageTemp.push(data['haftalik'][1]?.total)
    }

    setTitle(titleTemp)
    setCloseAverage(closeAverageTemp)
    setOpenAverage(openAverageTemp)
    setPendingAverage(pendingAverageTemp)
    setBlocked(blockedCount)
    setUnblocked(unblockedCount)
    setGrafik(true)
  }

  return (
    <div className={`card card-flush overflow-hidden`} style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      {/* begin::Header */}
      {/* <div className='card-header text-center mt-5'>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div>
            <h4 className='card-title align-items-center flex-column text-center w-100 m-1 p-1'>
              <span className='card-label fw-bolder text-dark fs-2'>{horizentalTitle}</span>
            </h4>
          </div>
        </Link>
        <div
          onClick={() => {
            homeWidgetShow()
          }}
        >
          <span
            className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex'
            style={{
              backgroundColor: '#f6f7f8',
              border: '1px solid rgb(1 13 88)',
              color: 'rgb(1 13 88)',
            }}
          >
            {plusOrMinus ? '+' : '-'}
          </span>
        </div>
      </div> */}
      <div className='d-flex justify-content-between p-5'>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='card-header text-center '>
            <h4 className='card-title  flex-column  w-100 m-1 p-1'>
              <span className='card-label fw-bolder text-dark fs-2'>{horizentalTitle}</span>
              {widgetId == '88' && <span className='fs-6'>Kritik Sistemler</span>}
              {widgetId == '89' && <span className='fs-6'>Kritik Sistemler</span>}
            </h4>
          </div>
        </Link>
        <div className='d-flex'>
          {widgetId == '62' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setServerIp(e.target.value)}
              >
                {serverIps?.map((sIp: any) => {
                  return (
                    <option key={sIp?.server_ip} value={sIp?.server_ip}>
                      {sIp?.server_ip}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {widgetId == '64' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setServerIp(e.target.value)}
              >
                {serverIps?.map((sIp: any) => {
                  return (
                    <option key={sIp?.server_ip} value={sIp?.server_ip}>
                      {sIp?.server_ip}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          <div
            onClick={() => {
              homeWidgetShow()
            }}
          >
            <span
              className='btn btn-icon btn-small btn-active-color-primary mt-n2 me-n3  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6 pie-chart1' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptionsVulnerabilitity = (title: any, loss: any, correction: any, assured: any, blocked: any, pointTitle: string, widgetId: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: title[0],
        data: loss,
      },
      {
        name: title[1],
        data: correction,
      },
      {
        name: title[2],
        data: assured,
      },
      {
        name: title[3],
        data: blocked,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: '200px',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    dataLabels: {
      enabled: false,
    },
    fill: {},
    stroke: {
      curve: 'straight',
      show: true,
      width: 2,
    },

    legend: {
      show: true,
      showForSingleSeries: true,
      fontSize: '12px',
      customLegendItems:
        widgetId === '62'
          ? ['Malicious Code', 'Web Application Attacks', 'Attack Scenario']
          : widgetId == '47'
          ? ['High Risk', 'Low Risk', 'Malicious', 'Medium Risk']
          : widgetId == '97'
          ? [title[0], title[1]]
          : ['Trusted', 'Untrusted'],
      markers: {
        // fillColors: ['#00E396', '#775DD0'],
      },
    },

    xaxis: {
      categories: widgetId === '68' ? ['10.6.236.121', '10.6.236.122'] : ['Aylık', 'Haftalık'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,

      labels: {
        show: false,
        rotate: 0,
        rotateAlways: false,
        offsetY: 4,
        style: {
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 1,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      x: {
        show: false,
      },
      // y: {
      //   formatter: function (val) {
      //     return val.toString()
      //   },
      // },
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
      size: 5,
    },
  }
}
const chartOptionsSecurityScore = (title: any, loss: any, correction: any, assured: any, blocked: any, unblocked: any, pointTitle: string, widgetId: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: title[0],
        data: loss,
      },
      {
        name: title[1],
        data: correction,
      },
      {
        name: title[2],
        data: assured,
      },
      {
        name: title[3],
        data: blocked,
      },
      {
        name: title[4],
        data: unblocked,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: '200',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    dataLabels: {
      enabled: false,
    },
    fill: {},
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },

    legend: {
      show: true,
      fontSize: '12px',
      showForSingleSeries: true,
      customLegendItems: title,
      markers: {
        //  fillColors: ['#00E396', '#775DD0']
      },
    },
    xaxis: {
      categories: widgetId === '64' ? ['Current Data', 'Changes Data'] : widgetId === '69' ? ['10.6.236.121', '10.6.236.122'] : title,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,

      labels: {
        show: false,
        rotate: 0,
        rotateAlways: false,
        offsetY: 3,
        style: {
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 1,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {text: ''},
      tickAmount: 5,
      max: Math.max.apply(Math, [Math.max.apply(Math, correction), Math.max.apply(Math, assured), Math.max.apply(Math, loss)]),
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
        // formatter: function (val) {
        //   return '$' + Number(val / 10).toFixed(1) + 'K'
        // },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
      size: 5,
    },
  }
}
