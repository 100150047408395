import {FC} from 'react'
import {useListView} from '../../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {SearchComponent} from '../../../../components/SearchComponent'
import {UsersListToolbar} from './UserListToolbar'

type Props = {
  setResult: any
  setStatus: any
  setWord?: any
  selectedDirectorate: string
}

const UsersListHeader: FC<Props> = ({setWord, setResult, setStatus, selectedDirectorate}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      {/* <UsersListSearchComponent setWord={setWord}/> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex justify-content-between'>
        <SearchComponent setSearchKeyClick={setWord} placeholder={'PoC Çalışması Ara'} />
        {selectedDirectorate != '' && <span className='badge badge-light badge-lg me-2 px-9 py-4'>{selectedDirectorate}</span>}
        <UsersListToolbar setWord={setWord} setStatus={setStatus} setResult={setResult} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
