import {auto} from '@popperjs/core'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {
  getActivityCategories,
  getStaticWidget,
  getUserByAssignment,
} from '../../Activities/core/_requests'
import {getAnnounCategories} from '../../Announcements/core/_requests'
import { getBlogCategories } from '../../Blog/core/_requests'

type Props = {
  item: any
}

export const Updated: FC<Props> = ({item}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [status, setStatus] = useState<any>([])
  const [publicBoxOld, setPublicboxOld] = useState(item?.changes?.old?.is_public)
  const [publicBox, setPublicbox] = useState(item?.changes?.attributes?.is_public)
  const [sliderBox, setSliderbox] = useState(item?.changes?.attributes?.is_slider)
  const [sliderBoxOld, setSliderboxOld] = useState(item?.changes?.old?.is_slider)

  useEffect(() => {
    if (item?.subject_type == 'Issue') {
      getActivityCategories().then(async ({data}) => {
        await setCategories(data.data)
      })

      getUserByAssignment().then(({data}) => {
        setUsers(data.data)
      })
      getStaticWidget()
        .then(({data}) => {
          setStatus(data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (item?.subject_type == 'Announcement') {
      getAnnounCategories().then(({data}) => {
        setCategories(data.data)
      })
    }
    else if (item?.subject_type == 'Blog') {
        getBlogCategories().then(({data}) => {
          setCategories(data.data)
        })
      }

  }, [])
  const [userForEdit] = useState<any>({
    ...item?.old,
    id: item?.changes?.old?.id,
    title: item?.changes?.old?.title,
    start_date: item?.changes?.old?.start_date,
    end_date: item?.changes?.old?.end_date,
    content: item?.changes?.old?.content,
    email_notification: item?.changes?.old?.email_notification,
    phone_notification: item?.changes?.old?.phone_notification,
    project_id: item?.changes?.old?.project?.id,
    issue_category_id: item?.changes?.old?.issue_category_id,
    assigned_by: item?.changes?.old?.assigned_by,
    status: item?.changes?.old?.status,
    //anouns
    is_slider: item?.changes?.old?.is_slider,
    announcement_category_id: item?.changes?.old?.announcement_category_id,
    is_public: item?.changes?.old?.is_public,
    assignments: item?.changes?.old?.assignments,

    //blog
    blog_category_id:item?.changes?.old?.blog_category_id,
  })
  const [userForUpdated] = useState<any>({
    ...item?.changes?.attributes,
    id: item?.changes?.attributes?.id,
    title: item?.changes?.attributes?.title,
    start_date: item?.changes?.attributes?.start_date,
    end_date: item?.changes?.attributes?.end_date,
    content: item?.changes?.attributes?.content,
    email_notification: item?.changes?.attributes?.email_notification,
    phone_notification: item?.changes?.attributes?.phone_notification,
    project_id: item?.changes?.attributes?.project?.id,
    issue_category_id: item?.changes?.attributes?.issue_category_id,
    assigned_by: item?.changes?.attributes?.assigned_by,
    status: item?.changes?.attributes?.status,
    //anouns
    is_slider: item?.changes?.attributes?.is_slider,
    announcement_category_id: item?.changes?.attributes?.announcement_category_id,
    is_public: item?.changes?.attributes?.is_public,
    assignments: item?.changes?.attributes?.assignments,

    //blog
    blog_category_id:item?.changes?.attributes?.blog_category_id,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })
  const formik1 = useFormik({
    initialValues: userForUpdated,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })

  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='row d-flex'>
            {item?.changes?.old?.status && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Durumu</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('status')}
                  name='status'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {status.map((st: any) => {
                    return (
                      <option key={st.status_id} value={st.status_id}>
                        {st.status_name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.status && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Durumu</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('status')}
                  name='status'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {status.map((st: any) => {
                    return (
                      <option key={st.status_id} value={st.status_id}>
                        {st.status_name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>
          <div className='row d-flex'>
            {item?.changes?.old?.title && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Başlık</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik.getFieldProps('title')}
                    type='text'
                    name='title'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', 'line-through')}
                    autoComplete='off'
                    disabled={true}
                    style={{textDecoration: 'line-through'}}
                  />
                </div>
              </div>
            )}

            {item?.changes?.attributes?.title && (
              <div className='col-xs-12 col-md-6'>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Yeni Başlık</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    placeholder='Başlık'
                    {...formik1.getFieldProps('title')}
                    // defaultValue={item?.changes?.attributes?.title}
                    type='text'
                    name='title'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled={true}
                  />

                  {/* end::Input */}
                </div>
              </div>
            )}

            {/* end::Input Kategori */}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.assigned_by && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Sorumlu</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('assigned_by')}
                  name='assigned_by'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            {item?.changes?.attributes?.assigned_by && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <label className=' fw-bold fs-7 mb-2'>Yeni Sorumlu</label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik1.getFieldProps('assigned_by')}
                  name='assigned_by'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Sorumlu Seç
                  </option>
                  {users.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>
          <div className='row d-flex'>
            {item?.changes?.old?.issue_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Kategori</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('issue_category_id')}
                  name='issue_category_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.issue_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Yeni Kategori</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('issue_category_id')}
                  name='issue_category_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          <div className='row d-flex'>
            {item?.changes?.old?.blog_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Kategori</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('blog_category_id')}
                  name='blog_category_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.blog_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Yeni Kategori</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('blog_category_id')}
                  name='blog_category_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          {/* start anouns */}
          <div className='row d-flex'>
            {item?.changes?.old?.announcement_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Kategori</label>
                </div>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('announcement_category_id')}
                  name='announcement_category_id'
                  style={{textDecoration: 'line-through'}}
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {item?.changes?.attributes?.announcement_category_id && (
              <div className='col-xs-12 col-md-6 mb-7'>
                <div className='d-flex justify-content-between'>
                  <label className=' fw-bold fs-7 mb-2'>Yeni Kategori</label>
                </div>

                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik1.getFieldProps('announcement_category_id')}
                  name='announcement_category_id'
                  disabled={true}
                >
                  <option selected disabled hidden>
                    Kategori
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>

          {/* end anouns */}

          <div className='row'>
            {item?.changes?.old?.start_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Başlangıç Tarihi</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                />
              </div>
            )}

            {item?.changes?.attributes?.start_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Yeni Başlangıç Tarihi</label>

                <input
                  placeholder=''
                  {...formik1.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                />
              </div>
            )}
          </div>
          <div className='row d-flex'>
            {item?.changes?.old?.end_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                  style={{textDecoration: 'line-through'}}
                />
              </div>
            )}

            {item?.changes?.attributes?.end_date && (
              <div className='col-xs-12 col-md-6 mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-7 mb-2'>Yeni Bitiş Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik1.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={true}
                />
              </div>
            )}

            {/* end::Input Bitiş tarihi */}
          </div>

          {item?.changes?.old?.content && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Açıklama</label>
              {/* end::Label */}
              <textarea
                placeholder='Faaliyet için açıklama yazınız'
                {...formik.getFieldProps('content')}
                name='content'
                className='form-control form-control-solid mb-3 mb-lg-0'
                disabled={true}
                style={{textDecoration: 'line-through'}}
              ></textarea>
            </div>
          )}
          {item?.changes?.attributes?.content && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Yeni Açıklama</label>
              {/* end::Label */}
              <textarea
                placeholder='Faaliyet için açıklama yazınız'
                {...formik1.getFieldProps('content')}
                name='content'
                className='form-control form-control-solid mb-3 mb-lg-0'
                disabled={true}
              ></textarea>
            </div>
          )}

          {/* end::Açıklama */}

          {(item?.changes?.old?.is_public || item?.changes?.attributes?.is_public) && (
            <div className='row mb-7 d-flex'>
              <div className='col-12'>
                <div className='d-flex flex-column mb-8 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bold mb-2 justify-content-between'>
                    <span className='required'>Kimler görüntüleyebilsin?</span>
                    <div>
                      <div style={{textDecoration: 'line-through', marginBottom: '10px'}}>
                        <input
                          className='form-check-input h-18px w-18px'
                          type='checkbox'
                          {...formik.getFieldProps('is_public')}
                          name='is_public'
                          disabled={true}
                          defaultChecked={publicBoxOld}
                        />
                        <span className='form-check-label fw-bold'> Herkes</span>
                      </div>

                      <div>
                        <input
                          className='form-check-input h-18px w-18px'
                          type='checkbox'
                          {...formik.getFieldProps('is_public')}
                          name='is_public'
                          disabled={true}
                          defaultChecked={publicBox}
                        />
                        <span className='form-check-label fw-bold'> Herkes</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          )}

          {(item?.changes?.old?.is_slider || item?.changes?.attributes?.is_slider) && (
            <div className='row mb-7 d-flex'>
              <div className='col-12'>
                <div className='mb-7 fv-row'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold me-5'>
                      <label className='required fs-6'>Görünürlük</label>
                      <div className='fs-7 text-gray-400'>
                        Duyuruyu slider alanında görüntülemek için aktif hale getiriniz
                      </div>{' '}
                    </div>
                    <div>
                      <div
                        className='form-check form-check-solid form-switch fv-row'
                        style={{textDecoration: 'line-through', marginBottom: '10px'}}
                      >
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          id='allowmarketing'
                          {...formik.getFieldProps('is_slider')}
                          name='is_slider'
                          value='is_slider'
                          defaultChecked={sliderBoxOld}
                        />
                        <label        style={{textDecoration: 'line-through', marginBottom: '10px'}} className='fs-7 text-gray-400'> Eski</label>
                      </div>
                      <div className='form-check form-check-solid form-switch fv-row'>
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          id='allowmarketing'
                          {...formik1.getFieldProps('is_slider')}
                          name='is_slider'
                          value='is_slider'
                          defaultChecked={sliderBox}
                        />
                                <label className='fs-7 text-gray-400'> Yenı</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* begin:: Bildirimler */}
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirim</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center' style={{textDecoration: 'line-through'}}>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={item?.changes?.old?.email_notification}
                    disabled={true}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={item?.changes?.old?.phone_notification}
                    disabled={true}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'></div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={item?.changes?.attributes?.email_notification}
                    disabled={true}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={item?.changes?.attributes?.phone_notification}
                    disabled={true}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          {/* end:: Bildirimler */}

          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
