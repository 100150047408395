/* eslint-disable jsx-a11y/anchor-is-valid */
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Link} from 'react-router-dom'
import {logout} from '../../../../app/pages/core/requests'
import {useAppSelector} from '../../../../setup/hooks/redux'
import {setValue} from '../../../../app/pages/components/hideMenuRedux'

const HeaderUserMenu = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const hideMenu = useAppSelector((state) => state.hideMenu.hideMenu)
  const dispatch = useDispatch()

  const changeVisibilityMenu = () => {
    dispatch(setValue())
  }
  const runLogout = () => {
    logout().then((res) => {
      if (res?.status == 200) {
        dispatch(auth.actions.logout())
      }
      // localStorage.removeItem('csrfToken')
      // localStorage.removeItem('lastRequestTimestamp')
    })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px' data-kt-menu='true'>
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            {user.avatar ? <img className='Logo' src={user.avatar} alt='' /> : <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-3'>{user.name?.substring(0, 1)}</div>}
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'profile'} state={user} className='menu-link px-5'>
          Profil
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <a onClick={runLogout} className='menu-link px-5'>
          Çıkış
        </a>
      </div>
      <div className='menu-item px-5'>
        <a onClick={changeVisibilityMenu} className='menu-link px-5'>
          {hideMenu ? `Menü'yü Görüntüle` : `Menü'yü Gizle`}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
