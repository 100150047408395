import { FC } from "react"
import { KTCard } from "../../../_theme/helpers"
import { SupplierList } from "./components/SupplierList"


export const Supplier: FC = () => {

    return(
        <>
        <KTCard>
        <SupplierList />
      </KTCard>
      </>
    )}