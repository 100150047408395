import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {DSRNItem} from './components/DSRNItem'
import {GetDSRN} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddDSRN} from './add-DSRN/Add-DSRN'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ExportLink} from '../../core/requests'
import {DetailFilters} from './components/DetailFilter'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const DSRN: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [filterModalShow, setFilterModalShow] = useState(false)
  const showFilterModal = () => {
    setFilterModalShow(true)
    setSearchKeyClick('')
    setMachine('')
    setCreatedAt('')
    setClusterName('')
    setIpAddress('')
    setOsRelease('')
    setOsVersion('')
    setDbVersion('')
  }
  const closeFilterModal = () => setFilterModalShow(false)
  const [dsrn, setDSRN] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [machine, setMachine] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [clusterName, setClusterName] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [osRelease, setOsRelease] = useState('')
  const [osVersion, setOsVersion] = useState('')
  const [dbVersion, setDbVersion] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=20'
    if (searchKeyClick != '') {
      queryParams += '&server_name=' + searchKeyClick
    }
    if (machine != '') {
      queryParams += '&machine=' + machine
    }
    if (createdAt != '') {
      queryParams += '&created_date=' + createdAt
    }
    if (clusterName != '') {
      queryParams += '&cluster_name=' + clusterName
    }
    if (ipAddress != '') {
      queryParams += '&ip_address=' + ipAddress
    }
    if (osRelease != '') {
      queryParams += '&os_release=' + osRelease
    }

    if (osVersion != '') {
      queryParams += '&os_version=' + osVersion
    }

    if (dbVersion != '') {
      queryParams += '&db_version=' + dbVersion
    }
    GetDSRN(queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setDSRN(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, searchKeyClick, machine, ipAddress, createdAt, clusterName, dbVersion, osVersion, osRelease])

  const downloa = () => {
    window.open(`${ExportLink}/data_security_request_numbers?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'DB Types'} link={'/data_scope_agents_total/db_types'} titleText={'Classification Number'} widgetId={'28'} plusOrMinus={true} linkToPage={''} setSelectedValue={setMachine} />
          </div>

          <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Os Name Classification Numbers'} widgetId={'29'} plusOrMinus={true} linkToPage={''} />
          </div>

          {/* end::Col */}

          {/* end::Col */}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-header'>
              {/* <SearchComponent setWord={setSearchKeyClick} /> */}
              <div className='d-flex m-3 justify-content-between w-100'>
                <HeaderInfo api={'/data_scope_agents_check'} />
                <div>
                  <Button className=' mt-3 mx-5 btn' onClick={showFilterModal}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 ' style={{marginRight: '10px'}} variant='primary' onClick={downloa}>
                    Dışarı Aktar
                  </Button>
                </div>
                {/*
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-0 scroll-x scroll-y mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5 ' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='  fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '0px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>server_name</th>
                      <th className=''>ip_address</th>
                      <th className=''>os_name</th>
                      <th className=''>db_types</th>
                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6' style={{overflow: 'scroll'}}>
                    {dsrn?.length < 1 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      dsrn?.map((dsqn: any) => {
                        return (
                          <>
                            <DSRNItem key={dsqn?.id} isam={dsqn} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddDSRN handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={filterModalShow} size='lg'>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters
            setMachine={setMachine}
            setCreatedAt={setCreatedAt}
            handleClose={closeFilterModal}
            setSearchKeyClick={setSearchKeyClick}
            setClusterName={setClusterName}
            setIpAddress={setIpAddress}
            setOsRelease={setOsRelease}
            setOsVersion={setOsVersion}
            setDbVersion={setDbVersion}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
