import React, {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {updateHomeWidgets, updateReportWidgets} from '../../../core/requests'
import {shallowEqual, useSelector} from 'react-redux'
import {GetReportsWidget} from '../../../OperationalData/core/request'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'
import {Link} from 'react-router-dom'

type Props = {
  plusOrMinus: boolean
  widgetId: any
  pageId?: any
  refreshPage?: any
  linkToPage: string
}
export const CarbonStatisticWidget: FC<Props> = ({plusOrMinus, pageId, widgetId, linkToPage}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {refreshPage} = useRefresh1()
  const [item, setItem] = useState<any>()
  const {refresh} = useRefresh1()
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  useEffect(() => {
    GetReportsWidget('/endpoint_protection_data_total/2').then((data) => {
      setItem(data?.data?.data)
    })
  }, [refresh])
  return (
    <div className='card card-flush pie-chart' style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <Link to={plusOrMinus ? '' : linkToPage}>
            <div>
              <div className='fs-2 fw-bolder'>Sensor Statistics</div>
            </div>
          </Link>
          <div>
            <span
              className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex'
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
              onClick={() => {
                homeWidgetShow()
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
        <div className='fs-3 mt-10 '>
          <div className='mt-5 d-flex justify-content-between'>
            <div className='fw-bolder me-0 text-gray-800'>Active Sensor Count</div>
            <div className='me-20'>{item?.active_sensor_count}</div>
          </div>
          <div className='mt-5 d-flex justify-content-between'>
            <div className='fw-bolder me-0 text-gray-800'>Num Eventlog Bytes</div>
            <div className='me-20'>{item?.num_eventlog_bytes_2}</div>
          </div>
          <div className='mt-5 d-flex justify-content-between'>
            <div className='fw-bolder me-0 text-gray-800'>Num Storefile Bytes</div>
            <div className='me-20'>{item?.num_storefiles_bytes_2}</div>
          </div>
          <div className='mt-5 d-flex justify-content-between'>
            <div className='fw-bolder me-0 text-gray-800'>Sensor Count</div>
            <div className='me-20'>{item?.sensor_count}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
