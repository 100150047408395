import {useFormik} from 'formik'
import React, {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import swal from 'sweetalert'
import {CreateMilestone, deleteMilestone, getProjectUsers, getTargetCategories, updateMilestone, updateTarget} from '../../core/requests'


type Props = {
  setCount: any
  target: any
  users: any
  targetCategories: any
  project_id: any
  update: boolean
  removeTarget?:any
}

export const AddMilestoneBody: FC<Props> = ({
  setCount,
  targetCategories,
  target,
  users,
  project_id,
  update,
  removeTarget
}) => {
  const [localUpdate,setUpdate] = useState<boolean>(update);
  const [loading, setLoading] = useState(false)
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        deleteMilestone(target.id).then(()=>{
          removeTarget(target);
        }).catch((err)=>{
          console.log(err)
        })
      }
    })
  }

  let formData = new FormData()

  const [userForEdit] = useState<any>({
    ...target,
    title: target?.title,
    project_id: project_id,
    project_plan_id: target?.plan?.id,
    content: target?.note,
  })
  const [userFor] = useState<any>({

    title: '',
    project_id: '',
    project_plan_id: '',
    content: ''
  })

  const targetSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu alan'),
    project_plan_id: Yup.string().required('Zorunlu alan'),
    content: Yup.string(),
  })

  const formik = useFormik({
    initialValues: update? userForEdit:userFor,
    validationSchema: targetSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
     
          if (localUpdate) {
            let UpdTarget = {
              title: values.title,
              project_plan_id: Number(values.project_plan_id),
              note: values.content,
              project_id:project_id

            }
           await updateMilestone(target.id, UpdTarget)
              .then(() => {
                setLoading(false)
              })
              .catch((err) => {
                swal('Hata', err.response.data.message, 'error', {timer: 2000})
              })
          } else {

           
            formData.append('title', values.title)
            formData.append('project_plan_id', values.project_plan_id)
            formData.append('note', values.content)
            formData.append('project_id', project_id)
        
           await CreateMilestone(formData)
              .then((data) => {   
                    
                setCount(data?.data?.data)
                setLoading(false)
                reset()

                // window.location.reload()
              })
              .catch((err) => {
                console.log(err.message)
                swal('Hata!', err.response.data.message, 'error', {timer: 2000})
              })
          }
       
  
     
      } catch (ex) {
        console.error(ex)
        swal('Hata!', 'error', {timer: 2000})
        setLoading(false)
      } finally {
        setSubmitting(true)
        setLoading(false)
      }
    },
  })
  const handleKeyPress = (event: { key: string }) => {
    if(event.key === 'Enter'){
      console.log('enter press here! ')
      const btn = (document.getElementById('btnsubmit') as  HTMLButtonElement);
      btn.click();
    }
  }
  const reset = ()=>{
    formik.resetForm()
  }

  return (
    <form
      id='kt_modal_add_user_form'
      onSubmit={formik.handleSubmit}
      className='form row d-flex justify-content-between border-primary border-bottom-dashed align-items-center py-2'
      noValidate
      
    >
      <div className='col p-1'>
        {' '}
        <div className='w-100 '>
          <select
            className='form-select form-select-solid'
            data-control='select2'
            id='select2'
            data-hide-search='true'
            data-placeholder='Kategori Seç'
            {...formik.getFieldProps('project_plan_id')}
            name='project_plan_id'
            //defaultValue={activity.assigned_by}
          >
            <option hidden>Proje Aşaması Seç</option>
            {targetCategories.map((category: any) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              )
            })}
          </select>
          {formik.touched.project_plan_id && formik.errors.project_plan_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.project_plan_id}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col p-1'>
        {' '}
        <div className='w-100'>
          {/* begin::Label */}
          {/* <label className='required fw-bold fs-6 mb-2'></label> */}
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz...'
            {...formik.getFieldProps('title')}
            type='text'
            id='title'
            name='title'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.title && formik.errors.title},
              {
                'is-valid': formik.touched.title && !formik.errors.title,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
            onKeyDown={handleKeyPress}
          />
          {formik.touched.title && formik.errors.title && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.title}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
      </div>
      <div className='col p-1'>
        <div className='w-100'>
          <input
            placeholder='Hedef ayrıntılarını giriniz'
            {...formik.getFieldProps('content')}
            name='content'
            id='content'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.content && formik.errors.content},
              {
                'is-valid': formik.touched.content && !formik.errors.content,
              }
            )}
            onKeyDown={handleKeyPress}
          />
          {formik.touched.content && formik.errors.content && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.content}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col p-1'>
       {localUpdate? <span className='menu-link text-danger px-3' style={{cursor: 'pointer'}}
        onClick={swalDelete}
       >
          Sil
        </span>:''}
        <button
          type='submit'
          id='btnsubmit'
          className='btn sm-btn  btn-sm'
          data-kt-users-modal-action='submit'
          disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
          style={{color: 'white'}}
        >
          {!loading && (<span className='indicator-label'>Gönder</span>)} 
          {loading && (
           
          
              <span className='spinner-border spinner-border-sm align-middle '></span>
      
          )}
        </button>
   
      </div>
    </form>
  )
}
