import {FC, useEffect, useState} from 'react'
type Props = {
  setRiskLevel: any
  setTrend: any
  setRefId: any
  setRefStatus: any
  handleClose: any
}

export const DetailFiltersDistrubutions: FC<Props> = ({setRiskLevel, setTrend, setRefId, setRefStatus, handleClose}) => {
  const handleSubmit = async () => {
    setRiskLevel(selectedRiskLevel)
    setTrend(selectedTrend)
    setRefId(selectedrefId)
    setRefStatus(selectedRefStatus)
    handleClose()
  }
  const [selectedRiskLevel, setSelectedRiskLevel] = useState('')
  const [selectedTrend, setSelectedTrend] = useState('')
  const [selectedrefId, setSelectedRefId] = useState('')
  const [selectedRefStatus, setSelectedRefStatus] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ref Id</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedRefId(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ref Status</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedRefStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Trend</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedTrend(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Risk Level</label>
        <input
          placeholder='Aranacak Kelimeyi Giriniz'
          type='text'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          autoComplete='off'
          onChange={(e) => {
            setSelectedRiskLevel(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button type='reset' className='btn btn-sm btn-light btn-active-light-primary me-2' data-kt-menu-dismiss='true' onClick={handleClose}>
          İptal
        </button>
        <button type='submit' className='btn btn-sm' data-kt-menu-dismiss='true' onClick={handleSubmit}>
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
