import React, {Key, useEffect} from 'react'
import {AssignmentWithSub} from './AssignmentWithSub'
import {AssignmentItem} from './AssignmentItem'

type Props = {
  element: any
  index?: any
}

const AssignmentItems: React.FC<Props> = ({element, index}) => {
  useEffect(() => {}, [])
  return (
    <>
      {/* {!element.is_last ? ( */}
      <AssignmentWithSub element={element} key={element.id} title={element.name} fontIcon='bi-chat-left' icon='/media/icons/duotune/arrows/arr001.svg'>
        {element.children.map(
          (
            e: {
              id: Key | null | undefined
              name: string
              children: any
              path: string
              is_last: boolean
            },
            index: any
          ) => {
            if (e.is_last) {
              return <AssignmentItem key={e.id} title={e.name} hasBullet={true} element={e} />
            } else {
              return <AssignmentItems key={e.id} element={e} index={index}></AssignmentItems>
            }
          }
        )}
      </AssignmentWithSub>
      {/* // ) : (
      //   <AssignmentItem key={element?.id} title={element?.name} hasBullet={true} element={element} />
      // )} */}
    </>
  )
}

export {AssignmentItems}
