import {FC, useState} from 'react'
import swal from 'sweetalert'
import {DeleteSupplier} from '../core/_requests'
import {Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddSupp} from '../supplier-edit-modal/AddSupp'
import {NavLink} from 'react-router-dom'
import moment from 'moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  supplier: any
  refreshPage: any
}

const deleteSupp = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteSupplier(id).then(() => {
        swal('Silindi!', 'Tedarki bilgileri başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const ListItem: FC<Props> = ({supplier, refreshPage}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [showModal, setShowmodal] = useState(false)
  const handleClose = () => {
    setShowmodal(false)
    refreshPage()
  }
  const handleShow = () => {
    setShowmodal(true)
  }

  return (
    <>
      <tr>
        <td className='fw-bolder'>
          <NavLink to={loginUser?.permissions.filter((permission) => permission.name == 'Show Supplier').length > 0 ? '../detail' : ''} state={supplier} className='text-gray-900 text-hover-primary'>
            {supplier?.name}
          </NavLink>
        </td>
        <td className='text-gray-500'>{supplier?.email}</td>
        <td className='text-gray-500'>{supplier?.company_name}</td>
        <td className='text-gray-500'>{moment(supplier?.created_at).format('DD.MM.YYYY')}</td>
        <td className='text-end'>
          <Dropdown>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className='btn btn-sm btn-light btn-active-light-primary me-2' id='dropdown-basic'></Dropdown.Toggle>
            <Dropdown.Menu>
              {loginUser?.permissions.filter((permission) => permission.name == 'Show Supplier').length > 0 && (
                <Dropdown.Item>
                  {' '}
                  <NavLink to='../detail' state={supplier} className='menu-link px-3 text-black'>
                    Görüntüle
                  </NavLink>
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleShow}>
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  deleteSupp(supplier.id, refreshPage)
                }}
              >
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={showModal} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddSupp
            supplier={{
              id: supplier.id,
              name: supplier.name,
              email: supplier.email,
              company_name: supplier.company_name,
              phone: supplier.phone,
              tax_number: supplier.tax_number,
              country_id: supplier.country.id,
              supplier_category_id: supplier.category.id,
              address: supplier.address,
              attachments: supplier.files,
              phone_notification: supplier.phone_notification,
              email_notification: supplier.email_notification,
            }}
            refreshPage={refreshPage}
            handleClose={handleClose}
            update={true}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
