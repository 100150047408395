import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const TrendmicroItem: FC<Props> = ({item}) => {
  return (
    <>
      <td className='fw-bolder'>{item?.hostName}</td>
      <td className='text-gray-500'>{item?.displayName}</td>
      <td className='text-gray-500'>{item?.description}</td>
      <td className='text-gray-500'>{item?.lastIPUsed}</td>
      <td className='text-gray-500'>{item?.platform}</td>
      <td className='text-gray-500'>{item?.groupID}</td>
      <td className='text-gray-500'>{item?.policyID}</td>
      <td className='text-gray-500'>{item?.relayListID}</td>
      <td className='text-gray-500'>{item?.lastAgentCommunication}</td>
      <td className='text-gray-500'>{item?.agentVersion}</td>
      <td className='text-gray-500'>{item?.ref_id}</td>
      <td className='text-gray-500'>{item?.cron_server_ip}</td>
      <tr></tr>
    </>
  )
}
