import {FC, useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import PaginationDown from '../../components/PaginationDown'
import {SearchComponent} from '../../components/SearchComponent'
import {BlogItem} from '../components/BlogItem'
import {GetBlogs} from '../core/_requests'

type LocationState = {
  num: number
  category: string
}
export const ViewDetail: FC = () => {
  const location = useLocation()
  const [blog, setBlog] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let data = location.state as LocationState
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const LoadMore = () => {
    setLoading(true)
    const nextPage = currentPage + 1
    currentTableData(nextPage)
  }

  const currentTableData = async (page: any) => {
    try {
      if (page > 0) {
        let queryParams = '?page[number]=' + page + '&page[size]=9' + '&blog_category_id=' + data?.num
        if (searchKeyClick !== '') {
          queryParams += '&q=' + searchKeyClick
        }
        const res = await GetBlogs(queryParams)
        setBlog((prev: any) => [...prev, ...res?.data?.data])
        setTotal(res?.data?.meta?.total)
        setCurrentPage(page)
      }
    } catch (error) {
      console.error('Hata:', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const resetAndFetchData = async () => {
      setBlog([])
      setLoading(true)
      await currentTableData(1)
    }
    resetAndFetchData()
  }, [searchKeyClick])
  return (
    <div id='kt_app_content_container' className='app-container container-fluid'>
      <div className='card'>
        <div className='card-body p-lg-10'>
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>{data?.category}</h3>
              <div className='card-title'>
                <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Blog Ara'} />
              </div>
            </div>
            <div className='separator separator-dashed mb-9'></div>
            <div className='row g-5 g-lg-10'>
              {blog?.length < 1 && !loading ? (
                <NoMatchRecords isTable={false} />
              ) : (
                blog.map((e: any, index: React.Key | null | undefined) => {
                  {
                    return <BlogItem key={index} el={e} path={e.files} />
                  }
                })
              )}
            </div>
            {total > blog?.length && (
              <Row className='mt-4'>
                <Col>
                  <PaginationDown isLoading={loading} onClickHandle={LoadMore} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
