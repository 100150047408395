import PropTypes from 'prop-types'
import React, {FC, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  blog: any
  path: any
}

export const Recent: FC<Props> = ({blog, path}) => {
  const link = path?.length > 0 ? path[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')
  return (
    <div className='d-flex align-items-center mb-7'>
      <div className='symbol symbol-60px symbol-2by3 me-4'>
        <div
          className='symbol-label'
          style={{
            backgroundImage: `url(${link})`,
          }}
        ></div>
      </div>

      <div className='m-0'>
        <Link
          className='d-block overlay text-dark fw-bolder text-hover-primary fs-6'
          data-fslightbox='lightbox-hot-sales'
          to='.'
          state={blog}
        >
          {blog?.title}
        </Link>
        <span
          style={{
            height: 'auto',
          }}
          className='text-gray-600 fw-bold d-block pt-1 fs-8'
        >
          {blog?.content?.slice(0, 50)}
          {blog?.content?.length > 50 ? '...' : ''}
        </span>
      </div>
    </div>
  )
}
