import {FC, useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../components/ModalHeader'
import {CardFiles} from './components/CardFiles'
import {AddFile} from './doc-edit-modal/AddFile'
import {ListFiles} from './components/ListFiles'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import {SearchComponent} from '../components/SearchComponent'
import {DetailFilters} from './components/DetailsFilter'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'

export const Documents: FC = () => {
  const [listShow, setListshow] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
    refreshPage()
  }
  const handleShow = () => setShow(true)
  const [filterModal, setFilterModal] = useState(false)
  const openFilterModal = () => {
    setSelectedOption('')
    setSelectedPeriod('')
    setSearchKeyClick('')
    setFilterModal(true)
  }
  const closeFilterModal = () => setFilterModal(false)

  const [orderBy, setOrderBy] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [totalCount, setTotalCount] = useState('')
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          <span className='fs-6 text-gray-400 fw-bold ms-1'>{totalCount} sonuç</span>
          <div className='card-title'>
            <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Doküman Ara'} />
          </div>
        </h3>

        <div className='d-flex flex-wrap my-1'>
          <ul className='nav nav-pills '>
            {loginUser?.permissions.filter((permission) => permission.name == 'Create Document').length > 0 && (
              <button type='button' className='btn me-5' data-toggle='modal' data-target='#kt_modal_upload' onClick={handleShow}>
                <span className='svg-icon svg-icon-2'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' />
                    <path
                      d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z'
                      fill='currentColor'
                    />
                    <path opacity='0.3' d='M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z' fill='currentColor' />
                  </svg>
                </span>
                Dosya Yükle
              </button>
            )}
          </ul>
          <Dropdown>
            <Dropdown.Toggle style={{backgroundColor: 'rgb(1 13 88)', color: 'white'}} className=' me-2 py-4' id='dropdown-basic'>
              {' '}
              Sırala{' '}
            </Dropdown.Toggle>
            <DropdownMenu>
              <Dropdown.Item onClick={(e) => setOrderBy('1')}>
                <span className='menu-link px-3'>A'dan Z'ye</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => setOrderBy('2')}>
                <span className='menu-link px-3'>Z'den A'ya</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => setOrderBy('3')}>
                <span className='menu-link px-3'>Yeniden Eskiye</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => setOrderBy('4')}>
                <span className='menu-link px-3'>Eskiden Yeniye</span>
              </Dropdown.Item>
            </DropdownMenu>
          </Dropdown>
          <div className=' btn py-3  me-2' onClick={openFilterModal}>
            Detaylı Ara
          </div>
        </div>
      </div>
      {listShow ? <CardFiles cat={selectedOption} searchKey={searchKeyClick} refresh={show} /> : <ListFiles cat={selectedOption} period={selectedPeriod} searchKey={searchKeyClick} setTotalCounts={setTotalCount} refresh={refresh} orderBy={orderBy} />}

      {/*begin : : add new file*/}
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddFile
            isUserLoading={false}
            doc={{
              id: undefined,
              name: undefined,
              uuid: undefined,
              tags: undefined,
              category: undefined,
              attachments: undefined,
              assignments: [],
              created_at: undefined,
              is_public: false,
              files: {},
              version: undefined,
            }}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>

      <Modal show={filterModal}>
        <Modal.Body>
          <ModalHeader titleHeader={'Detaylı Ara'} handleClose={closeFilterModal} />
          <DetailFilters setPeriod={setSelectedPeriod} setCategory={setSelectedOption} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal>
      {/*end : : add new file */}
    </>
  )
}
