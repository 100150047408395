import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'

type Props = {
  budget: any
}
export const BudgetInfo: FC<Props> = ({budget}) => {
  const filename = (param: any) => {
    const icon = param.split('.')[1]
    return icon
  }
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  console.log(budget)
  return (
    <>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ml-0'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-5'>
              <span className='fs-3 text-gray-800 fw-bolder mb-3'>{budget?.title}</span>

              <div className='mb-2'>
                <div className='badge badge-lg badge-light-primary d-inline'>{budget?.category?.name}</div>
              </div>
              {budget?.year}
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Şirket adı</div>
                <div className='text-gray-600'>{budget?.company?.name}</div>

                <div className='fw-bolder mt-5'>Tutar</div>
                <div className='text-gray-600'>
                  <NumberFormat value={budget?.amount} displayType={'text'} thousandSeparator={true} /> {}
                  {budget?.currency}
                </div>

                <div className='fw-bolder mt-5'>TL Tutarı</div>
                <div className='text-gray-600'>
                  <NumberFormat value={budget?.try_amount} displayType={'text'} thousandSeparator={true} /> {'₺'}
                </div>
                <div className='fw-bolder mt-5'>Satın alma Türü</div>
                <div className='text-gray-600'>{budget?.purchase_type?.name}</div>

                <div className='fw-bolder mt-5'>Tipi</div>
                <div className='text-gray-600'>{budget?.type?.name}</div>
                {budget?.kades_no != 'undefined' && (
                  <>
                    <div className='fw-bolder mt-5'>Kades No</div>
                    <div className='text-gray-600'>{budget?.kades_no}</div>
                  </>
                )}

                <div className='fw-bolder mt-5'>Görevli</div>
                <div className='text-gray-600'>{budget?.assignment?.alternative_name}</div>

                <div className='fw-bolder mt-5 mb-3'>Sahibi</div>
                <div className='  d-flex  justify-content-between'>
                  <div className='symbol symbol-55px symbol-circle w-55px '>
                    {budget?.owner?.avatar ? (
                      <div className='symbol symbol-55px symbol-circle w-55px'>
                        <img src={budget?.owner?.avatar} alt={budget?.owner?.name} className='w-100' />
                      </div>
                    ) : (
                      <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{budget?.owner?.name?.substring(0, 1)}</div>
                    )}
                  </div>
                  <div className='d-flex flex-column  p-3 w-100 align-item-center'>
                    <div
                      className='fw-bolder fs-6 text-hover-primary cursor-pointer'
                      onClick={async () => {
                        setProfilUserId(budget?.owner?.id)
                        await openProfilModal()
                      }}
                    >
                      {budget?.owner?.name}
                    </div>
                    <span className=' text-muted  fs-7'>{budget?.owner?.roles[0]?.name}</span>
                  </div>

                  <div></div>
                </div>

                <div className='fw-bolder mt-5 mb-3'>Oluşturan</div>
                <div className='d-flex  justify-content-between'>
                  <div className='symbol symbol-55px symbol-circle w-55px '>
                    {budget?.created_by?.avatar ? (
                      <div className='symbol symbol-55px symbol-circle w-55px'>
                        <img src={budget?.created_by?.avatar} alt={budget?.created_by?.name} className='w-100' />
                      </div>
                    ) : (
                      <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>{budget?.created_by?.name?.substring(0, 1)}</div>
                    )}
                  </div>
                  <div className='d-flex flex-column p-5 w-100'>
                    <div
                      className='fw-bolder d-flex align-items-center fs-6 text-hover-primary cursor-pointer'
                      onClick={async () => {
                        setProfilUserId(budget?.created_by?.id)
                        await openProfilModal()
                      }}
                    >
                      {budget?.created_by?.name}
                    </div>
                    <span className=' text-muted  fs-7'>{budget?.created_by?.roles[0]?.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
