import moment from 'moment'
import {FC} from 'react'

type Props = {
  cattact: any
}
export const CAItem: FC<Props> = ({cattact}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{moment(cattact?.attack_date).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{cattact?.customer_name}</td>
        <td className='text-gray-500'>{cattact?.time_range}</td>
        <td className='text-gray-500'>{cattact?.attack_size}</td>
        <td className='text-gray-500'>{cattact?.description}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
