import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_theme/assets/ts/_utils'
import {GetReportsWidget, GetVNStatistics} from '../core/request'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'
import {GetPocWidget} from '../../Project/Poc/core/_request'

type Props = {
  horizentalTitle: string
  widgetId: string
  pageId?: string
  plusOrMinus: boolean
  refreshPage?: any
  linkToPage: string
}
export const ChartsWidget4: FC<Props> = ({widgetId, horizentalTitle, plusOrMinus, pageId, linkToPage}) => {
  let chartRef = useRef<HTMLDivElement | null>(null)
  const [grafik, setGrafik] = useState(false)
  const [title, setTitle] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [data2, setData2] = useState<any>([])
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [directorates, setDirectorates] = useState<any>([])
  const {refreshPage} = useRefresh1()
  let count1: any = []
  let count2: any = []
  let title1: any = []
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }

  const pstatisticType2 = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.total_open)
      count2.push(data[i]?.total_close)
      title1.push(data[i]?.directorate_name)
    }
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title1, count2, count1))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }

  const pstatisticType3 = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.total_open)
      count2.push(data[i]?.total_close)
      title1.push(data[i]?.directorate_name)
    }
    setData(count2)
    setData2(count1)
    setTitle(title1)
    setGrafik(true)
    // if (!chartRef.current) {
    //   return
    // }
    // const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title1, count2, count1))
    // if (grafik) {
    //   if (chart) {
    //     chart.render()
    //   }
    //   return () => {
    //     if (chart) {
    //       chart.destroy()
    //     }
    //   }
    // }
  }
  const pstatisticType4 = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.count)
      title1.push(data[i]?.name)
    }
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title1, count1))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }

  const pstatisticType5 = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.vulnerabilityCount)
      data[i].hostname == '' ? title1.push(data[i].IP) : title1.push(data[i]?.hostname)
    }
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title1, count1))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }

  const pstatisticType6 = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      count1.push(data[i]?.coverage_rate)
      title1.push(data[i]?.device_name)
    }
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title1, count1))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }

  useEffect(() => {
    if (grafik) {
      const chart = new ApexCharts(chartRef.current, chartOptions(widgetId, title, data, data2))
      if (chart) {
        chart.render()
      }
      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }
  }, [grafik])

  useEffect(() => {
    setGrafik(false)
    if (widgetId == '67') {
      GetVNStatistics('2?directorate=' + selectedDirectorate).then((data) => {
        pstatisticType3(data?.data)
      })
    }
  }, [chartRef, selectedDirectorate])
  useEffect(() => {
    if (widgetId == '67') {
      GetReportsWidget('/vulnerability_notifications_directorates').then((data) => {
        setDirectorates(data?.data)
      })
    }
  }, [])
  useEffect(() => {
    if (widgetId == '66') {
      GetVNStatistics('3').then((data) => {
        pstatisticType2(data?.data)
      })
    } else if (widgetId == '76') {
      GetReportsWidget('/pentest_vulnerability_statistics_total').then((data) => {
        pstatisticType4(data?.data)
      })
    } else if (widgetId == '77') {
      GetReportsWidget('/pentest_asset_statistics_total').then((data) => {
        pstatisticType5(data?.data?.data)
      })
    } else if (widgetId == '79') {
      GetReportsWidget('/device_utilization_availabilities_total/device_id').then((data) => {
        pstatisticType6(data?.data)
      })
    }
  }, [chartRef])

  return (
    <div className={`card card-flush overflow-hidden`} style={{overflow: 'hidden', backgroundColor: '#f6f7f8', height: '427px'}}>
      {/* begin::Header */}
      <div className='card-header text-center mt-5'>
        {/* begin::Title */}
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div>
            {' '}
            <h3 className='card-title align-items-center flex-column text-center w-100 m-1'>
              <span className='card-label fw-bolder text-dark fs-2 mt-3'>{horizentalTitle}</span>
            </h3>
          </div>
        </Link>
        {/* end::Title */}
        <div className='d-flex'>
          {widgetId == '67' && (
            <div className='my-0 me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-solid w-150px h-40px'
                onChange={(e) => setSelectedDirectorate(e.target.value)}
              >
                <option value=''>Tümü</option>
                {directorates?.map((directorate: any) => {
                  return (
                    <option key={directorate?.assignment_name} value={directorate?.assignment_name}>
                      {directorate?.assignment_name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          <div
            onClick={() => {
              homeWidgetShow()
            }}
          >
            <span
              className='btn btn-icon  btn-active-color-primary mt-n2 me-n3  d-inline-flex '
              style={{
                backgroundColor: '#f6f7f8',
                border: '1px solid rgb(1 13 88)',
                color: 'rgb(1 13 88)',
              }}
            >
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column'>
        {/* begin::Statistics */}
        {/* end::Statistics */}
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptions = (widgetId: any, categoriesData: any, totalClose: any, totalOpen?: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')
  var secondaryColor = getCSSVariableValue('--bs-success')
  //var height = parseInt(KTUtil.css(Element, 'height'));

  return {
    series: [
      {
        name: '',
        data: totalClose,
      },
      {
        name: '',
        data: totalOpen,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '70%',
      },
    },
    legend: {
      show: widgetId == '76' || widgetId == '79' ? false : true,
      showForSingleSeries: widgetId == '76' || widgetId == '77' || widgetId == '79' ? false : true,
      customLegendItems: widgetId == '76' || widgetId == '77' || widgetId == '79' ? [''] : ['Açık Kayıt', 'Kapalı Kayıt'],
      fontSize: '15px',
      markers: {
        fillColors: ['#6BFFC7', '#008FFB'],
      },
    },
    dataLabels: {
      enabled: true,

      style: {
        fontSize: '15px',
        colors: ['#fff'],
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categoriesData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        trim: false,
        rotate: 0,
        rotateAlways: false,
        maxHeight: 15,
        hideOverlappingLabels: true,
        style: {
          colors: labelColor,
          fontSize: '20px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '18px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '18px',
      },
      y: {
        formatter: function (val) {
          if (widgetId == '79') return val.toString()
          else return val + ' adet'
        },
      },
    },
    colors: ['#008FFB', '#6BFFC7'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
