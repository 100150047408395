import {FC} from 'react'
type Props = {
  isTable: boolean
}
export const NoMatchRecords: FC<Props> = ({isTable}) => {
  return isTable ? (
    <tr>
      <td colSpan={10}>
        <div className='d-flex text-center w-100 align-content-center justify-content-center overflow-auto'>
          Sonuç Bulunamadı
        </div>
      </td>
    </tr>
  ) : (
    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
      Sonuç Bulunamadı
    </div>
  )
}
