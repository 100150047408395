import {FC, useEffect, useState} from 'react'
type Props = {
  setCreatedAt: any
  setProjectName: any
  setAttendees:any
  setTitle:any
  setStart:any
  setEnd: any
  handleClose: any
}

export const DetailFilters: FC<Props> = ({setCreatedAt,setAttendees,setProjectName,setTitle,setStart,setEnd, handleClose}) => {
  const handleSubmit = async () => {
    setCreatedAt(selectedCreatedAt)
    setAttendees(selectedAttendees)
    setProjectName(selectedProjectName)
    setTitle(selectedTitle)
    setStart(selectedStart)
    setEnd(selectedEnd)
    handleClose()
  }
  const [selectedCreatedAt, setSelectedCreatedAt] = useState('')
  const [selectedProjectName, setSelectedProjectName] = useState('')
  const [selectedAttendees, setSelectedAttendees] = useState('')
  const [selectedTitle, setSelectedTitle] = useState('')
  const [selectedStart,setSelectedStart] = useState('')
  const [selectedEnd, setSelectedEnd] = useState('')
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
    
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Attendees</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedAttendees(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Created Date</label>
        <input
          placeholder=''
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedCreatedAt(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Project Name</label>
        <input
          placeholder='Aranacak kelimeyi giriniz'
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedProjectName(e.target.value)
          }}
        />
      </div>

      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Title</label>
        <input
          placeholder='Aranacak kelimeyi giriniz'
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedTitle(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Start</label>
        <input
          placeholder='Aranacak kelimeyi giriniz'
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedStart(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>End</label>
        <input
          placeholder='Aranacak kelimeyi giriniz'
          type='date'
          className='form-control form-control-solid mb-3 mb-lg-0'
          autoComplete='off'
          onChange={(e) => {
            setSelectedEnd(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
