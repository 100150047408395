import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import {useDispatch} from 'react-redux'

import swal from 'sweetalert'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'
import {getCSRFToken} from '../../core/requests'
import {UpdatePhoto} from '../core/_requests'

type Props = {
  user_id: any
  handleClose: any
  doc: any
}
export const AddPhoto: FC<Props> = ({user_id, handleClose, doc}) => {
  const [files, setFiles] = useState<any>([])
  const [avatar, setAvatar] = useState<any>([])

  const [userForEdit] = useState<any>({
    ...doc,
  })

  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }

  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )
  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        for (let i = 0; i < files?.length; i++) {
          formData.append('avatar', avatar)
          //console.log(base64);
        }
        await UpdatePhoto(user_id, formData)
          .then(() => {
            swal('Eklendi', 'Dosya başarı ile eklendi.', 'success', {timer: 2000})
          })
          .catch((err) => {
            swal('Hata', err.response.data.message, 'error', {timer: 2000})
          })
        handleClose()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        handleClose()
      }
    },
  })
  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='modal-body'>
          <div className='form-group'>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7 '
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {/* <div className='me-5 position-relative'>
            <div className='symbol symbol-155px '>
              {avatar ? (
                <div className='symbol symbol-155px'>
                  <img src={avatar} alt={'avatar'} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                  {avatar?.substring(0, 1)}
                </div>
              )}{' '}
            </div>
          </div> */}
              <div>
                <div className='w-100 me-5'>
                  <Dropzone
                    onDrop={async (acceptedFiles) => {
                      setAvatar(acceptedFiles[0])
                      setFiles(acceptedFiles)
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()} className='btn btn-sm btn-primary me-2 '>
                          Fotoğraf Yükle
                          <input
                            style={{
                              opacity: '0',
                              position: 'absolute',
                              zIndex: '9999',
                              left: '70px',
                            }}
                            {...getInputProps()}
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>

              {/* <span className='form-text fs-6 text-muted'>Maksimum dosya boyutu her dosya için 10MB'tır. </span> */}
            </div>

            <aside>
              <ul className='m-0 p-0'>{filess}</ul>
            </aside>
            <button
              type='submit'
              className='btn btn-primary mt-5'
              data-kt-users-modal-action='submit'

              //disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched||files?.length<1}
            >
              <span className='indicator-label'>Gönder</span>
              {/* {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
