import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTCard} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {PluralBarChart} from '../../components/widgets/OperationalData/PluralBarChart'
import {ExportLink} from '../../core/requests'
import HeaderInfo from '../components/HeaderInfo'
import {GetSecurityScoreData, GetSecurityScoreStatus} from '../core/request'
import {DataItem} from './components/DataItem'
import {DetailFilterData} from './components/DetailFilterData'
import {DetailFilterStatus} from './components/DetailFilterStatus'
import {StatusItem} from './components/StatusItem'

export const SecurityScore: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const exportFile = () => {
    window.open(`${ExportLink}/security_score_data?token=${loginUser.token}`)
  }
  const [dataItems, setDataItems] = useState([])
  const [statusItems, setStatusItems] = useState([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [showFilter1, setShowFilter1] = useState(false)
  const showFilterModal1 = () => {
    setShowFilter1(true)
    setServerIpData('')
    setCreatedDateData('')
  }
  const closeFilterModal1 = () => setShowFilter1(false)
  const [showFilter2, setShowFilter2] = useState(false)
  const showFilterModal2 = () => {
    setShowFilter2(true)
    setServerIpStatus('')
    setCreatedDateStatus('')
    setType('')
  }
  const closeFilterModal2 = () => setShowFilter2(false)
  const [serverIpData, setServerIpData] = useState('')
  const [serverIpStatus, setServerIpStatus] = useState('')
  const [createdDateData, setCreatedDateData] = useState('')
  const [createdDateStatus, setCreatedDateStatus] = useState('')
  const [type, setType] = useState('')
  const [currentPage1, setCurrentPage1] = useState(1)
  const [total1, setTotal1] = useState(1)
  const [loading1, setLoading1] = useState(true)
  const [currentPage2, setCurrentPage2] = useState(1)
  const [total2, setTotal2] = useState(1)
  const [loading2, setLoading2] = useState(true)

  const currentTableData1 = () => {
    let queryParams = '?page=' + currentPage1 + '&limit=6'
    if (serverIpData != '') {
      queryParams += '&server_ip=' + serverIpData
    }
    if (createdDateData != '') {
      queryParams += '&created_date=' + createdDateData
    }
    GetSecurityScoreData(queryParams).then(({data}) => {
      setDataItems(data?.data)
      setTotal1(data?.meta?.total)
      setLoading1(false)
    })
  }

  const currentTableData2 = () => {
    let queryParams = '?page=' + currentPage2 + '&limit=6'
    if (serverIpStatus != '') {
      queryParams += '&server_ip=' + serverIpStatus
    }
    if (createdDateStatus != '') {
      queryParams += '&created_date=' + createdDateStatus
    }
    if (type != '') {
      queryParams += '&type=' + type
    }

    GetSecurityScoreStatus(queryParams).then(({data}) => {
      setTotal2(data?.meta?.total)
      setStatusItems(data?.data)
      setLoading2(false)
    })
  }

  useEffect(() => {
    currentTableData1()
    setLoading1(true)
  }, [currentPage1])
  useEffect(() => {
    setCurrentPage1(1)
    currentTableData1()
  }, [serverIpData, createdDateData])

  useEffect(() => {
    currentTableData2()
    setLoading2(true)
  }, [currentPage2])
  useEffect(() => {
    setCurrentPage2(1)
    currentTableData2()
  }, [serverIpStatus, createdDateStatus, type])

  return (
    <div>
      <div>
        <div className='col-xl-12 col-xxl-12 mb-xl-5 mb-xxl-10'>
          <PluralBarChart horizentalTitle={''} widgetId={'64'} plusOrMinus={true} linkToPage={''} />
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush border-top-0 border-bottom-0' style={{height: '550px'}}>
            <div className='card-header'>
              {/* <SearchComponent setWord={setSearchKeyClick} /> */}
              <div className='d-flex  justify-content-between m-3 w-100'>
                <div className='d-flex align-items-center position-relative my-3'>
                  <HeaderInfo api={'/security_score_data_check'} />
                </div>
                <div>
                  <Button className='btn mt-3 mx-5' onClick={showFilterModal1}>
                    Detaylı Ara
                  </Button>
                  <Button className='btn mt-3 ' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>

                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>

            <div className='card-body pt-3 overflow-auto' style={{maxHeight: '700px'}}>
              {loading1 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>current_data_total_vector_count</th>
                      <th className=''>current_data_total_threat_count</th>
                      <th className=''>current_data_total_security_score</th>
                      <th className=''>current_data_blocked</th>
                      <th className=''>current_data_unblocked</th>
                      <th className=''>changes_data_total_threat_count_change</th>
                      <th className=''>changes_data_total_security_score_change</th>
                      <th className=''>changes_data_total_blocked_change</th>
                      <th className=''>changes_data_total_unblocked_change</th>
                      <th className=''>cron_server_ip</th>
                      <th className=''>created_at</th>
                      <th className=''>updated_at</th>
                      <th className=''>current_data_categories_name</th>
                      <th className=''>current_data_categories_score</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {dataItems?.length < 1 && !loading1 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      dataItems?.map((item: any) => {
                        return (
                          <>
                            <DataItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage1(page)} totalCount={total1} currentPage={currentPage1} pageSize={6} loading={loading1} />
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0' style={{height: '550px', marginTop: '50px'}}>
            <div className='card-header'>
              {/* <SearchComponent setWord={setSearchKeyClick} /> */}
              <div className='d-flex  justify-content-between m-3 w-100'>
                <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                <Button className='btn mt-3' onClick={showFilterModal2}>
                  Detaylı Ara
                </Button>
                {/* <Button
                    className='btn mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={exportFile}
                  >
                    Dışarı Aktar
                  </Button> */}
                {/* <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button> */}
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto' style={{maxHeight: '700px'}}>
              {loading2 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>security_score</th>
                      <th className=''>type</th>
                      <th className=''>cron_server_ip</th>
                      <th className=''>created_at</th>
                      <th className=''>updated_at</th>
                      <th className=''>v.id</th>
                      <th className=''>v.trusted</th>
                      <th className=''>v.untrusted</th>
                      <th className=''>v.is_proxy</th>
                      <th className=''>v.description</th>
                      <th className=''>v.has_mitigation</th>
                      <th className=''>v.blocked_count</th>
                      <th className=''>v.blocked_change</th>
                      <th className=''>v.unblocked</th>
                      <th className=''>v.unblocked_change</th>
                      <th className=''>v.security_score</th>
                      <th className=''>v.security_score_change</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6'>
                    {statusItems?.length < 1 && !loading2 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      statusItems?.map((item: any) => {
                        return (
                          <>
                            <StatusItem key={item.id} item={item} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage2(page)} totalCount={total2} currentPage={currentPage2} pageSize={6} loading={loading2} />
          </div>
        </div>
      </KTCard>
      <Modal show={showFilter1}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal1} />
          <DetailFilterData handleClose={closeFilterModal1} setSearchKeyClick={setSearchKeyClick} setCreatedDate={setCreatedDateData} setServerIp={setServerIpData} />
        </Modal.Body>
      </Modal>
      <Modal show={showFilter2}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal2} />
          <DetailFilterStatus handleClose={closeFilterModal2} setSearchKeyClick={setSearchKeyClick} setCreatedDate={setCreatedDateStatus} setServerIp={setServerIpStatus} setType={setType} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
