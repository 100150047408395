import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import {getActivities, getAnnoun, getBlog, GetBudgets, getProjects} from '../core/requests'
type LocationState = {}

export const Search: FC = () => {
  const location = useLocation()
  let data1 = location.state as LocationState

  const [isues, setIsues] = useState<any>([])
  const [announs, setAnnouns] = useState<any>([])
  const [blogs, setBlogs] = useState<any>([])
  const [budget, setBudget] = useState<any>([])
  const [projects, setProjects] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)

  const setId = (id: any) => {
    localStorage.setItem('projectID', JSON.stringify(id))
  }

  useEffect(() => {
    getActivities('?q=' + data1).then((data) => {
      setIsues(data.data.data)
    })
    getAnnoun('?q=' + data1).then((data) => {
      setAnnouns(data.data.data)
    })
    getBlog('?q=' + data1).then((data) => {
      setBlogs(data.data)
    })
    GetBudgets('?q=' + data1).then((data) => {
      setBudget(data.data.data)
    })
    getProjects('?q=' + data1).then((data) => {
      setProjects(data.data.data)
    })
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  }, [data1])
  return (
    <div className='app-container container-fluid'>
      {loading ? (
        <>
          {blogs?.length > 0 || projects?.length > 0 || isues?.length > 0 || announs?.length > 0 || budget?.length > 0 ? (
            <>
              {blogs?.length > 0 ? (
                <div className='card'>
                  <div className='card-body p-lg-10'>
                    <div className='mb-17'>
                      <div className='d-flex flex-stack mb-5'>
                        <h3 className='text-dark'>Blog</h3>
                      </div>
                      <div className='separator separator-dashed mb-9'></div>
                      <div className='row g-5 g-lg-10'></div>
                    </div>
                    <div className='tab-content'>
                      <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
                        <div className='timeline'>
                          {blogs?.map((blog: any) => {
                            return (
                              <div className='timeline-item'>
                                <div className='timeline-line w-40px'></div>

                                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                  <div className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <path opacity='0.3' d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z' fill='currentColor' />
                                        <path d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z' fill='currentColor' />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                  <div className='pe-3 mb-5'>
                                    <div className='fs-5 fw-bold mb-2'>
                                      <Link to='/blog/detail' state={blog}>
                                        <h5 className='text-hover-primary'>{blog?.title}</h5>
                                      </Link>
                                      {/* <p className='fs-7'>{blog?.content}</p> */}
                                    </div>

                                    <div className='d-flex align-items-center mt-1 fs-6 '>
                                      <div className='text-muted me-5 fs-7 text-gray-700'>
                                        {moment(blog?.start_date).format('DD.MM.YYYY')}/{moment(blog?.end_date).format('DD.MM.YYYY')}
                                        <div className='d-flex justify-content-between mt-2'>
                                          <div className='me-3 position-relative'>
                                            <div className='symbol symbol-35px symbol-circle'>
                                              {blog?.created_by?.avatar ? <img alt='Pic' src={blog?.created_by?.avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{blog?.created_by?.name?.substring(0, 1)}</div>}
                                            </div>
                                          </div>
                                          <span className='text-primary fw-bolder mt-3' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
                                            {blog?.created_by.name}
                                          </span>
                                        </div>
                                      </div>

                                      {/* <ProfilePopUp user_id={created_by.id} /> */}
                                    </div>
                                  </div>
                                  {/* {blog?.files?.length > 1 ? (
                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                        {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                          if (index !== 0)
                            return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {projects?.length > 0 ? (
                <div className='card'>
                  <div className='card-body p-lg-10'>
                    <div className='mb-17'>
                      <div className='d-flex flex-stack mb-5'>
                        <h3 className='text-dark'>Projeler</h3>
                      </div>
                      <div className='separator separator-dashed mb-9'></div>
                      <div className='row g-5 g-lg-10'></div>
                    </div>
                    <div className='tab-content'>
                      <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
                        <div className='timeline'>
                          {projects?.map((project: any) => {
                            return (
                              <div className='timeline-item'>
                                <div className='timeline-line w-40px'></div>

                                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                  <div className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <path opacity='0.3' d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z' fill='currentColor' />
                                        <path d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z' fill='currentColor' />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                  <div className='pe-3 mb-5'>
                                    <div className='fs-5 fw-bold mb-2'>
                                      <Link state={project?.id} to='../../../projeler/detail' onClick={() => setId(project?.id)}>
                                        <h5 className='text-hover-primary'>{project?.name}</h5>
                                      </Link>
                                      {/* <p className='fs-7'>{blog?.content}</p> */}
                                    </div>

                                    <div className='d-flex align-items-center mt-1 fs-6 '>
                                      <div className='text-muted me-5 fs-7 text-gray-700'>
                                        {moment(project?.start_date).format('DD.MM.YYYY')}/ {moment(project?.end_date).format('DD.MM.YYYY')}
                                        <div className='d-flex justify-content-between mt-2'>
                                          <div className='me-3 position-relative'>
                                            <div className='symbol symbol-35px symbol-circle'>
                                              {project?.created_by?.avatar ? (
                                                <img alt='Pic' src={project?.created_by?.avatar} />
                                              ) : (
                                                <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{project?.created_by?.name?.substring(0, 1)}</div>
                                              )}
                                            </div>
                                          </div>
                                          <span className='text-primary fw-bolder mt-3' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
                                            {project?.created_by?.name}
                                          </span>
                                        </div>
                                      </div>

                                      {/* <ProfilePopUp user_id={created_by.id} /> */}
                                    </div>
                                  </div>
                                  {/* {blog?.files?.length > 1 ? (
                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                        {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                          if (index !== 0)
                            return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {isues?.length > 0 && (
                <div className='card'>
                  <div className='card-body p-lg-10'>
                    <div className='mb-17'>
                      <div className='d-flex flex-stack mb-5'>
                        <h3 className='text-dark'>Faaliyetler</h3>
                      </div>
                      <div className='separator separator-dashed mb-9'></div>
                      <div className='row g-5 g-lg-10'></div>
                    </div>
                    <div className='tab-content'>
                      <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
                        <div className='timeline'>
                          {isues?.map((isue: any) => {
                            return (
                              <div className='timeline-item'>
                                <div className='timeline-line w-40px'></div>

                                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                  <div className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <path opacity='0.3' d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z' fill='currentColor' />
                                        <path d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z' fill='currentColor' />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                  <div className='pe-3 mb-5'>
                                    <div className='fs-5 fw-bold mb-2'>
                                      <Link to='/faaliyetler/detail' state={isue}>
                                        <h5 className='text-hover-primary'>{isue?.title}</h5>
                                      </Link>
                                      {/* <p className='fs-7'>{blog?.content}</p> */}
                                    </div>

                                    <div className='d-flex align-items-center mt-1 fs-6 '>
                                      <div className='text-muted me-5 fs-7 text-gray-700'>
                                        {moment(isue?.start_date).format('DD.MM.YYYY')} /{moment(isue?.end_date).format('DD.MM.YYYY')}
                                        <div className='d-flex justify-content-between mt-2'>
                                          <div className='me-3 position-relative'>
                                            <div className='symbol symbol-35px symbol-circle'>
                                              {isue?.created_by?.avatar ? <img alt='Pic' src={isue?.created_by?.avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{isue?.created_by?.name?.substring(0, 1)}</div>}
                                            </div>
                                          </div>
                                          <span className='text-primary fw-bolder mt-3' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
                                            {isue?.created_by?.name}
                                          </span>
                                        </div>
                                      </div>

                                      {/* <ProfilePopUp user_id={created_by.id} /> */}
                                    </div>
                                  </div>
                                  {/* {blog?.files?.length > 1 ? (
                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                        {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                          if (index !== 0)
                            return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {announs?.length > 0 && (
                <div className='card'>
                  <div className='card-body p-lg-10'>
                    <div className='mb-17'>
                      <div className='d-flex flex-stack mb-5'>
                        <h3 className='text-dark'>Duyurular</h3>
                      </div>
                      <div className='separator separator-dashed mb-9'></div>
                      <div className='row g-5 g-lg-10'></div>
                    </div>
                    <div className='tab-content'>
                      <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
                        <div className='timeline'>
                          {announs?.map((blog: any) => {
                            return (
                              <div className='timeline-item'>
                                <div className='timeline-line w-40px'></div>

                                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                  <div className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <path opacity='0.3' d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z' fill='currentColor' />
                                        <path d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z' fill='currentColor' />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                  <div className='pe-3 mb-5'>
                                    <div className='fs-5 fw-bold mb-2'>
                                      <Link to='/duyurular/detail' state={blog}>
                                        <h5 className='text-hover-primary'>{blog?.title}</h5>
                                      </Link>
                                      {/* <p className='fs-7'>{blog?.content}</p> */}
                                    </div>

                                    <div className='d-flex align-items-center mt-1 fs-6 '>
                                      <div className='text-muted me-5 fs-7 text-gray-700'>
                                        {moment(blog?.start_date).format('DD.MM.YYYY')} / {moment(blog?.end_date).format('DD.MM.YYYY')}
                                        <div className='d-flex justify-content-between mt-2'>
                                          <div className='me-3 position-relative'>
                                            <div className='symbol symbol-35px symbol-circle'>
                                              {blog?.created_by?.avatar ? <img alt='Pic' src={blog?.created_by?.avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{blog?.created_by?.name?.substring(0, 1)}</div>}
                                            </div>
                                          </div>
                                          <span className='text-primary fw-bolder mt-3' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
                                            {blog?.created_by.name}
                                          </span>
                                        </div>
                                      </div>

                                      {/* <ProfilePopUp user_id={created_by.id} /> */}
                                    </div>
                                  </div>
                                  {/* {blog?.files?.length > 1 ? (
                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                        {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                          if (index !== 0)
                            return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {budget?.length > 0 && (
                <div className='card'>
                  <div className='card-body p-lg-10'>
                    <div className='mb-17'>
                      <div className='d-flex flex-stack mb-5'>
                        <h3 className='text-dark'>Bütçe</h3>
                      </div>
                      <div className='separator separator-dashed mb-9'></div>
                      <div className='row g-5 g-lg-10'></div>
                    </div>
                    <div className='tab-content'>
                      <div id='kt_activity_today' className='card-body p-0 tab-pane fade show active' role='tabpanel' aria-labelledby='kt_activity_today_tab'>
                        <div className='timeline'>
                          {budget?.map((blog: any) => {
                            return (
                              <div className='timeline-item'>
                                <div className='timeline-line w-40px'></div>

                                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                  <div className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <path opacity='0.3' d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z' fill='currentColor' />
                                        <path d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z' fill='currentColor' />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                  <div className='pe-3 mb-5'>
                                    <div className='fs-5 fw-bold mb-2'>
                                      <Link to='../butce/detail' state={blog}>
                                        <h5 className='text-hover-primary'>
                                          {blog?.title}({blog?.amount})
                                        </h5>
                                      </Link>
                                      {/* <p className='fs-7'>{blog?.content}</p> */}
                                    </div>

                                    <div className='d-flex align-items-center mt-1 fs-6 '>
                                      <div className='text-muted me-5 fs-7 text-gray-700'>
                                        {blog?.year}
                                        <div className='d-flex justify-content-between mt-2'>
                                          <div className='me-3 position-relative'>
                                            <div className='symbol symbol-35px symbol-circle'>
                                              {blog?.created_by?.avatar ? <img alt='Pic' src={blog?.created_by?.avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{blog?.created_by?.name?.substring(0, 1)}</div>}
                                            </div>
                                          </div>
                                          <span className='text-primary fw-bolder mt-3' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
                                            {blog?.created_by?.name}
                                          </span>
                                        </div>
                                      </div>

                                      {/* <ProfilePopUp user_id={created_by.id} /> */}
                                    </div>
                                  </div>
                                  {/* {blog?.files?.length > 1 ? (
                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-1 scroll-x'>
                        {path.map((el: { path: string; name: string; size: string; id: ID} , index: number) => {
                          if (index !== 0)
                            return <FileItem key={el.id} fileLink={el.path} name={el.name} size={el.size} />
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div>
                <div className='d-flex flex-column flex-row-auto flex-center'>
                  <h2>Sonuç Bulunamadı!</h2>
                </div>
                <div
                  style={{
                    height: '200px',
                    width: '100%',
                    borderRadius: '10px',
                    color: 'black',
                    fontSize: '16px',
                    paddingLeft: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '500',
                    marginTop: '12px',
                    marginBottom: '12px',
                    textAlign: 'center',
                  }}
                  className='d-flex justify-content-center'
                >
                  <img
                    style={{
                      height: '200px',
                      width: 'auto',

                      textAlign: 'center',
                    }}
                    alt='Logo'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
                    className=''
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <Spinner animation='border' variant='info' style={{width: '50px', height: '50px'}} />
          </div>
        </>
      )}
    </div>
  )
}
