import {FC} from 'react'
type Props = {
  item: any
}
export const SubDataItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td className='fw-bolder'>{item?.Name}</td>
        <td className='text-gray-500'>{item?.Score}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
