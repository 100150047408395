import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import Dropzone from 'react-dropzone'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_theme/helpers'
import swal from 'sweetalert'
import {createDoc, getDocCategories} from '../core/_requests'
// import {DocModel} from '../model/docModel'
import '../../../pages/style/style2.css'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCategory} from '../../Categories/AddCategory'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RolesTags} from '../../components/RolesTags'
import {getCSRFToken} from '../../core/requests'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'

type Props = {
  isUserLoading: boolean
  doc: any
  handleClose: any
  refreshPage?: any
}
const DocSchema = Yup.object().shape({
  name: Yup.string().required('Zorunlu Alan'),
  document_category_id: Yup.number().required('Zorunlu Alan'),
  // version: Yup.string().required('Zorunlu Alan'),
})
export const AddFile: FC<Props> = ({isUserLoading, doc, handleClose, refreshPage}) => {
  const cancel = () => {
    handleClose()
  }
  const [roles, setRoles] = useState<any>([])
  const [files, setFiles] = useState<any>([])
  const [publicBox, setPublicBox] = useState(false)
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  const [userForEdit] = useState<any>({
    ...doc,
    id: doc.id,
    name: doc.name,
    document_category_id: doc.document_category_id,
    attachments: doc.attachments,
    tags: doc.tags,
    assignments: doc.assignments,
    is_public: doc.is_public,
    version: doc.version,
  })

  const getRoles = useCallback(
    (selectRole) => {
      setRoles([...roles, selectRole])
    },
    [roles]
  )
  const deleteRole = useCallback(
    (tagIndex) => {
      setRoles(roles.filter((_: any, i: any) => i !== tagIndex))
    },
    [roles]
  )

  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }

  const removeAll = () => {
    setFiles([])
  }

  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm  mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )

  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: DocSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (isNotEmpty(values.id)) {
          // await updateUser(values)
        } else {
          formData.append('name', values?.version != undefined ? values?.name! + ' v' + values?.version : values?.name)
          formData.append('tags', values.tags!)
          formData.append('document_category_id', values.document_category_id!)
          formData.append('is_public', publicBox ? '1' : '0')
          for (let i = 0; i < files?.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
            //console.log(base64);
          }
          for (let i = 0; i < roles?.length; i++) {
            formData.append('assignments[' + i + ']', roles[i].id)
            //console.log(base64);
          }
          await createDoc(formData)
            .then(() => {
              swal({
                title: 'Eklendi',
                text: 'Dosya başarı ile eklendi.',
                icon: 'success',
                buttons: ['Tamam', false],
                timer: 2000,
              })
              refreshPage()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  useEffect(() => {
    getDocCategories().then(({data}) => {
      setCat(data.data)
    })
  }, [showCategoryModal])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='modal-body'>
          <div className='form-group'>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Başlık</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Başlık'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-6 mb-2'>Versiyon</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='1.2'
                {...formik.getFieldProps('version')}
                type='text'
                name='version'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.version && formik.errors.version},
                  // {
                  //   'is-valid': formik.touched.version && !formik.errors.version,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {/* {formik.touched.version && formik.errors.version && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.version}</span>
                  </div>
                </div>
              )} */}
              {/* end::Input */}
            </div>
            <div className='d-flex flex-column'>
              <div className='row mb-7 d-flex'>
                <div className='col-xs-12 col-md-12'>
                  {/* begin::Label */}
                  <div className='d-flex justify-content-between'>
                    <label className='required fw-bold fs-7 mb-2'>Kategori</label>
                    {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 && <img src={toAbsoluteUrl('/media/icons/add.png')} alt='add-icon' width='25px' className='m-1' onClick={openCategoryModal} />}
                  </div>

                  {/* end::Label */}

                  {/* begin::Input */}
                  <select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Kategori Seç' {...formik.getFieldProps('document_category_id')} name='document_category_id'>
                    <option hidden> Kategori Seç </option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.document_category_id && formik.errors.document_category_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.document_category_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column mb-8 fv-row'>
                <div className='d-flex justify-content-between'>
                  <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                    <span className='required'>Erişim Sağlayabilecek Roller</span>
                  </label>

                  <div>
                    <input
                      className='form-check-input h-18px w-18px'
                      type='checkbox'
                      {...formik.getFieldProps('is_public')}
                      name='is_public'
                      value='public'
                      defaultChecked={publicBox}
                      onChange={() => {
                        publicBox == false ? setPublicBox(true) : setPublicBox(false)
                      }}
                    />
                    <span className='form-check-label fw-bold'> Herkes</span>
                  </div>
                </div>

                <div style={{display: publicBox ? 'none' : 'block'}} className='form-control form-control-solid'>
                  {/* <Tags setRoles={getRoles} delRole={deleteRole} /> */}
                  <RolesTags setRoles={getRoles} delRole={deleteRole} tags1={doc.assignments} />
                  {formik.touched.tags && formik.errors.tags && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tags}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='w-50'>
                <Dropzone
                  onDrop={async (acceptedFiles) => {
                    setFiles(acceptedFiles)
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()} className='btn btn-sm me-2 ' style={{color: 'white'}}>
                        Dosya Yükle
                        <input style={{opacity: '0', position: 'absolute', zIndex: '9999', left: '70px'}} {...getInputProps()} />
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <span className='form-text fs-6 text-muted'>{/* Maksimum dosya boyutu her dosya için 8MB'tır.{' '} */}</span>
            </div>
            <aside>
              <ul className='m-0 p-0'>{filess}</ul>
            </aside>
            <div className='d-flex justify-content-end'>
              {' '}
              {files?.length > 0 && (
                <span onClick={removeAll} className='btn btn-sm me-2 '>
                  Hepsini Sil
                </span>
              )}
            </div>
            <div className='text-center pt-15'>
              <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting || isUserLoading}>
                İptal
              </button>

              <button type='submit' className='btn' data-kt-users-modal-action='submit' disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched || files?.length < 1}>
                <span className='indicator-label'>Gönder</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal show={showCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='document'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
