/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import '../style.css'
import {getCSRFToken} from '../../../pages/core/requests'
import {RootState} from '../../../../setup'
import {UserModel} from '../models/UserModel'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Lütfen E-Posta adresini doğru şekide giriniz')
    .min(3, 'Lütfen az 3 karakter giriniz')
    .max(50, 'En fazla 50 karakterden oluşmalı')
    .required('Zorunlu alan'),
  password: Yup.string()
    .min(8, 'Lütfen en az 8 karakter giriniz')
    .max(50, 'En fazla 50 karakter olabilir')
    .required('Zorunlu alan'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  // useEffect(() => {
  //   getCSRFToken().then(({data}) => {
  //     console.log(data.token)
  //     // setApiData(data.token)
  //     localStorage.setItem('csrfToken', data.token)
  //     const currentTime = new Date().getTime()
  //     localStorage.setItem('lastRequestTimestamp', currentTime.toString())
  //   })
  // }, [])

  const [csrfToken, setApiData] = useState<any | null>(null)
  const [remainingTime, setRemainingTime] = useState<number | null>(null)

  const fetchApiData = async () => {
    try {
      getCSRFToken().then(({data}) => {
        setApiData(data.token)
        localStorage.setItem('csrfToken', data.token)
        const currentTime = new Date().getTime()
        localStorage.setItem('lastRequestTimestamp', currentTime.toString())
      })
    } catch (error) {
      console.error('API Request Error:', error)
    }
  }

  // useEffect(() => {
  //   const CSRFToken = localStorage.getItem('csrfToken')
  //   const storedApiData = localStorage.getItem('csrfToken')
  //   console.log('1')
  //   if (storedApiData) {
  //     console.log('2')
  //     setApiData(storedApiData)
  //   }

  //   const storedTimestamp = localStorage.getItem('lastRequestTimestamp')
  //   const lastRequestTimestamp = storedTimestamp ? parseInt(storedTimestamp, 10) : null

  //   if (lastRequestTimestamp) {
  //     const currentTime = new Date().getTime()
  //     const elapsedTime = currentTime - lastRequestTimestamp
  //     console.log('4')
  //     if (elapsedTime < 3600000) {
  //       // 1 saat içinde, kalan süreyi hesapla
  //       const remaining = 3600000 - elapsedTime
  //       setRemainingTime(remaining)

  //       // Kalan süre sonunda API isteği gönder
  //       const remainingIntervalId = setTimeout(() => {
  //         fetchApiData()
  //         // Kalan süreyi sıfırla
  //         setRemainingTime(null)
  //       }, remaining)
  //       return () => {
  //         clearInterval(remainingIntervalId)
  //       }
  //     }
  //   } else {
  //     console.log('5')
  //     // 1 saat içinde yapılmış bir istek yoksa hemen bir istek gönder
  //     fetchApiData()

  //     // 1 saatte bir istek gönder
  //     const intervalId = setInterval(() => {
  //       fetchApiData()
  //     }, 3600000) // 1 saat = 3600000 milisaniye
  //     return () => {
  //       clearInterval(intervalId)
  //     }
  //   }
  // }, [])

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({data}) => {
            setLoading(false)
            dispatch(auth.actions.login(data.token, data))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Kullanıcı adınız veya şifreniz hatalı')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 col-6'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <input type={'hidden'} value={csrfToken} name='_token' />
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Giriş Yap</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className={clsx('mb-lg-15 alert alert-danger ', loading ? '' : ' shake-bottom ')}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Sicil No</label>
        <input
          placeholder='Sicil No'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='new-password'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack  mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Şifre</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {/* Forgot Password ? */}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='new-password'
          placeholder='Şifre'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Giriş Yap</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Lütfen Bekleyin...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* end::Google link */}
      </div>
      <div className='d-flex flex-center flex-column-auto'>
        <div className='d-flex align-items-start fw-bold fs-6'>
          {/* <a  className='text-muted text-hover-primary px-2'>
            1.7V
          </a> */}
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
