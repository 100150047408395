import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {GMYReport} from './GMYReport'
import {QualityReport} from './QualityReport'
import {WeeklyReport} from './WeeklyReport'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Anasayfa',
    path: '/ana-sayfa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const ReportPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='gmy-raporu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Raporlar' toDescription='/raporlar/gmy-raporu'>
                GMY Raporu
              </PageTitle>
              <GMYReport />
            </>
          }
        />

        <Route
          path='kalite-raporu'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Raporlar' toDescription='/raporlar/gmy-raporu'>
                Kalite Raporu
              </PageTitle>
              <QualityReport />
            </>
          }
        />
        <Route
          path='haftalik-rapor'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Raporlar' toDescription='/raporlar/gmy-raporu'>
                Haftalık Rapor
              </PageTitle>
              <WeeklyReport />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/gmy-raporu' />} />
    </Routes>
  )
}
