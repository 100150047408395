import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {SquareButton} from './SquareButton'

type Props = {
  issue: any
  backgroundColor?: string
  commentColor?: string
}

export const TargetItem: FC<Props> = ({issue, backgroundColor, commentColor}) => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className={`card mb-6 mb-xl-9 border border-${commentColor} `}>
        <div className='card-body  rounded' style={{backgroundColor: backgroundColor}}>
          <div className='d-flex flex-stack mb-3'>
            <div className=''>
              <Link to={loginUser?.permissions.filter((permission) => permission.name == 'Show Issue').length > 0 ? '/faaliyetler/detail' : ''} state={issue}>
                <h5 className='text-hover-primary'>{issue?.title}</h5>
              </Link>
            </div>

            {issue?.assigned?.id == loginUser.id || issue?.created_by?.id == loginUser.id || loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 ? (
              <SquareButton status={true} issue={issue} />
            ) : (
              ''
            )}
            {/* <EditStatus issue={issue}/>  */}
          </div>

          <div className='fs-6 fw-bold text-gray-600 mb-5'>
            {issue?.content != null ? issue?.content?.slice(0, 40) : ''}
            {issue?.content != null && issue?.content?.length > 40 ? '...' : ''}
          </div>
          <div className='d-flex justify-content-start flex-wrap mb-5'>
            <div className={`border border-${commentColor} rounded min-w-125px py-3 px-4 me-7 mb-3`}>
              <div className='fs-6 text-gray-800 fw-bolder'>{moment(issue?.start_date).format('DD.MM.YYYY')}</div>
              <div className='fw-bold text-gray-400'>Başlangıç T.</div>
            </div>
            <div className={`border border-${commentColor} rounded min-w-125px py-3 px-4 me-7 mb-3`}>
              <div className='fs-6 text-gray-800 fw-bolder'>{moment(issue?.end_date).format('DD.MM.YYYY')}</div>
              <div className='fw-bold text-gray-400'>Bitiş T.</div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-5'>
            {issue?.assigned != null ? (
              <div className='d-flex justify-content-start'>
                <div className='me-3 position-relative'>
                  <div className='symbol symbol-35px symbol-circle'>
                    {issue?.assigned.avatar ? (
                      <img alt='Pic' src={issue?.assigned?.avatar} />
                    ) : (
                      <div className={clsx('symbol-label fs-3', `bg-${commentColor}`, 'text-white')}>{issue?.assigned?.name?.substring(0, 1)}</div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={`text-${commentColor} fw-bolder `}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    style={{cursor: 'pointer'}}
                    onClick={async () => {
                      setProfilUserId(issue?.assigned?.id)
                      await openProfilModal()
                    }}
                  >
                    {issue?.assigned?.name}
                  </div>
                  <span className='fs-12 text-gray-600 fw-bolder'>Sorumlu</span>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='d-flex justify-content-end'>
              <div className='d-flex my-1' style={{position: 'relative', width: '40px', height: 'auto'}}>
                <Link to={loginUser?.permissions.filter((permission) => permission.name == 'Show Issue').length > 0 ? '/faaliyetler/detail' : ''} state={issue}>
                  {/* <div className='border border-dashed border-gray-300 rounded py-2 px-3 ms-3'> */}
                  <span className='svg-icon svg-icon-1'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 22 22' fill='none'>
                      <path
                        opacity='0.3'
                        d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                        fill='currentColor'
                      />
                      <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                      <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                    </svg>
                  </span>

                  <span className={` badge badge-circle bg-${commentColor} ms-1 fs-7 fw-bolder text-white`} style={{position: 'absolute', top: '-10px', right: '0px'}}>
                    {issue?.comments?.length}
                  </span>
                  {/* </div> */}
                </Link>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            {issue?.created_by != null ? (
              <div className='d-flex justify-content-start'>
                <div className='me-3 position-relative'>
                  <div className='symbol symbol-35px symbol-circle'>
                    {issue?.created_by.avatar ? (
                      <img alt='Pic' src={issue?.created_by.avatar} />
                    ) : (
                      <div className={clsx('symbol-label fs-3', `bg-${commentColor}`, 'text-white')}>{issue?.created_by?.name?.substring(0, 1)}</div>
                    )}
                  </div>
                </div>
                <div className=''>
                  <div
                    className={`text-${commentColor} fw-bolder `}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    style={{cursor: 'pointer'}}
                    onClick={async () => {
                      setProfilUserId(issue?.created_by?.id)
                      await openProfilModal()
                    }}
                  >
                    {issue?.created_by?.name}
                  </div>
                  <div className='fs-12 text-gray-600 fw-bolder'>Ekleyen</div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div> </div>
          </div>
          {issue?.updated_at !== null ? (
            <div className={`d-flex `}>
              <div className='fw-bold text-gray-400 '>Güncellenen Tarih: </div>
              <div className='fs-6 text-gray-400 fw-bolder ' style={{marginLeft: '5px'}}>
                {moment(issue?.updated_at).format('DD.MM.YYYY')}
              </div>
            </div>
          ) : (
            ''
          )}
          {issue?.project !== null ? (
            <div className={`d-flex `}>
              <div className='fw-bold text-gray-400 '>ilişkilendirilen Proje: </div>
              <div className='fs-6 text-gray-400 fw-bolder ' style={{marginLeft: '5px'}}>
                {issue?.project?.name}
              </div>
            </div>
          ) : (
            ''
          )}
          {/* <Badge badgeContent={5}
        color='primary'
      
        >
        {/* <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 22 22'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                      fill='currentColor' />
                    <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                    <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span> */}

          {/* </Badge> */}
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
