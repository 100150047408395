import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../components/ModalHeader'
import {ButtonActive} from './components/ButtonActive'
import {Completed} from './components/Completed'
import {InProgress} from './components/InProgress'
import {TargetLists} from './components/TargetLists'
import {YetToStart} from './components/YetToStart'
import {getSocialWidget, getStaticWidget} from './core/_requests'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {PieWidget} from '../components/widgets/PieWidget'
import {AddActivity} from './activity-edit-modal/addActivity'
import {SearchComponent} from '../components/SearchComponent'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {AddImmediateActivity} from './activity-edit-modal/addImmediateActivity'
import Swal from 'sweetalert2'
import {updateHomeWidgets, updateReportWidgets} from '../core/requests'
import {useRefresh1} from '../OrganizationalStructure/Context/RefreshAssignment'

export const Activities: FC = () => {
  const [listShow, setShow] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [immediateModal, setImmediateModalShow] = useState(false)
  const [period, setPeriod] = useState('')
  const [status, setStatus] = useState('')
  const [socials, setSocials] = useState([])
  const [showImmediateButton, setShowImmediateButton] = useState(false)
  const {refresh, refreshPage} = useRefresh1()
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const handleShow = () => setModalShow(true)
  const handleClose = () => {
    setModalShow(false)
  }
  const immediateModalShow = () => setImmediateModalShow(true)
  const immediateModalClose = () => {
    setImmediateModalShow(false)
  }

  const [assignedBy, setAssignedBy] = useState<any>('')
  const getAssignedBy = (id: string) => {
    setAssignmentId('')
    setAssignedBy(id)
  }
  const [assignmendtId, setAssignmentId] = useState('')
  const getAssignmentId = (id: string) => {
    setAssignedBy('')
    setAssignmentId(id)
  }
  const showListIssueToHomepage = () => {
    Swal.fire({
      title: 'Widget hangi sayfaya eklensin?',
      input: 'select',
      inputOptions: {
        '1': 'Ana Sayfa',
        '2': 'GMY Raporu',
        '3': 'Kalite Raporu',
        '4': 'Haftalık Rapor',
      },
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ekle',
      showLoaderOnConfirm: false,
      // showCancelButton: true,
      inputValidator: function (value) {
        return new Promise(function (resolve, reject) {
          if (value !== '') {
            resolve('')
          } else {
            reject('Sayfa seçmeniz lazım')
          }
        })
      },
    }).then(async function (result) {
      if (result?.value == '1') {
        updateHomeWidgets(loginUser.id, {widgets: [{id: '23', value: true}]})
        await refreshPage()
      } else if (result?.value == '2') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '23', value: true}], page: 61})
      } else if (result?.value == '3') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '23', value: true}], page: 62})
      } else if (result?.value == '4') {
        updateReportWidgets(loginUser.id, {pagewidgets: [{id: '23', value: true}], page: 63})
      }
    })
  }

  useEffect(() => {
    getSocialWidget().then(({data}) => {
      setSocials(data)
    })
  }, [assignedBy, selectedDirectorate, showImmediateButton, refresh])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const getRols = (...words: any[]) => loginUser?.roles?.filter((s) => words.some((w) => s.name.includes(w)))

  return (
    <>
      <div className='row g-5 '>
        <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden '>
          <PieWidget widgetId={7} refresh={refresh} text={'Toplam Faaliyet'} plusOrMinus={true} linkToPage={''} pageId={'5'} />
        </div>
        <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6 overflow-hidden '>
          <DirectorateWidget
            title='Kullanıcı Bazlı Faaliyetler'
            getSelectedId={getAssignedBy}
            setSelectedDirectorate={setSelectedDirectorate}
            setShowImmediateButton={setShowImmediateButton}
            widgetId={18}
            plusOrMinus={true}
            linkToPage={''}
            pageId={'5'}
          />
        </div>

        {/* {loginUser.roles.filter((e) => e.name == 'Müdür')?.length > 0 ? ( */}
        {getRols('Grup Müdürü', 'Süper Admin', 'Direktör')?.length > 0 ? (
          <div className='col-xxl-12'>
            <DirectorateWidget widgetId={12} title='Müdürlük Bazlı Faaliyetler' getSelectedId={getAssignmentId} setSelectedDirectorate={setSelectedDirectorate} plusOrMinus={true} linkToPage={''} pageId={'5'} />
          </div>
        ) : (
          ''
        )}
        {/* ) : (
          ''
        )} */}

        {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
          <div className='d-flex flex-column flex-column-fluid'> */}
        {/* <div id='kt_app_content' className='app-content flex-column-fluid'> */}
        {/* <div id='kt_app_content_container' className='app-container container-fluid'> */}
        <div className='d-flex flex-wrap flex-stack pt-10 pb-8'>
          <SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder='Faaliyet Ara' />
          {selectedDirectorate != '' && <span className='badge badge-light badge-lg me-2 px-9 py-4'>{selectedDirectorate}</span>}
          <div className='d-flex flex-wrap '>
            <ul className='nav nav-pills me-5'>
              <li className='nav-item m-0'>
                <a
                  style={{backgroundColor: listShow ? 'rgb(1 13 88)' : '', color: 'white'}}
                  className={`btn btn-sm btn-icon btn-light btn-color-muted  me-3 `}
                  //data-bs-toggle='tab'
                  onClick={() => setShow(true)}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg style={{color: 'white'}} xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                        <rect x='14' y='5' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                        <rect x='5' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                        <rect x='14' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
                      </g>
                    </svg>
                  </span>
                </a>
              </li>
              <li className='nav-item m-0'>
                <a
                  style={{backgroundColor: !listShow ? 'rgb(1 13 88)' : ''}}
                  className={`btn btn-sm btn-icon btn-light btn-color-muted `}
                  // data-toggle='tab'
                  onClick={() => setShow(false)}
                >
                  <span className='svg-icon svg-icon-2'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                      <path d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z' fill='currentColor' />
                      <path
                        opacity='0.3'
                        d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
            {!listShow ? (
              <div className='my-0 me-5'>
                <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setStatus(e.target.value)}>
                  <option value=''>Tümü</option>
                  <option value='0'>Planlanan</option>
                  <option value='1'>Devam Eden</option>
                  <option value='2'>Tamamlanan</option>
                </select>
              </div>
            ) : (
              ''
            )}

            <div className='my-0 me-5'>
              <select name='status' data-control='select2' data-hide-search='true' className='form-select form-select-sm form-select-solid w-150px h-40px' onChange={(e) => setPeriod(e.target.value)}>
                <option value=''>Tümü</option>
                <option value='daily'>Bugün</option>
                <option value='weekly'>Hafta</option>
                <option value='monthly'>Ay</option>
                <option value='yearly'>{new Date().getFullYear().toString()}</option>
              </select>
            </div>
            <div className='me-3' style={{display: showImmediateButton ? '' : 'none'}}>
              <ButtonActive text={'Hızlı Giriş'} clickFunc={immediateModalShow} />
            </div>
            {loginUser?.permissions.filter((permission) => permission.name == 'Create Issue').length > 0 && <ButtonActive text={'Yeni Ekle'} clickFunc={handleShow} />}
            {/* <div className='d-flex flex-row-reverse'>
                      <Button className=' h-100 pt-2' variant='primary'>
                        <span className='mt-2 '>Görevleri Dışarı Aktar</span>
                      </Button>
                    </div> */}
            {/* {!listShow ? ( */}
            <div className='p-1'>
              <div onClick={showListIssueToHomepage}>
                <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex ' style={{border: '1px solid rgb(1 13 88)', color: 'rgb(1 13 88)'}}>
                  +
                </span>
              </div>
            </div>
            {/* // ) : (
            //   ''
            // )} */}
          </div>
        </div>
        <div className='tab-content'>
          {listShow ? (
            <div id='kt_project_targets_card_pane' className='tab-pane fade show active'>
              <div className='row g-9'>
                <YetToStart period={period} assignedBy={assignedBy} searchKeyClick={searchKeyClick} assignmentId={assignmendtId} />
                <InProgress period={period} assignedBy={assignedBy} searchKeyClick={searchKeyClick} assignmentId={assignmendtId} />
                <Completed period={period} assignedBy={assignedBy} searchKeyClick={searchKeyClick} assignmentId={assignmendtId} />
              </div>
            </div>
          ) : (
            <TargetLists assignedBy={assignedBy} searchKeyClick={searchKeyClick} period={period} status={status} plusOrMinus={true} assignmentId={assignmendtId} />
          )}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div>
      </div> */}
      <Modal show={modalShow} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Yeni Faaliyet'} />
          <AddActivity
            handleClose={handleClose}
            activity={{
              id: undefined,
              title: '',
              content: '',
              start_date: '',
              end_date: '',
              phone_notification: false,
              email_notification: false,
              comments: [],
              //issue_category_id: ''
              //assigned_by: '',
            }}
            update={false}
          />
        </Modal.Body>
      </Modal>
      <Modal show={immediateModal}>
        <Modal.Body>
          <ModalHeader handleClose={immediateModalClose} titleHeader={'Hızlı Faaliyet Ekle'} />
          <AddImmediateActivity handleClose={immediateModalClose} assigned_id={assignedBy} />
        </Modal.Body>
      </Modal>
    </>
  )
}
