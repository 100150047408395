import React from 'react'
import HeaderTopbar from './HeaderTopbar'

const HeaderBase: React.FC = () => {
  return (
    <div id='kt_header' className='header align-items-stretch'>
      <div className='topbar'>
        <HeaderTopbar />
      </div>
    </div>
  )
}

export default HeaderBase
