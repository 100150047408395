import React, {FC, useEffect, useState} from 'react'
import Pagination from '../../components/Pagination'
import {getFilteredProjects} from '../core/requests'
import {ProjectTableBody} from './ProjectTableBody'

type Props = {
  modalShow: any
  setCount: any
  directorateID: any
  searchKeyClick: string
  selectedType: string
  selectedStatus: string
  selectedPlan: string
}

export const ProjectsList: FC<Props> = ({modalShow, setCount, directorateID, searchKeyClick, selectedPlan, selectedStatus, selectedType}) => {
  const [projects, setProjects] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const refreshCount = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const currentTableData = () => {
    let queryParams = '?q=' + searchKeyClick + '&page[number]=' + currentPage + '&page[size]=' + 10
    if (directorateID != '') {
      queryParams += '&directorate_id=' + directorateID
    }
    if (selectedStatus != '') {
      queryParams += '&status=' + selectedStatus
    }
    if (selectedType != '') {
      queryParams += '&project_type_id=' + selectedType
    }
    if (selectedPlan != '') {
      queryParams += '&project_plan_id=' + selectedPlan
    }
    getFilteredProjects(queryParams)
      .then(({data}) => {
        setTotal(data?.meta?.total)
        setProjects(data.data)
        setLoading(false)
        setCount(data.meta.total)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refresh, modalShow, directorateID, searchKeyClick, selectedPlan, selectedStatus, selectedType])

  return (
    <div className='row w-100' style={{marginTop: '0px'}}>
      <div className='row w-100'>
        <div className='card card-flush w-100 p-0' style={{width: '100vw'}}>
          <div className='card-body pt-3 w-100 mh-900  overflow-auto'>
            {loading ? (
              <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>
                <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
                  <tr style={{color: '#50c8da'}}>
                    <th className=''>Proje İSMİ</th>
                    <th className=''>GRUP MÜDÜRLÜĞÜ</th>
                    <th className=''>MÜDÜRLÜK</th>
                    <th className=''>PROJE SINIFI</th>
                    <th className=''>PROJE TÜRÜ</th>
                    <th className=''>PROJE KATEGORİSİ</th>
                    <th className=''>HEDEF BAŞLANGIÇ TARİHİ</th>
                    <th className=''>HEDEF BİTİŞ TARİHİ</th>
                    <th className=''>PROJE AMACI</th>
                    <th></th>

                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {projects?.map((project: any) => {
                    return (
                      <>
                        <ProjectTableBody key={project?.id} project={project} refreshPage={refreshCount} refreshCount={refreshCount} id={project?.id} type={''} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            )}
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={10} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  )
}
