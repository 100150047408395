import {FC} from 'react'
import {SubDataItem} from './SubDataItem'
import { SubStatusItem } from './SubStatusItem'
type Props = {
  item: any
}
export const StatusItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr style={{backgroundColor: '#e7e7e7'}}>
        <td className='fw-bolder'>{item?.security_score}</td>
        <td className='text-gray-500'>{item?.type}</td>
        <td className='text-gray-500'>{item?.cron_server_ip}</td>
        <td className='text-gray-500'>{item?.created_at}</td>
        <td className='text-gray-500'>{item?.updated_at}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        <td>{''}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
      {item.vectors.map((i: any) => {
          return <SubStatusItem item={i} />
        })}
    </>
  )
}
