import {current} from '@reduxjs/toolkit'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import ReactTags from 'react-tag-autocomplete'
import {GetAssignments} from '../../../OrganizationalStructure/core/_requests'

type Props = {
  setRoles: any
  delRole: any
  tags1?: any
}

export const RolesTags: FC<Props> = ({setRoles, delRole, tags1}) => {
  const [tags, setTags] = useState<any>([])

  const initalState = [
    {
      id: '',
      name: '',
    },
  ]

  const [suggestions, setSuggestions] = useState(initalState)

  const reactTags = useRef()

  const onDelete = useCallback(
    (tagIndex) => {
      delRole(tagIndex)
      setTags(tags.filter((_: any, i: any) => i !== tagIndex))
    },
    [tags]
  )

  const onAddition = useCallback(
    (newTag) => {
      setTags([...tags, newTag])
      setRoles(newTag)
    },
    [tags]
  )
  useEffect(() => {
    GetAssignments().then(async ({data}) => {
      await data.data.map((d: any) => {
        if (!suggestions.some((item) => item.name == d.alternative_name)) setSuggestions((current) => [...current, {id: d.id, name: d.alternative_name}])
      })
    })
    setTags(tags1)
  }, [])

  return (
    <>
      <ReactTags placeholderText={'Görev bazlı arama yap...'} autoresize={false} tags={tags} suggestions={suggestions} onDelete={onDelete} onAddition={onAddition} />
    </>
  )
}
