import React, {useEffect, useState} from 'react'
import {GetMilestone} from '../../core/requests'
import {Timeline} from '../../../../../_theme/partials/widgets/timeline/Timeline'
import {Modal} from 'react-bootstrap'
import {AddMilestoneHeader} from '../add-edit-modals/addMilestoneHeader'
import {ModalHeader} from '../../../components/ModalHeader'
import {Targets} from './widgets/Targets'
import {useRefresh1} from '../../../OrganizationalStructure/Context/RefreshAssignment'

export const ProjectProgress = () => {
  const [showTicketModal, setShowticketModal] = useState(false)
  const {refresh, refreshPage} = useRefresh1()
  const openTicketModal = () => {
    setShowticketModal(true)
  }
  const closeTicketModal = () => {
    refreshPage()
    setShowticketModal(false)
  }

  const [targets, setTargets] = useState<any>([])

  let data = JSON.parse(localStorage.getItem('projectID') || '{}')
  useEffect(() => {
    GetMilestone(data).then(({data}) => {
      setTargets([])
      setTargets(data.data)
    })
  }, [refresh])

  return (
    <div>
      <div className='row g-5 g-xxl-10'>
        <div className=' mb-xl-5 mb-xxl-10 '>
          <Timeline data={targets} />
        </div>
      </div>
      <Targets id={data} type={true} />
      <Modal show={showTicketModal} style={{position: 'absolute', top: '0px'}} dialogClassName='modal-90w' size='xl' aria-labelledby='example-custom-modal-styling-title'>
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Yeni Hedef Oluştur'} />
          <AddMilestoneHeader project_id={data} target={targets} target_id={undefined} handleClose={closeTicketModal} update={true} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
