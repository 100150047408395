import {FC, Key, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {GetExchangeRates, updateHomeWidgets, updateReportWidgets} from '../../core/requests'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {useRefresh1} from '../../OrganizationalStructure/Context/RefreshAssignment'

type Props = {
  plusOrMinus: boolean
  widgetId: any
  refreshPage?: any
  linkToPage: string
  pageId?: string
}
export const ExchangeRates: FC<Props> = ({plusOrMinus, widgetId, linkToPage, pageId}) => {
  const [exchangeRates, setExchangeRates] = useState<any>([])
  useEffect(() => {
    GetExchangeRates().then(async ({data}) => {
      await setExchangeRates(data)
    })
  }, [])
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {refreshPage} = useRefresh1()
  function homeWidgetShow() {
    plusOrMinus
      ? Swal.fire({
          title: 'Widget hangi sayfaya eklensin?',
          input: 'select',
          inputOptions: {
            '1': 'Ana Sayfa',
            '2': 'GMY Raporu',
            '3': 'Kalite Raporu',
            '4': 'Haftalık Rapor',
          },
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonText: 'Ekle',
          showLoaderOnConfirm: false,
          // showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve('')
              } else {
                reject('Sayfa seçmeniz lazım')
              }
            })
          },
        }).then(async function (result) {
          if (result?.value == '1') {
            updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            await refreshPage()
          } else if (result?.value == '2') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 61,
            })
          } else if (result?.value == '3') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 62,
            })
          } else if (result?.value == '4') {
            updateReportWidgets(loginUser.id, {
              pagewidgets: [{id: widgetId, value: plusOrMinus}],
              page: 63,
            })
          }
        })
      : swal({
          title: '',
          text: 'Widget silinecektir emin misiniz?',
          icon: 'warning',
          buttons: ['Vazgeç', 'Evet'],
        }).then(async (responese) => {
          if (responese) {
            if (pageId == '1') {
              await updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
            } else {
              await updateReportWidgets(loginUser.id, {
                pagewidgets: [{id: widgetId, value: plusOrMinus}],
                page: pageId,
              })
            }
            refreshPage()
          }
        })
  }
  return (
    <div className='card card-flush overflow-scroll' style={{backgroundColor: '#f6f7f8', height: '427px'}}>
      <div className='card-body' style={{backgroundColor: '#f6f7f8', height: '427px'}}>
        <div
          className='d-flex justify-content-end'
          onClick={() => {
            homeWidgetShow()
          }}
        >
          <span
            className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex  '
            style={{
              backgroundColor: '#f6f7f8',
              border: '1px solid rgb(1 13 88)',
              color: 'rgb(1 13 88)',
            }}
          >
            {plusOrMinus ? '+' : '-'}
          </span>
        </div>
        <Link to={plusOrMinus ? '' : linkToPage}>
          <div className='d-flex flex-stack mb-8'>
            <div className='d-flex align-items-center me-5'>
              <span className='text-dark fw-bolder text-hover-primary fs-6'>Döviz</span>
            </div>

            <div className='text-gray-800 fw-bolder fs-6'>
              <span className='me-7'>Alış</span>

              <span className='m-0'>
                <span className='svg-icon svg-icon-7 svg-icon-success'>
                  {/* <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              opacity='0.5'
                              d='M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z'
                              fill='currentColor'
                            />
                            <path
                              d='M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z'
                              fill='currentColor'
                            />
                          </svg> */}
                </span>
                Satış
              </span>
            </div>
          </div>
          {exchangeRates.map((ex: any, index: Key | null | undefined) => {
            return (
              <div key={index} className='d-flex flex-stack mb-8'>
                <div className='d-flex align-items-center me-5'>
                  <img
                    src={ex.currency_image}
                    className='me-3'
                    // style="width: 20px;border-radius: 4px"
                    alt=''
                  />

                  <span className='text-dark fw-bolder  fs-6'>{ex.currency_code}</span>
                </div>

                <div className='text-gray-600 fw-bolder fs-6'>
                  <span className='me-7'>{ex.banknote_buying}</span>

                  <span className='m-0'>
                    <span className='svg-icon svg-icon-7 svg-icon-success'>
                      {/* <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              opacity='0.5'
                              d='M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z'
                              fill='currentColor'
                            />
                            <path
                              d='M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z'
                              fill='currentColor'
                            />
                          </svg> */}
                    </span>
                    {ex.banknote_selling}
                  </span>
                </div>
              </div>
            )
          })}
        </Link>
      </div>
    </div>
  )
}
