import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setCompany: any
  setCaseTitle: any
  setCaseStatus: any
  setRaUser: any
  setAssignedGroup: any
  handleClose: any
  setInformedDepartments: any
}

export const DetailFilters: FC<Props> = ({setCompany, setCaseTitle, setCaseStatus, setRaUser, setAssignedGroup, handleClose, setInformedDepartments}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setCompany(selectedCompany)
    setCaseTitle(selectedCaseTitle)
    setCaseStatus(selectedCaseStatus)
    setRaUser(selectedRaUser)
    setAssignedGroup(selectedAssignedGroup)
    setInformedDepartments(selectedInformedDepartment)
    handleClose()
  }
  const [selectedInformedDepartment, setSelectedInformedDepartment] = useState('')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [selectedCaseTitle, setSelectedCaseTitle] = useState('')
  const [selectedCaseStatus, setSelectedCaseStatus] = useState('')
  const [selectedRaUser, setSelectedRaUser] = useState('')
  const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Case Title</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCaseTitle(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'> Case Status</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCaseStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Informed Department</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedInformedDepartment(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Company</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCompany(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Case Assigned Group</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedAssignedGroup(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Ra User</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedRaUser(e.target.value)
          }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <button type='reset' className='btn btn-sm btn-light btn-active-light-primary me-2' data-kt-menu-dismiss='true' onClick={handleClose}>
          İptal
        </button>
        <button type='submit' className='btn btn-sm' data-kt-menu-dismiss='true' onClick={handleSubmit}>
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
