import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_theme/helpers'
import {GetInventory} from '../core/_requests'

type Props = {
  inventory_id: any
  handleClose: any
}
export const InventoryModal: FC<Props> = ({inventory_id, handleClose}) => {
  const [inventory, setInventory] = useState<any>()
  useEffect(() => {
    GetInventory(inventory_id).then(async ({data}) => {
      await setInventory(data.data)
    })
  }, [])
  return (
    <div className='flex-column flex-lg-row-auto  ml-0 '>
      <div>
        <div className='d-flex justify-content-end'>
          <div className=' ' data-kt-users-modal-action='close' onClick={handleClose} style={{cursor: 'pointer'}}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <h2 className='fw-bolder text-center'>Envanter Detayı</h2>
      </div>
      <div className='card-body '>
        <div className='separator'></div>

        <div id='kt_user_view_details' className='row text-center'>
          <div className='pb-5 fs-6 col-6'>
            <div className='fw-bolder mt-5'>Model</div>
            <div className='text-gray-600'>{inventory?.model}</div>

            <div className='fw-bolder mt-5'>Kabin Adı</div>
            <div className='text-gray-600'>{inventory?.kabin_adi}</div>

            <div className='fw-bolder mt-5'>Racku Bilgisi</div>
            <div className='text-gray-600'>{inventory?.racku_bilgisi}</div>

            <div className='fw-bolder mt-5'>Cihaz Hostname</div>
            <div className='text-gray-600'>{inventory?.cihaz_hostname}</div>
            <div className='fw-bolder mt-5'>Cihazdaki Lisans Bilgisi</div>
            <div className='text-gray-600'>{inventory?.cihazdaki_lisans_bilgisi}</div>

            <div className='fw-bolder mt-5'>Lisans Sahibi Firma</div>
            <div className='text-gray-600'>{inventory?.lisans_sahibi_firma}</div>

            <div className='fw-bolder mt-5'>Lisans Modeli</div>
            <div className='text-gray-600'>{inventory?.lisans_modeli}</div>
            <div className='fw-bolder mt-5'>Lisans Tipi</div>
            <div className='text-gray-600'>{inventory?.lisans_tipi}</div>

            <div className='fw-bolder mt-5'>Lisans Başlangıç Tarihi</div>
            <div className='text-gray-600'>{inventory?.lisans_baslangic_tarihi}</div>

            <div className='fw-bolder mt-5'>Lisans Bitiş Tarihi</div>
            <div className='text-gray-600'>{inventory?.lisans_bitis_tarihi}</div>
          </div>
          <div className='pb-5 fs-6 col-6'>
            <div className='fw-bolder mt-5'>Alarm İzleme Sistemi</div>
            <div className='text-gray-600'>{inventory?.alarm_izleme_sistemi}</div>

            <div className='fw-bolder mt-5'>Satın Almanın Yapıldığı Firma</div>
            <div className='text-gray-600'>{inventory?.satin_almanin_yapildigi_firma}</div>

            <div className='fw-bolder mt-5'>Süresiz Garanti Lisans</div>
            <div className='text-gray-600'>{inventory?.suresiz_garanti_lisans}</div>
            <div className='fw-bolder mt-5'>EDR Durumu</div>
            <div className='text-gray-600'>{inventory?.edr_durumu}</div>

            <div className='fw-bolder mt-5'>Antivirus Durumu</div>
            <div className='text-gray-600'>{inventory?.antivirus_durumu}</div>

            <div className='fw-bolder mt-5'>MFA Uygulamam Durumu</div>
            <div className='text-gray-600'>{inventory?.mfa_uygulama_durumu}</div>
            <div className='fw-bolder mt-5'>Siement Entegrasyon Durumu</div>
            <div className='text-gray-600'>{inventory?.siement_entegrasyon_durumu}</div>

            <div className='fw-bolder mt-5'>Backup Alımı Durumu</div>
            <div className='text-gray-600'>{inventory?.backup_alimi_durumu}</div>

            <div className='fw-bolder mt-5'>User Authentication Method</div>
            <div className='text-gray-600'>{inventory?.user_authentication_method}</div>
            <div className='fw-bolder mt-5'>Zaafiyet Araştırma Durumu</div>
            <div className='text-gray-600'>{inventory?.zaafiyet_arastirma_durumu}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
