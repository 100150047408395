import {createSlice} from '@reduxjs/toolkit'

interface CSRFState {
  token: string
}

const initialState: CSRFState = {
  token: '',
}

export const CSRFSlice = createSlice({
  name: 'csrfToken',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const {setToken} = CSRFSlice.actions

export default CSRFSlice.reducer
