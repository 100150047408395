import React, {FC, useEffect, useState} from 'react'
import {NoMatchRecords} from '../../../../components/NoMatchRecords'
import {deleteMilestone, GetMilestone} from '../../../core/requests'
import {SubTargetItem} from '../components/SubTargetItem'
import {TargetItem} from '../components/TargetItem'
import swal from 'sweetalert'
import {useRefresh1} from '../../../../OrganizationalStructure/Context/RefreshAssignment'
import {ModalHeader} from '../../../../components/ModalHeader'
import {data} from 'jquery'
import {Modal} from 'react-bootstrap'
import {AddMilestoneHeader} from '../../add-edit-modals/addMilestoneHeader'
import Pagination from '../../../../components/Pagination'

type Props = {
  id?: string
  type: boolean
}

export const Targets: FC<Props> = ({id, type}) => {
  const [targets, setTargets] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [showTicketModal, setShowticketModal] = useState(false)
  const {refresh, refreshPage} = useRefresh1()
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const currentTableData = () => {
    let queryParams = '&page[number]=' + currentPage + '&page[size]=5'

    GetMilestone(`${id}` + queryParams)
      .then(async ({data}) => {
        setTargets([])
        await setTargets(data.data)
        setTotal(data?.meta?.total)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        // Hata işleme kodunu ekleyin
      })
  }

  useEffect(() => {
    setLoading(true)
    setCurrentPage(1)
    currentTableData()
  }, [refresh])
  useEffect(() => {
    setLoading(true)
    currentTableData()
  }, [currentPage])
  const openTicketModal = () => {
    setShowticketModal(true)
  }
  const closeTicketModal = () => {
    refreshPage()
    setShowticketModal(false)
  }

  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Alt hedefler de silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        deleteMilestone(id)
          .then(() => {
            swal('Silindi!', 'Hedef başarı ile silindi.', 'success', {timer: 2000})
            refreshPage()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  return (
    <>
      <div className='row'>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-0 '>
            <a className='btn btn-sm mt-3 ' data-bs-toggle='modal' data-bs-target='#kt_modal_offer_a_deal' style={{width: '10%', color: 'white', marginLeft: '90%'}} onClick={() => openTicketModal()}>
              Yeni Hedef
            </a>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered  gy-4 align-middle fw-bolder scroll-x'>
                  <thead
                    className='fs-7 text-gray-400 text-uppercase'
                    style={{
                      position: 'sticky',
                      top: '-15px',
                      backgroundColor: '#fdfcfb',
                      zIndex: '100',
                    }}
                  >
                    <tr>
                      <th className=''>Proje Aşaması</th>
                      <th className=''>İş Kırılım No</th>
                      <th className=''>Görev Adı</th>
                      <th className=''>Görev SAHİBİ</th>
                      <th className=''> DİĞER KULLANICILAR</th>
                      <th className=''>Görev Durumu</th>
                      <th className=''>P. Başlama TARİHİ</th>
                      <th className=''>P. BİTİŞ TARİHİ</th>
                      {type ? <th className=''>Süre</th> : ''}
                      {type ? <th className=''>G. Başlangıç TARİHİ</th> : ''}
                      {type ? <th className=''>G. BİTİŞ TARİHİ</th> : ''}
                      {type ? <th className=''>Süre</th> : ''}
                      {type ? <th className=''>Not</th> : ''}
                      <th>İLERLEME YÜZDESİ</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className=''>
                    {targets?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      targets?.reverse().map(
                        (
                          target: {
                            tasks: any
                            id: React.Key | null | undefined
                          },
                          index: number
                        ) => {
                          return (
                            <>
                              <TargetItem key={index} target={target} project_id={id} deleteTarget={swalDelete} refreshPage={refreshPage} number={index + 1} task={false} type={type} />
                              <SubTargetItem target_id={target?.id} refreshPage={refreshPage} number={index + 1} project_id={id} milestones={target?.id} taskss={target?.tasks} />
                            </>
                          )
                        }
                      )
                    )}
                  </tbody>
                </table>
              )}
              <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={5} loading={loading} />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showTicketModal} style={{position: 'absolute', top: '0px'}} dialogClassName='modal-90w' size='xl' aria-labelledby='example-custom-modal-styling-title'>
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Yeni Hedef Oluştur'} />
          <AddMilestoneHeader project_id={id} target={undefined} target_id={undefined} handleClose={closeTicketModal} update={false} />
        </Modal.Body>
      </Modal>
    </>
  )
}
