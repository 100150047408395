import React, {FC} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {AnnouncementDetail} from '../Announcements/Announcement-detail/AnnouncementDetail'
import {Blog} from './Blog'
import {BlogDetail} from './Blog-detail/BlogDetail'
import {ViewDetail} from './Blog-detail/ViewDetail'

const BlogPageWrapper: FC = () => {
  const dashboardBreadCrumbs: Array<PageLink> = [
    {
      title: 'Anasayfa',
      path: '/ana-sayfa',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='blog'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bilgi Paylaşım Platformu'>
                Blog
              </PageTitle>
              <Blog />
            </>
          }
        />
        <Route
          path='detail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bilgi Paylaşım Platformu' toDescription='/blog'>
                Detay
              </PageTitle>
              <BlogDetail />
            </>
          }
        />
        <Route
          path='ViewDetail/detail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bilgi Paylaşım Platformu' toDescription='/blog'>
                Detay
              </PageTitle>
              <BlogDetail />
            </>
          }
        />
        <Route
          path='ViewDetail'
          element={
            <>
              <PageTitle breadcrumbs={dashboardBreadCrumbs} description='Bilgi Paylaşım Platformu' toDescription='/blog'>
                Blog
              </PageTitle>
              <ViewDetail />
            </>
          }
        />

        <Route index element={<Navigate to='/blog' />} />
      </Route>
    </Routes>
  )
}

export {BlogPageWrapper}
