import {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {GetReport} from '../core/request'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import HeaderInfo from '../components/HeaderInfo'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {PieChart} from '../../components/widgets/OperationalData/PieChart'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import {AudingsAndFindingsItem} from './components/AudingsAndFindingsItem'

export const AudingsAndFindings: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [items, setItems] = useState<any>([])
  const [groupAssets, setGroupAssets] = useState<any>([])
  const [criticalLevel, setCriticalLevel] = useState('')
  const [refStatus, setRefStatus] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [currentpage2, setCurrentPage2] = useState(1)
  const [total2, setTotal2] = useState(1)
  const [loading2, setLoading2] = useState(false)
  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8
    if (refStatus != '') {
      queryParams += '&ref_status=' + refStatus
    }
    GetReport('/optimate_solution_planlanan_denetimler', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setItems(data?.data)
      setLoading(false)
    })
  }

  const currentTableData2 = () => {
    let queryParams = '?page=' + currentPage + '&limit=' + 8

    GetReport('/optimate_solution_acik_kapali_bulgu_sayilari', queryParams).then(({data}) => {
      setTotal2(data?.meta?.total)
      setGroupAssets(data?.data)
      setLoading2(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [refStatus])
  useEffect(() => {
    currentTableData2()
    setLoading2(true)
  }, [currentpage2])
  useEffect(() => {
    setCurrentPage2(1)
    currentTableData2()
  }, [refStatus])

  const exportFile = () => {
    window.open(`${ExportLink}/optimate_solution_planlanan_denetimler?token=${loginUser.token}&criticality_level=${criticalLevel}`)
  }
  const exportFile2 = () => {
    window.open(`${ExportLink}/optimate_solution_acik_kapali_bulgu_sayilari?token=${loginUser.token}`)
  }
  return (
    <>
      <div>
        <div className='row g-5 '>
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
            <PieChart name={'Durum Dağılımı'} link={''} widgetId={'90'} plusOrMinus={true} linkToPage={''} setSelectedValue={setRefStatus} />
          </div>

          <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Direktörlük Bazında Açık Ve Kapalı Bulgu Sayıları'} widgetId={'91'} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
        <div className='row g-5'>
          <div className='col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Müdürlük Bazında Açık Ve Kapalı Bulgu Sayıları'} widgetId={'93'} plusOrMinus={true} linkToPage={''} />
          </div>
          <div className='col-xl-6 col-xxl-6 mb-xl-5 mb-xxl-6'>
            <BarChart horizentalTitle={'Grup Müdürlüğü Bazında Açık Ve Kapalı Bulgu Sayıları'} widgetId={'94'} plusOrMinus={true} linkToPage={''} />
          </div>
        </div>
        <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
          <PieChart name={'Toplam Açık Kapalı Bulgu Sayıları'} link={''} widgetId={'101'} plusOrMinus={true} linkToPage={''} />
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_planlanan_denetimler_check'} />

                <span className='fs-2 fw-bolder mt-3'>Planlanan Denetimler</span>
                <div>
                  {/* <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button> */}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>NAME</th>
                      <th className=''>PLANNED AUDIT START DATE</th>
                      <th className=''>PLANNED AUDIT END DATE</th>
                      <th className=''>REF STATUS</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {items?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      items?.map((item: any) => {
                        return (
                          <>
                            <AudingsAndFindingsItem key={item?.id} item={item} tableId={1} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0 mt-5 '>
            <div className='card-header mt-5'>
              <div className='d-flex justify-content-between w-100'>
                <HeaderInfo api={'/optimate_solution_acik_kapali_bulgu_sayilari_check'} />
                <span className='fs-2 fw-bolder mt-3'> Açık Ve Kapalı Bulgu Sayıları</span>
                <div>
                  {/* <Button className='btn mt-3' style={{marginRight: '10px'}} onClick={showFilterModal}>
                    Detaylı Ara
                  </Button> */}
                  <Button className='btn mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile2}>
                    Dışarı Aktar
                  </Button>
                </div>
              </div>
            </div>
            <div className='card-body pt-3 overflow-auto mh-900'>
              {loading2 ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                  <thead className='fs-7 text-gray-400 text-uppercase ' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>REF_ID</th>
                      <th className=''>DATE TIME CREATED</th>
                      <th className=''>DATA MODIFIER</th>
                      <th className=''>FINDING</th>
                      <th className=''>REF_STATUS</th>
                      <th className=''>BUSINESS UNIT</th>
                      <th className=''>IS OPEN</th>
                      <th className=''>DIRECTORATE</th>
                      <th className=''>GROUP DEPARTMENT</th>
                      <th className=''>DEPARTMENT</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {groupAssets?.length < 1 && !loading2 ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      groupAssets?.map((item: any) => {
                        return (
                          <>
                            <AudingsAndFindingsItem key={item?.id} item={item} tableId={2} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className='mx-5'>
              <Pagination onPageChange={(page: number) => setCurrentPage2(page)} totalCount={total2} currentPage={currentpage2} pageSize={8} loading={loading2} />
            </div>
          </div>
        </div>
      </KTCard>
      {/* 
      <Modal show={filterModalShow}>
        <Modal.Body>
          <ModalHeader titleHeader='Detaylı Filtrele' handleClose={closeFilterModal} />
          <DetailFilters setRequester={setRequester} setOpeningTime={setOpening_time} handleClose={closeFilterModal} setSearchKeyClick={setSearchKeyClick} />
        </Modal.Body>
      </Modal> */}
    </>
  )
}
