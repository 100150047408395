import {FC, useEffect, useState} from 'react'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import Pagination from '../../components/Pagination'
import {getDoc} from '../core/_requests'
import {ListFileItem} from './ListFileItem'
import {ListFolder} from './ListFolder'

type Props = {
  cat: string
  period: string
  searchKey: string
  setTotalCounts: any
  refresh: boolean
  orderBy?: string
}

export const ListFiles: FC<Props> = ({cat, searchKey, setTotalCounts, refresh, orderBy, period}) => {
  const [refreshDelete, setRefresh] = useState(false)
  const refreshPage = () => {
    refreshDelete ? setRefresh(false) : setRefresh(true)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setToltal] = useState(1)
  const [loading, setLoading] = useState(true)
  const [docs, setDocs] = useState([
    {
      id: '1',
      name: '',
      uuid: '',
      tags: '',
      category: {
        id: 1,
        name: '',
      },
      attachments: [],
      assignments: [],
      banner: [],
      created_at: '',
      created_by: [],
      files: [
        {
          id: '',
          fileable_type: 'App\\Models\\Document',
          fileable_id: 1,
          uuid: '983a0310-f506-47ef-93c3-7d01a8a6d4af',
          name: 'odev-4.docx',
          mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          type: 'attachments',
          size: 13117,
          path: 'https://sgd.ecodation.com/storage/f6385c777c120fdbebd6dbf4ede5254d/odev-4.docx',
          created_at: '2022-05-28T09:47:55.000000Z',
          created_by: {
            id: 36,
            name: 'Süper Admin',
            email: 'superadmin@ecodation.com',
          },
        },
      ],
    },
  ])
  const ascByName = () => {
    setDocs(docs.sort((a, b) => (b.name.toUpperCase() > a.name.toUpperCase() ? -1 : b.name.toUpperCase() < a.name.toUpperCase() ? 1 : 0)))
  }
  const descByName = () => {
    setDocs(docs.sort((a, b) => (b.name.toUpperCase() < a.name.toUpperCase() ? -1 : b.name.toUpperCase() > a.name.toUpperCase() ? 1 : 0)))
  }
  const orderByNew = () => {
    setDocs(docs.sort((a, b) => (b.created_at.toUpperCase() < a.created_at.toUpperCase() ? -1 : b.created_at.toUpperCase() > a.created_at.toUpperCase() ? 1 : 0)))
  }
  const orderByOld = () => {
    setDocs(docs.sort((a, b) => (b.created_at.toUpperCase() > a.created_at.toUpperCase() ? -1 : b.created_at.toUpperCase() < a.created_at.toUpperCase() ? 1 : 0)))
  }

  const currentTableData = () => {
    let queryParams = '?page=' + currentPage + '&limit=12'
    if (searchKey != '') {
      queryParams += '&q=' + searchKey
    }
    if (cat != '') {
      queryParams += '&document_category_id=' + cat
    }
    if (period != '') {
      queryParams += '&period=' + period
    }
    getDoc(queryParams).then(async ({data}) => {
      setToltal(data?.meta?.total)
      setTotalCounts(data?.meta?.total)
      await setDocs(data?.data)
      setLoading(false)
      if (orderBy == '1') {
        ascByName()
      } else if (orderBy == '2') {
        descByName()
      } else if (orderBy == '3') {
        orderByNew()
      } else if (orderBy == '4') {
        orderByOld()
      }
    })
  }
  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setLoading(true)
    setDocs([])
    setCurrentPage(1)
    currentTableData()
  }, [cat, searchKey, refresh, refreshDelete, orderBy, period])

  return (
    <div className='card card-flush'>
      <div className='card-body'>
        {loading ? (
          <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <table id='kt_file_manager_list' data-kt-filemanager-table='folders' className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead style={{position: 'sticky', top: '-15px', backgroundColor: '#fdfcfb'}}>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                {/* <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_file_manager_list .form-check-input'
                    value='1'
                  />
                </div>
              </th> */}
                <th className='min-w-250px'>Dosya Adı</th>
                <th className='min-w-10px'>Boyutu</th>
                <th className='min-w-125px'>Sorumlusu</th>
                <th className='w-125px'>KATEGORİSİ</th>
                <th className='w-15'>Oluşturulma TARİHİ</th>
                <th></th>
              </tr>
            </thead>
            {/* {console.log(docs.sort((a, b) => b.name.toUpperCase() > a.name.toUpperCase() ? -1 : (b.name.toUpperCase() < a.name.toUpperCase() ? 1 : 0)))} */}
            {/* {console.log(docs.sort((a, b) => b.created_at.toUpperCase() > a.created_at.toUpperCase() ? -1 : (b.created_at.toUpperCase() < a.created_at.toUpperCase() ? 1 : 0)))} */}

            <tbody className='fw-bold text-gray-600'>
              {docs?.length < 1 && !loading ? (
                <NoMatchRecords isTable={true} />
              ) : (
                docs.sort().map((e: any) => {
                  {
                    if (e.files?.length > 1) {
                      return <ListFolder el={e} files={e.files} refreshPage={refreshPage} />
                    }
                    return e.files.map((file: any) => {
                      return (
                        <ListFileItem
                          key={file.id}
                          id={e.id}
                          attid={file.id}
                          categoryName={e.category.name}
                          path={file.path}
                          name={file.name}
                          fileName={e.name}
                          size={file.size.toString()}
                          created_by={file.created_by}
                          created_at={file.created_at}
                          lastMadied={file.created_by}
                          subitem={false}
                          fileAssignments={e?.assignments}
                          refreshPage={refreshPage}
                          is_public={e.is_public}
                        />
                      )
                    })
                  }
                })
              )}
            </tbody>
          </table>
        )}
        <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
      </div>
    </div>
  )
}
