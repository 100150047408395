import moment from 'moment'
import {FC} from 'react'
type Props = {
  item: any
}
export const AgentSituationItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.AuditMachineName}</td>
        <td className='text-gray-500'>{item?.IPAddress}</td>
        <td className='text-gray-500'>{item?.State} </td>
        <td className='fw-bolder'>{item?.AgentVersion}</td>
        <td className='text-gray-500'>{item?.FreeDiskSpace}</td>
        <td className='text-gray-500'>{moment(item?.LastUpdateTime).format('DD.MM.YYYY H:ss')}</td>
        <td className='text-gray-500'>{item?.integrated_service}</td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY H:ss')} </td>
      </tr>
    </>
  )
}
