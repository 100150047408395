import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {CAItem} from './components/CAItem'
import {GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCAttact} from './add-CA/Add-CAttack'
import {ExportLink} from '../../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {BarChart} from '../../components/widgets/OperationalData/BarChart'
import Pagination from '../../components/Pagination'
import {NoMatchRecords} from '../../components/NoMatchRecords'

export const CAttact: FC = () => {
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [dlp, setDLP] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [loading, setLoading] = useState(true)

  const currentTableData = () => {
    let queryParams = '?page[number]=' + currentPage + '&page[size]=20'
    if (searchKeyClick != '') {
      queryParams += '&q=' + searchKeyClick
    }

    GetReport('/customer_attack_statistics', queryParams).then(({data}) => {
      setTotal(data?.meta?.total)
      setDLP(data?.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    currentTableData()
    setLoading(true)
  }, [currentPage])
  useEffect(() => {
    setCurrentPage(1)
    currentTableData()
  }, [searchKeyClick, show])

  const exportFile = () => {
    window.open(`${ExportLink}/customer_attack_statistics?token=${loginUser.token}`)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>

        <div>
          <div className='row g-5 '>
            <div className='col-xl-6 col-xxl-6 mb-xl-6 mb-xxl-12'>
              <BarChart horizentalTitle={'Haftanın En Büyük Boyutlu DDos Atağı'} widgetId={'45'} plusOrMinus={true} linkToPage={''} />
            </div>
            <div className='col-xl-6 col-xxl-6 mb-xl-6 mb-xxl-12'>
              <BarChart horizentalTitle={'DDos Atak Sayısı Trendi'} widgetId={'46'} plusOrMinus={true} linkToPage={''} />
            </div>
            {/* end::Col */}
          </div>
          <div className='row g-5 g-xxl-10  mb-5'>{/* end::Col */}</div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          {/* <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
               

                
                  <Button className='btn mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div> */}

          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex align-items-center position-relative my-3'>{/* <HeaderInfo api={'/customer_attack_statistic_check'} /> */}</div>
              <div>
                {loginUser?.roles?.filter((role: any) => role.name === 'Süper Admin' || role?.name == 'Planlama')?.length > 0 && (
                  <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={handleShow}>
                    İçeri Aktar
                  </Button>
                )}
                <Button className='btn mt-3 mt-3' style={{marginRight: '10px'}} variant='primary' onClick={exportFile}>
                  Dışarı Aktar
                </Button>
              </div>
            </div>
            <div className='card-body pt-3'>
              {loading ? (
                <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <thead className='fs-7 text-gray-400 text-uppercase' style={{position: 'sticky', top: '-10px', backgroundColor: '#fcfaf8'}}>
                    <tr style={{color: '#50c8da'}}>
                      <th className=''>TARİH</th>
                      <th className=''>MÜŞTERİ Adı</th>
                      <th className=''>Saat</th>
                      <th className=''>Atak Boyutu (BW)</th>
                      <th className=''>Açıklama</th>
                      {/* <th className=''>FİRMA</th> */}
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {dlp?.length < 1 && !loading ? (
                      <NoMatchRecords isTable={true} />
                    ) : (
                      dlp?.map((dlp: any) => {
                        return (
                          <>
                            <CAItem key={dlp?.id} cattact={dlp} />
                          </>
                        )
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={20} loading={loading} />
          </div>
        </div>
      </KTCard>
      <Modal show={show}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddCAttact handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
