import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_theme/helpers'
import {CommentModel} from '../../Activities/models/commentModel'
import {BlogModel} from '../model/blogModel'

const API_URL = process.env.REACT_APP_API_URL
const BLOG = `${API_URL}/blogs`
const GET_CATEGORIES = `${API_URL}/blog_categories`
const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

const getBlog = (q: string, size: string): Promise<any> => {
  return axios.get(`${BLOG}${size}${q}`).then((d: AxiosResponse<any>) => d.data)
}

export async function getBlogDetail(id: string | undefined) {
  return await axios.get<any>(BLOG + '/' + id)
}
export async function getBlogCategories() {
  return await axios.get<any>(GET_CATEGORIES)
}

const createBlog = (announcement: any): Promise<BlogModel | undefined> => {
  return axios
    .post(BLOG, announcement, config)
    .then((response: AxiosResponse<Response<BlogModel>>) => response.data)
    .then((response: Response<BlogModel>) => response.data)
}

export async function CreateComment(comment: CommentModel, id: any) {
  return axios.post(BLOG + '/' + id + '/comments', comment)
  //     .then((response: AxiosResponse<Response<CommentModel>>) => response.data)
  //     .then((response: Response<CommentModel>) => response.data)
  //
}
export async function GetMostReadedBlogs() {
  return axios.get(BLOG + '?most_read&page[size]=10')
}
export async function DeleteBlog(id: any) {
  return await axios.delete(BLOG + '/' + id).then(() => {})
}

export async function UpdateBlog(id: any, blog: any) {
  return await axios.put(BLOG + '/' + id, blog)
}

export async function GetBlogByCategory(id: any, period: string, size: string) {
  return await axios.get(BLOG + '?blog_category_id=' + id + '&period=' + period + size)
}
export async function createAttachment(id: any, attachment: any) {
  return await axios.post(BLOG + '/' + id + '/attachments', attachment, config)
}

export async function updateBanner(id: any, banner: any) {
  return await axios.post(BLOG + '/' + id + '/banner', banner, config)
}
export async function DeleteAttachment(id: any, attachID: string) {
  return await axios.delete<any>(BLOG + '/' + id + '/attachments/' + attachID)
}

export async function GetBlogs(params: any) {
  return await axios.get<any>(BLOG + params)
}

export {getBlog, createBlog}
