import moment from 'moment'
import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}
export const ATItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.case_no}</td>
        <td className='text-gray-500'>{item?.case_title}</td>
        <td className='text-gray-500'>{moment(item?.case_start_date).format('DD.MM.YYYY H:ss')}</td>
        <td className='text-gray-500'>{moment(item?.case_end_date).format('DD.MM.YYYY H:ss')} </td>
        <td className='text-gray-500'>{item?.case_duration} </td>
        <td className='fw-bolder'>{item?.responsible_persons}</td>
        <td className='text-gray-500'>{item?.case_system_resource}</td>
        <td className='text-gray-500'>{item?.affected_side}</td>
        <td className='text-gray-500'>
          {item?.case_detail != null ? item?.case_detail?.slice(0, 50) : ''}
          {item?.case_detail != null && item?.case_detail?.length > 50 ? '...' : ''}{' '}
        </td>
        <td className='text-gray-500'>{item?.actions} </td>
        <td className='fw-bolder'>{item?.action_status}</td>
        <td className='text-gray-500'>{item?.action_owner}</td>
        <td className='text-gray-500'>{moment(item?.action_close_date).format('DD.MM.YYYY')}</td>
        <td className='text-gray-500'>{moment(item?.created_at).format('DD.MM.YYYY H:mm')} </td>
        <td className='text-gray-500'>{item?.created_by?.name} </td>
      </tr>
    </>
  )
}
