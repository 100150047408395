import {CommentModel} from './../models/commentModel'
import {Activity} from './../models/activityModel'
import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_theme/helpers'

const API_URL = process.env.REACT_APP_API_URL
export const ISSUE = `${API_URL}/issues`
export const GET_CATEGORIES = `${API_URL}/issue_categories`
export const GET_PRIORITIES = `${API_URL}/issue_priorities`
export const CREATE_COMMENT = `${API_URL}/issues`
export const GET_SOCIAL_WIDGET = `${API_URL}/widgets/issues/statusByDirectorate`
export const GET_STATICS_WIDGET = `${API_URL}/widgets/issues/status`
export const USERS = `${API_URL}/users`
export const WEEK = `${API_URL}/weeks `

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export async function getActivities(period: string, q: any, assignment_id: any, widgetId: any) {
  if (widgetId == 12)
    return await axios.get<any>(ISSUE + '?period=' + period + q + '&assignment_id=' + assignment_id)
  else
    return await axios.get<any>(ISSUE + '?period=' + period + q + '&assigned_by=' + assignment_id)
}

export async function getIssues(link: string) {
  return await axios.get<any>(ISSUE + link)
}

export async function getIssueById(id: any) {
  return await axios.get<any>(ISSUE + '/' + id)
}
export async function getActivityCategories() {
  return await axios.get<any>(GET_CATEGORIES)
}

export async function getActivityPriorities() {
  return await axios.get<any>(GET_PRIORITIES)
}
export const createIssue = (activity: any): Promise<Activity | undefined> => {
  return axios
    .post(ISSUE, activity, config)
    .then((response: AxiosResponse<Response<Activity>>) => response.data)
    .then((response: Response<Activity>) => response.data)
}

export async function DeleteIssue(id: any) {
  return await axios.delete<any>(ISSUE + '/' + id)
}

export async function UpdateIssue(id: any, issue: any) {
  return await axios.put(ISSUE + '/' + id, issue)
}

export async function CreateComment(comment: CommentModel, id: any) {
  return axios.post(CREATE_COMMENT + '/' + id + '/comments', comment)
  //     .then((response: AxiosResponse<Response<CommentModel>>) => response.data)
  //     .then((response: Response<CommentModel>) => response.data)
  //
}

export async function getSocialWidget() {
  return await axios.get<any>(GET_SOCIAL_WIDGET)
}

export async function getStaticWidget() {
  return await axios.get<any>(GET_STATICS_WIDGET)
}

export async function getUserByAssignment() {
  return await axios.get<any>(USERS)
}

export async function getWeeks() {
  return await axios.get<any>(WEEK)
}
