import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserModel} from '../../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../../components/ModalHeader'
import {AddBudget} from '../../Budget-edit/AddBudget'
type Props = {
  refreshPage: any
}
const BudgetToolbar: FC<Props> = ({refreshPage}) => {
  const [show, setShow] = useState(false)
  const openAddSupModal = () => {
    setShow(true)
  }
  const closeAddSupModal = () => {
    setShow(false)
  }
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {loginUser?.permissions.filter((permission) => permission.name == 'Create Budget').length > 0 && (
          <button type='button' className='btn' onClick={openAddSupModal}>
            Bütçe Ekle
          </button>
        )}

        {/* end::Add user */}
      </div>
      <Modal show={show} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddSupModal} titleHeader={'Yeni Bütçe Ekle'} />
          <AddBudget
            budget={{
              title: '',
              amount: '',
              currency: '',
              assignment: {
                id: 0,
              },
              owner: {
                id: 0,
              },
              type: {
                id: 0,
              },
              category: {
                id: 0,
              },
              company: {
                id: 0,
              },
              purchase_type: {
                id: 0,
              },
              year: '',
            }}
            update={false}
            handleClose={closeAddSupModal}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {BudgetToolbar}
