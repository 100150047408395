import React, {FC, useEffect, useState} from 'react'
import { useRefresh1 } from '../../../OrganizationalStructure/Context/RefreshAssignment'
import {getProjectPlans, getProjectUsers, getTargetCategories} from '../../core/requests'
import {AddMilestoneBody} from './addMilestoneBody'
import {AddTargetBodyTest1} from './addTargetBodyTest'

type Props = {
  target: any
  target_id: any
  handleClose: any
  update: boolean
  project_id: any
  refreshPage?: any
}

export const AddMilestoneHeader: FC<Props> = ({
  target,
  target_id,
  project_id,
  handleClose,
  update,
 
}) => {
  const {refreshPage} = useRefresh1();
  const init = update ? (target?.length > 0 ? target : [target]) : []
  const [targets, setTarget] = useState<any>(init)
  const addTarget = (newTarget: any) => {
    setTarget((current: any) => [...current, newTarget])
  }
  const removeTarget = (oldTarget: any) => {
    setTarget(targets.filter((item: {id: any}) => item?.id !== oldTarget?.id))
  }
  const [users, setUsers] = useState<any>([])
  const [targetCategories, setTargetCategories] = useState<any>([])
  useEffect(() => {
    getProjectPlans().then(({data}) => {
      setTargetCategories(data.data)
    })
  }, [])

  return (
    <div className='row w-100'>
      <div className='card card-flush w-100 p-0' style={{width: '100vw'}}>
        <div className='card-body pt-3 overflow-scroll w-100'>
          <div className='row d-flex border-primary border-bottom-dashed mb-5 pb-3'>
            <div className='col required' style={{color: 'rgb(1 13 88)'}}>Proje Aşaması</div>
            <div className='col  required' style={{color: 'rgb(1 13 88)'}}>Adı</div>
            <div className='col  ' style={{color: 'rgb(1 13 88)'}}>Not</div>
            <div className='col'></div>
          </div>
          <div className=' '>
            {targets?.map((target: any, index: React.Key | null | undefined) => {
              return (
                <>
                  <AddMilestoneBody
                    key={index}
                    setCount={setTarget}
                    target={target}
                    project_id={project_id}
                    users={users}
                    targetCategories={targetCategories}
                    update={true}
                    removeTarget={removeTarget}
                  />
                </>
              )
            })}
          </div>
          {update ? (
            ''
          ) : (
            <div className=' '>
              <AddMilestoneBody
                setCount={addTarget}
                target={target}
                project_id={project_id}
                users={users}
                targetCategories={targetCategories}
                update={update}
              />
            </div>
          )}
        </div>
      </div>
      {/* {targets?.length > 0 && (
        <div className='text-center pt-15'>
          <button className='btn btn-primary'>
            <span
              className='indicator-label'
              onClick={() => {
                refreshPage()
                handleClose()
              }}
            >
              Tamamla
            </span>
          </button>
        </div>
      )} */}
    </div>
  )
}
