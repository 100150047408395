import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import swal from 'sweetalert'
import {SearchComponent} from '../../components/SearchComponent'
type Props = {
  setActionStatus: any
  setActionOwner: any
  handleClose: any
  setSearchKeyClick: any
  setStartDate: any
  setEndDate: any
  setActionCloseDate: any
  setResponsiblePerson: any
}

export const DetailFilters: FC<Props> = ({
  setActionOwner,
  setActionStatus,
  setStartDate,
  setEndDate,
  setActionCloseDate,
  setResponsiblePerson,
  handleClose,
  setSearchKeyClick,
}) => {
  let date = new Date()
  const handleSubmit = async () => {
    setActionOwner(selectedActionOwner)
    setActionStatus(selectedActionStatus)
    setStartDate(selectedStartDate)
    setEndDate(selectedEndDate)
    setActionCloseDate(selectedCloseDate)
    setResponsiblePerson(selectedResponsiblePerson)
    setSearchKeyClick(inputWord)
    handleClose()
  }
  const [inputWord, setInputWord] = useState('')
  const [selectedActionOwner, setSelectedActionOwner] = useState('')
  const [selectedActionStatus, setSelectedActionStatus] = useState('')
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [selectedCloseDate, setSelectedCloseDate] = useState('')
  const [selectedResponsiblePerson, setSelectedResponsiblePerson] = useState('')
  useEffect(() => {}, [])
  return (
    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      {/* <div className='separator border-gray-200'></div> */}
      {/* <div className='px-7 py-5'>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setInputWord(e.target.value)
          }}
        />
      </div> */}
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Action Status</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedActionStatus(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Action Owner</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedActionOwner(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Case Start Date</label>
        <input
          type='date'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedStartDate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Case End Date</label>
        <input
          type='date'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedEndDate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Action Close Date</label>
        <input
          type='date'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedCloseDate(e.target.value)
          }}
        />
      </div>
      <div className='px-7 py-5'>
        <label className='form-label fw-bold'>Responsible Persons</label>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className={`form-control form-control-solid  me-2  'w-300px'`}
          placeholder={'Aranacak kelimeyi giriniz'}
          onChange={(e) => {
            setSelectedResponsiblePerson(e.target.value)
          }}
        />
      </div>
      <div className='d-flex justify-content-center'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleClose}
        >
          İptal
        </button>
        <button
          type='submit'
          className='btn btn-sm'
          data-kt-menu-dismiss='true'
          onClick={handleSubmit}
        >
          Detaylı Ara
        </button>
      </div>
    </div>

    //</form>
  )
}
