import React, {FC, useEffect, useState} from 'react'
import {TimeLineBody} from '../Project/project-details/components/components/TimeLineBody'

import {getTasksByUserId, getUserProjects} from './core/_request'
import {TimeLineHeader} from './TimeLineHeader'

type Props = {
  day: any
  user_id: any
}

export const TimeLine: FC<Props> = ({day, user_id}) => {
  const [tasks, setTasks] = useState<any>([])
  const [selectedProject, setSelectedProject] = useState<any>(0)
  const [activeDay, setActiveDay] = useState(new Date())
  const [count, setCount] = useState(0)
  useEffect(() => {
    getTasksByUserId(user_id).then(({data}) => {
      setTasks(data.data)
    })
  }, [])
  return (
    <div className='card  mb-4'>
      <div className='card-header border-0 pt-5 d-flex'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>Kullanıcının Hedefler Takvimi</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>{projects?.length} yaklaşan toplantı</span> */}
        </h3>
        {/* <div className='card-toolbar'> */}

        {/* <div className='my-0 justyify-content-end'>
          {/* <div className='card-toolbar  pe-5'>
            <a className='btn btn-sm btn-light h-30px'>
              {activeDay.toLocaleDateString('tr').slice(0, 10)}
            </a>
          </div> */}
        {/* <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm form-select-solid w-180px h-35px'
            // onChange={(e) => setSelectedOption(e.target.value)}
          >
            {/* <option value='1' selected>
                Kategori Seçiniz
        //       </option> */}
        {/* //     <option value='' selected>
        //       Proje Seçiniz
        //     </option>
        //     {projects.map((project: any) => { */}
        {/* //       return (
        //         <option key={project.id} value={project.id}>
        //           {project.name}
        //         </option>
        //       )
        //     })}
        //   </select>
        // </div> */}
        {/* </div> */}
      </div>

      <div className='card-body pt-7 px-0'>
        <TimeLineHeader getDate={setActiveDay} days={day} setCount={setCount} user_id={user_id} />
        <TimeLineBody setDate={activeDay.toLocaleDateString('tr').slice(0, 10)} data={tasks} />
      </div>
    </div>
  )
}
