import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import {ProfilePopUp} from '../Profile/ProfilePopUp'

type Props = {
  id: any
  name: string
  email: string
  avatar?: string
}

export const UserItem: FC<Props> = ({id, name, email, avatar}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className='d-flex align-items-center  symbol-circle'>
        <div className='me-5 position-relative'>
          <div className='symbol symbol-35px symbol-circle'>
            {avatar ? (
              <div className='symbol symbol-35px symbol-circle'>
                <img src={avatar} alt={name} className='w-100' />
              </div>
            ) : (
              <div className='symbol-label bg-primary text-inverse-primary fw-bolder'>{name?.substring(0, 1)}</div>
            )}{' '}
          </div>
        </div>

        <div className='fw-bold'>
          <a
            className='fs-7 fw-bolder text-gray-900 text-hover-primary cursor-pointer'
            onClick={async () => {
              setProfilUserId(id)
              await openProfilModal()
            }}
          >
            {name}
          </a>
          <div className='text-gray-400'>{email}</div>
        </div>
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
