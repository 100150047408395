import clsx from 'clsx'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'

type Props = {
  users: any
}

export const Users: FC<Props> = ({users}) => {
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <div className='symbol-group symbol-hover mb-3'>
        {users.map((user: any) => {
          return (
            <>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title={user.name}
                onClick={async () => {
                  setProfilUserId(user.id)
                  await openProfilModal()
                }}
              >
                {user.avatar ? <img alt='Pic' src={user.avatar} /> : <div className={clsx('symbol-label fs-3', 'bg-primary', 'text-white')}>{user.name.substring(0, 1)}</div>}
              </div>
            </>
          )
        })}

        {users?.length > 5 ? (
          <a href='#' className='symbol symbol-35px symbol-circle' data-bs-toggle='modal' data-bs-target='#kt_modal_view_users'>
            <span className='symbol-label bg-dark text-inverse-dark fs-8 fw-bolder' data-bs-toggle='tooltip' data-bs-trigger='hover' title='View more users'>
              {users?.length}
            </span>
          </a>
        ) : (
          ''
        )}
      </div>
      <Modal show={showProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
