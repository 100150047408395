import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {CreatePurchaseStep, UpdatePurchaseStep} from '../core/_requests'
import swal from 'sweetalert'
import {useDispatch} from 'react-redux'
import {setToken} from '../../components/csrf/reducers/CSRFSlice'
import {getCSRFToken} from '../../core/requests'

type Props = {
  handleClose: any
  purchaseSteps: any
  update: boolean
}
export const AddPurchaseSteps: FC<Props> = ({handleClose, purchaseSteps, update}) => {
  const [userForEdit] = useState<any>({
    ...purchaseSteps,
    id: purchaseSteps.id,
    budget_id: purchaseSteps.budget_id,
    sgd_status: purchaseSteps.sgd_status,
    purchase_status: purchaseSteps.purchase_status,
    sgd_request_status: purchaseSteps.sgd_request_status,
    description: purchaseSteps.description,
  })
  const purchaseStepSchema = Yup.object().shape({
    sgd_status: Yup.string().required('Zorunlu alan'),
    purchase_status: Yup.number().required('Zorunlu alan'),
    // sgd_request_status: Yup.string().required('Zorunlu Alan'),
    description: Yup.string().required('Zorunlu alan'),
  })
  let formData = new FormData()
  const dispatch = useDispatch()
  const fetchApiData = async () => {
    await getCSRFToken().then(({data}) => {
      dispatch(setToken(data?.token))
    })
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: purchaseStepSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await fetchApiData()
        if (update) {
          const updPurchaseStep = {
            budget_id: values.budget_id,
            description: values.description,
            sgd_status: values.sgd_dtatus,
            purchase_status: values.purchase_status,
            sgd_request_status: values.sgd_request_status,
          }
          console.log(values)
          await UpdatePurchaseStep(values.id, updPurchaseStep)
            .then(() => {
              swal('Düzenlendi', 'Satın Alma Takibi başarı ile düzenlendi.', 'success', {
                timer: 2000,
              })
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('budget_id', values.budget_id)
          formData.append('description', values.description)
          formData.append('sgd_status', values.sgd_status)
          formData.append('purchase_status', values.purchase_status)
          formData.append('sgd_request_status', values.sgd_request_status)
          await CreatePurchaseStep(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Satın Alma Takibi başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>SGD Durum</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('sgd_status')}
              type='text'
              name='sgd_status'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.sgd_status && formik.errors.sgd_status},
                {
                  'is-valid': formik.touched.sgd_status && !formik.errors.sgd_status,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.sgd_status && formik.errors.sgd_status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sgd_status}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Satın Alma Durumu</label>
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.purchase_status && formik.errors.purchase_status},
                {
                  'is-valid': formik.touched.purchase_status && !formik.errors.purchase_status,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Görevli'
              {...formik.getFieldProps('purchase_status')}
              name='purchase_status'
            >
              <option value='' hidden>
                Status Seçiniz{' '}
              </option>
              <option value='0'>SAT Onayla</option>
              <option value='1'>SAT Onaylandı</option>
              <option value='2'>PO Onaylandı</option>
            </select>
            {formik.touched.purchase_status && formik.errors.purchase_status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.pruchase_status}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Satın Alma Talep Durumu</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('sgd_request_status')}
              type='text'
              name='sgd_request_status'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid':
                    formik.touched.sgd_request_status && formik.errors.sgd_request_status,
                },
                {
                  'is-valid':
                    formik.touched.sgd_request_status && !formik.errors.sgd_request_status,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.sgd_request_status && formik.errors.sgd_request_status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sgd_request_status}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Açıklama</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Açıklama giriniz'
              {...formik.getFieldProps('description')}
              name='description'
              rows={8}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>

            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
