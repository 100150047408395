import {FC} from 'react'
import {Button, Popover} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import swal from 'sweetalert'
import {DeleteAttachment} from '../core/_requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  file: any
  supplier_id: any
  refresh: boolean
  setRefresh: any
}

export const ListFileItem: FC<Props> = ({file, supplier_id, refresh, setRefresh}) => {
  const filename = (param: any) => {
    const icon = param.split('.')[1]
    return icon
  }
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteAttachment(supplier_id, id).then(() => {
          swal('Silindi!', 'Dosya başarı ile silindi.', 'success')
          refresh ? setRefresh(false) : setRefresh(true)
        })
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <tr>
        {/* <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input' type='checkbox' value='1' />
          </div>
        </td> */}

        <td data-order='account' className=''>
          <div className='d-flex align-items-center'>
            <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
              <img
                style={{width: '24px'}}
                src={toAbsoluteUrl(`/media/svg/files/${filename(file.name)}.svg`)}
                alt=''
              />
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z'
                  fill='currentColor'
                />
                <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
              </svg> */}
            </span>

            <a href={file.path} className='text-gray-800 text-hover-primary'>
              {file.name}
            </a>
          </div>
        </td>

        <td>{file.size} KB</td>

        <td className=''>{file.created_by.name}</td>

        {user.id == file.created_by.id ||
        user?.roles?.filter((role: any) => role.name === 'Süper Admin')?.length > 0 ? (
          <td className='text-end'>
            {' '}
            <div
              className='btn'
              onClick={() => {
                swalDelete(file.id)
              }}
              style={{color: 'white'}}
            >
              Sil
            </div>
          </td>
        ) : (
          ''
        )}
      </tr>
    </>
  )
}
